const defaultState = () => ({
  loading: false,
  saving: false,
  archivedPlans: [],
  plans: [],
  plan: null,
})
const excludeFields = ['loading', 'saving']

export { defaultState, excludeFields }

export default defaultState
