// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue'
import App from './App.vue'
/* ============
 * Plugins
 * ============
 *
 * Import and bootstrap the plugins.
 */
import store from '@/store'
import Axios from '@/plugins/axios'
import router from '@/plugins/vue-router'
import { sync } from 'vuex-router-sync'
import lodash from 'lodash'
import VueTippy from 'vue-tippy'
import dayjs from '@/plugins/dayjs'
import { ToastSetup, ToastOptions, toastInstance } from '@/plugins/toast'
import { VueClipboard, toClipboard } from '@soerenmartius/vue3-clipboard'
import Form from '@/plugins/form'
import Filters from '@/services/Filters'
import DatePicker from '@/plugins/date-picker'
import VuePapaParse from 'vue-papa-parse'
import VueForceNextTick from 'vue-force-next-tick'
import VuePlyr from 'vue-plyr'
import Cropper from 'cropperjs'
import VueTelInput from 'vue-tel-input'
import LoadState from '@/store/plugins/LoadState'
import LoadPersistedAuthUser from '@/store/plugins/LoadPersistedAuthUser'
import Pagination from '@/plugins/pagination'
import EventBus from '@/plugins/event-bus'
import Sleep from '@/plugins/sleep'
import RegisterBaseComponents from '@/services/BaseComponents'
import MailTo from '@/plugins/mailto'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import { domainBroadcastId, domainBroadcastChannel } from "./plugins/domain-broadcast-channel"
import * as Sentry from "@sentry/vue";

/* ============
 * Styling
 * ============
 *
 * Import the application styling.
 * SCSS is used as a preprocessor.
 */

import '@/assets/scss/app.scss'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-toward.css'
import 'tippy.js/animations/shift-toward-subtle.css'
import 'tippy.js/animations/shift-toward-extreme.css'
import 'flatpickr/dist/flatpickr.css'
import 'vue-plyr/dist/vue-plyr.css'

/* ============
 * Config updates to Vue
 * ============
 *
 * Manually set onfig items here.
 */

window.onerror = function (message, source, lineno, colno, error) {
  console.log('Exception: ', message, source, lineno, colno, error)
}

LoadState().then(() => {

  LoadPersistedAuthUser().then(() => {

    const app = createApp(App)
    app.config.unwrapInjectedRef = true
    app.config.errorHandler = (error, vm, info) => {
      if (import.meta.env.PROD && info === 'localStorage') {
        return
      }

      if (error === undefined) {
        return
      }

      if (typeof error === 'string') {
        error = new Error(error)
      }

      error.vue = JSON.stringify({
        type: info,
        component: lodash.get(vm.$.vnode, 'componentOptions.tag', 'unknown'),
        route: lodash.pickBy(vm.$.route, function (value, key) {
          return key !== 'matched'
        }),
      })

      if (!import.meta.env.PROD) {
        console.error(error)
      }
      if (import.meta.env.VITE_APP_LOG_ERRORS === 'true') {
        store.dispatch('app/logErrors', error)
      }
    }

    app.config.globalProperties.$http = Axios
    app.config.globalProperties.$bus = EventBus
    app.config.globalProperties.$paginate = Pagination
    app.config.globalProperties.$sleep = Sleep
    app.config.globalProperties.lodash = lodash
    app.config.globalProperties.$form = (options) => new Form(options)
    app.config.globalProperties.$mailto = MailTo
    app.config.globalProperties.$moment = dayjs
    app.config.globalProperties.$copyText = toClipboard
    app.config.globalProperties.$toast = toastInstance

    // Setting a domain-wide broadcast channel for communication between multiple tabs
    // const domainBroadcastChannel = new BroadcastChannel('wf_domain_channel');
    // const domainBroadcastId = Math.floor(Math.random() * 10000) + 1;

    // const domainBroadcastChannel = new BroadcastChannel('wf_domain_channel');
    // const domainBroadcastId = Math.floor(Math.random() * 10000) + 1;

    app.config.globalProperties.$domainBroadcastChannel = domainBroadcastChannel
    app.config.globalProperties.$domainBroadcastId = domainBroadcastId
    // localForageAuthService.setItem('auth.domain_broadcast_master', data.access_token);


    app.use(VueTippy, {
      defaultProps: {
        directive: 'tippy',
        component: 'tippy',
        arrow: true,
        inertia: true,
        allowHTML: true,
        icon: true,
        boundary: 'viewport',
        duration: [120, 80],
        animation: 'shift-toward',
        onShow: (options) => {
          return !!options.props.content
        },
      },
    })
    app.use(ToastSetup, ToastOptions)
    app.use(router)
    app.use(store)
    app.use(RegisterBaseComponents)
    app.use(Filters)
    app.use(VuePapaParse)
    app.use(VueForceNextTick)
    app.use(VueClipboard)
    app.use(VuePlyr)
    app.use(VueTelInput)
    app.component('DatePicker', DatePicker)
    app.component('Cropper', Cropper)
    app.component('v-select', vSelect)

    // BaseComponents.register(app)
    // Filters.register(app)

    sync(store, router)

    Sentry.init({
      app,
      environment: import.meta.env.VITE_ENV,
      dsn: "https://045c7460bbd6437967ac0e8dc510fc42@o4507296052150272.ingest.us.sentry.io/4507296086753280",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });


    app.mount('body')
    if (import.meta.env.DEV) {
      window.vue = app
    }
  })



})
