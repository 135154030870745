<template>
  <div :class="[questionClass]">
    <row
      class="no-gutters font-xs"
      :class="[rowsClass]"
      :col-class="['d-flex', colsClass].join(' ')"
    >
      <span class="font-weight-light mr-1">Threat:</span>
      <span
        v-tippy
        :content="question.threat.summary"
        class="cursor-pointer"
      >
        {{ question.threat.name }}
      </span>
      <span class="font-weight-light ml-auto mr-1">Category:</span>{{ question.category.name }}
    </row>
    <row-divider
      class="no-gutters"
      :class="[rowsClass]"
    />
    <div
      class="question mb-3 font-sm font-italic"
      :class="[colsClass]"
      v-html="
        `<span class='text-secondary'>${question.tempid} | </span>${question.question}${
          question.question.options_message ? `<br>${question.question.options_message}` : ``
        }`
      "
    />
    <div
      v-if="question.parent"
      class="nmt-3 mb-3 font-xs font-italic text-secondary"
      :class="[colsClass]"
    >
      This question is a subquestion of id: {{ question.parent.tempid }}
    </div>
    <div
      v-if="!hideActions && showRemainingEntities && isAdmin"
      class="mb-2 px-3 d-flex justify-content-end align-items-center"
      :class="[colsClass]"
    >
      <span
        v-if="!remainingEntities.length"
        class="line-height-1 font-xs text-success-dark"
      >
        <icon iconic="circle-check font-xxs mr-1" />
        All Entites Answered
      </span>
      <btn
        v-else
        iconic="warning font-xxs align-bottom mr-1"
        class="line-height-1 btn-xs nohover text-primary"
        @click="
          $router.push({
            name: 'questions.answer',
            params: {
              questions: question.id,
              topic: question.id,
              question: question.id,
              entity: false,
            },
          })
        "
      >
        {{ `${remainingEntities.length} Unanswered ${$filters.pluralize('Entity', remainingEntities.length > 1)}` }}
      </btn>
      <btn
        v-if="!isComplete && !remainingEntities.length && incompleteEntities > 0"
        iconic="lock-locked font-xxs align-bottom"
        class="line-height-1 btn-xs nohover text-red ml-2"
        @click="$router.push({ name: 'entities.index' })"
      >
        However, {{ incompleteEntities }} Locked
        {{ $filters.pluralize('Entity', incompleteEntities) }}
        {{ incompleteEntities > 1 ? 'have' : 'has' }} not answered yet.
      </btn>
    </div>
  </div>
</template>

<script>
import { QuestionMethods } from '@/mixins/helpers/Questions'
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [QuestionMethods],
  props: {
    question: {
      type: Object,
      required: true,
    },
    showRemainingEntities: {
      type: Boolean,
      default: false,
    },
    rowsClass: {
      type: String,
      default: '',
    },
    colsClass: {
      type: String,
      default: '',
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    questionClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
    }),
    ...mapState('app', {
      notifications: 'notifications',
    }),
    isComplete() {
      return this.lodash.get(this.question, 'completion_meta.isComplete')
    },
    remainingEntities() {
      return this.lodash.get(this.question, 'completion_meta.remainingEntities', [])
    },
    incompleteEntities() {
      return this.lodash.get(this.question, 'completion_meta.incomplete', 0)
    },
    canAnswer() {
      return this.isAdmin
        ? true
        : !!this.notifications.filter(
            (n) =>
              ['question', 'collaboration'].includes(n.task_type) &&
              this.question.id === n.task_type_id &&
              n.completed === 0
          ).length
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
.question {
  :deep(ul) {
    margin: 0;
  }
}
</style>
