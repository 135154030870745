<template>
  <footer
    class="d-flex font-xs mt-auto align-items-center flex-shrink-1"
    :class="{
      'border-danger bg-danger-highlight': isAdmin && hasFeature('divisions') && !divisions.length,
    }"
  >
    <template v-if="authenticated && cachePages && (companyNav || !isProduction)">
      <span v-html="welcomeUser" />
      <span
        v-if="!organization"
        class="ml-auto text-muted"
        v-html="`No Company Selected`"
      />

      <span
        v-if="organization"
        class="ml-auto text-muted"
        v-html="`${userOrganizationCount > 1 ? `Selected ` : ``}Company &#x2794;`"
      />
      <btn
        v-if="userOrganizationCount > 1 && organization"
        class="btn-sm ml-1 font-weight-bold"
        @click.prevent="$router.push({ name: 'profile.company' })"
      >
        {{ lodash.get(organization, 'name') }}
      </btn>
      <span
        v-else-if="organization"
        class="ml-1 font-weight-bold text-muted"
        v-html="lodash.get(organization, 'name')"
      />
      <template v-if="hasFeature('divisions') && isAdmin">
        <span
          v-if="divisions.length"
          class="text-muted mx-1"
          v-html="`&#x2794; Selected Division &#x2794;`"
        />
        <btn
          v-if="!!divisions.length"
          class="btn-sm ml-1 font-weight-bold"
          @click.prevent="$bus.emit('change-division', false)"
        >
          {{ selectedDivision.name }}
        </btn>
        <span
          v-else
          class="text-error font-weight-bold ml-2"
        >
          <span class="flash-text"> WARNING: </span>
          You have not been assigned any divisions.
          <a
            :href="`mailto:${lodash.get(organization, 'primary_administrator.email')}`"
            target="_blank"
            class="text-error mx-1 text-underline"
          >
            Email {{ lodash.get(organization, 'primary_administrator.full_name') }}
          </a>
          for assistance.
        </span>
      </template>
    </template>
    <template v-else-if="!authenticated">
      Made in Nashville by
      <a
        href="https://www.wavefire.com"
        class="text-dark mx-1"
      >
        WaveFire, LLC
      </a>
      &copy; {{ $moment().local().year() }}
      <nav class="nav nav-light ml-auto justify-content-right">
        <a
          href="https://www.wavefire.com/contact/"
          class="nav-link"
        >
          <icon
            iconic="chat"
            class="text-primary bg-light mr-1"
          />
          Contact Us
        </a>
        <a
          href="https://www.wavefire.com/privacy-policy/"
          class="nav-link"
        >
          Privacy
        </a>
        <a
          href="https://www.wavefire.com/terms-of-use/"
          class="nav-link"
        >
          Terms of Use
        </a>
      </nav>
    </template>
  </footer>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import * as SentryPlugin from "@/plugins/sentry-plugin"

export default {
  props: {
    cachePages: {
      type: Boolean,
      required: true,
    },
    companyNav: {
      type: Boolean,
      required: true,
    },
    isProduction: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      timer: null,
      hour: 0,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapState('company', {
      organization: 'organization',
    }),
    ...mapGetters({
      selectedDivision: 'company/selectedDivision',
      authenticated: 'auth/authenticated',
      isAdmin: 'auth/isAdmin',
      isPrimaryAdmin: 'auth/isPrimaryAdmin',
      hasFeature: 'company/hasFeature',
      divisions: 'auth/myDivisions',
      userOrganizationCount: 'auth/getUserOrganizationCount',
    }),
    greeting() {
      if (this.hour > 4 && this.hour < 12) {
        return 'Good Morning'
      } else if (this.hour >= 12 && this.hour < 17) {
        return 'Good Afternoon'
      } else {
        return 'Good Evening'
      }
    },
    welcomeUser() {
      return `${this.greeting} ${this.lodash.get(this.user, 'first_name')}`
    },
  },
  mounted() {
    this.hour = this.$moment().hour()
    this.timer = setInterval(() => {
      this.hour = this.$moment().hour()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
  methods: {

  }
}
</script>
<style
  lang="scss"
  scoped
>
footer {
  border-top: 1px solid $gray-300;
  background: $light;
  height: 1.5rem;
  line-height: 1.5rem;
  padding-left: 15px;
  padding-right: 15px;
  color: $gray-700;
}
.flash-text {
  animation: blinker 1.25s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}
</style>
