export const LOADFAQS = 'LOADFAQS'
export const LOADING = 'LOADING'
export const FAQSLOADING = 'FAQSLOADING'
export const PUT = 'PUT'
export const RESET = 'RESET'

export default {
  LOADFAQS,
  LOADING,
  FAQSLOADING,
  PUT,
  RESET,
}

export const noPersist = [LOADING, FAQSLOADING]
