<template>
  <transition
    name="modal"
    type="animation"
    appear
    :duration="300"
    @before-enter="beforeEnter"
    @after-leave="afterLeave"
  >
    <div
      v-if="show"
      class="modal"
      :class="{ 'has-footer': !!$slots.footer }"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog"
        role="document"
        :class="[size ? size : '']"
        :style="addWidth"
      >
        <div
          class="modal-content border-0"
          :class="contentClass"
          :style="contentStyle"
        >
          <div
            v-if="!!$slots.header"
            class="modal-header"
            :class="headerClass"
          >
            <slot name="header" />
            <btn
              :link="false"
              class="close"
              :class="closeClass"
              :disabled="disableClose"
              @click="close"
            >
              &times;
            </btn>
          </div>
          <div
            v-if="!!$slots.body"
            class="modal-body"
            :class="[
              { 'rounded-bottom': !$slots.footer },
              { 'has-header': !!$slots.header },
              { 'has-footer': !!$slots.footer },
              bodyClass,
            ]"
            :style="bodyStyle"
          >
            <slot name="body" />
          </div>
          <div
            v-if="!!$slots.footer"
            class="modal-footer bg-light"
            :class="footerClass"
          >
            <slot name="footer"></slot>
          </div>
          <SavingDots
            :class="{ 'has-header': !!$slots.header }"
            :saving-class="savingClass"
          />
        </div>
        <slot name="menus" />
      </div>
      <slot name="modal" />
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    width: {
      type: Number,
      default: 500,
    },
    size: {
      type: String,
      default: null,
    },
    headerClass: {
      type: String,
      default: null,
    },
    closeClass: {
      type: String,
      default: null,
    },
    bodyClass: {
      type: String,
      default: null,
    },
    footerClass: {
      type: String,
      default: null,
    },
    contentClass: {
      type: String,
      default: null,
    },
    contentStyle: {
      type: String,
      default: null,
    },
    bodyStyle: {
      type: String,
      default: null,
    },
    savingClass: {
      type: String,
      default: 'flashpulse',
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-modal'],
  data() {
    return {
      ready: false,
      show: true,
      modalId: null,
    }
  },
  computed: {
    ...mapState('app', {
      openModals: 'openModals',
    }),
    addWidth() {
      return !this.size && this.width ? `width:${this.width}px;` : ''
    },
    lastModal() {
      return (
        this.openModals.findLast((modal) => modal.route === this.$route.name) || {
          id: null,
          route: null,
        }
      )
    },
  },
  beforeMount() {
    window.addEventListener('keyup', this.keyClose)
  },
  unmounted() {
    window.removeEventListener('keyup', this.keyClose)
  },
  methods: {
    ...mapActions({
      addModal: 'app/addModal',
      removeModal: 'app/removeModal',
    }),
    setId() {
      this.modalId = Math.floor(Math.random() * (1000000 - 1 + 1) + 1)
    },
    keyClose(event) {
      if (event.which === 27 && this.lastModal.id === this.modalId) {
        this.close()
      }
    },
    beforeEnter() {
      this.setId()
      this.addModal({
        id: this.modalId,
        route: `${this.$route.name}`,
      })
      this.ready = true
    },
    afterLeave() {
      this.removeModal(this.modalId).then(() => {
        this.$emit('close-modal')
      })
    },
    async close() {
      this.show = false
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.modal {
  transition-duration: 300ms !important;
  transition-property: opacity;
  opacity: 1;

  .modal-content {
    transform: scale(1);
    transition-delay: 0 !important;
    transition-duration: 300ms !important;
    transition-property: transform !important;
    transition-timing-function: ease-out !important;
  }

  &.modal-enter-from,
  &.modal-leave-to {
    opacity: 0 !important;
  }

  &.modal-enter-from,
  &.modal-leave-to {
    .modal-content {
      transform: scale(1.1);
    }
  }
}
</style>
