import axios from "axios";
import store from "@/store";
import { localForageAuthService } from "../store/plugins/LocalForageAuthService"

// const API_URL = 'https://87090b09-5815-4773-8af5-0c28e5b35279.mock.pstmn.io';
const API_URL = import.meta.env.VITE_APP_API_V2;

axios.defaults.baseURL = API_URL;

// Create an instance for api requests
const AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_V2,

  // `responseType` indicates the type of data that the server will respond with
  // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
  //   browser only: 'blob'
  responseType: "json", // default

  // `responseEncoding` indicates encoding to use for decoding responses (Node.js only)
  // Note: Ignored for `responseType` of 'stream' or client-side requests
  responseEncoding: "utf8",
});

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
// AxiosInstance.defaults.headers.common['Authorization'] = `Bearer ` + store.state.auth.accessToken;

// Alter defaults after instance has been created
// api.defaults.headers.common['Authorization'] = AUTH_TOKEN;

// Interceptor responsible for inserting the auth bearer token (accessToken)
AxiosInstance.interceptors.request.use(async function(config) {

    // Retaining old code here in case we encounter issues retrieving the token directly from local storage
    // const token = store.getters["auth/token"];
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    // return config;

    const accessToken = await localForageAuthService.getItem('auth.access_token');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Axios response inteceptors
AxiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    console.log(error)

    // Return non 401 errors
    if (error.response.status !== 401) {
      return Promise.reject(error);
    } else if (error.response.config.url === "/login") {
      console.log("Error on auth login redirect");
      return Promise.reject(error);
    }

    // Error on token refresh
    else if (error.response.config.url === "/login/refresh") {
      console.log("Error attempting to utilize refresh token");
      router.push("/logout");
      return Promise.reject(error);
    } else {
      // Reject the promise if all else fails

      console.log("======== Axios Service: Generic Promise Rejection ========")
      return Promise.reject(error);
    }
  }
);

function handleException_401() {}

export default AxiosInstance;
