<template>
  <component
    :is="{ ...element }"
    v-for="(element, count) in elements"
    :key="count"
  />
</template>

<script>
export default {
  props: {
    elements: {
      type: Array,
      required: true,
    },
  },
}
</script>
