import { defaultState } from './state'

import {
  SET,
  ADD,
  CONCAT,
  REMOVE,
  UPDATE,
  ERROR,
  RESUME,
  EMPTY,
  LOADING,
  QUESTION,
  SAVING,
  TOPICS,
  STAYPUT,
  OTHERDIVISIONENTITIES,
  RESET,
} from './mutation-types'

export default {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  [SAVING](state, bool) {
    state.saving = bool
  },
  [LOADING](state, bool) {
    state.loading = bool
  },
  [QUESTION](state, question) {
    state.question = question
  },
  [ADD](state, answer) {
    state.answers = state.answers.push(answer)
  },
  [CONCAT](state, answers) {
    state.answers = state.answers.concat(answers)
  },
  [REMOVE](state, answer) {
    state.answers = state.answers.filter((a) => a.id !== answer.id)
  },
  [UPDATE](state, answer) {
    let index = state.answers.findIndex((a) => a.id === answer.id)
    if (index !== -1) state.answers.splice(index, 1, answer)
  },
  [STAYPUT](state, stayput) {
    state.stayput = stayput
  },
  [ERROR](state, error) {
    if (error) {
      if (Array.isArray(error)) {
        state.errors = state.errors.concat(error)
      } else {
        state.errors.push(error)
      }
    } else {
      state.errors = []
    }
  },
  [EMPTY](state) {
    state.answers = []
  },
  [RESUME](state, router) {
    state.resume = router
      ? {
          name: router.name,
          params: router.params,
        }
      : null
  },
  [TOPICS](state, topics) {
    state.topics = topics
  },
  [OTHERDIVISIONENTITIES](state, entities) {
    state.otherDivisionEntities = entities
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
