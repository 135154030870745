<template>
  <header
    class="d-flex flex-shrink-1"
    :class="[routeName, { shadow: hasModal }]"
  >
    <nav class="flex-fill navbar bg-primary navbar-dark navbar-expand py-0 px-3">
      <brand />
      <nav
        v-if="ready"
        :class="['navbar-collapse']"
      >
        <ul
          v-if="organizationId"
          class="navbar-nav navbar-nav-links font-xs"
        >
          <li
            v-if="isAdmin"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'dashboard.index' }"
              class="nav-link"
            >
              Dashboard
            </router-link>
          </li>
          <li
            v-if="!isEmployee"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'entities.index' }"
              class="nav-link"
            >
              My Entities
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'questions.index' }"
              class="nav-link"
            >
              My Questions
            </router-link>
          </li>
          <li
            v-if="isAdmin"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'risks.index' }"
              class="nav-link"
            >
              My Risks
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'action-plans.index' }"
              class="nav-link"
            >
              My Action Plans
            </router-link>
          </li>

<!--          <li class="nav-item" v-show="isNotProduction">-->
<!--            <router-link-->
<!--              :to="{ name: 'document-request-lists.index' }"-->
<!--              class="nav-link"-->
<!--            >-->
<!--              Document Requests-->
<!--            </router-link>-->
<!--          </li>-->
          <li
            v-if="hasFeature('policies')"
            class="nav-item"
          >
            <router-link
              :to="{ name: 'policies.index' }"
              class="nav-link"
            >
              My Policies
            </router-link>
          </li>
          <Setup v-if="isAdmin" />
          <Resume v-if="resumable" />
        </ul>
        <ul
          v-if="user"
          class="navbar-nav ml-auto"
        >
          <notifications v-if="authenticated && organizationId" />
          <profile />
        </ul>
      </nav>
    </nav>
  </header>
</template>

<script>
import Notifications from './Notifications.vue'
import Profile from './Profile.vue'
import Brand from './branding/Brand.vue'
import Setup from './Setup.vue'
import Resume from './Resume.vue'
import { mapState, mapGetters } from 'vuex'
import _, { forEach } from 'lodash'
import Axios from "axios"

export default {

  components: {
    Brand,
    Notifications,
    Profile,
    Setup,
    Resume,
  },

  data() {
    return {
      testData: [],

    }
  },

  props: {
    ready: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      isEmployee: 'auth/isEmployee',
      isAdmin: 'auth/isAdmin',
      isPrimaryAdmin: 'auth/isPrimaryAdmin',
      organizationId: 'auth/getOrganizationId',
      getUserId: 'auth/getUserId',
      resumable: 'answers/resumable',
      hasFeature: 'company/hasFeature',
      activeDivisions: 'company/activeDivisions',
      organizations: 'auth/getUserOrganizations',
      organizationCount: 'auth/getUserOrganizationCount',
    }),
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapState('app', {
      openModals: 'openModals',
    }),
    ...mapState('company', {
      organization: 'organization',
      companyLoading: 'loading',
    }),

    hasModal() {
      return !!this.openModals.filter((modal) => modal.route === this.routeName).length
    },
    routeName() {
      return this.$route.name
    },
  },

  methods: {

    isNotProduction() {
      return false;
      return process.env.VITE_ENV !== 'production';
    }

  },

}
</script>

<style
  lang="scss"
  scoped
>
header {
  z-index: 2000;
}
.navbar {
  .navbar-nav-links {
    :deep(.nav-link) {
      color: $white;
      padding: 0.75rem;
      margin-left: 1rem;
      &:hover,
      &.active,
      &.router-link-active {
        background: rgba(255, 255, 255, 0.25);
      }
    }
  }
}
</style>
