<template>
  <div class="input-group input-group-sm">
    <div class="input-group-prepend">
      <icon
        iconic="magnifying-glass"
        class="input-group-text"
      />
    </div>
    <input
      type="text"
      class="form-control rounded-right pr-4"
      :placeholder="placeholder"
      :value="modelValue"
      @keypress.enter.prevent="$emit('enter', $event.target.value)"
      @input="update"
    />
    <btn
      iconic="circle-x"
      :disabled="!modelValue"
      class="input-group-cancel text-center"
      :class="{ 'text-primary': modelValue }"
      @click="$emit('update:modelValue', null)"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'
export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Search',
      required: false,
    },
    debounce: {
      type: Number,
      default: 200,
    },
  },
  emits: ['enter', 'update:modelValue'],
  methods: {
    update: debounce(function (e) {
      this.$emit('update:modelValue', e.target.value)
    }, 350),
  },
}
</script>
<style
  lang="scss"
  scoped
>
button {
  &.input-group-cancel {
    position: absolute;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1.5rem;
    z-index: 4;
    margin: 0;
    opacity: 0.8;
    &:focus {
      opacity: 1;
    }
    :deep(span) {
      &.iconic {
        left: -1px;
      }
    }
  }
}
</style>
