import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  set({ commit }, data) {
    commit(types.SET, data)
  },

  async loadControls({ commit }, trashed) {
    await commit(types.CONTROLSLOADING, true)
    return await Pagination(`/controls`, trashed ? { 'filter[trashed]': 'with' } : null)
      .then((response) => {
        commit(types.LOADCONTROLS, response)
        return response
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.CONTROLSLOADING, false)
      })
  },

  async loadProcesses({ commit, rootGetters }, trashed) {
    await commit(types.PROCESSESLOADING, true)
    return await Pagination(
      `/organizations/${rootGetters['auth/getOrganizationId']}/control-processes`,
      trashed ? { 'filter[trashed]': 'with' } : null
    )
      .then((response) => {
        commit(types.LOADPROCESSES, response)
        return response
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.PROCESSESLOADING, false)
      })
  },

  async loadFunctions({ commit, rootGetters }, trashed) {
    await commit(types.FUNCTIONSLOADING, true)
    return await Pagination(
      `/organizations/${rootGetters['auth/getOrganizationId']}/control-functions`,
      trashed ? { 'filter[trashed]': 'with' } : null
    )
      .then((response) => {
        commit(types.LOADFUNCTIONS, response)
        return response
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.FUNCTIONSLOADING, false)
      })
  },

  async loadRisks({ commit, rootGetters }, trashed) {
    await commit(types.RISKSLOADING, true)
    return await Pagination(
      `/organizations/${rootGetters['auth/getOrganizationId']}/control-risks`,
      trashed ? { 'filter[trashed]': 'with' } : null
    )
      .then((response) => {
        commit(types.LOADRISKS, response)
        return response
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.RISKSLOADING, false)
      })
  },

  async loadObjectives({ commit, rootGetters }, trashed) {
    await commit(types.OBJECTIVESLOADING, true)
    return await Pagination(
      `/organizations/${rootGetters['auth/getOrganizationId']}/control-objectives`,
      trashed ? { 'filter[trashed]': 'with' } : null
    )
      .then((response) => {
        commit(types.LOADOBJECTIVES, response)
        return response
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.OBJECTIVESLOADING, false)
      })
  },

  async loadCriteria({ commit }) {
    await commit(types.CRITERIALOADING, true)
    return await Pagination(`/regulations`, {
      include: 'entities',
    })
      .then((response) => {
        commit(types.LOADCRITERIA, response)
        return response
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.CRITERIALOADING, false)
      })
  },

  async loadDocumentRequests({ commit }, trashed) {
    await commit(types.DOCUMENTREQUESTSLOADING, true)
    return await Pagination(`/document-requests`, trashed ? { 'filter[trashed]': 'with' } : null)
      .then((response) => {
        commit(types.LOADDOCUMENTREQUESTS, response)
        return response
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.DOCUMENTREQUESTSLOADING, false)
      })
  },
}
