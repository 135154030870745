export const SET = 'SET'
export const EMPTY = 'EMPTY'
export const LOAD = 'LOAD'
export const LOADING = 'LOADING'
export const RESET = 'RESET'

export default {
  SET,
  EMPTY,
  LOAD,
  LOADING,
  RESET,
}

export const noPersist = [LOADING]
