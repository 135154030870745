<template>
  <transition name="submenu">
    <div
      v-if="open"
      class="submenu dropdown-menu border-0 shadow"
    >
      <div
        class="p-3 border-top rounded-top border-left border-right"
        :class="{ 'loading-sm py-5 border-bottom rounded-bottom': loading }"
      >
        <slot v-if="!loading" />
      </div>
      <div
        v-if="!loading"
        class="d-flex border-top px-3 py-2 p-3 border-bottom rounded-bottom border-left border-right"
      >
        <btn
          class="btn-xs"
          :disabled="filtering"
          @click.prevent="$emit('close')"
        >
          Cancel
        </btn>
        <btn
          class="btn-light btn-xs ml-auto"
          :link="false"
          :disabled="filtering"
          @click.prevent="$emit('clear')"
        >
          Clear All
        </btn>
        <btn
          class="btn-xs btn-primary ml-2"
          :link="false"
          :disabled="disableApply"
          :class="{ 'loading pl-4': filtering }"
          @click.prevent="$emit('filter')"
        >
          Apply
        </btn>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    filtering: {
      type: Boolean,
      default: false,
    },
    disableApply: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'clear', 'filter'],
}
</script>

<style
  lang="scss"
  scoped
>
.dropdown-toggle {
  &::after {
    margin-left: 0.5rem;
    margin-top: 0.125rem;
  }
}

.dropdown-menu {
  position: absolute;
  z-index: 50;
  display: block;
  top: 152px;
  left: 6px;
  opacity: 1;
  padding: 0;
  pointer-events: auto;
  transition: all 0.25s ease;
  min-width: 300px;
  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 7px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid rgba(black, 0.5);
  }
  &:after {
    content: '';
    position: absolute;
    margin: 0 0 -1rem 0;
    height: 1rem;
    background: transparent;
    width: 1px;
  }
  &.submenu-enter,
  &.submenu-enter-active,
  &.submenu-leave-active {
    opacity: 0;
    margin-top: 24px;
  }
}

:deep(.autocomplete-list) {
  max-height: 300px;
}
</style>
