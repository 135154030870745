export const SET = 'SET'
export const EMPTY = 'EMPTY'
export const LOAD = 'LOAD'
export const LOADING = 'LOADING'
export const LOADINGASSIGNMENTS = 'LOADINGASSIGNMENTS'
export const LOADINGSECTIONS = 'LOADINGSECTIONS'
export const ASSIGNMENTS = 'ASSIGNMENTS'
export const SECTIONS = 'SECTIONS'
export const RESET = 'RESET'

export default {
  SET,
  EMPTY,
  LOAD,
  LOADING,
  LOADINGASSIGNMENTS,
  LOADINGSECTIONS,
  ASSIGNMENTS,
  SECTIONS,
  RESET,
}

export const noPersist = [LOADING, LOADINGASSIGNMENTS, LOADINGSECTIONS]
