<template>
  <form
    ref="form"
    autocomplete="off"
    @submit.prevent="onSubmit"
    @input="onInput"
  >
    <slot />
  </form>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    method: {
      type: String,
      default: null,
    },
    toastedSuccess: {
      type: String,
      default: null,
    },
    toastedError: {
      type: String,
      default: null,
    },
  },
  emits: ['submitting', 'input', 'submitted', 'success', 'error', 'submit-form'],
  methods: {
    onInput(e) {
      if (this.form) {
        this.form.errors.clear(this.lodash.get(e, 'target.name') || this.lodash.get(e, 'target.dataset.name'))
      }
      this.$emit('input', e)
    },
    onSubmit(e) {
      let hasErrors = false
      if ((this.lodash.get(e, 'type') === 'click' || !!e) && !this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity()
        hasErrors = true
      }
      const requiredEditors = Array.from(this.$refs.form.querySelectorAll('.quillWrapper')).filter(el =>
        !!el.getAttribute('required')
      )
      if (requiredEditors.length) {
        requiredEditors.forEach((el) => {
          this.form.errors.push(el.getAttribute('name'), `The ${el.getAttribute('name')} field is required.`)
        })
        hasErrors = true
      }
      if (hasErrors) {
        return false
      }
      this.$emit('submitting', e)
      this.$nextTick(() => {
        if (this.url && this.method && this.form) {
          this.form
            .submit(this.method, this.url)
            .then((response) => {
              this.$emit('submitted')
              this.$emit('success', response)
              if (this.toastedSuccess) {
                this.$nextTick(() => {
                  this.$toast.success(this.toastedSuccess)
                })
              }
            })
            .catch((error) => {
              this.$emit('submitted')
              this.$emit('error', error)
              if (this.toastedError) {
                this.$nextTick(() => {
                  this.$toast.error(this.toastedError)
                })
              }
            })
        } else {
          this.$emit('submit-form', e)
        }
      })
    },
  },
}
</script>
