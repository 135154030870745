import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  async loadThreatRisks({ commit, rootGetters }, assessmentPeriodId) {
    await commit(types.THREATRISKSLOADING, true)
    await Pagination(`/risks/threats`, {
      'filter[assessment_period_id]': assessmentPeriodId
        ? assessmentPeriodId
        : rootGetters['company/assessmentPeriodId'],
    })
      .then((response) => {
        commit(types.LOADTHREATRISKS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.THREATRISKSLOADING, false)
      })
  },

  async clearThreatRisks({ commit }) {
    await commit(types.LOADTHREATRISKS, [])
  },

  async loadThreatEntityRisks({ commit, rootGetters }, assessmentPeriodId) {
    await commit(types.THREATENTITYRISKSLOADING, true)
    await Pagination(`/risks/threats-entity`, {
      'filter[assessment_period_id]': assessmentPeriodId
        ? assessmentPeriodId
        : rootGetters['company/assessmentPeriodId'],
    })
      .then((response) => {
        commit(types.LOADTHREATENTITYRISKS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.THREATENTITYRISKSLOADING, false)
      })
  },

  async clearThreatEntityRisks({ commit }) {
    await commit(types.LOADTHREATENTITYRISKS, [])
  },

  async loadCategoryRisks({ commit, rootGetters }, assessmentPeriodId) {
    await commit(types.CATEGORYRISKSLOADING, true)
    await Pagination(`/risks/categories`, {
      'filter[assessment_period_id]': assessmentPeriodId
        ? assessmentPeriodId
        : rootGetters['company/assessmentPeriodId'],
    })
      .then((response) => {
        commit(types.LOADCATEGORYRISKS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.CATEGORYRISKSLOADING, false)
      })
  },

  async clearCategoryRisks({ commit }) {
    await commit(types.LOADCATEGORYRISKS, [])
  },

  async loadCategoryEntityRisks({ commit, rootGetters }, assessmentPeriodId) {
    await commit(types.CATEGORYENTITYRISKSLOADING, true)
    await Pagination(`/risks/categories-entity`, {
      'filter[assessment_period_id]': assessmentPeriodId
        ? assessmentPeriodId
        : rootGetters['company/assessmentPeriodId'],
    })
      .then((response) => {
        commit(types.LOADCATEGORYENTITYRISKS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.CATEGORYENTITYRISKSLOADING, false)
      })
  },

  async clearCategoryEntityRisks({ commit }) {
    await commit(types.LOADCATEGORYENTITYRISKS, [])
  },

  async loadCategoryThreatRisks({ commit, rootGetters }, assessmentPeriodId) {
    await commit(types.CATEGORYTHREATRISKSLOADING, true)
    await Pagination(`/risks/categories-threat`, {
      'filter[assessment_period_id]': assessmentPeriodId
        ? assessmentPeriodId
        : rootGetters['company/assessmentPeriodId'],
    })
      .then((response) => {
        commit(types.LOADCATEGORYTHREATRISKS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.CATEGORYTHREATRISKSLOADING, false)
      })
  },

  async clearCategoryThreatRisks({ commit }) {
    await commit(types.LOADCATEGORYTHREATRISKS, [])
  },

  async loadCategoryThreatEntityRisks({ commit, rootGetters }, assessmentPeriodId) {
    await commit(types.CATEGORYTHREATENTITYRISKSLOADING, true)
    await Pagination(`/risks/categories-entity-threat`, {
      'filter[assessment_period_id]': assessmentPeriodId
        ? assessmentPeriodId
        : rootGetters['company/assessmentPeriodId'],
    })
      .then((response) => {
        commit(types.LOADCATEGORYTHREATENTITYRISKS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.CATEGORYTHREATENTITYRISKSLOADING, false)
      })
  },

  async clearCategoryThreatEntityRisks({ commit }) {
    await commit(types.LOADCATEGORYTHREATENTITYRISKS, [])
  },

  async clearAllRisks({ dispatch }) {
    await dispatch('clearThreatRisks')
    await dispatch('clearThreatEntityRisks')
    await dispatch('clearCategoryRisks')
    await dispatch('clearCategoryEntityRisks')
    await dispatch('clearCategoryThreatRisks')
    await dispatch('clearCategoryThreatEntityRisks')
  },

  async set({ commit }, data) {
    await commit(types.SET, data)
  },
}
