<template>
  <FormGroup>
    <FormLabel />
    <div
      class="input-group"
      :class="[groupClass, size ? `input-group-${size}` : null]"
    >
      <input
        ref="element"
        type="range"
        :class="['form-control-range', elclass]"
        :name="name"
        :value="modelValue"
        :step="step"
        :min="min"
        :max="max"
        :readonly="readonly"
        :disabled="disabled"
        :required="required"
        :list="!!list ? `${this.name}-tickmarks` : null"
        @change="$emit('update:modelValue', parseFloat($event.target.value))"
      />
      <datalist
        v-if="!!list"
        :id="`${this.name}-tickmarks`"
        class="d-flex justify-content-between font-xxs flex-fill"
      >
        <option
          v-for="tick in tickMarks"
          :value="tick"
          :label="tick"
          style="min-width: 1rem; text-align: center;"
        />
      </datalist>
    </div>
  </FormGroup>
</template>
<script>
import FormMixins from '@/mixins/FormMixins'
export default {
  mixins: [FormMixins],
  props: {
    step: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    list: {
      type: Number,
      required: false,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    tickMarks () {
      let ticks = []
      let ticksCount = this.list ? Math.floor(this.max / this.list) : 0
      while (ticksCount >= 0) {
        ticks.push(this.list * ticksCount)
        ticksCount--
      }

      return ticks.sort((a, b) => a - b)
    }
  }
}
</script>
<style
  lang="scss"
  scoped
></style>
