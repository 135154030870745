<template>
  <FormGroup>
    <FormLabel />
    <div class="date-picker position-relative">
      <date-picker
        v-model="localModel"
        :class="[{ 'd-none': inline }, 'rounded', elclass, size ? `form-control-${size}` : null]"
        :name="name"
        :config="dateConfig"
        :events="events"
        @on-change="onChange"
        @on-ready="onReady"
      />
      <icon
        v-if="!inline"
        iconic="calendar"
        title="Toggle"
        data-toggle
      />
    </div>
  </FormGroup>
</template>
<script>
import FormMixins from '@/mixins/FormMixins'
export default {
  name: 'FormDate',
  mixins: [FormMixins],
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      localModel: this.modelValue ? this.modelValue : null,
      events: ['onChange', 'onReady'],
      dateConfig: {
        inline: this.inline,
        wrap: !this.inline,
        defaultDate: this.modelValue ? this.modelValue : null,
      },
    }
  },
  beforeMount() {
    if (this.min) {
      this.dateConfig.minDate = this.min
    }
    if (this.max) {
      this.dateConfig.maxDate = this.max
    }
  },
  methods: {
    onChange(dates, date) {
      this.$emit('update:modelValue', date)
    },
    onReady(dates, date, { _input }) {
      _input.value = this.modelValue ? this.modelValue : null
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
.date-picker {
  :deep(.iconic-calendar) {
    color: $info-gray;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    margin-top: -7px;
    z-index: 3;
    transition: color 0.15s ease-in-out;
  }
  :deep(input) {
    background-color: #fff;
    cursor: pointer;
    &:focus + .iconic-calendar {
      color: #7aa1ca !important;
    }
  }
  .iconic-calendar:hover {
    cursor: pointer;
    color: #7aa1ca !important;
  }
}
</style>
