<template>
  <modal
    :class="{ loading: loading }"
    body-class="font-sm p-0"
    size="modal-lg"
    @close-modal="$emit('close')"
  >
    <template #header>
      <h5>
        Entity Summary
        <span class="font-weight-light">| {{ lodash.get(entity, 'name', 'Loading...') }}</span>
      </h5>
    </template>
    <template #body>
      <div
        v-if="entity"
        class="row no-gutters"
      >
        <div class="col-5 border-right px-0 py-3">
          <p class="px-4 pb-3 border-bottom">
            <span class="font-weight-light font-xs d-block">Description</span>
            {{ entity.description || `No description provided` }}
          </p>
          <p
            class="px-4 pb-3 border-bottom"
          >
            <span class="font-weight-light font-xs d-block"> Managed by </span>
            <a
              v-if="entity.manager"
              :href="`mailto:${entity.manager.email}`"
              target="_blank"
            >
              {{ entity.manager.full_name }}
            </a>
            <template v-else>
              N/A
            </template>
          </p>
          <p
            class="px-4 pb-3 border-bottom"
          >
            <span class="font-weight-light font-xs d-block">Location</span>
            {{ entity.location || 'N/A' }}
          </p>
          <p
            class="px-4 mb-0"
          >
            <span class="font-weight-light font-xs d-block">Reporting Group</span>
            {{ lodash.get(entity, 'group.name', 'N/A') }}
          </p>
        </div>
        <div class="col px-0 py-3 d-flex flex-column">
          <p class="px-4">
            <span class="font-weight-light font-xs d-block">Assets</span>
            <span
              v-for="(asset, index) in entity.assets"
              :key="index"
              class="badge badge-medium-gray mt-1 mr-2 font-weight-normal font-xs"
            >
              {{ asset.asset.name }} ({{ asset.count }})
            </span>
            <span v-if="!entity.assets.length"> N/A </span>
          </p>
          <p class="mt-auto mb-0 border-top pt-3 px-4">
            <span class="font-weight-light font-xs d-block">Tags</span>
            <span
              v-for="(tag, index) in entity.tags"
              :key="index"
              class="badge badge-primary text-white mt-1 mr-2 font-weight-normal"
            >
              {{ tag.name }}
            </span>
            <span v-if="!entity.tags.length"> N/A </span>
          </p>
        </div>
      </div>
      <div
        v-else
        class="p-4"
      />
    </template>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    preloadedEntity: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      entity: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      getOrganizationId: 'auth/getOrganizationId',
    }),
  },
  beforeMount() {
    if (this.preloadedEntity) {
      this.entity = this.preloadedEntity
    } else {
      this.loadEntity()
    }
  },
  methods: {
    loadEntity() {
      this.loading = true
      this.$http.get(`/organizations/${this.getOrganizationId}/entities/${this.entityId}`).then((response) => {
        this.entity = response.data
        this.loading = false
      })
    },
  },
}
</script>
