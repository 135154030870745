<script>
import ActionButton from './ActionButton.vue'
export default {
  props: {
    notify: {
      type: Boolean,
      default: false,
    },
  },
  extends: ActionButton,
  emits: ['back'],
  data() {
    return {
      direction: 'left',
      actionClass: 'btn-back',
    }
  },
  methods: {
    onClick() {
      if (this.notify) {
        this.$emit('back')
      } else {
        this.$router.back()
      }
    },
  },
}
</script>
