/**
 * Entity Helpers.
 * These methods help manage the state of entities.
 */
import { get } from 'lodash'
import moment from '@/plugins/dayjs'
import store from '@/store'

/**
 * Getters will return an object or collection of objects the match the methods arguments
 */

const getters = {
  entityCompletedAt(entityId) {
    return moment
      .utc(moment.max(...this.getEntity(entityId).answers.map((answer) => moment.utc(answer.updated_at))))
      .toISOString()
  },

  getEntity(entityId) {
    return (
      store.state.entities.entities.find((e) => e.id === entityId) || {
        answered: [],
        assessment_locks: [],
      }
    )
  },

  getEntities() {
    return store.state.entities.entities
  },

  getEntityManager(entityId) {
    return get(this.getEntity(entityId), 'manager.full_name', 'No Manager')
  },

  getEntitiesByManager(managerId) {
    return this.getActiveEntities().filter((entity) => entity.manager_id === managerId)
  },

  getEntityLocks(entityId) {
    return this.getEntity(entityId).assessment_locks
  },

  getLockedEntities() {
    return this.getEntities().filter((e) => e.is_current_assessment_locked)
  },

  getLockedEntitiesForQuestion(questionId) {
    return this.getEntities().filter((e) => {
      return e.is_current_assessment_locked && this.requiresAnswer(e.id, questionId) && !this.answered(e.id, questionId)
    })
  },

  getActiveEntities() {
    return store.state.entities.entities.filter((entity) => entity.deleted_at === null)
  },

  getEntityRiskRank(rank, wfrs) {
    return this.getActiveEntities().filter(
      (entity) => (wfrs ? entity.score.wfrs_risk_rank : entity.score.risk_rank) === rank
    )
  },

  getEntityQuestionMeta(entityId) {
    return (
      this.getEntity(entityId).question_meta || {
        answeredQuestions: [],
        assignedQuestions: [],
        childExclusions: [],
        collaborationsAnswered: [],
        collaborationsNeedsAnswer: [],
        assignedCollaborationQuestions: [],
        excludedQuestions: [],
        needsAnswer: [],
        rawAssignments: [],
        requireAnswer: [],
        topics: [],
      }
    )
  },

  entityFrameworks(entityId) {
    return (
      this.getActiveEntities().find((e) => e.id === entityId) || {
        protocols: [],
      }
    ).protocols.map((p) => p.id)
  },

  entityGroup(entityId) {
    return (
      this.getEntity(entityId).group || {
        created_at: null,
        created_by: null,
        deleted_at: null,
        deleted_by: null,
        id: null,
        manager_id: null,
        name: null,
        organization_id: null,
        updated_at: null,
        updated_by: null,
      }
    )
  },

  entityAssets(entityId) {
    return (this.getEntity(entityId).assets || []).map((asset) => asset.asset)
  },
}

/**
 * Counts will return the number of matching objects
 */

const counts = {
  entityCount() {
    return store.state.entities.entities.length
  },
  activeEntityCount() {
    return this.getActiveEntities().length
  },
}

/**
 * Tests checks a condition or state and always returns true/false
 */

const tests = {
  isEntityManager(entityId) {
    return get(entityId, 'manager_id', this.getEntity(entityId).manager_id) === store.getters['auth/getUserId']
  },

  isEntityAssessmentComplete(entityId) {
    return (
      !this.getEntityQuestionMeta(entityId).needsAnswer.length &&
      !!this.getEntityQuestionMeta(entityId).requireAnswer.length
    )
  },

  isEntityAssessmentLocked(entityId) {
    return this.getEntity(entityId).is_current_assessment_locked
  },

  canEditEntity(entity) {
    if (
      store.getters['auth/isSysAdmin'] ||
      (store.getters['auth/isPrimaryAdmin'] && entity.organization_id === store.getters['auth/getOrganizationId'])
    ) {
      return true
    } else {
      return false
    }
  },

  hasRiskProfiles(entity) {
    return (
      (entity.protocols || []).length &&
      (entity.protocols || []).length ===
        (entity.protocols || []).filter((protocol) => (protocol.pivot || { risk_profile_id: null }).risk_profile_id)
          .length
    )
  },
}

const EntityMethods = Object.assign(getters, counts, tests)

export default EntityMethods

export const EntitiesMethods = {
  methods: EntityMethods,
}
