const defaultState = () => ({
  loading: false,
  loadingAssignment: false,
  loadingSections: false,
  entities: [],
  sections: {},
  assignments: {},
})

const excludeFields = ['loading', 'loadingAssignment', 'loadingSections']

export { defaultState, excludeFields }

export default defaultState
