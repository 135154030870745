import { defaultState } from './state'

import { LOADFAQS, LOADING, FAQSLOADING, PUT, RESET } from './mutation-types'

export default {
  [FAQSLOADING](state, bool) {
    state.faqsLoading = bool
  },
  [LOADING](state, bool) {
    state.loading = bool
  },
  [LOADFAQS](state, faqs) {
    state.faqs = faqs || []
  },
  [PUT](state, faq) {
    state.faq = faq
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
