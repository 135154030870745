import localforage from 'localforage'
import { extendPrototype } from 'localforage-getitems'
// https://netterminalmachine.com/blog/2018/persisting-a-vuex-store-to-indexed-db

extendPrototype(localforage)

export const localForageService = localforage.createInstance({
  name: 'wavefire',
  version: 1.0,
  storeName: 'dashboard',
})

localForageService.setDriver([localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE]).catch(() => {
  //console.log('welp. you can\'t have nice things.')
})

export default {
  localforage,
  localForageService,
}
