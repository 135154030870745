<template>
  <div class="input-group input-group-sm filter-search">
    <input
      ref="control"
      type="text"
      class="form-control rounded-0 border-top-0 border-left-0 border-right-0"
      :class="inputClass"
      :disabled="inputDisabled"
      :placeholder="placeholder"
      :value="modelValue"
      @input="update"
    />
    <icon
      v-if="!modelValue"
      iconic="magnifying-glass"
      class="text-info-gray"
    />
    <btn
      v-if="modelValue"
      iconic="circle-x"
      @click="$emit('update:modelValue', null)"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'
export default {
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    inputClass: {
      type: String,
      default: null,
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    update: debounce(function (e) {
      this.$emit('update:modelValue', e.target.value)
    }, 350),
  },
}
</script>

<style
  lang="scss"
  scoped
>
.font-xs {
  &.input-group-sm {
    input.form-control {
      font-size: 0.75rem;
    }
  }
}
.filter-search {
  position: relative;
  .iconic-magnifying-glass {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 4;
  }
  button {
    position: absolute;
    right: 0.5rem;
    top: 0.2rem;
    z-index: 4;
    color: #7aa1ca;
  }
  input:focus + .iconic-magnifying-glass {
    color: #7aa1ca !important;
  }
}
</style>
