<template>
  <div class="dropdown d-flex">
    <btn
      class="dropdown-toggle text-white font-xs flex-fill nav-link"
      :class="{ open: open }"
      @click="open = !open"
      v-html="`Answering Session`"
    />
    <transition name="submenu">
      <div
        v-if="open"
        class="submenu dropdown-menu dropdown-menu-right show"
      >
        <h6 class="dropdown-header py-1">{{ progress }}% Complete</h6>
        <div class="dropdown-divider" />
        <btn
          :link="false"
          class="dropdown-item btn-sm"
          @click="resumeAnswering"
          v-html="`Resume Answering`"
        />
        <btn
          :link="false"
          class="dropdown-item btn-sm"
          @click.prevent="cancelAnswering"
          v-html="`Cancel Remaining`"
        />
        <div class="dropdown-divider" />
        <h6 class="font-xs px-4 text-center text-nowrap mb-0">
          Completed <b class="mx-1">{{ completedQuestions.length }}</b> of
          <b class="mx-1">{{ selectedTopics.length }}</b> selected topics
        </h6>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      open: false,
    }
  },
  computed: {
    ...mapGetters({
      progress: 'answers/progress',
      completedQuestions: 'answers/completedQuestions',
    }),
    ...mapState('answers', {
      questions: 'questions',
      selectedTopics: 'selectedTopics',
      returnToEntity: 'returnToEntity',
      returnToPlan: 'returnToPlan',
    }),
  },
  mounted() {
    this.$root.$el.addEventListener('click', this.closeOnClick)
  },
  beforeUnmount() {
    this.$root.$el.removeEventListener('click', this.closeOnClick)
  },
  methods: {
    ...mapActions({
      resume: 'answers/resume',
      clear: 'answers/clear',
    }),
    closeOnClick(event) {
      if (this.open && !this.$el.contains(event.target)) {
        this.open = !this.open
      }
    },
    resumeAnswering() {
      this.open = false
      this.resume()
    },
    cancelAnswering() {
      this.open = false
      if (this.$route.name === 'questions.answer') {
        this.exitAnswers()
      } else {
        this.clear()
      }
    },
    exitAnswers() {
      if (this.returnToPlan) {
        this.$router
          .push({
            name: 'action-plans.view',
            params: {
              planId: this.returnToPlan,
            },
          })
          .then(this.clear)
      } else if (this.returnToEntity) {
        this.$router
          .push({
            name: 'entities.index',
            params: {
              entityId: this.returnToEntity,
            },
          })
          .then(this.clear)
      } else {
        this.$router.push({ name: 'questions.index' }).then(this.clear)
      }
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
.dropdown-toggle {
  background: $dark-highlight;
  box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.18);
  &:hover,
  &.open {
    background: rgba($white, 0.15) !important;
    box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.25);
  }
  &:after {
    transition: all 0.25s ease 0.1s;
    transform: scaleY(1);
    margin-bottom: -1px;
    margin-left: 0.25rem;
  }
  &.open {
    &:after {
      transform: scaleY(-1);
    }
  }
}
.dropdown-menu {
  margin-top: 4px;
  transition: all 0.25s ease;
  opacity: 0.92;
  &::before {
    content: '';
    position: absolute;
    top: -9px;
    right: 7px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid rgba(black, 0.5);
  }
  &.submenu-enter,
  &.submenu-enter-active,
  &.submenu-leave-active {
    opacity: 0;
    margin-top: 24px;
  }
}
</style>
