export const SET = 'SET'
export const LOADING = 'LOADING'
export const LOGIN = 'LOGIN'
export const AUTH = 'AUTH'
export const REMEMBER = 'REMEMBER'
export const REMEMBERED = 'REMEMBERED'
export const LOGOUT = 'LOGOUT'
export const RESET = 'RESET'
export const ACTIVEORGANIZATION = 'ACTIVEORGANIZATION'

export default {
  SET,
  LOADING,
  LOGIN,
  AUTH,
  REMEMBER,
  REMEMBERED,
  LOGOUT,
  RESET,
  ACTIVEORGANIZATION,
}

export const noPersist = [LOADING]
