import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  set({ commit }, data) {
    commit(types.SET, data)
  },

  async load({ commit }, onlyTopics) {
    await commit(types.LOADING, true)
    await Pagination(
      `/questions`,
      Object.assign(
        {
          'page[size]': 25,
          'filter[required_questions]': 1,
        },
        onlyTopics ? { 'filter[topics]': 1 } : null
      )
    )
      .then((response) => {
        commit(types.LOAD, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  async loadAssignments({ commit }, questionId) {
    await commit(types.LOADINGASSIGNMENTS, true)
    await Pagination(`/questions/${questionId}/assigned`)
      .then((response) => {
        commit(types.ASSIGNMENTS, {
          questionId: questionId,
          assignments: response,
        })
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADINGASSIGNMENTS, false)
      })
  },

  async loadCollaborations({ commit }, questionId) {
    await commit(types.LOADINGCOLLABORATIONS, true)
    await Pagination(`/questions/${questionId}/assigned-collaborations`)
      .then((response) => {
        commit(types.COLLABORATIONS, {
          questionId: questionId,
          collaborations: response,
        })
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADINGCOLLABORATIONS, false)
      })
  },

  async clear({ commit }) {
    await commit(types.LOAD, [])
  },

  refresh({ dispatch }) {
    dispatch('load')
  },
}
