import { localForageService } from '@/store/plugins/LocalForage'
import { isExcludedField } from '@/store/plugins/ExcludeFields'
import { inDefaultState } from '@/store/plugins/DefaultState'
import store from '@/store'
import { get, cloneDeep, isEqual } from 'lodash'

const shouldSetField = (namespace, field) => store.getters['auth/authenticated'] || inDefaultState(namespace, field)

export const setField = async (namespace, field, value) => {
  if (!isExcludedField(namespace, field) && shouldSetField(namespace, field)) {
    const cleanedValue = cloneDeep(value)
    const currentValue = await getPersistedState(`${namespace}.${field}`)
    if (!isEqual(cleanedValue, currentValue)) {
      await localForageService.setItem(`${namespace}.${field}`, cleanedValue)
    }
  } else {
    return null
  }
}

export const setPersistedState = async (state, namespace) => {
  const promises = []

  return new Promise((resolve, reject) => {
    for (const field in state[namespace]) {
      promises.push(setField(namespace, field, get(state, `${namespace}.${field}`)))
    }

    Promise.all(promises)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getPersistedState = async (key) => {
  if (key) {
    return localForageService.getItem(key)
  } else {
    const modules = Object.keys(store._modulesNamespaceMap).map((name) => name.slice(0, -1))
    return localForageService.getItems().then((results) => {
      const items = Object.entries(results).reduce((items, [key, value]) => {
        const [namespace, field] = key.split('.')
        if (modules.includes(namespace)) {
          if (!items[namespace]) {
            items[namespace] = {}
          }
          if (!isExcludedField(namespace, field)) {
            items[namespace][field] = value
          } else {
            deletePersistedState(`${namespace}.${field}`)
          }
        } else {
          deletePersistedState(`${namespace}.${field}`)
        }
        return items
      }, {})
      return Promise.resolve(items)
    })
  }
}

export const deletePersistedState = async (key) => localForageService.removeItem(key)

// export const mapToPersistedState = async (state) => {
// not needed now console.log(state)
// }
