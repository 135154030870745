<template>
  <li class="nav-item notifications">
    <router-link
      :to="{ name: 'notifications.index' }"
      class="nav-link"
    >
      <icon
        iconic="bell"
        class="bg-red"
      />
      <span
        v-if="unread.length"
        class="badge badge-light"
        >{{ unread.length > 9 ? '9+' : unread.length }}</span
      >
    </router-link>
  </li>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      check: 60,
      timer: null,
    }
  },
  computed: {
    ...mapGetters({
      notificationCount: 'app/count',
      unread: 'app/unread',
      authenticated: 'auth/authenticated',
    }),
    ...mapState('app', {
      notifications: 'notifications',
    }),
  },
  mounted() {
    this.checkNotifications()
    this.start()
  },
  beforeUnmount() {
    this.stop()
  },
  methods: {
    ...mapActions('app', {
      checkNotifications: 'load',
    }),
    start() {
      this.timer = setInterval(
        function () {
          this.checkNotifications()
        }.bind(this),
        this.check * 1000
      )
    },
    stop() {
      clearInterval(this.timer)
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.notifications {
  .iconic {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    text-align: center;
    vertical-align: middle;
    line-height: 28px;
    font-size: 16px;
  }
  a.nav-link {
    position: relative;
    color: $white;
    padding: 2px;
    &.active {
      color: $white;
    }
    &:hover {
      .iconic {
        color: $white;
      }
    }
  }
  .badge {
    position: absolute;
    top: -4px;
    right: -4px;
    font-size: 9px;
  }
}
</style>
