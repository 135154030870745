<template>
  <div
    id="app"
    class="d-flex flex-column h-100"
    :class="{
      loading: !$store.state.initialized || loading,
      'disable-transition': !transition,
      'company-nav': showCompanyNav || !isProd,
      'testing-env': !isProd,
    }"
  >
    <div
      v-if="!appVisible"
      class="inactive-mask"
    />
    <Navigation
      :ready="isKeepAlive"
      :is-prod="isProd"
    />
    <main
      v-if="isReady"
      role="main"
      class="d-flex flex-grow-1 container-fluid no-scroll"
      :class="[{ loading: !cachePages && !loading }, 'name' in $route ? $filters.slugify($route.name) : '']"
    >
      <router-view v-slot="{ Component }">
        <transition
          name="fade"
          mode="out-in"
        >
          <keep-alive
            v-if="isKeepAlive"
            :exclude="excludeList"
            :max="10"
          >
            <component
              :is="Component"
              :key="getKey"
            />
          </keep-alive>
          <component
            :is="Component"
            v-else-if="cachePages"
          />
        </transition>
      </router-view>
    </main>
    <GlobalFooter
      v-if="appVisible"
      :cache-pages="cachePages"
      :company-nav="showCompanyNav"
      :is-production="isProd"
    />
    <TodaysPlanModal v-if="showTodaysPlan" />
    <MaintenanceMode v-if="inMaintenanceMode" />
    <AnswerFilesModal
      v-if="showAnswerFiles"
      :answer-files="answerFiles"
      :loading="answerFilesLoading"
    />
    <SelectDivisionModal
      v-if="selectDivision"
      @close="selectDivision = false"
    />
    <InviteUser
      v-if="showInviteUser"
      @success="userAdded"
      @close="showInviteUser = false"
    />
    <AnswerModal
      v-if="showAnswer"
      :provided-answer="showAnswer"
      :open-chat="true"
      @close="showAnswer = null"
    />
    <ViewQuestionExclusionRequestModal
      v-if="showExclusionTask"
      :exclusion-task-id="exclusionTaskId"
      @close="showExclusionTask = false"
    />
  </div>
  <div id="toast-container" />
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'
import InviteUser from '@/components/modals/InviteUser.vue'
import SelectDivisionModal from '@/components/modals/SelectDivisionModal.vue'
import AnswerFilesModal from '@/components/modals/AnswerFilesModal.vue'
import TodaysPlanModal from '@/components/modals/TodaysPlanModal.vue'
import MaintenanceMode from '@/components/overlays/MaintenanceMode.vue'
import AnswerModal from '@/components/modals/answermodal'
import ViewQuestionExclusionRequestModal from '@/components/modals/questionsmodal/ViewQuestionExclusionRequestModal.vue'
import { toast } from '@/plugins/toast'
import { mapState, mapActions, mapGetters } from 'vuex'
import * as SentryPlugin from "@/plugins/sentry-plugin"

export default {
  components: {
    AnswerModal,
    Navigation,
    GlobalFooter,
    SelectDivisionModal,
    AnswerFilesModal,
    MaintenanceMode,
    TodaysPlanModal,
    InviteUser,
    ViewQuestionExclusionRequestModal,
  },
  data() {
    return {
      showAnswer: false,
      showInviteUser: false,
      showAnswerFiles: false,
      showTodaysPlan: false,
      showExclusionTask: false,
      selectDivision: false,
      answerFiles: [],
      answerFilesLoading: false,
      logoutTimer: null,
      logoutNotification: null,
      cachePages: true,
      exclustionTask: null,
      excludeList: [
        'CompanySetup',
        'ControlOverview',
        'ForgotPassword',
        'Login',
        'ManageUser',
        'Overview',
        // 'Profile',
        'QuestionAnswer',
        'ResetPassword',
        'Rescore',
        // 'SelectCompany',
        'UserRegistration',
        'DocumentRequestLists',
        'DocumentRequestList',
      ],
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
      loggingOut: 'loggingOut'
    }),
    ...mapState('app', {
      message: 'message',
      error: 'error',
      loading: 'loading',
      transition: 'transition',
      appVisible: 'appVisible',
      inMaintenanceMode: 'maintenanceMode',
    }),
    ...mapState('company', {
      assessmentPeriod: 'assessment_period',
    }),
    ...mapState('questions', {
      questions: 'questions',
    }),
    ...mapState('entities', {
      entities: 'entities',
    }),
    ...mapGetters({
      authenticated: 'auth/authenticated',
      userOrganizationCount: 'auth/getUserOrganizationCount',
      isAdmin: 'auth/isAdmin',
      isPrimaryAdmin: 'auth/isPrimaryAdmin',
      divisions: 'auth/myDivisions',
      hasFeature: 'company/hasFeature',
      organizationId: 'auth/getOrganizationId',
      activeOrganization: 'auth/activeOrganization'
    }),
    showCompanyNav() {
      return (
        !!this.organizationId &&
        this.authenticated &&
        (this.userOrganizationCount > 1 || (this.hasFeature('divisions') && (this.isAdmin || this.isPrimaryAdmin))
          ? true
          : false)
      )
    },
    isLoginPage() {
      return (this.$route.name || '').includes('login.')
    },
    isKeepAlive() {
      return this.authenticated && this.cachePages && !this.isLoginPage
    },
    isReady() {
      return this.$store.state.initialized && !this.$store.state.resetting && !this.loggingOut
    },
    getKey() {
      if (this.$route.name === 'questions.answer') {
        return this.$route.name
      } else {
        const ids = Object.values(this.$route.params)
          .filter((v) => !!Number(v))
          .join('.')

        return `${this.$route.name}${ids.length ? '.' : ''}${ids}`
      }
    },
    isProd() {
      return import.meta.env.PROD
    },
  },
  watch: {
    $route(to, from) {
      if (this.showTodaysPlan && this.lodash.get(from, 'name') !== 'login.index') {
        this.$forceNextTick(() => {
          this.showTodaysPlan = false
        })
      }
      if (Object.keys(to.query).length) {
        if (`division_id` in this.$route.query && !!parseInt(this.$route.query['division_id'])) {
          let divisionId = parseInt(this.$route.query['division_id'])
          let query = Object.assign({}, this.$route.query)
          delete query['division_id']
          this.$router.replace({ query })
          if (divisionId) {
            this.changeDivision(divisionId)
          }
        }
        if (`answer-discussion` in this.$route.query) {
          let answerId = parseInt(this.$route.query['answer-discussion'])
          let query = Object.assign({}, this.$route.query)
          delete query['answer-discussion']
          this.$router.replace({ query })
          if (answerId) {
            this.loadAnswer(answerId)
          }
        }
        if (`exclusion_id` in this.$route.query) {
          let exclusionId = parseInt(this.$route.query['exclusion_id'])
          let query = Object.assign({}, this.$route.query)
          delete query['exclusion_id']
          this.$router.replace({ query })
          this.$bus.emit('review-exclusion', exclusionId)
        }
        if (`redirect_to` in this.$route.query) {
          let route = this.$route.query['redirect_to']
          let query = Object.assign({}, this.$route.query)
          delete query['redirect_to']
          this.$router.replace({ query })
          this.checkRedirect(route)
        }
      }
    },
    showAnswerFiles(current) {
      if (current) {
        this.answerFilesLoading = true
        this.$http
          .get(`/entities/${current.entity_id}/questions/${current.question_id}/answers/${current.id}`)
          .then((response) => {
            this.answerFiles = response.data.files
            this.answerFilesLoading = false
          })
          .catch((error) => {
            this.answerFiles = []
            this.answerFilesLoading = false
            this.$toast.error(error)
          })
      } else {
        this.answerFiles = []
      }
    },
    loading(current) {
      if (current) {
        this.startTimer()
      } else {
        this.clearTimer()
      }
    },

    // Populate Sentry.io session data if the vuex authenticated value changes
    authenticated() {
      SentryPlugin.updateUserSessionData()
    },
    // Populate Sentry.io session data if the vuex organizationId value changes
    organizationId() {
      SentryPlugin.updateUserSessionData()
    }

  },
  beforeMount() {
    this.$bus.on('show-todays-plan', this.setShowTodaysPlan)
    this.$bus.on('on-logout', this.onLogout)
    this.$bus.on('show-answer-files', this.setAnswer)
    this.$bus.on('change-division', this.selectingDivision)
    this.$bus.on('division-changing', this.dontCachePages)
    this.$bus.on('division-changed', this.doCachePages)
    this.$bus.on('company-changing', this.dontCachePages)
    this.$bus.on('company-changed', this.doCachePages)
    this.$bus.on('invite-user', this.doShowUser)
    this.$bus.on('review-exclusion', this.reviewExclusion)
    this.$bus.on('check-setup', this.checkSetup)
    window.addEventListener('dragover', this.onDragover, false)
    window.addEventListener('dragleave', this.onDragleave, false)
    window.addEventListener('dragend', this.onDragend, false)
    window.addEventListener('drop', this.onDrop, false)
    document.addEventListener('visibilitychange', this.onVisibilityChange, false)


  },

  mounted() {

    var store = this.$store

    this.$domainBroadcastChannel.postMessage({
      broadcasterId: this.$domainBroadcastId,
      message: "New Tab Opened With Id: " +this.$domainBroadcastId
    });

    this.$domainBroadcastChannel.onmessage = function(event) {
      let isBroadcaster = this.$domainBroadcastId == event.data.broadcasterId
      let title = event.data.title
      let message = event.data.message
      if (title == "ACCESS_TOKEN_UPDATED") {
        // Prevent app from broadcasting to itself that an update is needed (not sure if this is even possible, but just to be sure)
        if (!isBroadcaster) {
          let updateResult = store.dispatch('auth/updateAccessTokenFromLocalStorage');
        }
      }
    }


  },

  beforeUnmount() {
    this.$bus.off('show-todays-plan', this.setShowTodaysPlan)
    this.$bus.off('on-logout', this.onLogout)
    this.$bus.off('show-answer-files', this.setAnswer)
    this.$bus.off('change-division', this.selectingDivision)
    this.$bus.off('division-changing', this.dontCachePages)
    this.$bus.off('division-changed', this.doCachePages)
    this.$bus.off('company-changing', this.dontCachePages)
    this.$bus.off('company-changed', this.doCachePages)
    this.$bus.off('invite-user', this.doShowUser)
    this.$bus.off('review-exclusion', this.reviewExclusion)
    this.$bus.off('check-setup', this.checkSetup)
    window.removeEventListener('dragover', this.onDragover, false)
    window.removeEventListener('dragleave', this.onDragleave, false)
    window.removeEventListener('dragend', this.onDragend, false)
    window.removeEventListener('drop', this.onDrop, false)
    document.removeEventListener('visibilitychange', this.onVisibilityChange, false)
  },
  methods: {
    ...mapActions({
      doLogout: 'auth/logout',
      appLoading: 'app/loading',
      appLoaded: 'app/loaded',
      logErrors: 'app/logErrors',
      onVisibilityChange: 'app/onVisibilityChange',
      loadUsers: 'company/loadUsers',
      loadOrganization: 'company/load',
      loadEntities: 'entities/load',
      loadQuestions: 'questions/load',
      changeDivision: 'company/changeDivision',
    }),
    checkRedirect(route) {
      if (route === 'profile.company' && this.userOrganizationCount < 2) {
        return
      }

      this.$router.push({ name: route })
    },
    loadAnswer(answerId) {
      this.$http.get(`/organizations/${this.organizationId}/answers/${answerId}`).then((response) => {
        this.showAnswer = response.data
      })
    },
    userAdded(user) {
      this.showInviteUser = false
      this.loadUsers().then(() => {
        this.$bus.emit('user-added', user)
      })
    },
    startTimer() {
      if (!this.logoutTimer) {
        this.logoutTimer = setInterval(() => {
          clearInterval(this.logoutTimer)
          this.logoutTimer = null

          let actions = [
            {
              text: 'Logout Now',
              onClick: (vm) => {
                vm.$emit('close-toast')
                this.logoutNotification = null
                this.logErrors(new Error(JSON.stringify(Object.keys(this.$store.state)))).then(() => {
                  this.doLogout('logged')
                })
              },
            },
          ]

          if ((this.lodash.get(this.$route, 'name', '') || '').split('.').shift() !== 'login') {
            actions.push({
              text: 'Force Access',
              onClick: (vm) => {
                vm.$emit('close-toast')
                this.logoutNotification = null
                this.logErrors(new Error(JSON.stringify(Object.keys(this.$store.state)))).then(() => {
                  this.appLoaded()
                })
              },
            })
          }

          this.logoutNotification = this.$toast.error(
            `Sorry, we encountered an unknown error.<span class="d-block font-sm">Try logging in again.</span>`,
            {
              actions: actions,
            },
            {
              timeout: false,
              closeButton: false,
            }
          )
        }, 45000)
      }
    },
    clearTimer() {
      if (this.logoutNotification) {
        toast.dismiss(this.logoutNotification)
      }
      clearInterval(this.logoutTimer)
      this.logoutTimer = null
      this.logoutNotification = null
    },
    onDragover(e) {
      e = e || event
      document.getElementById('app').classList.add('dragging')
      e.preventDefault()
    },
    onDragleave(e) {
      e = e || event
      document.getElementById('app').classList.remove('dragging')
      e.preventDefault()
    },
    onDrop(e) {
      e = e || event
      document.getElementById('app').classList.remove('dragging')
      e.preventDefault()
    },
    onDragend(e) {
      e = e || event
      document.getElementById('app').classList.remove('dragging')
      e.preventDefault()
    },
    setAnswer(answer) {
      this.showAnswerFiles = answer
    },
    selectingDivision() {
      this.selectDivision = true
    },
    doCachePages() {
      this.$sleep(100).then(() => {
        this.cachePages = true
      })
    },
    dontCachePages() {
      this.cachePages = false
    },
    doShowUser() {
      this.showInviteUser = true
    },
    reviewExclusion(exclusionId) {
      this.exclusionTaskId = exclusionId
      this.showExclusionTask = true
    },
    checkSetup() {
      if (this.isAdmin) {
        this.appLoading()
      }
      Promise.all([this.loadOrganization(), this.loadEntities(), this.loadQuestions()]).then(() => {
        if (this.isAdmin) {
          this.appLoaded()
          if (this.isAdmin && !(this.entities.length && this.assessmentPeriod && this.questions.length)) {
            this.$router.push({ name: 'company.setup' })
          }
        }
      })
    },
    setShowTodaysPlan(bool) {
      this.showTodaysPlan = bool
    },
    onLogout() {
      this.showInviteUser = false
      this.showAnswer = false
      this.showAnswerFiles = false
      this.answerFilesLoading = false
      this.selectDivision = false
      this.showTodaysPlan = false
      this.showExclusionTask = false
    },
  },
}
</script>
<style lang="scss" scoped>
.inactive-mask {
  background: rgba(0,0,0,.75);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  &:after {
    content: "Restoring WaveFire...";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -.75rem;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
  }
}
</style>
