<template>
  <modal
    :class="{ 'loading-sm': loading }"
    size="modal-md"
    @close-modal="$bus.emit('show-answer-files', false)"
  >
    <template #header>
      <h5>
        File Viewer
        <span
          v-if="!loading"
          class="font-weight-light"
        >
          | for ({{ answerFiles.length }})
          {{ $filters.pluralize('Answer', answerFiles.length) }}
        </span>
        <span
          v-else
          class="font-weight-light"
        >
          | loading...
        </span>
      </h5>
    </template>
    <template #body>
      <div class="font-xs d-flex flex-column">
        <b
          v-if="answerFiles.length"
          class="mb-3 d-block"
        >
          These links will expire in 15 minutes.
        </b>
        <a
          v-for="(file, index) in answerFiles"
          :key="index"
          :href="file.temp_link"
          target="_blank"
          class="mb-3 file-link bg-white py-1 px-2"
          :class="file.extension"
        >
          {{ file.name }}
        </a>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    answerFiles: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
