<template>
  <div :class="['collapsable card show', classes, { collapse: collapse }]">
    <div
      class="d-flex align-items-center card-header"
      :class="[headerClass, headerClickable ? 'cursor-click' : null]"
      @click="() => (headerClickable ? toggle() : null)"
    >
      <slot name="header" />
      <btn
        iconic="chevron-bottom"
        class="ml-auto collapse-toggle"
        @click.stop="toggle"
      />
    </div>
    <div
      ref="wrapper"
      class="collapsable-body-wrapper"
      :class="wrapperClass"
    >
      <div
        ref="cardBody"
        class="card-body"
        :class="bodyClass"
      >
        <slot name="body" />
      </div>
      <div
        v-if="$slots.footer"
        class="card-footer"
      >
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    classes: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: null,
    },
    headerClass: {
      type: String,
      default: null,
    },
    bodyClass: {
      type: String,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    minimumHeight: {
      type: Number,
      default: 0,
    },
    headerClickable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  data() {
    return {
      collapse: !this.open,
    }
  },
  watch: {
    open(current) {
      this.collapse = !current
    },
    collapse(current) {
      if (this.$refs.wrapper && !this.$refs.wrapper.getAttribute('style')) {
        this.$refs.wrapper.style.height = `${this.calcHeight(this.$refs.cardBody)}px`
      }
      this.$forceNextTick().then(() => {
        this.$refs.wrapper.style.height = `${current ? 0 : this.calcHeight(this.$refs.cardBody)}px`
      })
    },
  },
  methods: {
    toggle() {
      this.collapse = !this.collapse
      this.$emit('toggle', !this.collapse)
    },
    closed(state) {
      this.collapse = state
    },
    calcHeight(node) {
      const list = ['margin-top', 'margin-bottom', 'border-top', 'border-bottom', 'height']

      const style = window.getComputedStyle(node)

      const calcHeight = list.map((k) => parseInt(style.getPropertyValue(k), 10)).reduce((prev, cur) => prev + cur)

      return this.minimumHeight && this.minimumHeight > calcHeight ? this.minimumHeight : calcHeight
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.card-header {
  z-index: 3;
}
.card-body {
  z-index: 2;
}
.card-footer {
  z-index: 1;
}
.collapsable-body-wrapper {
  transition: height 0.33s ease;
  max-height: 300px;
  overflow: auto;
}
.collapse-toggle {
  transition: transform 0.1s ease;
  transform: scaleY(-1);
  padding: 0;
  line-height: 1;
  color: $gray-600;
}
.collapse {
  .collapsable-body-wrapper {
    position: relative;
    height: 0;
    overflow: hidden;
  }
  .collapse-toggle {
    transform: scaleY(1);
  }
}
</style>
