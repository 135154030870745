import EntityMethods from './helpers/Entities'
import AnswersMethods from './helpers/Answers'
import CollaborationsMethods from './helpers/Collaborations'
import AssignmentsMethods from './helpers/Assignments'
import CompletionMethods from './helpers/Completion'
import QuestionsMethods from './helpers/Questions'
import PlansMethods from './helpers/Plans'

const methods = Object.assign(
  EntityMethods,
  AnswersMethods,
  CollaborationsMethods,
  AssignmentsMethods,
  CompletionMethods,
  QuestionsMethods,
  PlansMethods
)

if (!import.meta.env.PROD && !window.helpers) {
  window.helpers = methods
}

export default {
  methods: methods,
}
