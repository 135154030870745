<template>
  <modal
    :width="880"
    :class="{ saving: submitting }"
    body-class="height-350 modal-body-fixed font-sm p-0"
    @close-modal="$emit('close')"
  >
    <template #header>
      <h5>Create New <span class="font-weight-light">| Action Plan</span></h5>
    </template>
    <template #body>
      <div class="row no-gutters h-100">
        <div class="col-5 p-3 scroll-y">
          <BaseForm
            ref="actionPlanForm"
            :form="form"
            url="/action-plans"
            method="post"
            toasted-success="Action Plan Created"
            @submitting="submitting = true"
            @success="onAdded"
            @error="submitting = false"
          >
            <FormInput
              v-model="form.name"
              name="name"
              size="sm"
              :error="form.errors.get('name')"
            />
            <FormTextarea
              v-model="form.description"
              name="description"
              label="Desired Outcome"
              size="sm"
              :error="form.errors.get('description')"
            />
            <FormDate
              v-model="form.due_date"
              name="due_date"
              elclass="form-control-sm"
              :min="$moment().format('YYYY-MM-DD')"
              :error="form.errors.get('due_date')"
            />
          </BaseForm>
        </div>
        <SearchableList
          v-if="isAdmin"
          class="col border-left p-0 h-100"
          :error="form.errors.get('manager_id')"
          :no-results="!!(users.length && !filteredUsers.length)"
        >
          <template #search>
            <SearchBar
              v-model="searchUsers"
              placeholder="Select Action Plan Manager"
            />
          </template>
          <template #list>
            <FormRadioSelect
              v-model="form.manager_id"
              class="m-3"
              name="manager_id"
              label="full_name"
              list-type="num"
              option-value-label="full_name"
              option-value-key="id"
              tooltip="email"
              :hide-error-text="true"
              :groupvalue="form.manager_id"
              :options="filteredUsers"
            />
          </template>
        </SearchableList>
        <div
          v-else
          class="col border-left d-flex flex-column text-center p-3"
        >
          <p>Action Plan Manager:</p>
          {{ user.full_name }}
        </div>
      </div>
    </template>
    <template #footer>
      <ToggleCheck
        v-model:checked="form.cost_tracking_enabled"
        class="mr-4"
        text="Enable Cost Tracking?"
        :small="true"
        :align-right="true"
      />
      <ToggleCheck
        v-model:checked="form.completed"
        class="mr-auto"
        text="Mark as Completed?"
        :small="true"
        :align-right="true"
      />
      <FormSubmit
        size="sm"
        text="Create Action Plan"
        :submit-function="() => $refs.actionPlanForm.onSubmit()"
      />
    </template>
  </modal>
</template>

<script>
import dataHelpers from '@/mixins/dataHelpers'
import SearchBar from '@/components/SearchBar.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: { SearchBar },
  mixins: [dataHelpers],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['close', 'added'],
  data() {
    return {
      searchUsers: null,
      submitting: false,
      form: this.$form({
        id: null,
        name: null,
        description: null,
        manager_id: null,
        completed: false,
        cost_tracking_enabled: false,
        management_response: null,
        due_date: null,
        items: this.items,
      }),
    }
  },
  computed: {
    ...mapState('company', {
      users: 'users',
    }),
    ...mapState('entities', {
      allEntities: 'entities',
    }),
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
    }),
    filteredUsers() {
      return this.searchUsers
        ? this.users.filter((user) => user.full_name.toLocaleLowerCase().includes(this.searchUsers.toLocaleLowerCase()))
        : this.users
    },
  },
  watch: {
    'form.completed'(current) {
      if (current) {
        this.form.due_date = this.$moment().format('YYYY-MM-DD')
      } else {
        this.form.due_date = null
        this.form.management_response = null
      }
    },
  },
  beforeMount() {
    if (this.isManager) {
      this.form.manager_id = this.user.id
    } else {
      this.loadUsers()
    }
  },
  methods: {
    ...mapActions({
      loadUsers: 'company/loadUsers',
    }),
    onAdded(response) {
      this.$emit('added', response.data)
    },
  },
}
</script>
