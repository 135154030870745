<template>
  <row class="row no-gutters">
    <Question
      v-if="question"
      class="pt-3"
      rows-class="px-0"
      cols-class="px-3"
      :question="question"
    />
    <slot />
  </row>
</template>

<script>
import Question from '@/components/reviewanswer/Question.vue'

export default {
  components: {
    Question,
  },
  props: {
    question: {
      type: Object,
      default: null,
    },
  },
}
</script>
