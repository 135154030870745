import { has, get, isEqual } from 'lodash'
import store from '@/store'

import { defaultState as answersDefaultState } from '@/store/modules/answers/state'
import { defaultState as appDefaultState } from '@/store/modules/app/state'
import { defaultState as authDefaultState } from '@/store/modules/auth/state'
import { defaultState as categoriesDefaultState } from '@/store/modules/categories/state'
import { defaultState as companyDefaultState } from '@/store/modules/company/state'
import { defaultState as entitiesDefaultState } from '@/store/modules/entities/state'
import { defaultState as faqsDefaultState } from '@/store/modules/faqs/state'
import { defaultState as frameworksDefaultState } from '@/store/modules/frameworks/state'
import { defaultState as plansDefaultState } from '@/store/modules/plans/state'
import { defaultState as questionsDefaultState } from '@/store/modules/questions/state'
import { defaultState as risksDefaultState } from '@/store/modules/risks/state'
import { defaultState as threatsDefaultState } from '@/store/modules/threats/state'

export const DefaultState = {
  answers: answersDefaultState(),
  app: appDefaultState(),
  auth: authDefaultState(),
  categories: categoriesDefaultState(),
  company: companyDefaultState(),
  entities: entitiesDefaultState(),
  faqs: faqsDefaultState(),
  frameworks: frameworksDefaultState(),
  plans: plansDefaultState(),
  questions: questionsDefaultState(),
  risks: risksDefaultState(),
  threats: threatsDefaultState(),
}

export const inDefaultState = (namespace, field) => {
  return (
    has(DefaultState, `${namespace}.${field}`) &&
    has(store.state, `${namespace}.${field}`) &&
    isEqual(store.state[namespace][field], DefaultState[namespace][field])
  )
}

export const getDefaultValue = (namespace, field) => {
  return get(store.state, `${namespace}.${field}`)
}
