export const SET = 'SET'
export const CONTROLSLOADING = 'CONTROLSLOADING'
export const PROCESSESLOADING = 'PROCESSESLOADING'
export const FUNCTIONSLOADING = 'FUNCTIONSLOADING'
export const RISKSLOADING = 'RISKSLOADING'
export const OBJECTIVESLOADING = 'OBJECTIVESLOADING'
export const CRITERIALOADING = 'CRITERIALOADING'
export const DOCUMENTREQUESTSLOADING = 'DOCUMENTREQUESTSLOADING'
export const LOADCONTROLS = 'LOADCONTROLS'
export const LOADPROCESSES = 'LOADPROCESSES'
export const LOADFUNCTIONS = 'LOADFUNCTIONS'
export const LOADRISKS = 'LOADRISKS'
export const LOADOBJECTIVES = 'LOADOBJECTIVES'
export const LOADCRITERIA = 'LOADCRITERIA'
export const LOADDOCUMENTREQUESTS = 'LOADDOCUMENTREQUESTS'
export const RESET = 'RESET'

export default {
  SET,
  CONTROLSLOADING,
  PROCESSESLOADING,
  FUNCTIONSLOADING,
  RISKSLOADING,
  OBJECTIVESLOADING,
  CRITERIALOADING,
  DOCUMENTREQUESTSLOADING,
  LOADCONTROLS,
  LOADPROCESSES,
  LOADFUNCTIONS,
  LOADRISKS,
  LOADOBJECTIVES,
  LOADCRITERIA,
  LOADDOCUMENTREQUESTS,
  RESET,
}

export const noPersist = []
