<template>
  <FormGroup>
    <slot />
    <FormLabel />
    <with-root :show="withPrepend">
      <div
        class="input-group"
        :class="[size ? `input-group-${size}` : null]"
      >
        <div
          v-if="prepend"
          class="input-group-prepend"
        >
          <span
            class="input-group-text iconic"
            :class="`iconic-${prepend}`"
          />
        </div>
        <input
          ref="inputRef"
          type="text"
          :class="['form-control', elclass, size ? `form-control-${size}` : null]"
          :tabindex="tabindex"
          :readonly="readonly"
          :disabled="disabled"
          :required="required"
        />
      </div>
    </with-root>
  </FormGroup>
</template>
<script>
import FormMixins from '@/mixins/FormMixins'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'FormCurrency',
  mixins: [FormMixins],
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    prepend: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default(rawProps) {
        // default function receives the raw props object as argument
        return Object.assign(
          {
            locale: 'en-US',
            currency: 'USD',
            currencyDisplay: 'hidden',
            precision: 2,
            hideCurrencySymbolOnFocus: false,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: true,
            autoDecimalDigits: false,
            autoSign: true,
            useGrouping: true,
            accountingSign: false,
          },
          rawProps.options || {}
        )
      },
    },
  },
  emits: ['update:modelValue', 'keyup', 'change', 'blur'],
  setup(props) {
    const { inputRef } = useCurrencyInput(props.options)

    return { inputRef }
  },
  computed: {
    withPrepend() {
      return !!(this.prepend && this.prepend.length)
    },
  },
}
</script>
