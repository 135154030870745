<template>
  <div
    class="progress"
    :class="{ [getProgressBarClass]: !progressBarClass }"
    :style="height ? `height: ${height}px` : null"
  >
    <div
      class="progress-bar"
      :class="[
        progressBarClass,
        { 'progress-bar-striped': striped },
        { 'progress-bar-animated': animated },
        { 'border-0': getPercent === 0 || getPercent > 99 },
      ]"
      role="progressbar"
      :style="`width: ${getPercent}%`"
      :aria-valuenow="getPercent"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <span
        v-if="!hideCount"
        class="mx-2"
        :style="fontSize ? `font-size: ${fontSize} !important` : null"
      >
        {{ displayText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    showPercent: {
      type: Boolean,
      default: false,
    },
    hideCount: {
      type: Boolean,
      default: false,
    },
    progressBarClass: {
      type: String,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    fontSize: {
      type: String,
      default: null,
    },
    striped: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayText() {
      return this.showPercent ? `${this.getPercent}%` : `${this.count} of ${this.total}`
    },
    getPercent() {
      return Math.ceil((this.count / this.total) * 100) || 0
    },
    getProgressBarClass() {
      let percent = this.count / this.total
      if (percent === 1) {
        return 'Very Low'
      } else if (percent >= 0.75) {
        return 'Low'
      } else if (percent >= 0.5) {
        return 'Medium'
      } else if (percent >= 0.25) {
        return 'High'
      } else {
        return 'Very High'
      }
    },
  },
}
</script>
