const defaultState = () => ({
  loading: false,
  error: null,
  message: null,
  notifications: [],
  archived: [],
  frameworks: [],
  features: [],
  featuresLoading: false,
  frameworksLoading: false,
  saved: false,
  env: import.meta.env.NODE_ENV,
  transition: true,
  openModals: [],
  isRefreshing: false,
  refreshingCall: null,
  maintenanceMode: false,
  loginRedirect: null,
  appVisible: true,
  terms: {
    controlTerm: `Control`,
    controlText: `Please enter a description of the control(s) relevant to the question. A "control" is a policy, process and/or technology intended to aid the organization in effectively managing the risk associated with a particular issue.`,
    noControlText: `There is not a control for this answer.`,
    uploadText: `Add Supporting Documents`,
  },
})

const excludeFields = ['loading', 'error', 'message', 'loginRedirect', 'openModals', 'isRefreshing', 'appVisible']

export { defaultState, excludeFields }

export default defaultState
