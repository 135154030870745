import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import Axios from '@/plugins/axios'
import { toastInstance as toast, toast as toastRoot } from '@/plugins/toast'
import { getPersistedState } from '@/store/plugins/StateMapper'
import AppErrorToast from '@/components/AppErrorToast.vue'

export default {
  async set({ commit }, data) {
    await commit(types.SET, data)
  },

  async onVisibilityChange({ dispatch, commit }, event) {
    const visible = !document.hidden

    if (visible) {
      await getPersistedState()
        .then(async (cached) => {
          await commit('loadFromCache', cached, { root: true })
          await dispatch('set', {
            appVisible: visible,
          })
        }).catch(async () => {
          await dispatch('set', {
            appVisible: visible,
          })
        })
    } else {
      await dispatch('set', {
        appVisible: visible,
      })
    }
  },

  async loading({ commit }) {
    await commit(types.LOADING, true)
  },

  async loaded({ commit }, error) {
    await commit(types.LOADING, false)
    if (error) {
      await commit(types.ERROR, error)
    }
  },

  async error({ commit }, error) {
    await commit(types.ERROR, error)
    if (error) {
      toast.error(
        error,
        {},
        {
          id: 'app-error',
          position: 'top-center',
          timeout: 0,
        }
      )
    } else {
      toast.dismiss('app-error')
    }
  },

  async message({ commit }, message) {
    await commit(types.MESSAGE, message)
    if (message) {
      toast.success(
        message,
        {},
        {
          id: 'app-message',
          position: 'top-center',
          timeout: 30000,
        }
      )
    } else {
      toast.dismiss('app-message')
    }
  },

  async notifications({ commit }, notifications) {
    await commit(types.NOTIFICATIONS, notifications)
  },

  async transitions({ commit }, bool) {
    await commit(types.TRANSITIONS, bool)
  },

  async addModal({ commit }, obj) {
    await commit(types.MODALS, obj)
    return await obj
  },

  async removeModal({ commit }, key) {
    await commit(types.MODALS, key)
  },

  async load({ commit, rootGetters }, params) {
    if (rootGetters['auth/authenticated']) {
      await Pagination(`/users/${rootGetters['auth/getUserId']}/tasks`, params).then((response) => {
        commit(types.NOTIFICATIONS, response)
      })
    }
  },

  async loadArchived({ commit, rootGetters }) {
    if (rootGetters['auth/authenticated']) {
      await Pagination(
        `/users/${rootGetters['auth/getUserId']}/tasks`,
        Object.assign({
          'filter[only_archived]': 1,
        })
      ).then((response) => {
        commit(types.ARCHIVED, response)
      })
    }
  },

  async isRead(context, id) {
    await Axios.put(`/tasks/${id}`, {
      read: 1,
    })
  },

  async isUnRead(context, id) {
    await Axios.put(`/tasks/${id}`, {
      read: 0,
    })
  },

  async deleteNotification(context, id) {
    await Axios.delete(`/tasks/${id}`)
  },

  async archiveNotification(context, id) {
    await Axios.post(`/tasks/${id}/archive`)
  },

  async loadFrameworks({ commit }, trashed) {
    await commit(types.FRAMEWORKSLOADING, true)
    await Pagination(
      `/protocols`,
      Object.assign(
        {
          sysAdmin: 1,
          include: 'organizationsCount',
        },
        trashed ? { 'filter[trashed]': 'with' } : null
      )
    )
      .then((response) => {
        commit(types.LOADFRAMEWORKS, response)
      })
      .finally(() => {
        commit(types.FRAMEWORKSLOADING, false)
      })
  },

  async loadFeatures({ commit }) {
    await commit(types.FEATURESLOADING, true)
    await Pagination('/features')
      .then((response) => {
        commit(types.LOADFEATURES, response)
      })
      .finally(() => {
        commit(types.FEATURESLOADING, false)
      })
  },

  async postError({ rootState }, error) {
    return await Axios.post('/logs', {
      app: 'dashboard',
      user: rootState.auth.user,
      description: error.toString(),
      error:
        typeof error === 'string'
          ? error
          : JSON.parse(
              JSON.stringify(error, [
                'vue',
                'name',
                'type',
                'message',
                'stack',
                'columnNumber',
                'fileName',
                'lineNumber',
              ])
            ),
    })
  },

  async logErrors({}, error) {
    toastRoot(AppErrorToast, {
      type: 'error',
      timeout: false,
      hideProgressBar: true,
      closeButton: false,
      draggable: false,
      closeOnClick: false,
      props: {
        message: error,
        toastId: 'fatal-error',
      },
    })
  },
}
