<template>
  <FormGroup>
    <FormLabel />
    <textarea
      ref="element"
      :class="['form-control', elclass, size ? `form-control-${size}` : null]"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :name="name"
      :rows="rows"
      :value="modelValue"
      :readonly="readonly"
      :disabled="disabled"
      :required="required"
      :pattern="pattern"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </FormGroup>
</template>
<script>
import FormMixins from '@/mixins/FormMixins'
export default {
  mixins: [FormMixins],
  props: {
    rows: {
      type: [Number, String],
      default: 3,
    },
  },
  emits: ['update:modelValue'],
}
</script>
