<script>
import BaseFormInput from './BaseFormInput.vue'
export default {
  extends: BaseFormInput,
  props: {
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      type: 'input',
    }
  },
}
</script>
