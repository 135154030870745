<template>
  <span
    v-if="user"
    v-tippy="{ content: user.email, theme: 'light small' }"
    class="border-bottom border-dotted border-secondary cursor-default"
    @click="copyEmail"
    v-html="user.full_name"
  />
  <span
    v-else-if="na"
    v-text="na"
  />
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    na: {
      type: String,
      default: null,
    },
  },
  methods: {
    copyEmail() {
      this.$copyText(this.user.email).then(
        () => {
          this.$filters.possessive(this.user.full_name)
          this.$toast.success(
            `Copied to clipboard! <span class="d-block font-xs">${this.$filters.possessive(
              this.user.full_name
            )} email (${this.user.email})</span>`
          )
        },
        () => {
          this.$toast.error(
            `Cant't copy to clipboard! <span class="d-block font-xs">${this.$filters.possessive(
              this.user.full_name
            )} email (${this.user.email})</span>`
          )
        }
      )
    },
  },
}
</script>
