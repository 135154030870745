<template>
  <btn
    :link="link"
    :class="buildClasses"
    :iconic="iconic"
    :badge="badge"
    :count="count !== null && !!badge ? count : null"
  >
    {{ label }}
    <slot />
    <span
      v-if="count !== null && !badge"
      class="ml-auto"
    >
      {{ count }}
    </span>
  </btn>
</template>
<script>
export default {
  props: {
    link: {
      type: Boolean,
      default: true,
    },
    iconic: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    badge: {
      type: String,
      default: null,
    },
  },
  computed: {
    buildClasses() {
      return [this.linkClass, this.disabledClass, this.activeClass].filter((e) => e).join(' ')
    },
    linkClass() {
      return this.link ? null : `btn-bright mb-4 btn-sm`
    },
    activeClass() {
      return this.active ? `router-link-exact-active` : null
    },
    disabledClass() {
      return this.disabled ? `disabled` : null
    },
  },
}
</script>
