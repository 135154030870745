const getters = {
  collaborationsByEntity(entityId, questionId) {
    return this.getEntityQuestionMeta(entityId).collaborationsAnswered.filter((id) => id === questionId)
  },
}

const counts = {
  collaborationsByEntityCount(entityId, questionId) {
    return this.collaborationsByEntity(entityId, questionId).length
  },
}

const tests = {}

const CollaborationsMethods = Object.assign(getters, counts, tests)

export default CollaborationsMethods
