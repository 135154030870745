import { get } from 'lodash'

export default {
  isInterview(state, getters, rootState, rootGetters) {
    return rootGetters['auth/isAdmin'] && rootGetters['company/hasFeature']('interviews') && !!state.interviewee && !!state.interviewer
  },
  entities(state) {
    return get(state.question, 'entities', [])
  },
  lockedEntities(state) {
    return get(state.question, 'entities', []).filter((e) => e.is_current_assessment_locked)
  },
  answered(state, getters) {
    return getters.entities.filter((entity) =>
      get(state.question, 'completion_meta.answeredEntities', []).includes(entity.id)
    )
  },
  needAnswer(state, getters, rootState, rootGetters) {
    return getters.entities.filter(
      (entity) =>
        getters.needAnswerIds.includes(entity.id) &&
        ((!!rootGetters['company/hasFeature']('collaboration') &&
        !!get(state.question, 'assigned_collaborations_count', [])
          ? !!get(state.question, 'assigned_collaborations', []).filter((c) => c.entity_id === entity.id).length
          : false) ||
          !get(state.question, 'completion_meta.answeredEntities', []).includes(entity.id))
    )
  },
  needAnswerIds(state, getters, rootState, rootGetters) {
    let remainingEntities = get(state.question, 'completion_meta.remainingEntities', [])
    let notAnsweredCollabEntities = get(state.question, 'completion_meta.collaborationNotAnsweredEntities', [])
    let entityExclusionRequests = get(state.question, 'completion_meta.excludedEntityRequests', [])

    let total = (
      rootGetters['company/hasFeature']('collaboration')
        ? remainingEntities.concat(notAnsweredCollabEntities)
        : remainingEntities
    ).filter((entityId) => {
      return (
        (getters.isTopic ? entityId : getters.forceEntityCompletion.includes(entityId)) &&
        !entityExclusionRequests.includes(entityId)
      )
    })

    return [...new Set([...total])]
  },
  position(state) {
    return state.selectedTopics.indexOf(state.topicId) + 1
  },
  bodySections(state) {
    return get(state.question, 'sections', []).filter(
      (section) =>
        section.section === 'body' &&
        (!section.section.entity_id ||
          !state.selectedEntities.length ||
          state.selectedEntities.map((e) => e.id).includes(section.entity_id))
    )
  },
  sidebarSections(state) {
    return get(state.question, 'sections', []).filter(
      (section) =>
        section.section === 'sidebar' &&
        (!section.section.entity_id ||
          !state.selectedEntities.length ||
          state.selectedEntities.map((e) => e.id).includes(section.entity_id))
    )
  },
  completedQuestions(state, getters) {
    return state.selectedTopics.slice(0, getters.position - 1)
  },
  resumable(state) {
    return !!(state.selectedTopics.length && state.topicId && state.selectedQuestionId)
  },
  progress(state, getters) {
    return state.selectedTopics.length
      ? ((getters.completedQuestions.length / state.selectedTopics.length) * 100).toFixed(2)
      : 0
  },
  isTopic(state) {
    return !get(state.question, 'parent_question_id', '')
  },
  selectedEntityIds(state) {
    return state.selectedEntities.map((e) => e.id)
  },
  notSelectedEntityIds(state, getters) {
    return getters.needAnswer.filter((e) => !getters.selectedEntityIds.includes(e.id)).map((e) => e.id)
  },
  forceEntityCompletion(state, getters) {
    return getters.isTopic
      ? []
      : state.answers
          .filter((a) => a.question_id === get(state.question, 'parent_question_id', ''))
          .map((a) => a.entity_id)
  },
  nonSelectedForcedEntities(state, getters) {
    return getters.isTopic
      ? []
      : getters.forceEntityCompletion.filter((id) => getters.notSelectedEntityIds.includes(id))
  },
  crossDivisionEnabled(state, getters, rootState, rootGetters) {
    return (
      !!rootGetters['auth/isAdmin'] &&
      !!rootGetters['company/hasFeature']('divisions') &&
      rootGetters['auth/myDivisions'].length > 1
    )
  },
}
