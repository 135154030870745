<template>
  <div
    :class="classList"
    class="agile"
    @touchstart="() => {}"
  >
    <div
      ref="list"
      class="agile__list"
    >
      <div
        ref="track"
        :style="style"
        class="agile__track"
        @mouseout="handleMouseOut('track')"
        @mouseover="handleMouseOver('track')"
      >
        <div
          v-show="slidesCloned"
          ref="slidesClonedBefore"
          class="agile__slides agile__slides--cloned"
        >
          <slot />
        </div>

        <div
          ref="slides"
          class="agile__slides agile__slides--regular"
        >
          <slot />
        </div>

        <div
          v-show="slidesCloned"
          ref="slidesClonedAfter"
          class="agile__slides agile__slides--cloned"
        >
          <slot />
        </div>
      </div>
    </div>

    <div
      v-if="$slots.caption"
      class="agile__caption"
    >
      <slot name="caption" />
    </div>

    <div
      v-if="!settings.unagile && (settings.navButtons || settings.dots)"
      class="agile__actions"
    >
      <button
        v-if="settings.navButtons && !settings.unagile"
        ref="prevButton"
        :disabled="!canGoToPrev"
        aria-label="Previous"
        class="agile__nav-button agile__nav-button--prev"
        type="button"
        @click="goToPrev"
      >
        <slot name="prevButton">
          <i class="left" />
        </slot>
      </button>

      <ul
        v-if="settings.dots && !settings.unagile"
        ref="dots"
        class="agile__dots"
      >
        <li
          v-for="n in countSlides"
          :key="n"
          :class="{ 'agile__dot--current': n - 1 === currentSlide }"
          class="agile__dot"
          @mouseout="handleMouseOut('dot')"
          @mouseover="handleMouseOver('dot')"
        >
          <button
            type="button"
            @click="goTo(n - 1), restartAutoPlay()"
          >
            {{ n }}
          </button>
        </li>
      </ul>

      <button
        v-if="settings.navButtons && !settings.unagile"
        ref="nextButton"
        :disabled="!canGoToNext"
        aria-label="Next"
        class="agile__nav-button agile__nav-button--next"
        type="button"
        @click="goToNext"
      >
        <slot name="nextButton">
          <i class="right" />
        </slot>
      </button>
    </div>
  </div>
</template>

<script>
import handlers from './mixins/handlers'
import helpers from './mixins/helpers'
import methods from './mixins/methods'
import preparations from './mixins/preparations'
import settings from './mixins/settings'
import throttle from './mixins/throttle'
import watchers from './mixins/watchers'

export default {
  name: 'Agile',

  mixins: [handlers, helpers, methods, preparations, settings, throttle, watchers],

  emits: ['before-change', 'after-change', 'breakpoint'],

  data() {
    return {
      autoplayInterval: null,
      autoplayRemaining: null,
      autoplayStartTimestamp: null,
      autoplayTimeout: null,
      currentSlide: null,
      dragDistance: 0,
      dragStartX: 0,
      dragStartY: 0,
      isAutoplayPaused: false,
      isMouseDown: false,
      slides: [],
      slidesClonedAfter: [],
      slidesClonedBefore: [],
      isSSR: typeof window === 'undefined',
      transitionDelay: 0,
      translateX: 0,
      widthWindow: 0,
      widthContainer: 0,
    }
  },

  computed: {
    breakpoints: function () {
      return !this.initialSettings.responsive ? [] : this.initialSettings.responsive.map((item) => item.breakpoint)
    },

    canGoToPrev: function () {
      return this.settings.infinite || this.currentSlide > 0
    },

    canGoToNext: function () {
      return this.settings.infinite || this.currentSlide < this.countSlides - 1
    },

    classList: function () {
      return {
        'agile--ssr': this.isSSR,
        'agile--auto-play': this.settings.autoplay,
        'agile--disabled': this.settings.unagile,
        'agile--fade': this.settings.fade && !this.settings.unagile,
        'agile--rtl': this.settings.rtl,
        'agile--no-nav-buttons': !this.settings.navButtons,
      }
    },

    countSlides: function () {
      return this.isSSR ? this.htmlCollectionToArray(this.$slots.default).length : this.slides.length
    },

    countSlidesAll: function () {
      return this.slidesAll.length
    },

    currentBreakpoint: function () {
      const breakpoints = this.breakpoints.map((item) => item).reverse()
      return this.initialSettings.mobileFirst
        ? breakpoints.find((item) => item < this.widthWindow) || 0
        : breakpoints.find((item) => item > this.widthWindow) || null
    },

    marginX: function () {
      if (this.settings.unagile) {
        return 0
      }

      let marginX = this.slidesCloned ? this.countSlides * this.widthSlide : 0

      // Center mode margin
      if (this.settings.centerMode) {
        marginX -=
          (Math.floor(this.settings.slidesToShow / 2) - +(this.settings.slidesToShow % 2 === 0)) * this.widthSlide
      }

      return this.settings.rtl ? marginX : -1 * marginX
    },

    slidesCloned: function () {
      return !this.settings.unagile && !this.settings.fade && this.settings.infinite
    },

    slidesAll: function () {
      return this.slidesCloned ? [...this.slidesClonedBefore, ...this.slides, ...this.slidesClonedAfter] : this.slides
    },

    style: function () {
      return `--agile-track-transform: translate(${
        this.translateX + this.marginX
      }px); --agile-track-transition: transform ${this.settings.timing} ${this.transitionDelay}ms`
    },

    widthSlide: function () {
      return !this.settings.unagile ? this.widthContainer / this.settings.slidesToShow : 'auto'
    },
  },

  mounted() {
    // Windows resize listener
    window.addEventListener('resize', this.getWidth)
    if (this.arrowNav) {
      window.addEventListener('keyup', this.keyToSlide)
    }
    // Mouse and touch events
    this.$refs.track.addEventListener('touchstart', this.handleMouseDown)
    this.$refs.track.addEventListener('touchend', this.handleMouseUp)
    this.$refs.track.addEventListener('touchmove', this.handleMouseMove)
    this.$refs.track.addEventListener('mousedown', this.handleMouseDown)
    this.$refs.track.addEventListener('mouseup', this.handleMouseUp)
    this.$refs.track.addEventListener('mousemove', this.handleMouseMove)

    // Init
    this.isSSR = false
    this.reload()
  },

  // Vue 3
  beforeUnmount() {
    this.destroy()
  },

  methods: {
    destroy() {
      window.removeEventListener('resize', this.getWidth)
      if (this.arrowNav) {
        window.removeEventListener('keyup', this.keyToSlide)
      }

      this.$refs.track.removeEventListener('touchstart', this.handleMouseDown)
      this.$refs.track.removeEventListener('touchend', this.handleMouseUp)
      this.$refs.track.removeEventListener('touchmove', this.handleMouseMove)
      this.$refs.track.removeEventListener('mousedown', this.handleMouseDown)
      this.$refs.track.removeEventListener('mouseup', this.handleMouseUp)
      this.$refs.track.removeEventListener('mousemove', this.handleMouseMove)

      this.disableAutoPlay()
    },

    keyToSlide(e) {
      if (e.which === 37) {
        this.goToPrev()
      }
      if (e.which === 39) {
        this.goToNext()
      }
    },

    // Return current breakpoint
    getCurrentBreakpoint() {
      return this.currentBreakpoint
    },

    // Return settings for current breakpoint
    getCurrentSettings() {
      return this.settings
    },

    // Return current slide index
    getCurrentSlide() {
      return this.currentSlide
    },

    // Return initial settings
    getInitialSettings() {
      return this.initialSettings
    },

    // Go to slide
    goTo(n, transition = true, asNav = false) {
      // Break goTo() if unagile is active
      if (this.settings.unagile) {
        return false
      }

      if (!asNav) {
        this.settings.asNavFor.forEach((carousel) => {
          if (carousel) {
            carousel.goTo(n, transition, true)
          }
        })
      }

      let slideNextReal = n

      if (transition) {
        if (this.settings.infinite && n < 0) {
          slideNextReal = this.countSlides - 1
        } else if (n >= this.countSlides) {
          slideNextReal = 0
        }

        this.$emit('before-change', { currentSlide: this.currentSlide, nextSlide: slideNextReal })

        this.currentSlide = slideNextReal

        if (n !== slideNextReal) {
          setTimeout(() => {
            this.goTo(slideNextReal, false)
          }, this.settings.speed)
        }
      }

      const translateX = !this.settings.fade ? n * this.widthSlide * this.settings.slidesToScroll : 0
      this.transitionDelay = transition ? this.speed : 0

      if (this.infinite || this.currentSlide + this.slidesToShow <= this.countSlides) {
        this.translateX = this.settings.rtl ? translateX : -1 * translateX
      }
    },

    // Go to next slide
    goToNext() {
      if (this.canGoToNext) {
        this.goTo(this.currentSlide + 1)
        this.restartAutoPlay()
      }
    },

    // Go to previous slide
    goToPrev() {
      if (this.canGoToPrev) {
        this.goTo(this.currentSlide - 1)
        this.restartAutoPlay()
      }
    },

    // Reload carousel
    reload() {
      this.getWidth()
      this.prepareSlides()
      this.prepareCarousel()
      this.toggleFade()
      this.toggleAutoPlay()
    },
  },
}
</script>
