<template>
  <modal
    :width="984"
    body-class="modal-body-fixed p-0 d-flex"
    header-class="align-content-center bg-light"
    footer-class="align-content-center bg-light justify-content-center"
    @close-modal="forceClose"
  >
    <template #header>
      <h5>
        Today's Plan
        <span
          v-if="!!totalItems"
          class="font-weight-light"
        >
          | {{ totalItems }} {{ $filters.pluralize('item', totalItems > 1) }} needs your attention
        </span>
        <span
          v-else
          class="font-weight-light"
        >
          | All Caught Up!
        </span>
      </h5>
    </template>
    <template #body>
      <div class="row no-gutters w-100">
        <div class="col-5 p-4 h-100 scroll-y">
          <today-section
            title="Assigned Questions"
            class="mb-4"
            :help="{
              name: 'faq.search',
              params: { search: 'questions' },
            }"
            :has-info="!!uniqueAssignments.length"
            :info-message="
              !uniqueAssignments.length
                ? null
                : `You have ${uniqueAssignments.length} ${$filters.pluralize(
                    'question',
                    uniqueAssignments.length > 1
                  )} assigned to you.`
            "
            success-message="Congrats! There are no questions assigned to you."
            body-class="justify-content-center"
            @close="forceClose"
          >
            <btn
              :link="false"
              class="btn-xs btn-success-highlight py-1 align-self-center"
              @click="
                $router
                  .push({
                    name: 'questions.answer',
                    params: {
                      questions: assignmentIds.join(','),
                      topic: assignmentIds[0],
                      question: assignmentIds[0],
                      entity: false,
                    },
                  })
                  .catch(forceClose)
              "
            >
              Answer Now
            </btn>
            <btn
              v-if="pastDueAssignments.length"
              :link="false"
              class="btn-xs btn-error py-1 ml-3 align-self-center"
              @click="
                $router
                  .push({
                    name: 'questions.answer',
                    params: {
                      questions: pastDueIds.join(','),
                      topic: pastDueIds[0],
                      question: pastDueIds[0],
                      entity: false,
                    },
                  })
                  .catch(forceClose)
              "
            >
              {{ pastDueAssignments.length }} Past Due
            </btn>
          </today-section>
          <today-section
            title="Action Plan Tasks"
            :class="{ 'mb-4': isAdmin }"
            :help="{
              name: 'faq.search',
              params: { search: 'action plans' },
            }"
            :has-info="!!actionPlansCount"
            :info-message="
              !actionPlanTasks.length
                ? null
                : `You have ${actionPlanTasks.length} ${$filters.pluralize(
                    'task',
                    actionPlanTasks.length > 1
                  )} from ${actionPlansCount} ${$filters.pluralize('plan', actionPlansCount > 1)} assigned to you.`
            "
            body-class="flex-column justify-content-center"
            success-message="Congrats! There are no action plan tasks assigned to you."
            @close="forceClose"
          >
            <slider
              v-if="!!actionPlansCount"
              :nav-buttons="actionPlansCount > 1"
              :dots="actionPlansCount > 1"
              class="action-plan-slider"
              @before-change="({ nextSlide }) => (activePlanSlide = nextSlide)"
            >
              <div
                v-for="(tasks, planName, i) in actionPlans"
                :key="i"
              >
                <blockquote class="blockquote font-sm text-center mb-0 mx-3">
                  <btn
                    :link="false"
                    class="btn-xs py-1 my-1"
                    :class="[
                      tasks.filter((a) => today.isAfter(a.due_date)).length ? 'btn-error' : 'btn-success-highlight',
                    ]"
                    @click="
                      $router
                        .push({
                          name: 'action-plans.view',
                          params: {
                            planId: (tasks[0] || {}).task_type_id,
                          },
                        })
                        .catch(forceClose)
                    "
                  >
                    {{ tasks.filter((a) => today.isAfter(a.due_date)).length ? 'Past Due! ' : '' }}
                    You have {{ tasks.length }} {{ $filters.pluralize('task', tasks.length > 1) }} assigned to you.
                  </btn>
                  <footer class="blockquote-footer font-xs line-height-1 text-truncate">
                    {{ planName }}
                  </footer>
                </blockquote>
              </div>
            </slider>
          </today-section>
          <today-section
            v-if="isAdmin"
            title="Question Exclusion Requests"
            :help="null"
            :has-info="!!exclusionRequests.length"
            :info-message="`You have ${exclusionRequests.length} exclusion ${$filters.pluralize(
              'request',
              exclusionRequests.length > 1
            )} assigned to you.`"
            body-class="justify-content-center"
            success-message="Congrats! There are no exclusion requests assigned to you."
            @close="forceClose"
          >
            <btn
              :link="false"
              class="btn-xs btn-success-highlight py-1 align-self-center"
              @click="
                $router
                  .push({
                    name: 'notifications.index',
                  })
                  .catch(forceClose)
              "
            >
              Respond Now
            </btn>
          </today-section>
        </div>
        <div
          class="col border-left d-flex px-4"
          :class="{ 'py-4': !unReadWelcomeMessages.length }"
        >
          <today-section
            v-if="!unReadWelcomeMessages.length"
            style="width: 362px"
            class="mx-auto message-done"
            title="Today's Messages"
            :help="null"
            :has-info="false"
            :info-message="null"
            success-message="All caught up!"
            body-class="justify-content-center"
            @close="forceClose"
          />
          <slider
            v-else
            :nav-buttons="unReadWelcomeMessages.length > 1"
            :dots="unReadWelcomeMessages.length > 1"
            class="align-self-stretch mx-auto messages-slider py-4 mx-4"
            @before-change="({ nextSlide }) => (activeMessageSlide = nextSlide)"
          >
            <div
              v-for="(message, i) in unReadWelcomeMessages"
              :key="i"
              class="h-100"
            >
              <message
                :message="message"
                class="h-100 mx-5"
              />
            </div>
          </slider>
        </div>
      </div>
    </template>
    <template
      v-if="!!totalItems"
      #footer
    >
      <btn
        :link="false"
        class="btn-info-gray btn-xs px-4 py-1"
        @click="forceClose"
      >
        I'm Not Ready
      </btn>
    </template>
  </modal>
</template>

<script>
import Message from '@/components/todaysplan/Message.vue'
import { VueAgile as Slider } from '@/components/vue-agile'
import TodaySection from '@/components/todaysplan/Section.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Message,
    Slider,
    TodaySection,
  },
  data() {
    return {
      activeMessageSlide: 0,
      activePlanSlide: 0,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
      loggingIn: 'loggingIn',
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      actionPlanTasks: 'auth/todaysActionPlanTasks',
      assignments: 'auth/todaysAssignments',
      unReadWelcomeMessages: 'auth/unReadWelcomeMessages',
      exclusionRequests: 'auth/todaysExclusionRequests',
    }),
    totalItems() {
      return (
        this.actionPlanTasks.length +
        this.uniqueAssignments.length +
        this.exclusionRequests.length +
        this.unReadWelcomeMessages.length
      )
    },
    assignmentIds() {
      return this.uniqueAssignments.map((q) => q.task_type_id)
    },
    actionPlans() {
      return this.lodash.groupBy(this.actionPlanTasks, 'plan.name')
    },
    actionPlansCount() {
      return Object.keys(this.actionPlans).length
    },
    pastDueAssignments() {
      return this.assignments.filter((a) => this.today.isAfter(a.due_date))
    },
    pastDueIds() {
      return this.pastDueAssignments.map((q) => q.task_type_id)
    },
    today() {
      return this.$moment().startOf('day')
    },
    uniqueAssignments() {
      return this.lodash.uniqBy(this.assignments, 'task_type_id')
    },
    pastDueActionPlanTasks() {
      return this.actionPlanTasks.filter((task) => this.today.isAfter(task.due_date)).length
    },
  },
  watch: {
    unReadWelcomeMessages(current, previous) {
      if (current.length !== previous.length && current.length < this.activeMessageSlide + 1) {
        this.activeMessageSlide = 0
      }
    },
  },
  beforeMount() {
    if (!this.loggingIn) {
      this.refreshUser()
    }
  },
  methods: {
    ...mapActions({
      refreshUser: 'auth/getUser',
    }),
    forceClose() {
      this.$bus.emit('show-todays-plan', false)
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
:deep(.action-plan-slider) {
  height: 64px;
  .agile__nav-button--prev,
  .agile__nav-button--next {
    top: 0.25rem !important;
    bottom: unset !important;
    width: 20px;
    padding: 0.75rem 0;
  }
  .agile__dots {
    margin-top: 0.875rem;
  }
}
:deep(.messages-slider) {
  .agile__nav-button--prev,
  .agile__nav-button--next {
    top: 49px !important;
    bottom: 49px !important;
    width: 24px;
    padding: 0;
  }
}
:deep(.message) {
  position: relative;
  .card-text {
    > *:last-child {
      margin-bottom: 0;
    }
  }
}
:deep(.message-done) {
  .iconic-circle-check {
    font-size: 4.5rem !important;
  }
}
</style>
