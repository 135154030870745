<script>
import ActionButton from './ActionButton.vue'
export default {
  extends: ActionButton,
  emits: ['skip'],
  data() {
    return {
      direction: 'right',
      actionClass: 'btn-skip',
    }
  },
  methods: {
    onClick() {
      this.$emit('skip')
    },
  },
}
</script>
