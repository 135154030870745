<template>
  <modal
    :class="{ saving: submitting }"
    @close-modal="$emit('close')"
  >
    <template #header>
      <h5>User <span class="font-weight-light">| Invitation</span></h5>
    </template>
    <template #body>
      <BaseForm
        ref="userForm"
        :form="form"
        url="/users"
        method="post"
        :toasted-success="successMessage"
        :toaster-error="errorMessage"
        @submitting="submitting = true"
        @success="(response) => $emit('success', response.data)"
        @error="onError"
      >
        <div class="form-row">
          <FormInput
            v-model="form.first_name"
            name="first_name"
            class="col"
            autocomplete="given-name"
            :required="true"
            :error="form.errors.get('first_name')"
          />
          <FormInput
            v-model="form.last_name"
            name="last_name"
            class="col"
            autocomplete="family-name"
            :required="true"
            :error="form.errors.get('last_name')"
          />
        </div>
        <div class="form-row">
          <FormEmail
            v-model="form.email"
            name="email"
            class="col"
            autocomplete="email"
            :required="true"
            :error="form.errors.get('email')"
          />
        </div>
      </BaseForm>
    </template>
    <template #footer>
      <FormSubmit
        size="sm"
        :text="buttonText"
        :submit-function="() => $refs.userForm.onSubmit()"
      />
    </template>
  </modal>
</template>
<script>
export default {
  props: {
    roles: {
      type: Array,
      default: () => ['Employee'],
    },
    buttonText: {
      type: String,
      default: 'Invite User',
    },
    successMessage: {
      type: String,
      default: 'User Invited',
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'success', 'error'],
  data() {
    return {
      submitting: false,
      form: this.$form({
        first_name: null,
        last_name: null,
        email: null,
        roles: this.roles,
      }),
    }
  },
  methods: {
    onError(e) {
      this.submitting = false
      this.$emit('error', e)
    },
  },
}
</script>
