<template>
  <div class="toast-inner-content d-flex">
    <div class="content">
      <h5>{{ title }}</h5>
      <div
        v-if="resolution"
        v-html="resolution"
      />
    </div>
    <btn
      v-if="closable"
      class="btn-xs align-baseline btn-light ml-3 align-self-center"
      :link="false"
      @click="$emit('close-toast')"
    >
      Dismiss
    </btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    message: {
      type: [String, Object],
      default: '',
    },
  },
  emits: ['close-toast'],
  data() {
    return {
      title: 'An Error has occured',
      resolution: '<p class="font-sm mb-0">Loading error resolution...</p>',
      closable: false,
    }
  },
  mounted() {
    this.postError(this.message)
      .then(() => {
        this.resolution =
          '<p class="font-sm mb-1">Rest assured we have been notified of this issue and will work quickly to resolve it.</p><p class="font-sm mb-0">Further, we will attempt to notify you upon resolution, until then if you have question email <a class="text-white" href="mailto:support@wavefire.com">support@wavefire.com</a></p>'
      })
      .catch(() => {
        this.resolution =
          '<p class="font-sm mb-1">Further, we were not able to notify the team of this error (most like because your are logged out of WaveFire).</p><p class="font-sm mb-1">Rest assured we are here to help. If this was unexpected please contact us at <a class="text-white" href="mailto:support@wavefire.com">support@wavefire.com</a></p>'
      })
      .finally(() => {
        this.title = 'Unfortunately an error occured.'
        this.closable = true
      })
  },
  methods: {
    ...mapActions({
      postError: 'app/postError',
    }),
  },
}
</script>
