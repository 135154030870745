export const MailTo = (email) => {
  if (email) {
    const a = document.createElement('a')
    a.href = `mailto:${email}`
    a.setAttribute('target', '_blank')
    a.className = 'd-none'
    document.body.appendChild(a)
    a.click()
    setTimeout(function () {
      document.body.removeChild(a)
    }, 1)
  }
}

export default MailTo
