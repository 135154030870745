const defaultState = () => ({
  loading: false,
  saving: false,
  categories: [],
})

const excludeFields = ['loading', 'saving']

export { defaultState, excludeFields }

export default defaultState
