/* ============
 * Vuex Store
 * ============
 *
 * The store of the application.
 *
 * http://vuex.vuejs.org/en/index.html
 */

import Vuex from 'vuex'
import onLogout from './events/onLogout'
import { persistencePlugin } from '@/store/plugins/PersistencePlugin'
import { isSkippedNamespace } from '@/store/plugins/SkippedCache'
import { DefaultState, getDefaultValue } from '@/store/plugins/DefaultState'
import { setField } from '@/store/plugins/StateMapper'

// Modules
import auth from './modules/auth'
import app from './modules/app'
import comments from './modules/comments'
import documentrequestlists from './modules/documentrequestlists'
import documentrequestlist from './modules/documentrequestlist'
import documentrequests from './modules/documentrequests'
import documentrequest from './modules/documentrequest'
import documentRequestPeriods from './modules/documentRequestPeriods'
import dictionary from './modules/dictionary'
import entities from './modules/entities'
import questions from './modules/questions'
import threats from './modules/threats'
import categories from './modules/categories'
import company from './modules/company'
import controls from './modules/controls'
import answers from './modules/answers'
import plans from './modules/plans'
import frameworks from './modules/frameworks'
import risks from './modules/risks'
import faqs from './modules/faqs'
import ui from './modules/ui'
import users from './modules/users'
const isJson = (value) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}

const store = new Vuex.Store({
  state: {
    initialized: false,
    resetting: false,
  },
  actions: {
    async checkMigrations(context, items) {
      store.state.initialized = false
      for (const key in items) {
        const [namespace, field] = key.split('.')
        if (!!namespace && !!key) {
          const value = localStorage.getItem(key)
          const parsedValue = isJson(value) ? JSON.parse(value) : null
          await setField(namespace, field, parsedValue)
        }
        await localStorage.removeItem(key)
      }
    },
  },
  mutations: {
    loadFromCache(state, cached) {
      if (!store.state.initialized) {
        Object.entries(cached).forEach(([namespace, elements]) => {
          state[namespace] = Object.assign({}, DefaultState[namespace], state[namespace], elements)
        })
      }
      store.state.initialized = true
    },

    resetRemembered(state) {
      state.auth.remembered = getDefaultValue('auth', 'remembered')
    },
    resetStore(state) {
      store.state.resetting = true
      for (const namespace in state) {
        if (!isSkippedNamespace(namespace)) {
          store.commit(`${namespace}/RESET`)
        }
      }
      store.state.resetting = false
    },
  },
  modules: {
    answers,
    app,
    auth,
    categories,
    comments,
    company,
    controls,
    documentrequestlists,
    documentrequestlist,
    documentrequests,
    documentrequest,
    documentRequestPeriods,
    dictionary,
    entities,
    faqs,
    frameworks,
    plans,
    questions,
    risks,
    threats,
    ui,
    users,
  },

  /**
   * If strict mode should be enabled (Never in Prod).
   */
  strict: !import.meta.env.PROD,

  /**
   * Plugins used in the store.
   */
  plugins: [persistencePlugin, onLogout],
})

export default store
