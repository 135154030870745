export const ADD = 'ADD'
export const CONCAT = 'CONCAT'
export const REMOVE = 'REMOVE'
export const UPDATE = 'UPDATE'
export const SET = 'SET'
export const ERROR = 'ERROR'
export const RESUME = 'RESUME'
export const EMPTY = 'EMPTY'
export const LOADING = 'LOADING'
export const QUESTION = 'QUESTION'
export const SAVING = 'SAVING'
export const TOPICS = 'TOPICS'
export const STAYPUT = 'STAYPUT'
export const OTHERDIVISIONENTITIES = 'OTHERDIVISIONENTITIES'
export const RESET = 'RESET'

export default {
  ADD,
  CONCAT,
  REMOVE,
  UPDATE,
  SET,
  ERROR,
  RESUME,
  EMPTY,
  LOADING,
  QUESTION,
  SAVING,
  TOPICS,
  STAYPUT,
  OTHERDIVISIONENTITIES,
  RESET,
}

export const noPersist = [LOADING]
