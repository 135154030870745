const defaultState = () => ({
  loading: false,
  saving: false,
  questions: [],
  loadingAssignments: false,
  loadingCollaborations: false,
  assignments: {},
  collaborations: {},
})

const excludeFields = ['loading', 'loadingAssignments', 'loadingCollaborations', 'saving']

export { defaultState, excludeFields }

export default defaultState
