<script>
import BaseFormInput from './BaseFormInput.vue'
export default {
  extends: BaseFormInput,
  data() {
    return {
      type: 'input',
    }
  },
}
</script>
