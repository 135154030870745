<template>
  <modal
    ref="modal"
    :width="1200"
    :class="{ loading: switching }"
    body-class="sticky-table searchable-table font-sm p-0 flex-column"
    @close-modal="$emit('close')"
  >
    <template #header>
      <h5>
        Division Navigation
        <span class="font-weight-light">| Select Division</span>
      </h5>
    </template>
    <template #body>
      <Grid
        table-class="table table-hover mb-0"
        search="name"
        search-placeholder="Filter divisions by name"
        :data="gridAssets"
        :columns="gridColumns"
      />
      <div class="flex-shrink-1 text-center font-xs font-italic border-top p-1">
        click and row to select the division
      </div>
    </template>
  </modal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  emits: ['close'],
  data() {
    return {
      switching: false,
      gridColumns: [
        {
          key: 'name',
          label: 'Division Name',
          width: 'auto',
          sortable: true,
        },
        {
          key: 'description',
          width: 'auto',
          sortable: true,
        },
        {
          key: 'admin',
          label: 'Primary Admin',
          class: 'text-nowrap',
          width: 'auto',
          sortable: true,
        },
        {
          key: 'admins',
          label: '# Admins',
          width: 100,
          class: 'text-nowrap text-center',
          sortable: true,
        },
        {
          key: 'entities',
          label: '# Entities',
          width: 105,
          class: 'text-nowrap text-center',
          sortable: true,
        },
      ],
      gridAssets: [],
    }
  },
  computed: {
    ...mapState('company', {
      activeDivision: 'activeDivision',
    }),
    ...mapGetters({
      divisions: 'auth/myDivisions',
    }),
    activeDivisions() {
      return this.divisions.filter((d) => !d.deleted_at)
    },
  },
  watch: {
    activeDivisions(current, previous) {
      if (!this.lodash.isEqual(current, previous) && !this.switching) {
        this.buildAssets()
      }
    },
  },
  beforeMount() {
    if (this.activeDivisions.length) {
      this.buildAssets()
    }
    Promise.allSettled([this.loadOrganization(), this.getUser()])
  },
  mounted() {
    this.$el.addEventListener('click', this.onClick)
  },
  unmounted() {
    this.$el.removeEventListener('click', this.onClick)
  },
  methods: {
    ...mapActions({
      appLoading: 'app/loading',
      changeDivision: 'company/changeDivision',
      loadOrganization: 'company/load',
      getUser: 'auth/getUser',
      loadEntites: 'entities/load',
      loadQuestions: 'questions/load',
      clearAnswers: 'answers/clear',
      clearQuestions: 'questions/clear',
    }),
    buildAssets() {
      this.gridAssets = this.activeDivisions.map((division) => ({
        rowClass: division.id === this.activeDivision ? 'bg-dark-highlight' : `select-division division-${division.id}`,
        name: {
          content: division.name,
        },
        description: {
          content: division.description || 'N/A',
        },
        admin: {
          content: division.primary_admin ? division.primary_admin.full_name : 'N/A',
        },
        admins: {
          class: 'text-center',
          content: division.admins_count || '0',
        },
        entities: {
          class: 'text-center',
          content: division.entities_count || '0',
        },
        actions: {
          class: 'text-nowrap text-center',
          content: [
            {
              methods: {
                clicked: () => this.switchTo(division.id),
                disabled: () => division.id === this.activeDivision,
              },
              template: `<btn :class="{ disabled: disabled() }" @click="clicked">Select</btn>`,
            },
          ],
        },
      }))
    },
    onClick(event) {
      const parent = event.target.parentElement
      if (parent && parent.classList.contains('select-division')) {
        const divisionId = Array.from(parent.classList).reduce((num, item) => {
          const [name, id] = item.split('-')
          if (!!name && !!parseInt(id)) {
            num = parseInt(id)
          }
          return num
        }, null)

        this.switchTo(divisionId)
      }
    },
    switchTo(divisionId) {
      if (divisionId) {
        this.switching = true
        this.$forceNextTick().then(() => {
          this.changeDivision(divisionId).then(() => {
            this.$refs.modal.close()
          })
        })
      }
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
:deep(.wavefire-grid) {
  .filter-search {
    input {
      padding-left: 1rem;
    }
    span {
      right: 1rem;
    }
  }
  .vue-grid {
    tr {
      td:first-child,
      th:first-child {
        padding-left: 1rem;
      }
      td:last-child,
      th:last-child {
        padding-right: 1rem;
      }
    }
    thead {
      tr {
        th {
          box-shadow: inset 0 -1px 0 #dfdfdf !important;
        }
      }
    }
    tbody {
      &:first-of-type {
        tr {
          td {
            border-top: 0;
          }
        }
      }
      tr {
        &.select-division {
          &:hover {
            cursor: pointer;
          }
        }
        &.bg-dark-highlight {
          &:hover {
            background-color: $dark-highlight !important;
          }
        }
      }
    }
  }
}
</style>
