<template>
  <colgroup>
    <col
      v-if="selectable"
      width="30"
    />
    <col
      v-if="collapsable"
      width="30"
    />
    <col
      v-for="(column, i) in columns.filter((c) => !c.hidden)"
      :key="`grid-column-${i}`"
      :width="column.width || false"
    />
  </colgroup>
</template>
<script>
export default {
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>
