<template>
  <modal
    body-class="p-0 d-flex flex-column"
    :width="800"
    @close-modal="$emit('close')"
  >
    <template #header>
      <h5>
        {{ criteria.regulation }}
        <span class="font-weight-light"> | {{ criteria.identifier }} </span>
      </h5>
    </template>
    <template #body>
      <div
        v-if="criteria.category || criteria.subcategory"
        class="row border-bottom no-gutters flex-shrink-1 font-xs line-height-xxxs"
      >
        <div
          v-if="criteria.category"
          class="col py-2 px-3"
        >
          <strong
            v-if="criteria.category"
            v-text="`Category`"
          />
          <div
            v-if="criteria.category"
            v-html="criteria.category"
          />
        </div>
        <div
          v-if="criteria.subcategory"
          class="col py-2 px-3 text-right"
        >
          <strong v-text="`Subcategory`" />
          <div v-html="criteria.subcategory" />
        </div>
      </div>
      <row
        v-if="criteria.standard"
        class="border-bottom no-gutters flex-shrink-1 font-xs line-height-xxxs"
        col-class="col py-2 px-3 text-center"
      >
        <strong
          class="mr-1"
          v-text="`Standard:`"
        />
        <span v-html="criteria.standard" />
      </row>
      <div class="row font-sm no-gutters flex-grow-1 no-scroll">
        <div
          v-if="criteria.requirement"
          class="col pt-2 pb-3 px-3 scroll-y h-100"
          :class="{ 'border-right': criteria.guidance }"
        >
          <strong class="font-xs"> Requirement </strong>
          <div v-html="criteria.requirement" />
        </div>
        <div
          v-if="criteria.guidance"
          class="col pt-2 pb-3 px-3 scroll-y h-100"
        >
          <strong class="font-xs"> Guidance </strong>
          <div v-html="criteria.guidance" />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    criteria: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
}
</script>
