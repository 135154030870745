import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'
import ApiService from "../../../services/ApiService"
import _, { get } from 'lodash'
import {
  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationPerPage,
  updatePaginationLastPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL
} from "../../globals/pagination.global"
import { RESET } from "../ui/mutation-types"

const defaultState = () => {
  return {
    isLoading: false,
    isLoadingAssignments: false,
    isLoadingComments: false,
    isLoadingFiles: false,
    documentRequest: {
      id: null,
      uuid: null,
      number: null,
      title: null,
      description: null,
      status: null,
      due_date: null,
      document_request_list_id: null,
      document_request_list: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
    },

    // Relations
    assignments: [],
    comments: [],
    // document_request_list: {},
    files: [],

    api: {
      filters: {
        withDocumentRequestList: true,
      },
      paginated: false,
      pagination: {
        currentPage: 1,
        lastPage: null,
        perPage: 50,
        from: null,
        to: null,
        total: null
      },
      sorts: {},

      // Relations
      assignments: {},
      comments: {
        filters: {},
        pagination: {},
        sorts: {
          created_at: 'desc'
        }
      },
      files: {
        filters: {},
        pagination: {},
        sorts: {}
      },
    }


  }
}

const state = defaultState()

const getters = {
  isLoading: (state) => {
    return state.isLoading
  },
  isLoadingAssignments: (state) => {
    return state.isLoadingAssignments
  },
  isLoadingComments: (state) => {
    return state.isLoadingComments
  },
  isLoadingFiles: (state) => {
    return state.isLoadingFiles
  },
  assignments: (state) => {
    return state.assignments
  },
  comments: (state) => {
    return state.comments
  },
  files: (state) => {
    return state.files
  },
  documentRequest: (state) => {
    return state.documentRequest
  },
  documentRequestAssignments: (state) => {
    return state.documentRequest.assignments
  },
  documentRequestComments: (state) => {
    return state.documentRequest.comments
  },
  title: (state) => {
    return state.documentRequest.title
  },
  description: (state) => {
    return state.documentRequest.description
  },
  getFilters: (state) => {
    return state.filters
  },
}

const actions = {

  // Imported pagination actions
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationLastPage,
  updatePaginationPerPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,

  async getDocumentRequest({commit, state, dispatch}, parameters) {

    // await commit('IS_LOADING', true)
    let url = `/document-requests/` + parameters.id;

    console.log("---- parameters ----")
    console.log(parameters)

    return await ApiService.get(url, {
      vuexModule: 'documentrequest',
      api: state.api
    })
      .then((response) => {
        let data = response.data

        console.log("---- response data ----")
        console.log(data)

        commit('SET_DOCUMENT_REQUEST', data.data)
      })
      // .catch((e) => {
      //   toast.error(e)
      // })
      .finally(() => {
        commit('IS_LOADING', false)
      })
  },

  async getComments({commit, getters, rootState, rootGetters, dispatch}, parameters) {
    let url = `/document-requests/` + parameters.id + '/comments';
    commit('SET_IS_LOADING_COMMENTS', true)
    return await ApiService.get(url, {
      vuexModule: 'documentrequest',
      api: state.api.comments,
    })
      .then((response) => {
        let data = response.data
        commit('SET_COMMENTS', data.data)
      })
      .finally(() => {
        commit('SET_IS_LOADING_COMMENTS', false)
      })
  },

  async getAssignments({commit, getters, rootState, rootGetters, dispatch}, parameters) {
    let url = `/document-requests/` + parameters.id + '/assignments';
    commit('SET_IS_LOADING_ASSIGNMENTS', true)
    return await ApiService.get(url, {
      vuexModule: 'documentrequest',
      api: state.api.comments,
    })
      .then((response) => {
        let data = response.data
        commit('SET_ASSIGNMENTS', data.data)
      })
      // .catch((e) => {
      //   toast.error(e)
      // })
      .finally(() => {
        commit('SET_IS_LOADING_ASSIGNMENTS', false)
      })
  },

  async getFiles({commit, getters, rootState, rootGetters, dispatch}, parameters) {
    let url = `/document-requests/` + parameters.id + '/files';

    commit('SET_IS_LOADING_FILES', true)

    return await ApiService.get(url, {
      vuexModule: 'documentrequest',
      api: state.api.comments,
    })
      .then((response) => {
        let data = response.data
        commit('SET_FILES', data.data)
      })
      // .catch((e) => {
      //   toast.error(e)
      // })
      .finally(() => {
        commit('SET_IS_LOADING_FILES', false)
      })
  },

  async create({commit, getters, rootState, rootGetters, dispatch}, payload) {
    await commit('SET_IS_LOADING', true)

    let url = `/document-requests/`

    return await ApiService.post(url, payload)
      .then((response) => {
        toast.success(response.data.message);
        return response;
      })
      // .catch((e) => {
      //   toast.error(e)
      // })
      .finally(() => {
        commit('SET_IS_LOADING', false)
      })
  },

  async update({commit, state, getters, rootState, rootGetters, dispatch}, payload) {
    await commit('SET_IS_LOADING', true)

    let url = `/document-requests/` + state.documentRequest.id

    return await ApiService.post(url, payload)
      .then((response) => {
        toast.success(response.data.message);
        return response;
      })
      // .catch((e) => {
      //   toast.error(e)
      // })
      .finally(() => {
        commit('SET_IS_LOADING', false)
      })
  },

  // async update({commit, state, getters, rootState, rootGetters, dispatch}, payload) {
  //   await commit('SET_IS_LOADING_ASSIGNMENTS', true)
  //   let url = `/document-requests/` + state.documentRequest.id;
  //
  //   return await ApiService.post(url, payload)
  //     .then((response) => {
  //       toast.success(response.data.message);
  //       return response;
  //     })
  //     // .catch((e) => {
  //     //   toast.error(e)
  //     // })
  //     .finally(() => {
  //       commit('SET_IS_LOADING_ASSIGNMENTS', false)
  //     })
  // },

  async createComment({commit, state, getters, rootState, rootGetters, dispatch}, payload) {
    let url = `/document-requests/` + state.documentRequest.id + '/comments';
    return await ApiService.post(url, payload)
      .then((response) => {
        toast.success(response.data.message);
        return response;
      })
      .finally(() => {
        commit('SET_IS_LOADING_ASSIGNMENTS', false)
      })
  },

  async getFileDownloadLink({commit, state, getters, rootState, rootGetters, dispatch}, params) {
    let url = `/document-requests/${params.documentRequestId}/files/${params.fileId}/download-link`
    return await ApiService.get(url)
      .then((response) => {
        console.log("response.data")
        return response.data.data
      })
  },

  async deleteFile({commit, state, getters, rootState, rootGetters, dispatch}, fileId) {
    let url = `/document-requests/${state.documentRequest.id}/files/${fileId}`

    return await ApiService.delete(url, {})
      .then((response) => {
        commit('SET_IS_LOADING_FILES', false);
      })
  },

}

const mutations = {
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL,

  SET(state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },

  IS_LOADING(state, bool) {
    state.isLoading = bool
  },
  SET_IS_LOADING(state, bool) {
    state.isLoading = bool
  },
  SET_IS_LOADING_FILES(state, bool) {
    state.isLoadingFiles = bool
  },
  SET_IS_LOADING_COMMENTS(state, bool) {
    state.isLoadingComments = bool
  },
  SET_IS_LOADING_ASSIGNMENTS(state, bool) {
    state.isLoadingAssignments = bool
  },
  SET_DOCUMENT_REQUEST(state, item) {
    state.documentRequest = item
  },
  SET_ASSIGNMENTS(state, assignments) {
    state.assignments = assignments
  },
  SET_COMMENTS(state, comments) {
    state.comments = comments
  },
  SET_FILES(state, files) {
    state.files = files
  },
  SET_TITLE(state, title) {
    state.documentRequest.title = title
  },
  SET_STATUS(state, status) {
    state.documentRequest.status = status
  },
  SET_DESCRIPTION(state, description) {
    state.documentRequest.description = description
  },
  SET_DUE_DATE(state, dueDate) {
    state.documentRequest.due_date = dueDate
  },

  [RESET](state) {
    Object.assign(state, defaultState())
  },

}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    // nested
  }
}
