/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */

import store from '@/store'

export default [
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/authenticated']) {
        store.dispatch('auth/redirectToHomePage')
      } else {
        next({
          name: 'login.index',
          replace: true,
        })
      }
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard.index',
    component: () => import('@/pages/dashboard/Index.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company',
    name: 'company.index',
    component: () => import('@/pages/company/Index.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/users',
    name: 'company.users',
    component: () => import('@/pages/company/Users.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/users/:userId/manage',
    name: 'company.users.manage',
    component: () => import('@/pages/company/ManageUser.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/groups',
    name: 'company.groups',
    component: () => import('@/pages/company/Groups.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/entities',
    name: 'company.entities',
    component: () => import('@/pages/company/Entities.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/divisions',
    name: 'company.divisions',
    component: () => import('@/pages/company/Divisions.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/organization-dashboard',
    name: 'company.dashboard',
    component: () => import('@/pages/company/Dashboard.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/assets',
    name: 'company.assets',
    component: () => import('@/pages/company/Assets.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/assessment-periods',
    name: 'company.assessment-periods',
    component: () => import('@/pages/company/AssessmentPeriods.vue'),
    meta: {
      title: 'Assessments',
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/assessment-periods/:assessmentPeriodId(\\d+)',
    name: 'company.assessment-period.overview',
    meta: {
      title: 'Assessment Overview',
      auth: 'requiresAdmin',
    },
    component: () => import('@/pages/company/AssessmentPeriodView.vue'),
  },
  {
    path: '/company/report-builder',
    name: 'company.report-builder',
    component: () => import('@/pages/company/Reports.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/manage-risk',
    name: 'company.manage-risk',
    component: () => import('@/pages/company/ManageRisk.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/setup',
    name: 'company.setup',
    component: () => import('@/pages/company/Setup.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/rescore',
    name: 'company.rescore',
    component: () => import('@/pages/company/Rescore.vue'),
    meta: {
      title: 'Rescore',
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/frameworks',
    name: 'company.frameworks',
    component: () => import('@/pages/company/Frameworks.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/frameworks/:frameworkId/questions',
    name: 'company.frameworkQuestions',
    component: () => import('@/pages/company/FrameworkQuestions.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/company/controls',
    name: 'company.controls',
    meta: {
      auth: 'requiresAdmin',
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['company/hasFeature']('controls')) {
        store.dispatch('app/error', 'Feature Controls is required to view this page')
        if (!from.name || from.name === 'company.controls') {
          store.dispatch('auth/redirectToHomePage')
        } else {
          next(false)
        }
      } else {
        next()
      }
    },
    component: () => import('@/pages/company/Controls.vue'),
  },
  {
    path: '/company/controls/:controlId(\\d+)/overview',
    name: 'company.control.overview',
    meta: {
      auth: 'requiresAdmin',
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['company/hasFeature']('controls')) {
        store.dispatch('app/error', 'Feature "Controls" is required to view this page')
        if (!from.name || from.name === 'controls.overview') {
          store.dispatch('auth/redirectToHomePage')
        } else {
          next(false)
        }
      } else {
        next()
      }
    },
    component: () => import('@/pages/company/ControlOverview.vue'),
  },
  {
    path: '/company/control-objectives',
    name: 'company.control-objectives',
    meta: {
      auth: 'requiresAdmin',
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['company/hasFeature']('controls')) {
        store.dispatch('app/error', 'Feature Controls is required to view this page')
        if (!from.name || from.name === 'company.control-objectives') {
          store.dispatch('auth/redirectToHomePage')
        } else {
          next(false)
        }
      } else {
        next()
      }
    },
    component: () => import('@/pages/company/ControlObjectives.vue'),
  },
  {
    path: '/company/control-functions',
    name: 'company.control-functions',
    meta: {
      auth: 'requiresAdmin',
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['company/hasFeature']('controls')) {
        store.dispatch('app/error', 'Feature Controls is required to view this page')
        if (!from.name || from.name === 'company.control-functions') {
          store.dispatch('auth/redirectToHomePage')
        } else {
          next(false)
        }
      } else {
        next()
      }
    },
    component: () => import('@/pages/company/ControlFunctions.vue'),
  },
  {
    path: '/company/control-processes',
    name: 'company.control-processes',
    meta: {
      auth: 'requiresAdmin',
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['company/hasFeature']('controls')) {
        store.dispatch('app/error', 'Feature Controls is required to view this page')
        if (!from.name || from.name === 'company.control-processes') {
          store.dispatch('auth/redirectToHomePage')
        } else {
          next(false)
        }
      } else {
        next()
      }
    },
    component: () => import('@/pages/company/ControlProcesses.vue'),
  },
  {
    path: '/company/control-risks',
    name: 'company.control-risks',
    meta: {
      auth: 'requiresAdmin',
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['company/hasFeature']('controls')) {
        store.dispatch('app/error', 'Feature Controls is required to view this page')
        if (!from.name || from.name === 'company.control-risks') {
          store.dispatch('auth/redirectToHomePage')
        } else {
          next(false)
        }
      } else {
        next()
      }
    },
    component: () => import('@/pages/company/ControlRisks.vue'),
  },
  {
    path: '/company/policies',
    name: 'company.policies',
    meta: {
      auth: 'requiresAdmin',
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['company/hasFeature']('policies')) {
        store.dispatch('app/error', 'Feature Policies is required to view this page')
        if (!from.name || from.name === 'company.policies') {
          store.dispatch('auth/redirectToHomePage')
        }
      } else {
        next()
      }
    },
    component: () => import('@/pages/company/Policies.vue'),
  },
  {
    path: '/login',
    name: 'login.index',
    component: () => import('@/pages/login-v2/Index.vue'),
    beforeEnter: (to, from, next) => {
      return next();

      if (store.getters['auth/authenticated']) {
        store.dispatch('auth/redirectToHomePage')
      } else {
        next()
      }
    },
  },
  {
    path: '/login-v2',
    name: 'login.new',
    component: () => import('@/pages/login/Index.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/authenticated']) {
        store.dispatch('auth/redirectToHomePage')
      } else {
        next()
      }
    },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: () => {
      store.dispatch('auth/logout')
      return false
    },
  },
  {
    path: '/login/forgot/:token?',
    name: 'login.forgot',
    component: () => import('@/pages/login/ForgotPassword.vue'),
    props: (router) => ({
      token: router.params.token,
    }),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/authenticated']) {
        store.dispatch('auth/redirectToHomePage')
      } else {
        next()
      }
    },
  },
  {
    path: '/login/reset-password/:token?',
    name: 'login.reset',
    component: () => import('@/pages/login/ResetPassword.vue'),
    props: (router) => ({
      token: router.params.token,
    }),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/authenticated']) {
        store.dispatch('auth/redirectToHomePage')
      } else {
        next()
      }
    },
  },
  {
    path: '/register/:token?',
    name: 'login.registration',
    component: () => import('@/pages/login/UserRegistration.vue'),
    props: (router) => ({
      token: router.params.token,
    }),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/authenticated']) {
        store.dispatch('auth/redirectToHomePage')
      } else {
        next()
      }
    },
  },
  {
    path: '/action-plans',
    name: 'action-plans.index',
    meta: {
      title: 'My Action Plans',
    },
    component: () => import('@/pages/action-plans/Index.vue'),
  },
  {
    path: '/action-plans/accounting',
    name: 'action-plans.accounting',
    meta: {
      title: 'My Action Plans Accounting',
    },
    component: () => import('@/pages/action-plans/Accounting.vue'),
  },
  {
    path: '/action-plans/:planId(\\d+)',
    name: 'action-plans.view',
    meta: {
      title: 'Manage Action Plan',
    },
    component: () => import('@/pages/action-plans/View.vue'),
    props: (route) => ({
      showTaskId: route.params.showTaskId || null,
    }),
  },
  {
    path: '/policies',
    name: 'policies.index',
    meta: {
      title: 'My Policies',
    },
    component: () => import('@/pages/policies/Index.vue'),
  },
  {
    path: '/document-request-lists',
    name: 'document-request-lists.index',
    meta: {
      title: 'Document Request Lists',
    },
    component: () => import('@/pages/document-requests/DocumentRequestLists.vue'),
  },

  {
    path: '/document-request-lists/:id',
    name: 'document-request-lists.show',
    meta: {
      title: 'Document Requests',
    },
    component: () => import('@/pages/document-requests/DocumentRequestList.vue'),
  },

  {
    path: '/document-requests/:id',
    name: 'document-requests.show',
    meta: {
      title: 'Document Requests',
    },
    component: () => import('@/pages/document-requests/DocumentRequest.vue'),
  },

  {
    path: '/document-requests/:id/upload',
    name: 'document-requests.upload',
    meta: {
      title: 'Document Request Upload',
    },
    component: () => import('@/pages/document-requests/DocumentRequestUpload.vue'),
  },


  {
    path: '/document-request/:token?',
    name: 'login.document-request',
    meta: {
      title: 'Document Request',
    },
    component: () => import('@/pages/document-requests/PublicDocumentRequest.vue'),
    props: (router) => ({
      token: router.params.token,
    }),
  },
  {
    path: '/entities/:entityId(\\d+)?',
    name: 'entities.index',
    component: () => import('@/pages/entities/Index.vue'),
    meta: {
      auth: 'notEmployee',
    },
  },
  {
    path: '/entities/:entityId(\\d+)/overview',
    name: 'entity.overview',
    component: () => import('@/pages/entities/Overview.vue'),
    meta: {
      auth: 'notEmployee',
    },
  },
  {
    path: '/questions',
    name: 'questions.index',
    component: () => import('@/pages/questions/Index.vue'),
  },
  {
    path: '/questions/:questions/topic/:topic/question/:question/entity/:entity',
    name: 'questions.answer',
    component: () => import('@/pages/questions/Answer.vue'),
    props: (route) => ({
      returnToPlanId: route.params.returnToPlanId || null,
    }),
  },
  {
    path: '/risks',
    name: 'risks.index',
    component: () => import('@/pages/risks/Index.vue'),
    meta: {
      auth: 'requiresAdmin',
    },
  },
  {
    path: '/notifications',
    name: 'notifications.index',
    component: () => import('@/pages/notifications/Index.vue'),
  },
  {
    path: '/profile',
    name: 'profile.index',
    component: () => import('@/pages/profile/Index.vue'),
  },
  {
    path: '/select-company',
    name: 'profile.company',
    component: () => import('@/pages/profile/Company.vue'),
  },
  {
    path: '/faq/search/:search?',
    name: 'faq.search',
    component: () => import('@/pages/faq/Search.vue'),
  },
  {
    path: '/faq/:search?',
    name: 'faq.index',
    component: () => import('@/pages/faq/Index.vue'),
    props: true,
  },
  {
    path: '/admin',
    name: 'admin.index',
    component: () => import('@/pages/admin/Index.vue'),
    meta: {
      auth: 'requiresSysAdmin',
    },
  },
  {
    path: '/admin/faq',
    name: 'admin.faqs',
    component: () => import('@/pages/admin/Faqs.vue'),
  },
  {
    path: '/admin/regulations',
    name: 'admin.regulations',
    component: () => import('@/pages/admin/Regulations.vue'),
    meta: {
      auth: 'requiresSysAdmin',
    },
  },
  {
    path: '/admin/threats',
    name: 'admin.threats',
    component: () => import('@/pages/admin/Threats.vue'),
    meta: {
      auth: 'requiresSysAdmin',
    },
  },
  {
    path: '/admin/categories',
    name: 'admin.categories',
    component: () => import('@/pages/admin/Categories.vue'),
    meta: {
      auth: 'requiresSysAdmin',
    },
  },
  {
    path: '/admin/asset-categories',
    name: 'admin.assetCategories',
    component: () => import('@/pages/admin/AssetCategories.vue'),
    meta: {
      auth: 'requiresSysAdmin',
    },
  },
  {
    path: '/admin/frameworks',
    name: 'admin.frameworks',
    component: () => import('@/pages/admin/Frameworks.vue'),
    meta: {
      auth: 'requiresSysAdmin',
    },
  },
  {
    path: '/admin/frameworks/:frameworkId/questions',
    name: 'admin.frameworkQuestions',
    component: () => import('@/pages/admin/FrameworkQuestions.vue'),
    meta: {
      auth: 'requiresSysAdmin',
    },
  },
  {
    path: '/admin/reset',
    name: 'admin.reset',
    component: () => import('@/pages/admin/ResetEnvironment.vue'),
    meta: {
      auth: 'requiresSysAdmin',
    },
  },
  {
    path: '/contact',
    name: 'contact.index',
    component: () => import('@/pages/contact/Index.vue'),
  },
  {
    path: '/organization-inactive',
    name: 'errors.organization-inactive',
    meta: {
      title: 'Organization Inactive',
    },
    component: () => import('@/pages/errors/OrganizationInactive.vue'),
  },

  /**
   * 404 Not Found
   */

  {
    path: '/:pathMatch(.*)*',
    name: 'errors.notfound',
    component: () => import('@/pages/errors/NotFound.vue'),
  },
]
