const defaultState = () => ({
  controls: [],
  functions: [],
  processes: [],
  risks: [],
  objectives: [],
  criteria: [],
  documentRequests: [],
  loading: false,
  loadingProcesses: false,
  loadingFunctions: false,
  loadingRisks: false,
  loadingObjectives: false,
  loadingCriteria: false,
  loadingDocumentRequests: false,
})

const excludeFields = []

export { defaultState, excludeFields }

export default defaultState
