const defaultState = () => ({
  loading: false,
  loadingArchived: false,
  periods: [],
  archivedPeriods: [],
  archiving: null,
  unarchiving: null,
  deleting: null,
  restoring: null,
  selectedPeriod: null,
})

const excludeFields = ['loading', 'loadingArchived', 'archiving', 'unarchiving', 'deleting', 'restoring']

export { defaultState, excludeFields }

export default defaultState
