import store from '@/store'
import lodash, { get, differenceBy, isNil, merge } from 'lodash'
import { localForageAuthService } from '@/store/plugins/LocalForageAuthService'
import * as SentryPlugin from "@/plugins/sentry-plugin"

export default async function () {

  // Check localstorage for existing access token
  const accessToken = await localForageAuthService.getItem('auth.access_token');
  const expiresAt = await localForageAuthService.getItem('auth.expires_at');

  if (!isNil(accessToken)) {
    console.log("Persisting user from localStorage")
    console.log("accessToken: " + accessToken)
    console.log("expiresAt: " + expiresAt)

    // Set retrieved auth user data in the store
    const setAuthData = await store.dispatch('auth/setAuthenticationData', {
      access_token: accessToken,
      expires_at: expiresAt
    })

    let tokenNeedsRefresh = await store.dispatch("auth/tokenNeedsRefresh");

    console.log("Token Needs Refresh Result: " + tokenNeedsRefresh);

    if (tokenNeedsRefresh) {
      // Initiate a manual token refresh attempt prior to the app loading
      const attemptTokenRefresh = await store.dispatch("auth/attemptTokenRefresh")
      // Resolve async function
      console.log("attemptTokenRefresh: " + attemptTokenRefresh)
    }

    SentryPlugin.updateUserSessionData()

    return true;

  } else {
    return true;
  }

}
