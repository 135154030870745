<template>
  <FormGroup>
    <slot />
    <FormLabel />
    <div
      class="input-group"
      :class="[groupClass, size ? `input-group-${size}` : null]"
    >
      <div
        v-if="prepend"
        class="input-group-prepend"
      >
        <span
          class="input-group-text iconic"
          :class="`iconic-${prepend}`"
        />
      </div>
      <input
        ref="element"
        :type="type"
        :class="['form-control', elclass, size ? `form-control-${size}` : null]"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :name="name"
        :value="modelValue"
        :readonly="readonly"
        :disabled="disabled"
        :required="required"
        :pattern="pattern"
        :min="getMin"
        :max="getMax"
        :tabindex="tabindex"
        :minlength="minlength"
        :maxlength="maxlength"
        @input="$emit('update:modelValue', $event.target.value)"
        @keyup="onKeyup"
        @change="onChange"
        @blur="onBlur"
      />
    </div>
  </FormGroup>
</template>
<script>
import FormMixins from '@/mixins/FormMixins'
export default {
  mixins: [FormMixins],
  props: {
    prepend: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue', 'keyup', 'enter', 'blur', 'change'],
  data() {
    return {
      type: 'input',
    }
  },
  computed: {
    getMin() {
      return 'min' in this ? this.min : null
    },
    getMax() {
      return 'max' in this ? this.max : null
    },
    withPrepend() {
      return !!(this.prepend && this.prepend.length)
    },
  },
  methods: {
    onKeyup(e) {
      this.$emit('keyup', e)

      if (e.keyCode === 13) {
        this.$emit('enter', e.target.value)
      }
    },
    onBlur(e) {
      this.$emit('blur', e)
    },
    onChange(e) {
      this.$emit('change', e)
    },
  },
}
</script>
