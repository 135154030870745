<template>
  <modal
    :width="400"
    :class="{ saving: loading }"
    body-class="rounded bg-white text-center shadow"
    content-class="bg-transparent shadow-none"
    footer-class="bg-transparent px-0 pb-0 border-0"
    @close-modal="$emit('close')"
  >
    <template #body>
      <div v-html="message" />
    </template>
    <template
      v-if="!loading"
      #footer
    >
      <btn
        :link="false"
        class="flex-fill shadow"
        :class="getCancelClass"
        @click="doCancel"
      >
        {{ cancelButtonText }}
      </btn>
      <btn
        :link="false"
        class="flex-fill ml-3 shadow"
        :class="getConfirmClass"
        @click="doConfirm"
      >
        {{ confirmButtonText }}
      </btn>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    confirmClass: {
      type: String,
      default: null,
    },
    cancelClass: {
      type: String,
      default: null,
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    onCancel: {
      type: Function,
      default: null,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'cancel', 'confirm'],
  computed: {
    getConfirmClass() {
      return this.confirmClass ? this.confirmClass : 'btn-primary'
    },
    getCancelClass() {
      return this.cancelClass ? this.cancelClass : 'btn-light '
    },
  },
  methods: {
    doCancel() {
      if (this.onCancel) {
        this.onCancel()
      } else {
        this.$emit('cancel')
      }
    },
    doConfirm() {
      if (this.onConfirm) {
        this.onConfirm()
      } else {
        this.$emit('confirm')
      }
    },
  },
}
</script>
