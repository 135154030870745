import { setPersistedState } from '@/store/plugins/StateMapper'
import { isSkippedNamespace, isSkippedMutation } from '@/store/plugins/SkippedCache'

const shouldSkipCache = (namespace, type) => isSkippedNamespace(namespace) || isSkippedMutation(namespace, type)

export const persistencePlugin = (store) => {
  store.subscribe((mutation, state) => {
    const [namespace, field] = mutation.type.split('/')

    if (store.state.initialized && store.state.app.appVisible && !shouldSkipCache(namespace, field)) {
      setPersistedState(state, namespace).catch((error) => {
        console.warn(`failed to cache state for: ${namespace}.${field}`, error)
      })
    }
  })
}
