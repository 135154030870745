import { defaultState } from './state'

import {
  SET,
  EMPTY,
  LOAD,
  LOADING,
  LOADINGASSIGNMENTS,
  LOADINGSECTIONS,
  ASSIGNMENTS,
  SECTIONS,
  RESET,
} from './mutation-types'

export default {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  [LOADING](state, bool) {
    state.loading = bool
  },
  [LOADINGASSIGNMENTS](state, loading) {
    state.loadingAssignments = loading
  },
  [LOADINGSECTIONS](state, loading) {
    state.loadingSections = loading
  },
  [LOAD](state, entities) {
    state.entities = entities || []
  },
  [EMPTY](state) {
    state.entities = []
  },
  [ASSIGNMENTS](state, { entityId, assignments }) {
    state.assignments[entityId] = assignments || []
  },
  [SECTIONS](state, { entityId, sections }) {
    state.sections[entityId] = sections || []
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
