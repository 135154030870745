<template>
  <div
    class="page-content h-100"
    :class="getSideBar"
    @scroll="handleScroll"
  >
    <slot name="header" />
    <div
      class="row page-content-body-row"
      :class="bodyRowClass"
    >
      <slot name="body" />
      <SavingDots
        v-if="saving"
        class="saving-wrapper d-flex flex-fill flex-column align-items-center justify-content-center h-100"
        saving-class="flashpulse"
      />
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  props: {
    bodyRowClass: {
      type: [String, Array, Object],
      default: null,
    },
    sideBar: {
      type: String,
      default: null,
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollTop: 0,
    }
  },
  computed: {
    getSideBar() {
      let c = this.sideBar
      switch (this.sideBar) {
        case 'left':
          c = 'col-10'
          break
        case 'right':
          c = 'col-9'
          break
        default:
          c = 'col-12'
          break
      }
      return c
    },
  },
  activated() {
    if (this.scrollTop) {
      this.$el.scrollTop = this.scrollTop
    }
  },
  methods: {
    handleScroll(e) {
      this.scrollTop = e.target.scrollTop
    },
    scrollToTop() {
      this.$el.scrollTop = 0
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.page-content {
  overflow: auto;
  :deep(.page-actions) {
    padding: 1rem 2.5rem;
    border-bottom: 1px solid $gray-300;
  }
  :deep(.page-body) {
    padding: 4rem 6rem;
    div.loading {
      min-height: 125px;
    }
  }
  :deep(table) {
    font-size: 0.875rem;
    thead {
      background: $gray-100;
      th,
      td {
        border-bottom-width: 1px;
        font-weight: normal;
      }
      .sortable-badge {
        transform: translate(-5%, 25%);
      }
    }
    tr {
      th,
      td {
        padding: 0.5rem;
      }
      th:first-child,
      td:first-child {
        padding-left: 1.5rem;
      }
      th:last-child,
      td:last-child {
        padding-right: 1.5rem;
      }
    }
  }
}
</style>
