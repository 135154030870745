<template>
  <FormGroup>
    <RadioSelect
      v-for="(option, index) in options"
      :key="option[optionValueKey] || index"
      :option="option"
      :index="index"
      :required="required"
      :html="label ? label : option"
      :option-value-key="optionValueKey ? optionValueKey : false"
      :list-type="listType"
      :name="`${name}-${index}`"
      :value="modelValue"
      :groupvalue="groupvalue"
      :tooltip="tooltip"
      :class="[option.class || null]"
      :disabled="disabled || option.disabled"
      @update:model-value="$emit('update:modelValue', $event)"
    />
  </FormGroup>
</template>
<script>
import FormMixins from '@/mixins/FormMixins'

export default {
  mixins: [FormMixins],
  props: {
    listType: {
      type: String,
      default: 'alpha',
    },
    options: {
      type: Array,
      default: null,
    },
    optionValueKey: {
      type: String,
      default: null,
    },
    optionValueLabel: {
      type: String,
      default: null,
    },
    groupvalue: {
      type: [String, Number],
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:modelValue'],
}
</script>

<style
  lang="scss"
  scoped
>
:deep(label:last-child) {
  margin-bottom: 0 !important;
  span.num {
    margin-bottom: 0 !important;
  }
}
</style>
