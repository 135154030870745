import moment from '@/plugins/dayjs'
import parsePhoneNumber from 'libphonenumber-js'
import dayjs from '@/plugins/dayjs'
import { escapeRegExp, unescape } from 'lodash'

export const filters = {
  highlight: (string, query) => {
    if (query) {
      return string.replace(new RegExp(escapeRegExp(query), 'gi'), (str) => `<mark>${str}</mark>`)
    } else {
      return string
    }
  },

  capitalize: (value) => {
    if (value) {
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    } else {
      return value
    }
  },

  lowercase: (value) => {
    if (value) {
      return value.toString().toLowerCase()
    } else {
      return value
    }
  },

  uppercase: (value) => {
    if (value) {
      return value.toUpperCase()
    } else {
      return value
    }
  },

  pluralize: (word, count) => {
    if (word && count && (count > 1 || count === true)) {
      if (word.slice(-1).toLowerCase() === 'y') {
        return `${word.substring(0, word.length - 1)}ies`
      } else if (word.slice(-2).toLowerCase() === 'ss') {
        return `${word.substring(0, word.length - 2)}sses`
      } else {
        return `${word}s`
      }
    } else {
      return word
    }
  },

  possessive: (name) => {
    if (name) {
      return `${name}${name.slice(-1).toLowerCase() === 's' ? `'` : `'s`}`
    } else {
      return name
    }
  },

  convertToDateTime: (value) => {
    if (value) {
      return moment(`${value} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString()
    } else {
      return value
    }
  },

  toDate: (value, z) => {
    if (value) {
      let utc = z === undefined ? true : !!z
      if (utc) {
        return moment.utc(value, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YYYY')
      } else {
        return moment(value, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')
      }
    } else {
      return value
    }
  },

  toDateTime: (value, z) => {
    if (value) {
      let utc = z === undefined ? true : !!z
      if (utc) {
        return moment.utc(value).local().format('L LT')
      } else {
        return moment(value).format('L LT')
      }
    } else {
      return value
    }
  },

  currentTime: (value, z) => {
    // if value is null will return now
    let utc = z === undefined ? true : !!z
    if (utc) {
      return moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss')
    } else {
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    }
  },

  slugify: (value) => {
    if (value) {
      return value
        .toString()
        .toLowerCase()
        .trim()
        .replace(/&/g, '-and-') //
        .replace(/[\s\W-]+/g, '-')
        .replace(/--+/g, '-')
        .replace(/^-+|-+$/g, '')
    } else {
      return value
    }
  },

  stripHTML: (value) => {
    if (value) {
      return unescape(
        value
          .replace(/(<li>)/gi, '\r\n $1')
          .replace(/(<p>)/gi, '\r\n $1')
          .replace(/(<div>)/gi, '\r\n $1')
          .replace(/<[^>]+>/g, '')
      )
    } else {
      return value
    }
  },

  phone: (e164) => {
    let pn = parsePhoneNumber(String(e164))
    if (pn) {
      return pn.formatNational()
    } else {
      return e164
    }
  },

  currency: (number) => {
    if (!!number || number === 0) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(number)
    } else {
      return number
    }
  },

  moment: (format, value) => {
    return dayjs(format, value)
  },

  nl2br: (str) => {
    if (typeof str === 'string') {
      return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '<br>')
    } else {
      return str
    }
  },
}

export default {
  install(app) {
    app.config.globalProperties.$filters = filters
  },
}
