<template>
  <div>
    <vue-dropzone
      id="dropzone"
      ref="dropzone"
      class="d-flex flex-row flex-wrap justify-content-center"
      :duplicate-check="true"
      :options="dropzoneOptions"
      @vdropzone-file-added="fileadded"
      @vdropzone-files-added="added"
      @vdropzone-removed-file="fileremoved"
      @vdropzone-duplicate-file="duplicate"
      @vdropzone-sending="sending"
      @vdropzone-error="error"
      @vdropzone-success="success"
      @vdropzone-queue-complete="complete"
    />
    <p
      v-if="showHint"
      class="font-xs font-italic m-0 p-1 text-center file-disclaimer"
    >
      Maximum file size is 50Mb (Hint: try compressing larger files)
    </p>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone-vue3'
import { mapState } from 'vuex'
import ApiService from "../services/ApiService"
import SparkMD5 from 'spark-md5'

export default {
  components: {
    vueDropzone,
  },
  props: {
    sendingFile: {
      type: Function,
      default() {
        return {}
      },
    },
    uploadType: {
      type: String,
      required: false,
      default: ""
    },
    uploadParentId: {
      type: String,
      required: false,
      default: ""
    },
    relatedIds: {
      type: String,
      required: false,
      default: ""
    },
    legacyUrl: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: false,
      default: ""
    },
    config: {
      type: Object,
      default() {
        return {}
      },
    },
    showHint: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['upload-complete', 'upload-progress', 'filesadded', 'fileadded', 'fileremoved', 'duplicatefile'],
  data() {
    return {
      uploadProgress: false,
      uploadTotal: 0,
      uploaded: 0,
      uploadComplete: false,
    }
  },
  computed: {
    ...mapState('auth', {
      token: 'access_token',
    }),
    urlHost() {
      return import.meta.env.VITE_APP_API
    },
    dropzoneOptions() {

      var self = this;

      return Object.assign(
        {
          url: `${this.urlHost}${this.url}`,
          autoProcessQueue: false,
          addRemoveLinks: true,
          maxFilesize: 50,
          timeout: 120000,
          parallelUploads: 2,
          acceptedFiles:
            '.zip,.7z,.tar,.rar,.xml,.json,.html,.htm,.avi,.mkv,.mov,.mp4,.webm,.wmv,.png,.jpg,.jpeg,.gif,.bmp,.tiff,.ppt,.pptx,.sxi,.odp,.otp,.odg,.doc,.docx,.log,.txt,.rtf,.odt,.sxw,.xls,.xlsx,.xlt,.xltx,.xlsb,.csv,.ods,.sxc,.pdf',
          dictDefaultMessage: `<span class="iconic iconic-cloud-transfer-upload mr-2" aria-hidden="true"></span>Drop files here or click to upload.`,
          dictRemoveFile: `<span class="iconic iconic-circle-x cursor-pointer" aria-hidden="true"></span>`,
          dictCancelUpload: '',
          dictUploadCanceled: '',
          dictCancelUploadConfirmation: '',
          headers: {
            Accept: 'application/json',
            'Cache-Control': null,
            'X-Requested-With': 'XmlHttpRequest',
            Authorization: `Bearer ${this.token}`,
          },
          transformFile: async function (file, done) {
            file.md5 = await self.getFileMD5(file);
            file.extension = await self.getFileExtension(file);
            file.relatedIds = await self.generateRelatedIds();
            file.legacyUrl = await self.getLegacyUrl();
            file.uploadType = await self.getUploadType()
            file.uploadParentId = await self.getUploadParentId()

            let initData  = await self.initUpload(file);
            file.presign  = initData.presign;
            file.fileName = initData.name;
            done(file);
          },
        },
        this.config
      )
    },
    uploading() {
      return typeof this.uploadProgress === 'number'
    },
    dz() {
      return this.$refs.dropzone
    },
  },
  watch: {
    token(current) {
      if (current && this.dz.dropzone) {
        this.dz.dropzone.options.headers.Authorization = `Bearer ${current}`
      }
    },
    url(current) {
      this.dz.dropzone.options.url = `${this.urlHost}${current}`
    },
    uploadComplete() {
      if (this.uploadComplete) {
        this.$emit('upload-complete')
      }
    },
    uploadProgress() {
      if (this.uploadProgress) {
        this.$emit('upload-progress', this.uploadProgress)
      }
    },
  },
  methods: {

    initUpload(file) {

      var self = this

      return new Promise(function (resolve, reject) {
        ApiService.post("/files/upload/init", {
          'file_name': file.name,
          'file_extension': file.extension,
          'file_md5': file.md5,
          'content_type': file.type,
          'related_ids': file.relatedIds,
          'legacy_url': file.legacyUrl,
          'upload_type': file.uploadType,
          'upload_parent_id': file.uploadParentId,
        }).then((response) => {
          resolve(response.data.data)
        })

      });
    },

    getFileExtension(file) {
      return new Promise(function (resolve, reject) {
        resolve(file.name.split('.').pop())
      });
    },

    getFileMD5(blob) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        reader.readAsBinaryString(blob);
        reader.onloadend = function () {
          let hash = btoa(SparkMD5.hashBinary(reader.result, true));
          resolve(hash);
        };
      });
    },

    generateRelatedIds() {
      var self = this
      return new Promise(function (resolve, reject) {
        resolve(self.relatedIds)
      });
    },

    getLegacyUrl() {
      var self = this
      return new Promise(function (resolve, reject) {
        resolve(self.url)
      });
    },

    getUploadType() {
      var self = this
      return new Promise(function (resolve, reject) {
        resolve(self.uploadType)
      });
    },

    getUploadParentId() {
      var self = this
      return new Promise(function (resolve, reject) {
        resolve(self.uploadParentId)
      });
    },

    sending(file, xhr, formData) {
      var self = this
      xhr.open(file.presign.attributes.method, file.presign.attributes.action, true);
      Object.keys(file.presign.inputs).forEach(function (key) {
        formData.append(key, file.presign.inputs[key]);
      });
      let _send = xhr.send
      xhr.send = function () {
        _send.call(xhr, formData)
      }
      xhr.ontimeout = () => {
        self.error(file, 'Server timeout', xhr)
      }
    },

    error(file, message, xhr) {
      if (file && this.dz.dropzone) {
        file.previewElement.querySelector('.dz-progress .dz-upload').style.width = 0
      }

      if (message) {
        let actions = []

        if (xhr) {
          actions.push({
            text: 'Retry',
            onClick: (vm) => {
              vm.$emit('close-toast')
              if (file && this.dz.dropzone) {
                this.uploadProgress = Math.round((this.uploaded / this.uploadTotal) * 100)
                file.upload.progress = 0
                file.upload.bytesSent = 0
                file.upload.total = 0
                file.previewElement.classList.remove('dz-error', 'dz-complete')
                file.previewElement.querySelector('.dz-error-message span').textContent = ''
                this.$nextTick(() => {
                  this.dz.dropzone.uploadFile(file)
                })
              }
            },
          })
        }

        actions.push({
          text: 'Cancel',
          onClick: (vm) => {
            vm.$emit('close-toast')
            if (file && this.dz.dropzone) {
              this.dz.removeFile(file)
            }
            if (xhr) {
              this.incrementCounter()
            }
          },
        })

        this.$toast.error(
          `Error: ${message.error || message} ${file.name}`,
          {
            actions: actions,
          },
          {
            timeout: false,
            closeButton: false,
          }
        )
      }
    },
    success(file) {

      var self = this

      ApiService.post("/files/upload/success", {
        's3_path': file.fileName,
        'file_name': file.name,
        'file_extension': file.extension,
        'content_type': file.type,
        'related_ids': file.relatedIds,
        'legacy_url': file.legacyUrl,
        'upload_type': file.uploadType,
        'upload_parent_id': file.uploadParentId,
        'upload': JSON.stringify(file)
      }).then((response) => {
        self.incrementCounter()
      })

    },
    complete() {
      if (this.uploading && !this.dz.dropzone.files.filter((file) => file.status === 'error').length) {
        this.uploadComplete = true
      }
    },
    added(files) {
      Array.from(files).forEach((file) => {
        this.$nextTick(() => {
          file.previewElement.classList.add(file.name.split('.').pop())
        })
      })
      this.$emit('filesadded', files)
    },
    fileadded(file) {
      this.$emit('fileadded', file)
    },
    fileremoved(file) {
      this.$emit('fileremoved', file)
    },
    duplicate(file) {
      this.$toast.info(`This file has already been added ${file.name}`)
      this.$emit('duplicatefile', file)
    },
    startUpload() {
      if (this.dz.dropzone && this.dz.getQueuedFiles().length) {
        this.uploadTotal = this.dz.getQueuedFiles().length
        this.uploaded = 0
        this.uploadProgress = 0
        this.dz.processQueue()
      } else {
        this.uploadProgress = 100
        this.complete()
      }
    },
    resetDropzone() {
      if (this.dz.dropzone) {
        this.uploadProgress = false
        this.uploadTotal = 0
        this.uploaded = 0
        this.uploadComplete = false
        this.dz.disable()
        this.$nextTick(() => {
          this.dz.removeAllFiles()
          this.dz.enable()
        })
      }
    },
    incrementCounter() {
      this.uploaded = this.uploaded + 1

      this.uploadProgress = Math.round((this.uploaded / this.uploadTotal) * 100)
      this.continueUpload()
    },
    continueUpload() {
      if (
        this.uploadTotal &&
        this.uploadTotal !== this.uploaded &&
        !this.uploadComplete &&
        this.dz.dropzone &&
        !this.dz.getUploadingFiles().length
      ) {
        this.dz.processQueue()
      } else if (this.uploadTotal === this.uploaded) {
        this.complete()
      }
    },
  },
}
</script>
