<template>
  <template v-if="answers.length">
    <Question
      v-if="question"
      class="pt-3"
      v-bind="$props"
    />
    <div
      class="modal-slider-container"
      :class="{ 'hide-help': !!hideActions, 'has-actions': !hideActions }"
    >
      <slider
        v-if="!loading"
        :dots="false"
        :nav-buttons="answers.length > 1"
        @before-change="({ nextSlide }) => $emit('update:modelValue', nextSlide)"
      >
        <Answer
          v-for="(answer, index) in answers"
          :key="index"
          v-bind="$props"
          :count="index + 1"
          :total="answers.length"
          :answer="answer"
          :title-class="titleClass"
          @show-regulation="$emit('show-regulation', $event)"
          @show-chat="$emit('show-chat', $event)"
          @view-notes="$emit('view-notes', $event)"
          @show-answer="$emit('show-answer', $event)"
          @remediate="$emit('remediate', $event)"
          @new-messages="$emit('new-messages', $event)"
          @new-notes="$emit('new-notes', $event)"
          @answer-accepted="$emit('answer-accepted', $event)"
        />
      </slider>
    </div>
  </template>
  <div
    v-else
    class="p-6 text-center"
  >
    {{ errorMessage }}
  </div>
</template>

<script>
import { VueAgile as Slider } from '@/components/vue-agile'
import Answer from '@/components/reviewanswer/Answer.vue'
import Question from '@/components/reviewanswer/Question.vue'

export default {
  components: {
    Slider,
    Answer,
    Question,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    question: {
      type: Object,
      default: null,
    },
    showRemainingEntities: {
      type: Boolean,
      default: false,
    },
    answers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRemediation: {
      type: Boolean,
      default: true,
    },
    showAcceptance: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: 'No Answers Recorded',
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    answerClass: {
      type: String,
      default: '',
    },
    questionClass: {
      type: String,
      default: '',
    },
    titleClass: {
      type: String,
      default: 'px-3 py-2',
    },
    bodyClass: {
      type: String,
      default: 'px-6 py-4',
    },
    actionsClass: {
      type: String,
      default: 'px-3 py-2',
    },
    rowsClass: {
      type: String,
      default: 'px-0',
    },
    colsClass: {
      type: String,
      default: 'px-3',
    },
  },
  emits: [
    'show-regulation',
    'show-chat',
    'view-notes',
    'show-answer',
    'remediate',
    'new-messages',
    'new-notes',
    'answer-accepted',
    'update:modelValue',
  ],
  data() {
    return {
      showSlider: false,
    }
  },
}
</script>

<style
  lang="scss"
  scoped
>
.modal-slider-container {
  &.hide-help {
    &:after {
      display: none;
    }
  }
  &.has-actions {
    :deep(.agile__nav-button) {
      bottom: 46px;
    }
  }
  &:after {
    content: 'Keyboard enabled:\00a0\00a0\00a0\021E6\00a0\00a0\00a0\021E8\00a0\00a0\00a0[ESC]';
    text-align: right;
    font-size: 0.625rem;
    color: $secondary;
    width: 100%;
    display: block;
    padding: 0.25rem 0.5rem;
  }
  :deep(.agile__actions) {
    border-top: 1px solid $light-gray;
  }
}
</style>
