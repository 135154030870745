import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import Axios from '@/plugins/axios'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  async loadFaqs({ commit }, trashed) {
    await commit(types.FAQSLOADING, true)
    await Pagination(`/frequently-asked-questions`, trashed ? { 'filter[trashed]': 'with' } : null)
      .then((response) => {
        commit(types.LOADFAQS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.FAQSLOADING, false)
      })
  },

  async fetch({ commit }, faqId) {
    await commit(types.LOADING, true)
    await Axios.get(`/frequently-asked-questions/${faqId}`)
      .then((response) => {
        commit(types.PUT, response.data)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },
}
