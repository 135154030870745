import _, {isNil} from 'lodash';

/**
 * Global Getters
 */
function getPagination(state) {
  return state.api.pagination
}
function getPaginationCurrentPage(state) {
  return state.api.pagination.currentPage
}
function getPaginationPerPage(state) {
  return state.api.pagination.perPage
}
function getPaginationLastPage(state) {
  return state.api.pagination.lastPage
}
function getPaginationFrom(state) {
  return state.api.pagination.from
}
function getPaginationTo(state) {
  return state.api.pagination.to
}
function getPaginationTotal(state) {
  return state.api.pagination.total
}

/**
 * Global Actions
 */
function updatePagination({commit}, payload) {
  if (!_.isNil(payload.current_page)) {
    commit('UPDATE_PAGINATION_CURRENT_PAGE', payload.current_page)
  }
  if (!_.isNil(payload.per_page)) {
    commit('UPDATE_PAGINATION_PER_PAGE', payload.per_page)
  }
  if (!_.isNil(payload.last_page)) {
    commit('UPDATE_PAGINATION_LAST_PAGE', payload.last_page)
  }
  if (!_.isNil(payload.total)) {
    commit('UPDATE_PAGINATION_TOTAL', payload.total)
  }
  if (!_.isNil(payload.from)) {
    commit('UPDATE_PAGINATION_FROM', payload.from)
  }
  if (!_.isNil(payload.to)) {
    commit('UPDATE_PAGINATION_TO', payload.to)
  }
}
function updatePaginationCurrentPage({commit}, val) {
  commit('UPDATE_PAGINATION_CURRENT_PAGE', val)
}
function updatePaginationPerPage({commit}, val) {
  commit('UPDATE_PAGINATION_LIMIT', val)
}
function updatePaginationLastPage({commit}, val) {
  commit('UPDATE_PAGINATION_LAST_PAGE', val)
}
function updatePaginationTotal({commit}, val) {
  commit('UPDATE_PAGINATION_TOTAL', val)
}
function updatePaginationFrom({commit}, val) {
  commit('UPDATE_PAGINATION_FROM', val)
}
function updatePaginationTo({commit}, val) {
  commit('UPDATE_PAGINATION_TO', val)
}


/**
 * Global Mutations
 */

function UPDATE_PAGINATION(state, payload) {
  state.api.pagination[payload.parameterName] = payload.parameterValue
}
function UPDATE_PAGINATION_CURRENT_PAGE(state, currentPage) {
  state.api.pagination.currentPage = currentPage
}
function UPDATE_PAGINATION_PER_PAGE(state, limit) {
  state.api.pagination.perPage = limit
}
function UPDATE_PAGINATION_LAST_PAGE(state, total) {
  state.api.pagination.lastPage = total
}
function UPDATE_PAGINATION_FROM(state, limit) {
  state.api.pagination.from = limit
}
function UPDATE_PAGINATION_TO(state, limit) {
  state.api.pagination.to = limit
}
function UPDATE_PAGINATION_TOTAL(state, total) {
  state.api.pagination.total = total
}

export {
  // Pagination Getters
  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,
  // Pagination Actions
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationLastPage,
  updatePaginationPerPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,
  // Pagination Mutations
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL

}
