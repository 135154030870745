<template>
  <div
    class="p-6 text-center font-lg"
    v-html="!loading ? `Answer Not Found` : ``"
  />
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
