<template>
  <li class="nav-item ml-2 user-profile menu">
    <a
      href="#"
      class="nav-link"
      @click.prevent="open = !open"
    >
      <ProfilePicture
        :user="user"
        :size="28"
        background-color="white"
        :border-radius="6"
      />
      <transition name="submenu">
        <div
          v-if="open"
          class="submenu dropdown-menu"
          @close="open = false"
        >
          <router-link
            class="dropdown-item"
            :to="{ name: 'profile.index' }"
          >
            View Profile
          </router-link>
          <router-link
            v-if="userOrganizationCount > 1"
            class="dropdown-item"
            :to="{ name: 'profile.company' }"
          >
            Switch Company
          </router-link>
          <btn
            :link="false"
            class="dropdown-item"
            :class="{ disabled: !organizationId }"
            @click="$bus.emit('show-todays-plan', true)"
          >
            Show Today's Plan
          </btn>
          <div class="dropdown-divider" />
          <router-link
            v-if="hasFeature('divisions') && (isAdmin || isPrimaryAdmin || isSysAdmin)"
            class="dropdown-item"
            :class="{ disabled: !organizationId }"
            :to="{ name: 'company.dashboard' }"
          >
            Organization Dashboard
          </router-link>
          <div
            v-if="hasFeature('divisions') && (isAdmin || isPrimaryAdmin || isSysAdmin)"
            class="dropdown-divider"
          />
          <router-link
            v-if="(isAdmin || isPrimaryAdmin || isSysAdmin) && assessmentPeriodId"
            class="dropdown-item"
            :class="{ disabled: !organizationId }"
            :to="{
              name: 'company.assessment-period.overview',
              params: { assessmentPeriodId: assessmentPeriodId },
            }"
          >
            Assessment Overview
          </router-link>
          <router-link
            v-if="isAdmin || isPrimaryAdmin || isSysAdmin"
            class="dropdown-item"
            :class="{ disabled: !organizationId }"
            :to="{ name: 'company.index' }"
          >
            Manage Company
          </router-link>
          <btn
            v-if="hasFeature('divisions') && (isAdmin || isPrimaryAdmin || isSysAdmin)"
            class="dropdown-item font-reg py-1 px-4"
            :class="{ disabled: !organizationId }"
            @click.prevent="$bus.emit('change-division', false)"
          >
            Switch Division
          </btn>
          <div class="dropdown-divider" />
          <router-link
            v-if="isSysAdmin"
            class="dropdown-item"
            :to="{ name: 'admin.index' }"
          >
            Administration
          </router-link>
          <div
            v-if="isSysAdmin"
            class="dropdown-divider"
          />
          <router-link
            class="dropdown-item"
            :class="{ disabled: !organizationId }"
            :to="{ name: 'faq.search' }"
          >
            Knowledge Base
          </router-link>
          <div class="dropdown-divider" />
          <btn
            :link="false"
            class="dropdown-item logout"
            @click="() => $forceNextTick().then(logout)"
          >
            Logout
          </btn>
        </div>
      </transition>
    </a>
  </li>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      open: false,
    }
  },
  computed: {
    ...mapGetters({
      isSysAdmin: 'auth/isSysAdmin',
      isPrimaryAdmin: 'auth/isPrimaryAdmin',
      isAdmin: 'auth/isAdmin',
      organizationId: 'auth/getOrganizationId',
      assessmentPeriodId: 'company/assessmentPeriodId',
      userOrganizationCount: 'auth/getUserOrganizationCount',
      hasFeature: 'company/hasFeature',
    }),
    ...mapState('auth', {
      user: 'user',
    }),
  },
  mounted() {
    this.$root.$el.addEventListener('click', this.closeOnClick)
  },
  beforeUnmount() {
    this.$root.$el.removeEventListener('click', this.closeOnClick)
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    closeOnClick(event) {
      if (this.open && !this.$el.contains(event.target)) {
        this.open = !this.open
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.menu {
  a {
    position: relative;
    display: inline-block;
    .dropdown-menu {
      position: absolute;
      z-index: 1;
      display: block;
      right: 0;
      left: auto;
      margin-top: 10px;
      opacity: 0.92;
      // visibility: hidden;
      pointer-events: auto;
      transition: all 0.25s ease;
      &::before {
        content: '';
        position: absolute;
        top: -9px;
        right: 7px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid rgba(black, 0.5);
      }
      .logout {
        color: red;
      }
      &.submenu-enter,
      &.submenu-enter-active,
      &.submenu-leave-active {
        opacity: 0;
        margin-top: 24px;
      }
    }
    &.open {
      .dropdown-menu {
        // pointer-events: auto;
        // margin-top: 10px;
        // opacity:.92;
        li {
          color: red;
        }
      }
    }
  }
}
.user-profile {
  margin-left: 0.5rem;
  span {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    text-align: center;
    vertical-align: middle;
    line-height: 28px;
    font-size: 16px;
  }
  a.nav-link {
    color: $white;
    padding: 2px;
  }
}
.show .menu a .dropdown-menu {
  display: flex;
  flex-direction: row;
  flex-flow: column;
  margin-top: 6px;
  margin-bottom: 48px;
  position: static;
  &:before {
    right: auto;
    left: 8px;
    top: 30px;
    border-bottom: 8px solid $white;
    transition: none;
  }
}
</style>
