<template>
  <div class="toast-inner-content d-flex">
    <span v-html="displayMessage" />
    <btn
      v-for="(action, i) in actions"
      :key="i"
      :link="false"
      class="btn-sm btn-outline-light ml-3 align-self-center text-nowrap"
      @click="doClick(action)"
      v-text="action.text"
    />
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: [String, Object],
      default: '',
    },
    actions: {
      type: Array,
      default: null,
    },
    toastId: {
      type: [String, Number],
      required: true,
    },
  },
  emits: ['close-toast'],
  computed: {
    displayMessage() {
      return typeof this.message === 'string' ? this.message : this.processObject()
    },
  },
  methods: {
    processObject() {
      if (this.lodash.has(this.message, 'response.data.errors')) {
        return `<p class="mb-0">Error(s) Found</p><ul class="m-0 pl-3 font-weight-bold line-height-15 font-sm">${Object.values(
          this.message.response.data.errors
        )
          .flat()
          .map((e) => `<li class="mb-0">${e}</li>`)
          .join('')}</ul>`
      }
      if (this.lodash.has(this.message, 'response.data.error')) {
        return this.lodash.get(this.message, 'response.data.error', 'Unknown error occurred')
      }
      if (this.lodash.has(this.message, 'response.data.message')) {
        return this.lodash.get(this.message, 'response.data.message', 'Unknown error occurred')
      }

      return 'Unknown error occurred'
    },
    doClick(action) {
      if (typeof action.onClick === 'function') {
        action.onClick(this)
      }
      if (action.onClick === 'close') {
        this.$emit('close-toast')
      }
    },
  },
}
</script>
