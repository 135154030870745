<template>
  <div class="dropdown d-inline-block">
    <btn class="dropdown-toggle"> Copy link </btn>
    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="dropdownMenuButton"
    >
      <btn
        class="dropdown-item"
        @click.prevent="copyDetails"
      >
        Details Link
      </btn>
      <btn
        class="dropdown-item"
        @click.prevent="copyVideo"
      >
        Video Link
      </btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faq: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyVideo() {
      this.copyToClipboard(true)
    },
    copyDetails() {
      this.copyToClipboard(false)
    },
    copyToClipboard(video) {
      let url = `${window.location.origin}${window.location.pathname}?faqId=${this.faq.id}&video=${video}`
      this.$copyText(url).then(
        () => {
          this.$toast.success(
            `Copied to clipboard! <a href="${url}" target="_blank" class="d-block font-xs text-white">${url}</a>`
          )
        },
        () => {
          this.$toast.error(
            `Can't copy to clipboard! <a href="${url}" target="_blank" class="d-block font-xs text-white">${url}</a>`
          )
        }
      )
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.dropdown {
  .dropdown-toggle:after {
    transition: transform 0.25s ease;
    transform: scaleY(1);
  }

  &:not(.disabled):hover {
    .dropdown-toggle:after {
      transform: scaleY(-1);
    }
    .dropdown-menu {
      display: block;
      margin-top: 0; // remove the gap so it doesn't close
      font-size: 0.75rem;
      min-width: 6rem;
      right: 0;
      left: auto;
      :deep(.dropdown-item) {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
        text-align: center;
      }
    }
  }
  .dropdown-toggle {
    &:after {
      vertical-align: middle;
    }
  }
}
</style>
