<template>
  <label
    class="toggle-check"
    :class="{
      'toggle-check-no-label': noLabel,
      'toggle-check-right': alignRight,
      'toggle-check-sm': small,
      disabled: disabled,
    }"
  >
    <input
      type="checkbox"
      class="form-control toggle-check-input"
      :class="elclass"
      :checked="checked"
      :disabled="disabled"
      @keypress.enter.prevent="onInput"
      @input="onInput"
    />
    <span class="toggle-check-text" />
    <span
      class="toggle-check-label"
      v-html="text"
    />
  </label>
</template>
<script>
import { debounce } from 'lodash'
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: false,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
    elclass: {
      type: String,
      default: null,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: null,
    },
    debounce: {
      type: Number,
      default: 100,
    },
  },
  emits: ['update:checked'],
  created() {
    this.debounced = debounce((event) => {
      this.$emit('update:checked', event.target.checked)
    }, this.debounce)
  },
  methods: {
    onInput(event) {
      this.debounced(event)
    },
  },
}
</script>
