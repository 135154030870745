import { defaultState } from './state'

import {
  SET,
  CONTROLSLOADING,
  PROCESSESLOADING,
  FUNCTIONSLOADING,
  DOCUMENTREQUESTSLOADING,
  RISKSLOADING,
  OBJECTIVESLOADING,
  CRITERIALOADING,
  LOADCONTROLS,
  LOADPROCESSES,
  LOADFUNCTIONS,
  LOADRISKS,
  LOADOBJECTIVES,
  LOADCRITERIA,
  LOADDOCUMENTREQUESTS,
  RESET,
} from './mutation-types'

export default {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  [DOCUMENTREQUESTSLOADING](state, bool) {
    state.loadingDocumentRequests = bool
  },
  [CONTROLSLOADING](state, bool) {
    state.loading = bool
  },
  [PROCESSESLOADING](state, bool) {
    state.loadingProcesses = bool
  },
  [FUNCTIONSLOADING](state, bool) {
    state.loadingFunctions = bool
  },
  [RISKSLOADING](state, bool) {
    state.loadingRisks = bool
  },
  [OBJECTIVESLOADING](state, bool) {
    state.loadingObjectives = bool
  },
  [CRITERIALOADING](state, bool) {
    state.loadingCriteria = bool
  },
  [LOADCONTROLS](state, controls) {
    state.controls = controls || []
  },
  [LOADPROCESSES](state, processes) {
    state.processes = processes || []
  },
  [LOADFUNCTIONS](state, functions) {
    state.functions = functions || []
  },
  [LOADRISKS](state, risks) {
    state.risks = risks || []
  },
  [LOADOBJECTIVES](state, objectives) {
    state.objectives = objectives || []
  },
  [LOADCRITERIA](state, criteria) {
    state.criteria = criteria || []
  },
  [LOADDOCUMENTREQUESTS](state, documentRequests) {
    state.documentRequests = documentRequests || []
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
