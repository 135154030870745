// import ToastService from '@/services/toast.service'
import _, { get, forEach, isBoolean, has } from 'lodash'
import store from '@/store'
import AxiosInstance from './AxiosService'
import DateTimeService from "./DateTimeService"
import queryString from 'query-string'

const API_URL = import.meta.env.VITE_APP_API_V2

class ApiService {
  debouncedBuildApiRequest = _.debounce(function (vuexAction) {
    store.dispatch(vuexAction)
  }, 1000)

  buildApiRequest(vuexAction) {
    store.dispatch(vuexAction)
  }

  async debug(endpoint, parameters = {}) {
    let url = API_URL + endpoint
    let queryString = this.buildQueryString(parameters)
    let finalUrl = url + "?" + queryString;
    console.log("QueryString: " + queryString)
    return;
    var res = await AxiosInstance.get(finalUrl)
    return res
  }

  async get(endpoint, parameters = {}) {
    let url = API_URL + endpoint
    let queryString = this.buildQueryString(parameters)
    let finalUrl = url + "?" + queryString;
    var res = await AxiosInstance.get(finalUrl)
    return res

    try {

      let queryString = this.buildQueryString(parameters)
      let url = API_URL + endpoint + `?${queryString}`

      return await AxiosInstance.get(url)

    } catch (error) {
      let self = this
      return self.handleApiErrors(error)
    }
  }

  post(endpoint, payload) {
    let self = this
    return AxiosInstance.post(API_URL + endpoint, payload)
      .catch(function (error) {
        return handleApiErrors(error)
        // ToastService.create({
        //   type: 'error',
        //   message: error.message,
        // })
      })
      .then((response) => {
        return response
      })
  }

  async postAsync(endpoint, payload) {
    try {
      var response = await AxiosInstance.post(API_URL + endpoint, payload)
      return response
    } catch (error) {
      let self = this
      return self.handleApiErrors(error)
      // ToastService.create({
      //   type: 'error',
      //   message: error.message,
      // })
    }
  }

  put(endpoint, payload) {
    let self = this
    return AxiosInstance.put(API_URL + endpoint, payload)
      .catch(function (error) {
        return self.handleApiErrors(error)
        // ToastService.create({
        //   type: 'error',
        //   message: error.message,
        // })
      })
      .then((response) => {
        return response
      })
  }

  async patchAsync(endpoint, payload) {
    try {
      var response = await AxiosInstance.patch(API_URL + endpoint, payload)
      return response
    } catch (error) {
      let self = this
      return self.handleApiErrors(error)
      // ToastService.create({
      //   type: 'error',
      //   message: error.message,
      // })
    }
  }

  delete(endpoint, payload) {
    let self = this
    payload._method = "delete";
    return AxiosInstance.post(API_URL + endpoint, payload)
      .then((response) => {
        return response
      })
      // .catch(function (error) {
        // return handleApiErrors(error)
        // ToastService.create({
        //   type: 'error',
        //   message: error.message,
        // })
      // })
  }

  handleApiErrors(error) {

    if (error.response) {
      // On 401 refresh our access token and retry the request
      // if (error.response.status === 401) {
      //   return store.dispatch('auth/refreshToken').then((res) => {
      //     error.config.headers['Authorization'] = store.getters['auth/accessToken']
      //     error.config.baseURL = undefined
      //     return AxiosInstance.request(error.config)
      //   })
      // }

      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // Using toJSON you get an object with more information about the HTTP error.
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Using toJSON you get an object with more information about the HTTP error.
      // console.log(error.toJSON());
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
    }
    // console.log(error.config);
  }

  buildQueryString(parameters) {

    // Create default object for storing/merging url parameters
    let combinedParameters = {};

    /**
     * Pre: Check api parameters for existence
     */

    /**
     * 1. Append filter parameters
     */
    // Check if current request should include pagination parameters
    if (_.has(parameters, 'api.filters') && !_.isNil(parameters.api.filters) && _.isObject(parameters.api.filters)) {
      combinedParameters = this.buildQueryString_Filters(combinedParameters, parameters.api.filters)
    }

    /**
     * 2. Append order parameters
     */
    // Check if current request should include pagination parameters
    if (_.has(parameters, 'api.sorts') && !_.isNil(parameters.api.sorts)) {
      let testCombinedParameters = this.buildQueryString_Sorts(combinedParameters, parameters.api.sorts)
    }

    /**
     * 3. Append pagination parameters
     */
    // Check if current request should include pagination parameters
    if (_.has(parameters, 'api.pagination') && !_.isNil(parameters.api.pagination) && _.isObject(parameters.api.pagination)) {
      if (_.get(parameters.api, 'paginated', false) === true) {
        combinedParameters = this.buildQueryString_Pagination(combinedParameters, parameters.api.pagination)
      }
    }

    // Convert the parameter object into a valid query string
    return queryString.stringify(combinedParameters, {arrayFormat: 'bracket'})
  }

  /**
   * Merge parameters object with current Vuex Module pagination parameters
   * @param parametersObject
   * @param parameters
   * @returns {*}
   */
  buildQueryString_Pagination(parametersObject, apiPagination) {
    let currentPage = apiPagination.currentPage;
    let perPage = apiPagination.perPage;

    _.merge(parametersObject, {
      page: currentPage,
      limit: perPage
    })
    return parametersObject;
  }

  buildQueryString_Filters(parametersObject, apiFilters) {

    let queryFilters = []

    _.forEach(apiFilters, function (filterValue, filterKey) {

      // Exclude if value is null
      if (_.isNil(filterValue)) {
        return
      }

      // Handle boolean values
      if (_.isBoolean(filterValue)) {
        queryFilters[filterKey] = filterValue
      }

      // Handle string values
      if (_.isString(filterValue)) {
        if (filterValue.length <= 0) {
          return
        }
        queryFilters[filterKey] = filterValue
      }

      // Handle string values
      if (_.isArray(filterValue)) {
        if (_.isEmpty(filterValue)) {
          return
        }
        queryFilters[filterKey] = filterValue
      }

    })

    _.merge(parametersObject, queryFilters)
    return parametersObject;

  }

  buildQueryString_Sorts(parametersObject, apiSortOperations) {

    // Group order operations into a single array key named 'orderby'
    let sorts = []

    _.forEach(apiSortOperations, function (sortValue, sortKey) {

      // sortKey should contain the property name we're applying a sort against
      // sortValue should contain either 'ASC' or 'DESC'
      // In order to be parsed by the API, these values should be combined into a single string with a colon delimiter

      sorts.push(sortKey + '|' + sortValue);
    })

    let sortOperations = [];
    sortOperations["sorts"] = sorts

    _.merge(parametersObject, sortOperations)
    return parametersObject;

  }

}

export default new ApiService()
