import store from '@/store'

const getters = {
  getPlan(planId) {
    if (store.state.plans.plan && store.state.plans.plan.id === planId) {
      return store.state.plans.plan
    } else {
      return store.state.plans.plans.find((plan) => plan.id === planId) || {}
    }
  },

  filterPlans(threatIds, entityIds, completed) {
    if (Number.isInteger(threatIds)) {
      threatIds = [threatIds]
    }
    if (Number.isInteger(entityIds)) {
      entityIds = [entityIds]
    }

    return store.state.plans.plans.filter((plan) => {
      return (
        (threatIds
          ? plan.items.map((item) => item.threat_id).some((threatId) => threatIds.includes(threatId))
          : plan) &&
        (entityIds
          ? plan.items
              .map((item) => item.entity_id)
              .some((entityId) => entityId === null || entityIds.includes(entityId))
          : plan) &&
        (completed !== undefined ? !!plan.completed === completed : plan)
      )
    })
  },
  planThreats(planId) {
    return ((store.state.plans.plans.find((plan) => plan.id === planId) || {}).items || []).map((item) => item.threat)
  },
  plansByThreat(threatId) {
    return store.state.plans.plans.filter((plan) => plan.items.map((item) => item.threat_id).includes(threatId))
  },
  plansCompletedByThreat(threatId) {
    return store.state.plans.plans.filter(
      (plan) => plan.completed && plan.items.map((item) => item.threat_id).includes(threatId)
    )
  },
  plansThreats() {
    return store.state.plans.plans.map((plan) => plan.items.map((item) => item.threat)).flat()
  },
}

const counts = {
  plansByThreatCount(threatId) {
    return this.plansByThreat(threatId).length
  },
  plansCompletedByThreatCount(threatId) {
    return this.plansCompletedByThreat(threatId).length
  },
  plansThreatsCount() {
    return this.plansThreats().length
  },
  planCount(threatIds, entityIds) {
    if (Number.isInteger(threatIds)) {
      threatIds = [threatIds]
    }
    if (Number.isInteger(entityIds)) {
      entityIds = [entityIds]
    }
  },
}

const tests = {
  isPlanManager(planId) {
    return !!(store.getters['auth/getUserId'] === this.getPlan(planId).manager_id)
  },
}

const PlanMethods = Object.assign(getters, counts, tests)

export default PlanMethods

// export const methods = Object.assign(getters, counts, tests)
