<template>
  <router-link
    :to="navLink"
    class="navbar-brand"
  >
    <img
      src="@/assets/images/wavefire-logo-white.svg"
      alt="WaveFire Compliance"
    />
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      isAdmin: 'auth/isAdmin',
    }),
    navLink() {
      return this.authenticated
        ? this.isAdmin
          ? { name: 'dashboard.index' }
          : { name: 'questions.index' }
        : { name: 'login.index' }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.navbar-brand {
  padding: 0;
  height: 42px;
  img {
    height: 25px;
  }
}
</style>
