<template>
  <div
    v-if="isAdmin && hasFeature('collaboration')"
    class="input-group-sm"
  >
    <label class="font-xxs mb-0 text-secondary"> Show Completion By </label>
    <TypeAhead
      v-model="showCompletionBy"
      anchor="name"
      :options="[{ name: 'Answers' }, { name: 'Collaborators' }, { name: 'Combined' }]"
      init-value="Answers"
      :add-clear="false"
      :filter-by-anchor="false"
      class="input-group-sm"
      placeholder="Show Completion By"
      :dropdown="true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showCompletionBy: null,
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      hasFeature: 'company/hasFeature',
    }),
  },
  watch: {
    showCompletionBy() {
      this.$emit('update:modelValue', this.showCompletionBy)
    },
  },
}
</script>
