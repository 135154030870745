<template>
  <div class="row justify-content-center d-flex flex-fill scroll-y">
    <div :class="cols">
      <div class="card py-5 border-0">
        <h3
          class="card-header rounded-0 bg-transparent border-0 text-center"
          v-text="title"
        />
        <div
          class="card-body"
          :class="{ 'loading mt-6': loading }"
        >
          <p
            v-if="!loading && instructions"
            class="card-text text-center mb-4"
            v-html="instructions"
          />
          <slot v-if="!loading" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    instructions: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: String,
      default: 'col-6',
    },
  },
}
</script>
