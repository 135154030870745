<template>
  <modal
    body-class="p-0 m-0"
    header-class="align-items-center"
    :content-class="loading ? 'loading' : ''"
    @close-modal="$emit('close')"
  >
    <template #header>
      <h5 class="d-flex align-items-center">
        <btn
          iconic="reload"
          class="mr-25 line-height-1"
          @click="loadMessages()"
        />
        Discuss
        <span class="ml-2 font-weight-light">| Question Answer ({{ answer.question.tempid }})</span>
      </h5>
    </template>
    <template #body>
      <chat-window
        :messages="messages"
        :loading="loading"
        :form="form"
        :default-message="defaultMessage"
        @update-message="(val) => (form.message = val)"
        @send-message="submitMessage"
      />
    </template>
  </modal>
</template>
<script>
import ChatWindow from '@/components/ChatWindow.vue'
export default {
  components: {
    ChatWindow,
  },
  props: {
    answer: {
      type: Object,
      default: null,
    },
    defaultMessage: {
      type: String,
      default: 'Have a question? Ask your team!',
    },
  },
  emits: ['close', 'new-messages'],
  data() {
    return {
      loading: false,
      messages: [],
      form: this.$form({
        message: null,
      }),
    }
  },
  beforeMount() {
    this.loadMessages()
  },
  methods: {
    async loadMessages() {
      this.loading = true
      await this.$paginate(
        `/entities/${this.answer.entity_id}/questions/${this.answer.question_id}/answers/${this.answer.id}/discussion`
      )
        .then((response) => {
          this.messages = response
        })
        .catch((error) => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    submitMessage() {
      this.form
        .post(
          `/entities/${this.answer.entity_id}/questions/${this.answer.question_id}/answers/${this.answer.id}/discussion`
        )
        .then(() => {
          this.loadMessages().then(() => {
            this.$emit('new-messages', this.messages.length)
          })
        })
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
:deep(.modal-content) {
  &.loading {
    .modal-body {
      .card-body {
        opacity: 0.35;
      }
      .card-footer {
        input,
        button {
          pointer-events: none;
          opacity: 0.65;
        }
      }
    }
  }
}
</style>
