import store from '@/store'
import { getPersistedState } from '@/store/plugins/StateMapper'

export default function () {
  return new Promise((resolve, reject) => {
    const ignore = ['loglevel:webpack-dev-server', 'debug']
    const items = Object.entries({ ...localStorage }).reduce((items, [key, value]) => {
      if (!ignore.includes(key)) {
        items[key] = value
      }
      return items
    }, {})

    store.dispatch('checkMigrations', items).then(() => {
      if (store.state.initialized) {
        resolve()
      }
      getPersistedState()
        .then((cached) => {
          store.commit('loadFromCache', cached)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  })
}
