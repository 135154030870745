export default {
  props: {
    label: {
      type: [String, Boolean],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    elclass: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    help: {
      type: String,
      default: null,
    },
    labelClass: {
      type: String,
      default: null,
    },
    groupClass: {
      type: String,
      default: null,
    },
    hideErrorText: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      default: null,
    },
    invalidMessage: {
      type: String,
      default: null,
    },
    minlength: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    tabindex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isValid: true,
    }
  },
  computed: {
    hasError() {
      return typeof this.error === 'string' && !!this.error.length
    },
  },
}
