import moment from '@/plugins/dayjs'
import { get, uniqBy } from 'lodash'

export default {
  count(state) {
    return state.notifications.length
  },
  uniqueCount(state, getters) {
    return getters.uniqueNotifications.length
  },
  read(state, getters) {
    return getters.uniqueNotifications.filter((n) => n.read === 1)
  },
  unread(state, getters) {
    return getters.uniqueNotifications.filter((n) => n.read === 0)
  },
  complete(state, getters) {
    return getters.uniqueNotifications.filter((n) => n.completed === 1)
  },
  incomplete(state, getters) {
    return getters.uniqueNotifications.filter((n) => n.completed === 0)
  },
  uniqueArchived(state) {
    return uniqBy(state.archived, (n) => {
      return [n.task_type === 'collaboration' ? 'question' : n.task_type, n.task_type_id, n.completed].join()
    })
  },
  taskTypes(state, getters) {
    return getters.uniqueNotifications.reduce((all, n) => {
      if (!all.includes(n.task_type)) {
        all.push({ name: n.task_type })
      }
      return uniqBy(all, 'name')
    }, [])
  },
  pastDue(state, getters) {
    let today = moment().startOf('day').local().format()

    return getters.uniqueNotifications.filter((n) => {
      return moment(n.due_date).isBefore(today, 'day')
    })
  },
  dueToday(state, getters) {
    let today = moment().startOf('day').local().format()

    return getters.uniqueNotifications.filter((n) => {
      return moment(n.due_date).isSame(today, 'day')
    })
  },
  getFramework: (state) => (frameworkId) => {
    return state.frameworks.find((framework) => framework.id === frameworkId)
  },
  incompleteAssignments(state, getters) {
    return getters.incomplete.filter((n) => n.task_type === 'question')
  },
  uniqueNotifications(state) {
    return uniqBy(state.notifications, (n) => {
      return [
        n.task_type === 'collaboration' ? 'question' : n.task_type,
        n.task_type_id,
        n.task_type === 'exclusion' ? n.entity_id : null,
        n.task_type === 'exclusion' ? n.assessment_period_id : null,
        n.completed,
      ].join()
    })
  },
  questionAssignmentTypes: (state, getters, rootState, rootGetters) => (question, entity) => {
    return state.notifications
      .filter(
        (n) =>
          ['question', 'collaboration'].includes(n.task_type) &&
          (n.entity_id === entity.id || (rootGetters['auth/isAdmin'] && !n.entity_id)) &&
          ((n.task_type_id === question.id && !n.completed) || n.task_type_id === question.parent_question_id) &&
          !get(state.question, n.task_type === 'collaboration' ? 'collaboration_answers' : 'answers', []).find(
            (a) => a.entity_id === n.entity_id
          )
      )
      .map((n) => n.task_type)
      .flat()
  },
}
