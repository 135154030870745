import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  async set({ commit }, data) {
    commit(types.SET, data)
  },

  async fetch({ commit, dispatch }, documentRequestPeriodId) {
    return await this.$http.get(`/document-request-periods/${documentRequestPeriodId}`)
      .then(async (response) => {
        await dispatch('set', {
          selectedPeriod: response.data
        })
      })
      .catch((e) => {
        toast.error(e)
      })
  },

  async load({ commit }, trashed) {
    await commit(types.PERIODSLOADING, true)
    return await Pagination(`/document-request-periods`, trashed ? { 'filter[trashed]': 'with' } : null)
      .then((response) => {
        commit(types.LOADPERIODS, response)
        return response
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.PERIODSLOADING, false)
      })
  },

  async loadArchived({ commit }, trashed) {
    await commit(types.ARCHIVEPERIODSLOADING, true)
    return await Pagination(
      `/document-request-periods`,
      Object.assign(
        {
          'filter[only_archived]': 1,
        },
        trashed ? { 'filter[trashed]': 'with' } : null
      )
    )
      .then((response) => {
        commit(types.LOADARCHIVEPERIODS, response)
        return response
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.ARCHIVEPERIODSLOADING, false)
      })
  },

  async archive({ commit }, documentRequestPeriod) {
    await commit(types.ARCHIVING, documentRequestPeriod.id)
    return await this.$http
      .post(`/document-request-periods/${documentRequestPeriod.id}/archive`)
      .then((response) => {
        return response
      })
      .catch((e) => {
        toast.error(e)
        return e
      })
      .finally(() => {
        commit(types.ARCHIVING, null)
      })
  },
  async unarchive({ commit }, documentRequestPeriod) {
    await commit(types.UNARCHIVING, documentRequestPeriod.id)
    return await this.$http
      .post(`/document-request-periods/${documentRequestPeriod.id}/unarchive`)
      .then((response) => {
        return response
      })
      .catch((e) => {
        toast.error(e)
        return e
      })
      .finally(() => {
        commit(types.UNARCHIVING, null)
      })
  },
  async delete({ commit }, documentRequestPeriod) {
    await commit(types.DELETING, documentRequestPeriod.id)
    return await this.$http
      .delete(`/document-request-periods/${documentRequestPeriod.id}`)
      .then((response) => {
        return response
      })
      .catch((e) => {
        toast.error(e)
        return e
      })
      .finally(() => {
        commit(types.DELETING, null)
      })
  },
  async restore({ commit }, documentRequestPeriod) {
    await commit(types.RESTORING, documentRequestPeriod.id)
    return await this.$http
      .post(`/document-request-periods/${documentRequestPeriod.id}/restore`)
      .then((response) => {
        return response
      })
      .catch((e) => {
        toast.error(e)
        return e
      })
      .finally(() => {
        commit(types.RESTORING, null)
      })
  },
}
