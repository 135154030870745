import { defaultState } from './state'

import { SET, RESET } from './mutation-types'

export default {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
