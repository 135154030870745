<template>
  <div
    class="form-group m-0 d-flex"
    :class="{ 'flex-fill': !inline }"
  >
    <btn
      v-if="withTrash"
      iconic="trash"
      :link="false"
      class="btn-red"
      :disabled="trashDisabled || saving"
      :class="[btnSize]"
      @click.prevent="$emit('on-trash')"
    />
    <btn
      v-if="withCancel"
      class="btn-light"
      :class="[btnSize, cancelAlignment]"
      :link="false"
      :disabled="saving"
      @click.prevent="onCancelClick"
      v-text="cancelText"
    />
    <btn
      :class="[submitAlignment, btnSize, elclass, btnType, { loading: saving }]"
      :link="link"
      :tabindex="tabindex"
      :disabled="disabled"
      type="submit"
      v-on="listeners"
      v-text="text"
    />
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    align: {
      type: String,
      default: 'right',
      validator: (val) => ['right', 'left'].includes(val),
    },
    withTrash: {
      type: Boolean,
      default: false,
    },
    withCancel: {
      type: Boolean,
      default: false,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
    elclass: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    link: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trashDisabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['on-trash', 'click-submit'],
  computed: {
    btnType() {
      return this.link ? '' : 'btn-primary'
    },
    listeners() {
      if (this.submitFunction) {
        return {
          click: (e) => {
            this.$emit('click-submit', e)
            this.submitFunction(e)
          },
        }
      }
      return {}
    },
    submitAlignment() {
      if (this.withCancel) {
        return this.align === 'right' ? 'ml-3' : 'mr-auto'
      } else {
        return `m${this.align === 'right' ? 'l' : 'r'}-auto`
      }
    },
    cancelAlignment() {
      return this.align === 'right' ? 'ml-auto' : 'mr-3'
    },
    btnSize() {
      return this.size ? `btn-${this.size}` : this.size
    },
  },
  methods: {
    onCancelClick() {
      if (this.onCancel) {
        this.onCancel()
      } else {
        this.$router.back()
      }
    },
  },
}
</script>
