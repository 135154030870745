<template>
  <label class="radio_select mb-3">
    <input
      ref="radio"
      type="radio"
      :name="name"
      :required="required"
      :checked="option[optionValueKey] === groupvalue"
      :value="option[optionValueKey]"
      @keypress.enter.prevent="changed"
      @click="changed"
    />
    <span
      v-if="tooltip && tooltip in option"
      v-tippy="{ theme: 'light small', distance: 5, duration: [200, 100] }"
      :content="option[tooltip]"
      :class="[listType]"
      :data-symbol="getSymbol(index)"
      v-html="option[html]"
    />
    <span
      v-else
      :class="[listType]"
      :data-symbol="getSymbol(index)"
      v-html="option[html]"
    />
  </label>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    html: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    groupvalue: {
      type: [String, Number],
      default: null,
    },
    listType: {
      type: String,
      default: 'alpha',
    },
    optionValueKey: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    }
  },
  methods: {
    changed(e) {
      const value =
        !isNaN(e.target.value) && Number.isInteger(parseFloat(e.target.value))
          ? parseInt(e.target.value)
          : e.target.value

      if (this.groupvalue === value) {
        this.$emit('update:modelValue', null)
        this.$refs.radio.blur()
      } else {
        this.$emit('update:modelValue', value)
      }
    },
    getSymbol(index) {
      if (this.listType === 'alpha') {
        return this.alphabet.substr(index, 1) || '-'
      } else {
        return index + 1
      }
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
.radio_select {
  &[disabled] {
    opacity: 0.42;
    pointer-events: none;
  }
  position: relative;
  display: block;
  input {
    position: absolute;
    z-index: 1;
    left: 2px;
    top: 2px;
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
  span {
    position: relative;
    z-index: 2;
    display: block;
    border: 1px solid $gray-300;
    padding: 12px 12px 12px 54px;
    margin: 0 0 1rem;
    background: $white;
    transition: all 0.25s ease-in-out;
    font-size: 0.875rem;
    color: $gray-600;
    border-radius: 2px;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 42px;
      background: $gray-100;
      border-right: 1px solid $gray-300;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: all 0.25s ease-in-out;
    }
    &.alpha::before {
      content: attr(data-symbol);
    }
    &.num::before {
      content: attr(data-symbol);
    }
  }
  input:checked ~ span {
    &::before {
      background-color: $bright;
      color: $white;
    }
    color: $gray-900;
    border-color: $bright;
    box-shadow: 0px 2px 6px $gray-400;
  }
  input:focus:not(:checked) ~ span {
    &::before {
      background-color: darken($gray-100, 3);
      border-color: rgba($bright, 0.5);
    }
    color: $gray-600;
    border-color: rgba($bright, 0.5);
    background-color: rgba($bright, 0.025);
  }
}
</style>
