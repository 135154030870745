import { toastInstance as toast } from '@/plugins/toast'
import ApiService from "../../../services/ApiService"
import _ from "lodash"

const defaultState = () => {
  return {
    documentRequest: {
      status: {
        "Awaiting Submission": "AWAITING_SUBMISSION",
        "Submitted": "SUBMITTED",
        "Return For Question": "RETURN_FOR_QUESTION",
        "Completed": "COMPLETED",
      }
    }

  }
}

const state = defaultState()

const getters = {
  getDocumentRequestStatusList: (state) => {
    return state.documentRequest.status
  },
}

const actions = {

  async getReadable({commit, state, dispatch}) {
    return false
  }


}

const mutations = {
  EXAMPLE_MUTATION(state, args) {
    state.example = args;
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    // nested
  }
}
