const defaultState = () => ({
  threatRisksloading: false,
  threatEntityRisksloading: false,
  categoryRisksloading: false,
  categoryEntityRisksloading: false,
  categoryThreatRisksloading: false,
  categoryThreatEntityRisksloading: false,
  threatRisks: [],
  threatEntityRisks: [],
  categoryRisks: [],
  categoryEntityRisks: [],
  categoryThreatRisks: [],
  categoryThreatEntityRisks: [],
})

const excludeFields = [
  'threatRisksloading',
  'threatEntityRisksloading',
  'categoryRisksloading',
  'categoryEntityRisksloading',
  'categoryThreatRisksloading',
  'categoryThreatEntityRisksloading',
]

export { defaultState, excludeFields }

export default defaultState
