<template>
  <label
    v-if="label !== false"
    :for="name"
    :class="labelClass"
    v-html="printLabel"
  />
</template>
<script>
export default {
  computed: {
    label() {
      return this.$parent.label
    },
    labelClass() {
      return this.$parent.labelClass
    },
    name() {
      return this.$parent.name
    },
    printLabel() {
      return this.label
        ? this.label
        : this.name
            .split('_')
            .map((n) => this.$filters.capitalize(n))
            .join(' ')
    },
  },
}
</script>
