import { defaultState } from './state'
import { isObject } from 'lodash'

import {
  SET,
  REFRESHING,
  SETREFRESHINGCALL,
  LOADING,
  ERROR,
  MESSAGE,
  NOTIFICATIONS,
  ARCHIVED,
  LOADFRAMEWORKS,
  LOADFEATURES,
  FEATURESLOADING,
  FRAMEWORKSLOADING,
  TRANSITIONS,
  MODALS,
  RESET,
} from './mutation-types'

export default {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  [REFRESHING](state, value) {
    state.isRefreshing = value
  },
  [SETREFRESHINGCALL](state, func) {
    state.refreshingCall = func
  },
  [LOADING](state, bool) {
    state.loading = bool
  },
  [ERROR](state, error) {
    state.error = error
  },
  [NOTIFICATIONS](state, notifications) {
    state.notifications = notifications || []
  },
  [ARCHIVED](state, archived) {
    state.archived = archived || []
  },
  [LOADFRAMEWORKS](state, frameworks) {
    state.frameworks = frameworks || []
  },
  [LOADFEATURES](state, features) {
    state.features = features || []
  },
  [FEATURESLOADING](state, bool) {
    state.featuresLoading = bool
  },
  [FRAMEWORKSLOADING](state, bool) {
    state.frameworksLoading = bool
  },
  [MESSAGE](state, message) {
    state.message = message
  },
  [TRANSITIONS](state, bool) {
    state.transition = bool
  },
  [MODALS](state, item) {
    if (isObject(item)) {
      state.openModals.push(item)
    } else if (!!item) {
      state.openModals.splice(
        state.openModals.findIndex((modal) => {
          item.id === modal.id
        })
      )
    }
  },
  [RESET](state) {
    Object.assign(state, defaultState(), {
      error: state.error,
      message: state.message,
      loginRedirect: state.loginRedirect,
      loading: state.loading,
    })
  },
}
