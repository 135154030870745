<template>
  <modal
    ref="modal"
    :class="{ saving: submitting, loading: loading }"
    :width="1200"
    body-class="px-0 pt-3 pb-0"
    @close-modal="$emit('close')"
  >
    <template #header>
      <h5>
        Question Exclusion Request <span class="font-weight-light">| {{ completedOn }}</span>
      </h5>
    </template>
    <template
      v-if="exclusionTask"
      #body
    >
      <Question
        :question="exclusionTask.question"
        cols-class="px-3"
        class="mb-3"
      />
      <div class="row border-top no-gutters">
        <div class="col-8 p-3 options">
          <div
            v-for="option in lodash.get(exclusionTask, 'question.options')"
            :key="option.id"
            class="d-flex mb-2 border border-light-info-gray bg-info-gray font-xs"
          >
            <span class="align-self-center py-2 px-3 font-weight-bold">
              {{ getSelectedOption(exclusionTask.question, option.id) }}
            </span>
            <span
              class="border-left border-light-info-gray p-2 flex-grow-1 bg-light"
              v-html="getOptionText(exclusionTask.question, option.id)"
            />
          </div>
        </div>
        <div class="col-4 border-left font-xs p-3 d-flex flex-column">
          <div class="flex-shrink-1">
            <div class="row mb-1">
              <div class="col-4">Requested by:</div>
              <div class="col-8 text-break pl-0 text-right font-weight-bold">
                {{ exclusionTask.assignor.full_name }}
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-4">Entity</div>
              <div class="col-8 text-break pl-0 text-right">
                <btn
                  class="font-weight-bold"
                  @click="show.entityDetails = true"
                >
                  {{ exclusionTask.entity.name }}
                </btn>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-4">Assessment:</div>
              <div class="col-8 text-break pl-0 text-right font-weight-bold">
                {{ exclusionTask.assessment_period.name }}
              </div>
            </div>
            <div
              class="row mb-3"
              :class="{
                border: exclusionTask.state !== 'Pending',
                'border-success-dark': exclusionTask.state === 'Approved',
                'bg-success-highlight': exclusionTask.state === 'Approved',
                'border-error': exclusionTask.state === 'Denied',
                'bg-error-highlight': exclusionTask.state === 'Denied',
                'border-left-0': exclusionTask.state !== 'Pending',
                'border-right-0': exclusionTask.state !== 'Pending',
              }"
            >
              <div class="col-4">Status:</div>
              <div
                class="col-8 text-break pl-0 text-right font-weight-bold"
                :class="{
                  'text-success-dark': exclusionTask.state === 'Approved',
                  'text-error': exclusionTask.state === 'Denied',
                }"
              >
                {{ exclusionTask.state }}
              </div>
            </div>
            <row class="mb-0"> Reason Given: </row>
          </div>
          <div
            class="flex-grow-1 font-italic rounded text-break bg-dark-highlight p-2"
            v-html="exclusionTask.description"
          />
        </div>
      </div>
    </template>
    <template
      v-else
      #body
    >
      <div class="pt-5 pb-6 text-center">Exclusion request not found. Please try again later.</div>
    </template>
    <template
      v-if="showFooter"
      #footer
    >
      <div class="w-100 d-flex">
        <btn
          :link="false"
          class="btn-sm btn-red"
          @click="showConfirm('deny')"
        >
          Deny
        </btn>
        <btn
          :link="false"
          class="ml-auto btn-sm btn-primary"
          @click="showConfirm('approve')"
        >
          Approve
        </btn>
      </div>
    </template>
    <template #modal>
      <ConfirmModal
        v-if="show.confirm"
        :message="message"
        :confirm-class="confirmButtonClass"
        :confirm-button-text="confirmButtonText"
        @cancel="onCancel"
        @confirm="sendResponse"
      />
      <EntitySummaryModal
        v-if="show.entityDetails"
        :entity-id="exclusionTask.entity_id"
        @close="show.entityDetails = false"
      />
    </template>
  </modal>
</template>

<script>
import Question from '@/components/reviewanswer/Question.vue'
import { QuestionMethods } from '@/mixins/helpers/Questions'
import EntitySummaryModal from '@/components/modals/entities/EntitySummaryModal.vue'

export default {
  components: {
    Question,
    EntitySummaryModal,
  },
  mixins: [QuestionMethods],
  props: {
    exclusionTaskId: {
      type: Number,
      required: true,
    },
  },
  emits: ['close', 'reload'],
  data() {
    return {
      loading: false,
      submitting: false,
      show: {
        confirm: false,
        entityDetails: false,
      },
      response: null,
      exclusionTask: null,
    }
  },
  computed: {
    showFooter() {
      return this.exclusionTask && !this.exclusionTask.completed
    },
    completedOn() {
      return this.exclusionTask && this.exclusionTask.completed === 1
        ? `Completed on ${this.$filters.toDate(this.exclusionTask.completed_at, false)}`
        : 'Incomplete'
    },
    confirmButtonClass() {
      return this.response === 'Approved' ? '' : 'btn-red'
    },
    confirmButtonText() {
      return this.response === 'Approved' ? 'Approve' : 'Deny'
    },
    message() {
      return this.response === 'Approved'
        ? `<p>Are you sure you want to <strong>approve</strong> this exclusion request for ${this.exclusionTask.entity.name}?</p> The question will be removed from this Entity's scope.`
        : `<p>Are you sure you want to <strong>deny</strong> this exclusion request for ${this.exclusionTask.entity.name}?</p> The question will not be removed from this Entity's scope.`
    },
    responseMethod() {
      return this.response === 'Approved' ? 'post' : 'delete'
    },
    responseUrl() {
      return this.response === 'Approved'
        ? `question-exclusion-requests/${this.exclusionTask.id}/approve`
        : `question-exclusion-requests/${this.exclusionTask.id}/deny`
    },
  },
  beforeMount() {
    this.loading = true
    this.loadExclusion(this.exclusionTaskId)
  },
  methods: {
    onCancel() {
      this.show.confirm = false
      this.response = null
    },
    showConfirm(response) {
      this.response = response === 'approve' ? 'Approved' : 'Denied'
      this.show.confirm = true
    },
    loadExclusion(id) {
      this.loading = true
      this.$http
        .get(`/question-exclusion-requests/${id}`)
        .then((response) => {
          this.exclusionTask = response.data
          this.loading = false
        })
        .catch((error) => {
          this.$toast.error(error)
          this.loading = false
        })
    },
    sendResponse() {
      this.submitting = true
      this.$http[this.responseMethod](this.responseUrl)
        .then(() => {
          this.$toast.success('Response Sent!')
          this.$bus.emit('exclusion-request-changed')
          this.submitting = false
          this.emitAndClose()
        })
        .catch((error) => {
          this.$toast.error(error)
          this.submitting = false
          this.emitAndClose()
        })
    },
    emitAndClose() {
      this.$refs.modal.close()
      this.$emit('reload')
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
.options > div:last-child {
  margin-bottom: 0 !important;
}
</style>
