import { noPersist as answersNoPersist } from '@/store/modules/answers/mutation-types'
import { noPersist as appNoPersist } from '@/store/modules/app/mutation-types'
import { noPersist as authNoPersist } from '@/store/modules/auth/mutation-types'
import { noPersist as categoriesNoPersist } from '@/store/modules/categories/mutation-types'
import { noPersist as companyNoPersist } from '@/store/modules/company/mutation-types'
import { noPersist as entitiesNoPersist } from '@/store/modules/entities/mutation-types'
import { noPersist as faqsNoPersist } from '@/store/modules/faqs/mutation-types'
import { noPersist as frameworksNoPersist } from '@/store/modules/frameworks/mutation-types'
import { noPersist as plansNoPersist } from '@/store/modules/plans/mutation-types'
import { noPersist as questionsNoPersist } from '@/store/modules/questions/mutation-types'
import { noPersist as risksNoPersist } from '@/store/modules/risks/mutation-types'
import { noPersist as threatsNoPersist } from '@/store/modules/threats/mutation-types'
import { noPersist as documentrequestlistsNoPersist } from '@/store/modules/threats/mutation-types'
import { noPersist as documentrequestlistNoPersist } from '@/store/modules/threats/mutation-types'
import { noPersist as documentrequestsNoPersist } from '@/store/modules/threats/mutation-types'
import { noPersist as documentrequestNoPersist } from '@/store/modules/threats/mutation-types'
import { noPersist as commentsNoPersist } from '@/store/modules/threats/mutation-types'
import { noPersist as usersNoPersist } from '@/store/modules/threats/mutation-types'
import { noPersist as dictionaryNoPersist } from '@/store/modules/threats/mutation-types'

export const skippedNamespace = ['route', 'initialized', 'resetting', 'documentrequestlists', 'documentrequestlist', 'documentrequests', 'documentrequest', 'dictionary']

export const skippedMutations = {
  answers: answersNoPersist,
  app: appNoPersist,
  auth: authNoPersist,
  categories: categoriesNoPersist,
  comments: commentsNoPersist,
  company: companyNoPersist,
  entities: entitiesNoPersist,
  faqs: faqsNoPersist,
  frameworks: frameworksNoPersist,
  plans: plansNoPersist,
  questions: questionsNoPersist,
  risks: risksNoPersist,
  threats: threatsNoPersist,
  documentrequestlists: documentrequestlistsNoPersist,
  documentrequestlist: documentrequestlistNoPersist,
  documentrequestsNoPersist: documentrequestsNoPersist,
  documentrequestNoPersist: documentrequestNoPersist,
  users: usersNoPersist,
  dictionary: dictionaryNoPersist,
}

export const isSkippedNamespace = (namespace) => skippedNamespace.includes(namespace)

export const isSkippedMutation = (namespace, field) =>
  namespace in skippedMutations && field in skippedMutations[namespace]
