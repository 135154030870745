import * as types from './mutation-types'
import { get } from 'lodash'
import router from '@/plugins/vue-router'
import Pagination from '@/plugins/pagination'
import Axios from '@/plugins/axios'

export default {
  async add({ commit }, answer) {
    await commit(types.ADD, answer)
  },

  async set({ commit }, data) {
    await commit(types.SET, data)
  },

  async saveAnswer({ commit, state, getters, dispatch, rootGetters }, { params }) {
    await commit(types.LOADING, true)
    await commit(types.SAVING, true)

    const urls = []
    const chunkSize = 15

    const data = state.selectedEntities
      .map((entity) => {
        return {
          ...params,
          ...{
            entity_id: entity.id,
            assignmentTypes: rootGetters['app/questionAssignmentTypes'](state.question, entity),
            assessment_period_id:
              rootGetters['auth/isAdmin'] && get(entity, 'divisions', []).length
                ? get(entity, 'divisions', []).map((division) => get(division, 'assessment_period.id', null))
                : null,
          },
        }
      })
      .reduce((all, answer) => {
        if (Array.isArray(answer.assessment_period_id)) {
          answer.assessment_period_id.forEach((id) => {
            let newAnswer = Object.assign({}, answer)
            newAnswer.assessment_period_id = id === rootGetters['company/assessmentPeriodId'] ? null : id
            all.push(newAnswer)
          })
        } else {
          all.push(answer)
        }
        return all
      }, [])

    if (getters.isInterview) {
      data.reduce((all, one, index) => {
        const chunk = Math.floor(index / chunkSize)
        all[chunk] = [].concat(all[chunk] || [], one)
        return all
      }, []).forEach((answer) => {
        urls.push({
          url: `/questions/${state.question.id}/mass-answers`,
          params: answer.map((a) => {
            let obj = Object.assign({
              interviewer_id: state.interviewer.id,
              interviewee_id: state.interviewee.id,
            }, a)
            delete obj.assignmentTypes
            return obj
          }),
        })
      })
    } else {
      if (rootGetters['company/hasFeature']('collaboration')) {
        data.filter((item) =>
          item.assignmentTypes.includes('collaboration')
        ).reduce((all, one, index) => {
            const chunk = Math.floor(index / chunkSize)
            all[chunk] = [].concat(all[chunk] || [], one)
            return all
        }, []).forEach((collaboration) => {
          urls.push({
            url: `/questions/${state.question.id}/mass-collaboration-answers`,
            params: collaboration.map((c) => {
              let obj = Object.assign({}, c)
              delete obj.assignmentTypes
              return obj
            }),
          })
        })
      }

      data.filter((item) =>
        item.assignmentTypes.includes('question') || (!item.assignmentTypes.includes('collaboration') && rootGetters['auth/isAdmin'])
      ).reduce((all, one, index) => {
        const chunk = Math.floor(index / chunkSize)
        all[chunk] = [].concat(all[chunk] || [], one)
        return all
      }, []).forEach((answer) => {
        urls.push({
          url: `/questions/${state.question.id}/mass-answers`,
          params: answer.map((a) => {
            let obj = Object.assign({}, a)
            delete obj.assignmentTypes
            return obj
          }),
        })
      })
    }

    const totalChunks = urls.length
    let currentChunk = 0

    return Promise.allSettled(
      urls.map((item) =>
        Axios.post(item.url, {
          answers: item.params,
        }).then((response) => {
          currentChunk++
          commit(types.SET, {
            savingProgress: Math.round((currentChunk / totalChunks) * 100),
          })
          return response.data
        }).catch((error) => {
          currentChunk++
          commit(types.SET, {
            savingProgress: Math.round((currentChunk / totalChunks) * 100),
          })
          commit(types.ERROR, [error.response.data.errors || error.response.data.message || 'Unknown error occured'])
        })
      )
    ).then((all) => {
      dispatch('app/load', null, { root: true }).then(() => {
        commit(types.LOADING, false)
        commit(types.SAVING, false)
      })
      if (state.refreshCompany) {
        dispatch('answers/set', {
          refreshCompany: false,
        }, { root: true })
        dispatch('company/refresh', null, { root: true })
      }

      const persistedAnswers = all.reduce((all, item) => {
        if (get(item, 'value.answered', []).length) {
          all = [].concat(all, item.value.answered)
        }
        return all
      }, [])
      const answerErrors = all.reduce((all, item) => {
        if (get(item, 'value.answerErrors', []).length) {
          all = [].concat(all, item.value.answerErrors)
        }
        return all
      }, [])

      commit(types.CONCAT, persistedAnswers)
      commit(types.ERROR, answerErrors)

      return persistedAnswers
    })
  },

  async loadQuestion({ commit, getters, rootGetters }, questionId) {
    await commit(types.LOADING, true)
    await commit(types.SET, {
      previousAnswers: [],
    })
    await Axios.get(`/questions/${questionId}`).then((response) => {
      commit(types.QUESTION, response.data)
      commit(types.LOADING, false)
      if (getters.crossDivisionEnabled) {
        return Pagination(`/organizations/${rootGetters['auth/getOrganizationId']}/entities`, {
          'filter[protocol_id]': response.data.protocol_id,
          'filter[question_requires_answer]': response.data.id,
        }).then((response) => {
          commit(types.OTHERDIVISIONENTITIES, response)
        })
      }
    })
  },

  async updateSelectedEntities({ state, getters, commit }) {
    let selected = state.selectedEntities

    let entities = getters.needAnswer.concat(
      state.otherDivisionEntities.filter((e) => !getters.needAnswerIds.includes(e.id))
    )

    let returnToEntity = state.returnToEntity ? entities.find((e) => e.id === state.returnToEntity) : null

    if (!entities.length && state.selectedEntities.length) {
      selected = []
    } else {
      if (entities.length === 1) {
        selected = [entities[0]]
      }
      if (returnToEntity) {
        selected = [returnToEntity]
      }
      if (getters.nonSelectedForcedEntities.length) {
        selected = entities.filter((e) => getters.nonSelectedForcedEntities.includes(e.id))
      }
    }
    await commit(types.SET, {
      selectedEntities: selected.filter((entity) => {
        return [
          ...state.question.completion_meta.remainingEntities,
          ...(state.question.completion_meta.collaborationNotAnsweredEntities || []),
          ...state.otherDivisionEntities.map((e) => e.id),
        ]
          .filter((v, i, a) => a.indexOf(v) === i)
          .includes(entity.id)
      }),
    })
  },

  async loadPreviousAnswers({ commit }, questionId) {
    await commit(types.SET, {
      previousAnswersLoading: true,
    })
    await Pagination(`/questions/${questionId}/previous-answers`).then((response) => {
      commit(types.SET, {
        previousAnswers: response,
        previousAnswersLoading: false,
      })
    })
  },

  async clear({ commit }) {
    await commit(types.RESET)
  },

  resume({ state }) {
    router.push({
      name: 'questions.answer',
      params: {
        questions: state.selectedTopics.join(','),
        topic: state.topicId,
        question: state.selectedQuestionId,
        entity: state.returnToEntity,
      },
    })
  },

  async saveResume({ commit }, location) {
    await commit(types.RESUME, location || null)
  },
}
