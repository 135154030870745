export const LOAD = 'LOAD'
export const LOADING = 'LOADING'
export const LOADQUESTIONS = 'LOADQUESTIONS'
export const RESET = 'RESET'

export default {
  LOAD,
  LOADING,
  LOADQUESTIONS,
  RESET,
}

export const noPersist = [LOADING]
