const defaultState = () => ({
  access_token: null,
  refresh_token: null,
  remember: false,
  remembered: {},
  loading: false,
  user: null,
  pending: false,
  loggingIn: false,
  loggingOut: false,
  forcedLogout: false,
  activeOrganization: null,
})

const excludeFields = ['remembered', 'loading', 'loggingOut', 'loggingIn', 'forcedLogout', 'access_token', 'refresh_token', 'remember', 'expires_at', 'tokenIsRefreshing', 'tokenRefreshQueue']

export { defaultState, excludeFields }

export default defaultState
