<template>
  <div
    class="select-container"
    :class="[size ? `select-container-${size}` : null, loading ? `loading-sm` : null]"
  >
    <select
      ref="element"
      class="form-control"
      :class="[elclass, { 'text-secondary': selectOneOption && !modelValue }]"
      :name="name"
      :readonly="readonly"
      :disabled="disabled || (loading && !options.length)"
      :required="required"
      @input="onChange"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
    >
      <option
        v-if="selectOneOption"
        :value="null"
      >
        {{ selectOneOptionText }}
      </option>
      <option
        v-for="(option, i) in formattedOptions"
        :key="i"
        :value="option.value"
        :selected="option.isSelected"
        :class="option.class || null"
        :disabled="option.disabled || false"
        v-html="option.display"
      />
    </select>
  </div>
</template>
<script>
import FormMixins from '@/mixins/FormMixins'

export default {
  mixins: [FormMixins],
  props: {
    selectOneOption: {
      type: Boolean,
      default: true,
    },
    selectOneOptionText: {
      type: String,
      default: 'Select One...',
    },
    options: {
      type: Array,
      default: null,
    },
    optionValueKey: {
      type: String,
      default: null,
    },
    optionValueLabel: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Number, String, Array, Object, Boolean],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableDefaultSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'focus', 'blur', 'change'],
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    formattedOptions() {
      return this.options.map((option, index) => {
        const value = this.getValue(index)
        return Object.assign(
          { ...option },
          {
            value: value,
            display: this.getDisplay(index),
            isSelected: this.selected === value,
          }
        )
      })
    },
  },
  watch: {
    options(current) {
      if (current.length === 1 && !this.disableDefaultSelected) {
        this.defaultSelected()
      }
    },
    modelValue(current) {
      this.selected = current
    },
  },
  mounted() {
    if (!this.disableDefaultSelected) {
      this.defaultSelected()
    }
  },
  methods: {
    getValue(index) {
      return this.optionValueKey ? this.options[index][this.optionValueKey] : this.options[index]
    },
    getDisplay(index) {
      return this.optionValueLabel ? this.options[index][this.optionValueLabel] : this.options[index]
    },
    setSelected(value) {
      let v = value
      if (parseInt(v)) {
        v = parseInt(v)
      }
      if (parseFloat(v)) {
        v = parseFloat(v)
      }

      this.selected = v
      this.$sleep(1).then(() => {
        this.$emit('update:modelValue', v)
      })
    },
    defaultSelected() {
      if (this.options.length === 1) {
        this.setSelected(this.optionValueKey ? this.options[0][this.optionValueKey] : this.options[0])
      } else {
        this.setSelected(this.modelValue)
      }
    },
    onChange(e) {
      Object.values(e.target.selectedOptions)
        .map((option) => option._value)
        .forEach((value) => {
          this.setSelected(value)
        })
    },
  },
}
</script>
