/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/routes'
import store from '@/store'
import { capitalize } from 'lodash'
import { axiosRequests } from '@/plugins/axios'
import { worker } from '@/plugins/serviceworker'
import lodash from "lodash"
import { localForageAuthService } from '@/store/plugins/LocalForageAuthService'

export const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

const checkWorker = async () => worker.update()

/* ============
 * Preboot events
 * ============
 *
 * Check if users is authenticated
 */
router.beforeResolve(async () => {
  if (worker) {
    checkWorker()
  }
})

router.beforeEach((to, from, next) => {

  // ================================================================================================
  // ================================================================================================
  // ================================================================================================


  // Checking localStorage for persisted authentication

  return defaultRouterFunctions(from, to, next);

  // if (!store.getters['auth/authenticated']) {
  //   store.dispatch('auth/handleAuthPersistence').then((hasPersistedUser) => {
  //     if (hasPersistedUser) {
  //       store.dispatch("auth/tokenNeedsRefresh").then((needsRefresh) => {
  //         if (needsRefresh == true) {
  //           store.dispatch("auth/attemptTokenRefresh").then((result) => {
  //             return defaultRouterFunctions(from, to, next);
  //           })
  //         } else {
  //           return defaultRouterFunctions(from, to, next);
  //         }
  //       })
  //     } else {
  //       return defaultRouterFunctions(from, to, next);
  //     }
  //   })
  // } else {
  //   return defaultRouterFunctions(from, to, next);
  // }

  // ================================================================================================
  // ================================================================================================
  // ================================================================================================

})

const defaultRouterFunctions = (from, to, next) => {

  // Redirect empty path to dashboard
  if (store.getters['auth/authenticated'] && to.fullPath === '/') {
    return next({ path: "dashboard" })
  }

  // Token authentication redirect handling
  if (store.getters['auth/authenticated']) {
    if (!lodash.isNil(from.query['redirect']) || !lodash.isNil(to.query['redirect'])) {
      if (!lodash.isNil(to.query['redirect'])) {
        if (from.path !== to.query["redirect"]) {
          return next({ path: to.query["redirect"], query: {foo: 1}, params: {foo: 1} })
        }
      }
      if (!lodash.isNil(from.query['redirect'])) {
        if (to.path !== from.query["redirect"]) {
          return next({ path: from.query["redirect"], query: {foo: 1}, params: {foo: 1} })
        }
      }
    }
  }

  let isLogin = to.name && to.name.split('.').shift() === 'login'

  // Verify that organization is active
  if (store.getters['auth/authenticated'] && !isLogin) {
    let organization = store.getters['company/organization'];
    let organizationIsActive = lodash.get(organization, 'is_active', null)
    if (!lodash.isNil(organizationIsActive)) {
      if (organizationIsActive !== true) {
        if (to.name !== 'errors.organization-inactive' && to.name !== 'profile.company') {
          // Exclude all admin routes
          if (to.path.indexOf('admin') !== -1 ) {
            // Do nothing if user is attempting to visit the admin
          } else {
            return next({ name: 'errors.organization-inactive' })
          }
        }
      }
    }
  }

  if (!store.getters['auth/authenticated'] && !isLogin) {
    next(false)
    if (to.name) {
      store.dispatch('app/set', {
        loginRedirect: to,
      })
    }
    store.dispatch('auth/logout', 'Login Required')
  } else {
    if (!store.state.auth.user && !isLogin) {
      store.dispatch('auth/getUser', next())
    } else if (
      store.getters['auth/authenticated'] &&
      to.name !== 'logout' &&
      !store.getters['auth/getOrganizationId'] &&
      to.name.split('.').shift() !== 'profile' &&
      !(store.getters['auth/isSysAdmin'] && to.name.split('.').shift() === 'admin')
    ) {
      next({ name: 'profile.company' })
    } else {
      const denied = checkForAccess(to)

      setDocumentTitle(to)

      if (denied) {
        store.dispatch('app/error', 'Permission Denied')
        next(from.path)
      } else {
        updateTransitions(from, to)
        next()
      }
    }
  }

}

export default router

const setDocumentTitle = (to) => {
  const title = to.meta.title
    ? to.meta.title
    : `${((to.name || '').includes('.index') ? 'My' : '')} ${(to.name || '').split('.').map((word) => (word !== 'index' ? capitalize(word) : '')).join(' ')}`

  document.title = `WaveFire | ${title}`
}

const checkForAccess = (to) => {
  if (to.meta.auth === 'requiresAdmin' && !store.getters['auth/isAdmin']) {
    return true
  }

  if (to.meta.auth === 'requiresPrimaryAdmin' && !store.getters['auth/isPrimaryAdmin']) {
    return true
  }

  if (to.meta.auth === 'requiresSysAdmin' && !store.getters['auth/isSysAdmin']) {
    return true
  }

  if (to.meta.auth === 'notEmployee' && store.getters['auth/isEmployee']) {
    return true
  }
}

const updateTransitions = (from, to) => {
  const fromPath = from.path.split('/')[1]
  const toPath = to.path.split('/')[1]
  const exclusionPath = ['admin', 'company']

  if (fromPath === toPath && !exclusionPath.includes(toPath)) {
    store.dispatch('app/transitions', false)
  } else {
    store.dispatch('app/transitions', true)
  }
  if (!store.state.auth.loggingIn) {
    axiosRequests.cancel()
  }
}
