<template>
  <FormGroup>
    <FormLabel />
    <vue-tel-input
      v-model="phone"
      name="telephone"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :default-country="defaultCountry"
      :auto-default-country="autoDefaultCountry"
      :disabled="disabled"
      :dropdown-options="dropdownOptions"
      :ignored-countries="ignoredCountries"
      :input-classes="['form-control', elclass, size ? `form-control-${size}` : null]"
      :input-options="inputOptions"
      :max-len="maxLength"
      :mode="mode"
      :only-countries="onlyCountries"
      :preferred-countries="preferredCountries"
      :required="required"
      :valid-characters-only="validCharactersOnly"
      :wrapper-classes="wrapperClasses"
      :custom-validate="customValidate"
      @country-changed="onCountryChanged"
      @validate="changed"
      @on-input="doInput"
    />
  </FormGroup>
</template>
<script>
import { VueTelInput } from 'vue-tel-input'
import FormMixins from '@/mixins/FormMixins'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  name: 'FormTelephone',
  components: {
    VueTelInput,
  },
  mixins: [FormMixins],
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    defaultCountry: {
      type: String,
      default: 'US',
    },
    autoDefaultCountry: {
      type: Boolean,
      default: true,
    },
    dropdownOptions: {
      type: Object,
      default: () => ({
        disabledDialCode: false,
        tabindex: 0,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showFlags: true,
      }),
    },
    ignoredCountries: {
      type: Array,
      default: () => [],
    },
    inputOptions: {
      type: Object,
      default: () => ({
        showDialCode: false,
        tabindex: 0,
        placeholder: 'Enter a phone number',
        name: 'telephone',
      }),
    },
    maxLength: {
      type: Number,
      default: 25,
    },
    mode: {
      type: String,
      default: 'national',
    },
    onlyCountries: {
      type: Array,
      default: () => [],
    },
    preferredCountries: {
      type: Array,
      default: () => ['US', 'CA', 'GB'],
    },
    validCharactersOnly: {
      type: Boolean,
      default: false,
    },
    wrapperClasses: {
      type: [String, Array, Object],
      default: '',
    },
    customValidate: {
      type: [Boolean, RegExp],
      default: false,
    },
    containerClass: {
      type: String,
      default: null,
    },
  },
  emits: ['is-valid', 'country-code', 'update:modelValue'],
  data() {
    return {
      phone: null,
    }
  },
  beforeMount() {
    this.phone = this.modelValue
  },
  methods: {
    doInput(number, phoneObject) {
      this.changed(phoneObject)
    },
    onCountryChanged({ iso2 }) {
      this.$emit('country-code', iso2)
    },
    changed({ number, valid, countryCode }) {
      if (countryCode !== undefined) {
        this.$emit('country-code', countryCode)
      }
      if (valid !== undefined) {
        this.$emit('is-valid', valid)
      }
      if (number !== undefined) {
        this.$emit('update:modelValue', number)
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
:deep(.vti__dropdown) {
  outline: none;
  padding: 0 0.5rem;
  .vti__selection {
    font-size: 0.875rem;
  }
  .vti__dropdown-arrow {
    font-size: 0.5rem;
  }
}
:deep(.vti__dropdown-arrow) {
  margin-left: 0.125rem;
}
:deep(.vue-tel-input) {
  border-color: #ced4da;
  &:focus-within {
    box-shadow: none;
    border-color: #7aa1ca;
    outline: 0;
  }
  input {
    &:focus {
      color: #495057;
    }
    color: #495057;
    background-color: #fff;
    padding: 0.25rem 0.5rem;
    &.form-control-sm {
      height: 29px;
    }
  }
}
</style>
