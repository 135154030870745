import { defaultState } from './state'

import {
  SET,
  ADDUSER,
  EMPTYUSERS,
  LOADUSERS,
  ADDGROUP,
  EMPTYGROUPS,
  LOADGROUPS,
  LOADTAGS,
  LOADASSETS,
  LOADROLES,
  LOADANSWERS,
  LOADASSIGNMENTS,
  LOADASSESSMENTS,
  LOADING,
  GROUPSLOADING,
  TAGSLOADING,
  USERSLOADING,
  ASSIGNMENTSLOADING,
  ASSESSMENTSLOADING,
  ANSWERSLOADING,
  ORG,
  CHANGEDIVISION,
  COLLABORATIONSLOADING,
  LOADCOLLABORATIONS,
  RESET,
  LOADENTITYQUESTIONEXCLUSIONS,
  ENTITYQUESTIONEXCLUSIONSLOADING,
  LOADENTITYQUESTIONEXCLUSIONREQUESTS,
  ENTITYQUESTIONEXCLUSIONREQUESTSLOADING,
} from './mutation-types'

export default {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  [ORG](state, org) {
    if (org && `id` in org) {
      state.organization = org
      state.divisions = org.divisions || []
      state.features = org.features
      state.groups = org.groups || []
      state.configurations = org.configurations || []
    } else {
      state.organization = null
      state.divisions = []
      state.activeDivision = null
      state.features = []
      state.groups = []
      state.configurations = []
    }
  },
  [CHANGEDIVISION](state, id) {
    state.activeDivision = id
  },
  [LOADING](state, bool) {
    state.loading = bool
  },
  [ENTITYQUESTIONEXCLUSIONSLOADING](state, bool) {
    state.entityQuestionExclusionsLoading = bool
  },
  [LOADENTITYQUESTIONEXCLUSIONS](state, questions) {
    state.entityQuestionExclusions = questions || []
  },
  [ENTITYQUESTIONEXCLUSIONREQUESTSLOADING](state, bool) {
    state.entityQuestionExclusionRequestsLoading = bool
  },
  [LOADENTITYQUESTIONEXCLUSIONREQUESTS](state, requests) {
    state.entityQuestionExclusionRequests = requests || []
  },
  [GROUPSLOADING](state, bool) {
    state.groupsLoading = bool
  },
  [TAGSLOADING](state, bool) {
    state.tagsLoading = bool
  },
  [ASSIGNMENTSLOADING](state, bool) {
    state.assignmentsLoading = bool
  },
  [COLLABORATIONSLOADING](state, bool) {
    state.collaborationsLoading = bool
  },
  [ASSESSMENTSLOADING](state, bool) {
    state.assessmentsLoading = bool
  },
  [USERSLOADING](state, bool) {
    state.usersLoading = bool
  },
  [ANSWERSLOADING](state, bool) {
    state.answersLoading = bool
  },
  [LOADUSERS](state, users) {
    state.users = users || []
  },
  [LOADGROUPS](state, groups) {
    state.groups = groups || []
  },
  [LOADTAGS](state, tags) {
    state.tags = tags || []
  },
  [LOADASSETS](state, assets) {
    state.assets = assets || []
  },
  [LOADROLES](state, roles) {
    state.roles = roles || []
  },
  [LOADANSWERS](state, answers) {
    state.answers = answers || []
  },
  [LOADASSIGNMENTS](state, assignments) {
    state.assignments = assignments || []
  },
  [LOADCOLLABORATIONS](state, collaborations) {
    state.collaborations = collaborations || []
  },
  [LOADASSESSMENTS](state, assessment_periods) {
    state.assessment_periods = assessment_periods || []
  },
  [ADDUSER](state, user) {
    state.users.push(user)
  },
  [ADDGROUP](state, group) {
    state.groups.push(group)
  },
  [EMPTYUSERS](state) {
    state.users = []
  },
  [EMPTYGROUPS](state) {
    state.groups = []
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
