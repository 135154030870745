<template>
  <div class="saving-wrapper">
    <div :class="getSavingClass" />
  </div>
</template>
<script>
export default {
  props: {
    savingClass: {
      type: String,
      default: 'pulse',
    },
  },
  computed: {
    getSavingClass() {
      return `dot-${this.savingClass}`
    },
  },
}
</script>
