export default {
  count(state, getters) {
    return getters.active.length
  },
  active(state) {
    return state.threats.filter((threat) => threat.deleted_at === null)
  },
  get(state) {
    return (id) => state.threats.find((e) => e.id === id)
  },
}
