import { toastInstance as toast } from '@/plugins/toast'
import { has, isPlainObject } from 'lodash'

class Errors {
  /**
   * Create a new Errors instance.
   */
  constructor() {
    this.errors = {}
  }

  /**
   * Determine if an errors exists for the given field.
   *
   * @param {string} field
   */
  has(field) {
    return field in this.errors
  }

  /**
   * Determine if we have any errors.
   */
  any() {
    return Object.keys(this.errors).length > 0
  }

  count() {
    return Object.keys(this.errors).length
  }

  /**
   * Retrieve the error message for a field.
   *
   * @param {string} field
   */
  get(field) {
    if (this.has(field)) {
      if (typeof this.errors[field] === 'string') {
        return this.errors[field]
      } else if (Array.isArray(this.errors[field])) {
        return this.errors[field].join(', ')
      }
    }
  }

  /**
   * Record the new errors.
   *
   * @param {object} errors
   */
  record(errors) {
    if (has(errors, 'response.data.errors')) {
      this.errors = errors.response.data.errors
    } else if (has(errors, 'response.data.message')) {
      this.errors = {
        invalid: errors.response.data.message,
      }
      toast.error(errors.response.data.message)
    } else if (has(errors, 'response.data.error')) {
      this.errors = {
        error: errors.response.data.error,
      }
      toast.error(errors.response.data.error)
    } else if (isPlainObject(errors)) {
      this.errors = errors
      toast.error(`Error(s): ${Object.values(this.errors).join(', ')}`)
    }
  }

  push(key, error) {
    Object.assign(this.errors, {
      [key]: error,
    })
  }

  /**
   * Clear one or all error fields.
   *
   * @param {string|null} field
   */
  clear(field) {
    if (has(this.errors, 'invalid')) {
      delete this.errors.invalid
    }

    if (this.has(field)) {
      delete this.errors[field]
    }
  }

  clearMany(fields) {
    ;(fields || []).forEach((field) => {
      this.clear(field)
    })
  }
}

export default Errors
