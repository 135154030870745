import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import Axios from '@/plugins/axios'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  set({ commit }, data) {
    commit(types.SET, data)
  },

  async load({ commit }, sysAdmin) {
    await commit(types.LOADING, true)
    await Pagination(
      `/threats`,
      Object.assign(
        {
          include: 'organizationsCount',
        },
        sysAdmin ? { sysAdmin: 1 } : null
      )
    )
      .then((response) => {
        commit(types.LOAD, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  async deleteThreat({}, threatId) {
    await Axios.delete(`/threats/${threatId}`)
      .then(() => {
        toast.success('Threat Deleted')
      })
      .catch((error) => {
        toast.error(error)
      })
  },

  clear({ commit }) {
    commit(types.LOAD, [])
  },

  refresh({ dispatch }) {
    dispatch('load')
  },
}
