export const SET = 'SET'
export const ADDUSER = 'ADDUSER'
export const EMPTYUSERS = 'EMPTYUSERS'
export const LOADUSERS = 'LOADUSERS'
export const ADDGROUP = 'ADDGROUP'
export const EMPTYGROUPS = 'EMPTYGROUPS'
export const LOADGROUPS = 'LOADGROUPS'
export const LOADTAGS = 'LOADTAGS'
export const LOADASSETS = 'LOADASSETS'
export const LOADROLES = 'LOADROLES'
export const LOADANSWERS = 'LOADANSWERS'
export const LOADASSESSMENTS = 'LOADASSESSMENTS'
export const LOADING = 'LOADING'
export const USERSLOADING = 'USERSLOADING'
export const GROUPSLOADING = 'GROUPSLOADING'
export const TAGSLOADING = 'TAGSLOADING'
export const ASSIGNMENTSLOADING = 'ASSIGNMENTSLOADING'
export const COLLABORATIONSLOADING = 'COLLABORATIONSLOADING'
export const ASSESSMENTSLOADING = 'ASSESSMENTSLOADING'
export const ANSWERSLOADING = 'ANSWERSLOADING'
export const ORG = 'ORG'
export const LOADASSIGNMENTS = 'LOADASSIGNMENTS'
export const LOADCOLLABORATIONS = 'LOADCOLLABORATIONS'
export const CHANGEDIVISION = 'CHANGEDIVISION'
export const RESET = 'RESET'
export const ENTITYQUESTIONEXCLUSIONSLOADING = 'ENTITYQUESTIONEXCLUSIONSLOADING'
export const LOADENTITYQUESTIONEXCLUSIONS = 'LOADENTITYQUESTIONEXCLUSIONS'
export const ENTITYQUESTIONEXCLUSIONREQUESTSLOADING = 'ENTITYQUESTIONEXCLUSIONREQUESTSLOADING'
export const LOADENTITYQUESTIONEXCLUSIONREQUESTS = 'LOADENTITYQUESTIONEXCLUSIONREQUESTS'

export default {
  SET,
  ADDUSER,
  EMPTYUSERS,
  LOADUSERS,
  ADDGROUP,
  EMPTYGROUPS,
  LOADGROUPS,
  LOADTAGS,
  LOADASSETS,
  LOADROLES,
  LOADANSWERS,
  LOADASSIGNMENTS,
  LOADASSESSMENTS,
  LOADING,
  USERSLOADING,
  GROUPSLOADING,
  TAGSLOADING,
  ASSIGNMENTSLOADING,
  ASSESSMENTSLOADING,
  ANSWERSLOADING,
  ORG,
  CHANGEDIVISION,
  COLLABORATIONSLOADING,
  LOADCOLLABORATIONS,
  RESET,
  ENTITYQUESTIONEXCLUSIONSLOADING,
  LOADENTITYQUESTIONEXCLUSIONS,
  ENTITYQUESTIONEXCLUSIONREQUESTSLOADING,
  LOADENTITYQUESTIONEXCLUSIONREQUESTS,
}

export const noPersist = [
  LOADING,
  LOADING,
  USERSLOADING,
  GROUPSLOADING,
  ASSIGNMENTSLOADING,
  ASSESSMENTSLOADING,
  ANSWERSLOADING,
  COLLABORATIONSLOADING,
]
