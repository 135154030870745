<template>
  <div class="file-placeholder position-relative d-inline-block text-center">
    <btn
      class="d-block document"
      iconic="document align-top text-medium-gray"
      @click="openFile"
    >
      <span
        class="file-logo position-absolute font-xxxlg"
        :class="ext"
      />
    </btn>
    <btn
      class="file-name text-break text-center"
      @click="openFile"
    >
      {{ name }}
    </btn>
    <btn
      v-if="showDelete"
      class="delete position-absolute"
      iconic="trash"
      @click="$emit('trash', file)"
    />
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    showDelete: {
      type: Boolean,
      default: null,
    },
    onOpen: {
      type: Function,
      default: null,
    },
  },
  emits: ['trash'],
  computed: {
    ext() {
      return this.file.extension || this.lodash.get(this.file, 'path', '').split('.').pop()
    },
    name() {
      return this.file.name || ''
    },
  },
  methods: {
    openFile() {
      if (this.onOpen) {
        this.onOpen(this.file)
      } else {
        window.open(this.file.temp_link, '_blank')
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
@import '@/assets/scss/_logos';
@import '@/assets/scss/_iconic-bootstrap';
.file-placeholder {
  max-width: 96px;
  .document {
    font-size: 6rem !important;
    line-height: 1;
    overflow: hidden;
    width: 100%;
    :deep(.iconic) {
      left: 0.1875rem;
    }
  }
  .delete {
    display: none;
    top: -0.25rem;
    right: 0.25rem;
    z-index: 1;
  }
  &:hover .delete {
    display: block;
  }
}
.file-logo {
  left: 50%;
  top: 50%;
  width: 2rem;
  height: 2rem;
  margin-left: -1rem;
  margin-top: -1rem;
  z-index: 1;
  text-align: start;
  &:before {
    @extend .icomoon-logo;
    position: absolute;
  }
  &.pdf {
    @extend .logo-pdf;
  }
  &.xls,
  &.xlsx,
  &.xlt,
  &.xltx,
  &.xlsb,
  &.csv,
  &.ods,
  &.sxc {
    @extend .logo-excel;
  }
  &.doc,
  &.docx,
  &.txt,
  &.rtf,
  &.odt,
  &.sxw,
  &.log {
    @extend .logo-word;
  }
  &.ppt,
  &.pptx,
  &.sxi,
  &.odp,
  &.otp,
  &.odg {
    @extend .logo-powerpoint;
  }
  &.png,
  &.jpg,
  &.jpeg,
  &.gif,
  &.bmp,
  &.tiff,
  &.tif {
    @extend .logo-photo;
  }
  &.avi,
  &.mkv,
  &.mov,
  &.mp4,
  &.webm,
  &.wmv {
    @extend .logo-video;
  }
  &.html,
  &.htm {
    @extend .logo-html5;
  }
  &.xml,
  &.json {
    @extend .logo-visualstudio;
  }
  &.zip,
  &.tar,
  &.rar,
  &[class~='7z'] {
    &:before {
      @extend .iconic;
      top: 0.25rem;
      font-family: iconic-md !important;
      color: $bright;
    }
    @extend .iconic-file-zip;
  }
}
.file-name {
  font-size: 0.75rem !important;
  line-height: 1;
}
</style>
