import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'
import ApiService from "../../../services/ApiService"
import _, { get } from 'lodash'
import {
  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationPerPage,
  updatePaginationLastPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL
} from "../../globals/pagination.global"
import { RESET } from "../ui/mutation-types"

const defaultState = () => {
  return {
    loading: false,
    items: [],
    pagination: {
      currentPage: 1,
      lastPage: null,
      perPage: 50,
      from: null,
      to: null,
      total: null
    }
  }
}

const state = defaultState()

const getters = {

  comments: (state) => {
    return state.items
  },

  loading: (state) => {
    return state.loading
  },

  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,
}

const actions = {

  // Imported pagination actions
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationLastPage,
  updatePaginationPerPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,

  async getComments({commit, getters, rootState, rootGetters, dispatch}, parameters) {

    await commit('IS_LOADING', true)

    return await ApiService.get(parameters.url, {
      vuexModule: 'comments',
      paginated: true,
      filtered: false,
    })
      .then((response) => {
        let data = response.data
        commit('SET_ITEMS', data.data)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit('IS_LOADING', false)
      })

  },

}

const mutations = {
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL,

  SET(state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },

  IS_LOADING(state, bool) {
    state.loading = bool
  },

  SET_ITEMS(state, items) {
    state.items = items
  },

  [RESET](state) {
    Object.assign(state, defaultState())
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    // nested
  }
}
