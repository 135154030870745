import { excludeFields as answersExcludeFields } from '@/store/modules/answers/state'
import { excludeFields as appExcludeFields } from '@/store/modules/app/state'
import { excludeFields as authExcludeFields } from '@/store/modules/auth/state'
import { excludeFields as categoriesExcludeFields } from '@/store/modules/categories/state'
import { excludeFields as companyExcludeFields } from '@/store/modules/company/state'
import { excludeFields as entitiesExcludeFields } from '@/store/modules/entities/state'
import { excludeFields as faqsExcludeFields } from '@/store/modules/faqs/state'
import { excludeFields as frameworksExcludeFields } from '@/store/modules/frameworks/state'
import { excludeFields as plansExcludeFields } from '@/store/modules/plans/state'
import { excludeFields as questionsExcludeFields } from '@/store/modules/questions/state'
import { excludeFields as risksExcludeFields } from '@/store/modules/risks/state'
import { excludeFields as threatsExcludeFields } from '@/store/modules/threats/state'

export const excludedFields = {
  answers: answersExcludeFields,
  app: appExcludeFields,
  auth: authExcludeFields,
  categories: categoriesExcludeFields,
  company: companyExcludeFields,
  entities: entitiesExcludeFields,
  faqs: faqsExcludeFields,
  frameworks: frameworksExcludeFields,
  plans: plansExcludeFields,
  questions: questionsExcludeFields,
  risks: risksExcludeFields,
  threats: threatsExcludeFields,
}

export const isExcludedField = (namespace, field) =>
  namespace in excludedFields && excludedFields[namespace].includes(field)
