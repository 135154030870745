import { toRaw } from 'vue'
import { get, defaultsDeep } from 'lodash'

export default {
  activeUsers(state) {
    return state.users.filter((user) => user.deleted_at === null)
  },
  activeDivisions(state) {
    return state.divisions.filter((division) => division.deleted_at === null)
  },
  excludedQuestionIds(state) {
    return state.entityQuestionExclusions.map((exclusion) => exclusion.question_id)
  },
  getEntityQuestionExclusions: (state) => (entityId) => {
    return state.entityQuestionExclusions.filter((exclusion) => exclusion.entity_id === entityId)
  },
  activeGroups(state) {
    return state.groups.filter((group) => group.deleted_at === null)
  },
  activeAssets(state) {
    return state.assets.filter((asset) => asset.deleted_at === null)
  },
  getUserFullName: (state) => (id) => {
    return state.users.find((user) => user.id === id).full_name || 'n/a'
  },
  countGroups(state) {
    return state.groups.length
  },
  countUsers(state) {
    return state.users.length
  },
  getByType: (state) => (type) => {
    return state.users
      .map((user) => {
        return !user.deleted_by && get(user, 'roles', []).find((role) => role.type === type) ? user : null
      })
      .filter((o) => !!o)
  },
  employees(state, getters) {
    return getters.getByType('Employee')
  },
  admins(state, getters) {
    return getters.getByType('Admin')
  },
  managers(state, getters) {
    return getters.getByType('Manager')
  },
  assessmentPeriodId(state) {
    return state.assessment_period ? state.assessment_period.id : null
  },
  hasFeature: (state) => (key) => {
    return !!state.features.find((feature) => feature.key === key)
  },
  getThreshold: (state) => (frameworkId) => {
    return (state.assessment_period || { thresholds: [] }).thresholds.find(
      (threshold) => threshold.protocol_id === frameworkId
    )
  },
  thresholds(state) {
    return (state.assessment_period || { thresholds: [] }).thresholds.reduce((all, threshold) => {
      all[threshold.protocol_id] = threshold.threshold
      return all
    }, {})
  },
  selectedDivision(state) {
    return state.divisions.find((division) => division.id === state.activeDivision) || {}
  },
  rawConfiguration: (state) => (frameworkId) => {
    return defaultsDeep(
      toRaw(state.configurations.find((configuration) => configuration.protocol_id === frameworkId)),
      {
        configuration: {
          controlTerm: null,
          controlText: null,
          noControlText: null,
          uploadText: null,
          allowUploads: false,
          allowNotApplicable: false,
          rollOverAnswers: false,
          viewPastAnswers: false,
          defaultAllowReassignment: false,
        },
      }
    )
  },
  configuration: (state, getters) => (frameworkId) => {
    return getters.rawConfiguration(frameworkId).configuration || {}
  },
  organization(state) {
    return state.organization
  },

}
