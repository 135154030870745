<template>
  <FormGroup>
    <ToggleCheck
      v-bind="bindToggle"
      :checked="checked"
      @update:checked="$emit('update:checked', $event)"
    />
    <slot />
  </FormGroup>
</template>
<script>
import ToggleCheck from './ToggleCheck.vue'
import FormMixins from '@/mixins/FormMixins'
import { toRaw } from 'vue'

export default {
  name: 'FormToggle',
  extends: ToggleCheck,
  mixins: [FormMixins],
  emits: ['update:checked'],
  computed: {
    bindToggle() {
      return Object.entries(this.combinedProps).reduce((obj, [key, value]) => {
        if (key === 'label') {
          obj.text = this.printLabel
        } else if (key !== 'checked' && (this.toggleKeys.includes(key) || key.startsWith('on'))) {
          obj[key] = value
        } else if (key === 'size' && value === 'sm') {
          obj['small'] = true
        }
        return obj
      }, {})
    },
    combinedProps() {
      return { ...toRaw(this.$props), ...toRaw(this.$attrs) }
    },
    toggleKeys() {
      return Object.keys(ToggleCheck.props)
    },
    printLabel() {
      return this.label
        ? this.label
        : this.name
            .split('_')
            .map((n) => this.$filters.capitalize(n))
            .join(' ')
    },
  },
}
</script>
