<template>
  <div class="card mb-0">
    <div class="card-header font-xl font-weight-light d-flex justify-content-center border-0 bg-white">
      {{ title }}
      <btn
        v-if="help"
        class="ml-2 mb-2 help"
        @click="$router.push(help).catch(() => $emit('close'))"
      >
        (help)
      </btn>
    </div>
    <template v-if="hasInfo">
      <div
        class="card-body d-flex align-contents-center border-0 bg-white p-25 scroll-y"
        :class="[bodyClass]"
      >
        <slot />
      </div>
      <div
        v-if="infoMessage"
        class="card-footer text-muted text-center font-xs border-0 bg-white px-0"
      >
        {{ infoMessage }}
      </div>
    </template>
    <template v-else>
      <div class="card-body d-flex align-contents-center justify-content-center border-0 bg-white p-25 scroll-y">
        <Icon
          iconic="circle-check text-success align-self-center"
          class="d-block font-xxxlg"
        />
      </div>
      <div class="card-footer text-muted text-center font-xs border-0 bg-white px-0">
        {{ successMessage }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    help: {
      type: Object,
      default: null,
    },
    hasInfo: {
      type: Boolean,
      default: false,
    },
    infoMessage: {
      type: String,
      default: null,
    },
    successMessage: {
      type: String,
      default: null,
    },
    bodyClass: {
      type: String,
      default: null,
    },
  },
  emits: ['close'],
}
</script>
<style
  lang="scss"
  scoped
>
:deep(.card-header .help) {
  font-size: 0.75rem !important;
  line-height: 1 !important;
}
</style>
