/**
 * Question Completion Helpers.
 * These methods help determine question completion state.
 */
import { get } from 'lodash'
import store from '@/store'

/**
 * Getters will return an object or collection of objects the match the methods arguments
 */

const getters = {
  /**
   * Gets all questions that have been completed for all entities.
   * @return {Array} collections of questions.
   */
  completeQuestions() {
    return store.state.questions.questions.filter((q) => get(q, 'completion_meta.isComplete', false))
  },

  /**
   * Gets all entities that have been completed.
   * @return {Array} collections of entities.
   */
  completedEntities() {
    return this.getActiveEntities().filter((entity) => this.isEntityAssessmentComplete(entity.id))
  },

  /**
   * Gets all entities that have been completed.
   * @return {Array} collections of entities.
   */
  incompleteEntities() {
    return this.getActiveEntities().filter((entity) => !this.isEntityAssessmentComplete(entity.id))
  },

  /**
   * Gets all topics that have been completed for all entities.
   * @return {Array} collections of topics.
   */
  completeTopics(questions) {
    return (questions || this.requiredTopics()).filter((q) => get(q, 'completion_meta.isComplete', false))
  },

  completeCollaborationTopics(questions) {
    return (questions || this.requiredTopics()).filter((q) => get(q, 'completion_meta.isCollaborationComplete', false))
  },

  completeCombinedTopics(questions) {
    return (questions || this.requiredTopics()).filter(
      (q) => get(q, 'completion_meta.isComplete', false) && get(q, 'completion_meta.isCollaborationComplete', false)
    )
  },

  completeTopicsByType(questions, requiredType) {
    if (requiredType === 'Answers') {
      return this.completeTopics(questions)
    } else if (requiredType === 'Collaborators') {
      return this.completeCollaborationTopics(questions)
    } else {
      return this.completeCombinedTopics(questions)
    }
  },

  /**
   * Gets all topics that have not been completed for a category.
   * @return {Array} collections of topics.
   */
  completeTopicsByCategory(categoryId) {
    return this.requiredTopics().filter(
      (q) => q.category_id === categoryId && get(q, 'completion_meta.isComplete', false)
    )
  },

  /**
   * Gets all questions that have not been completed for all entities.
   * @return {Array} collections of questions.
   */
  incomplete() {
    return store.state.questions.questions.filter((q) => !get(q, 'completion_meta.isComplete', true))
  },

  /**
   * Gets all topics that have not been completed for all entities.
   * @return {Array} collections of topics.
   */
  incompleteTopics(questions) {
    return (questions || this.requiredTopics()).filter((q) => !get(q, 'completion_meta.isComplete', true))
  },

  incompleteCollaborationTopics(questions) {
    return (questions || this.requiredTopics()).filter((q) => !get(q, 'completion_meta.isCollaborationComplete', true))
  },

  incompleteCombinedTopics(questions) {
    return (questions || this.requiredTopics()).filter(
      (q) => !get(q, 'completion_meta.isComplete', true) || !get(q, 'completion_meta.isCollaborationComplete', true)
    )
  },

  incompleteTopicsByType(questions, requiredType) {
    if (requiredType === 'Answers') {
      return this.incompleteTopics(questions)
    } else if (requiredType === 'Collaborators') {
      return this.incompleteCollaborationTopics(questions)
    } else {
      return this.incompleteCombinedTopics(questions)
    }
  },

  /**
   * Gets all topics that have not been completed for a category.
   * @return {Array} collections of topics.
   */
  incompleteTopicsByCategory(categoryId) {
    return this.requiredTopics().filter(
      (q) => q.category_id === categoryId && !get(q, 'completion_meta.isComplete', true)
    )
  },

  /**
   * Gets all questions that have been completed for an entity.
   * @param {Number} entityId
   * @return {Array} collections of questions.
   */
  questionsCompletedByEntity(entityId) {
    return store.state.questions.questions.filter((question) =>
      get(question, 'completion_meta.answeredEntities', []).includes(entityId)
    )
  },

  /**
   * Gets all topics that have been completed for an entity.
   * @param {Number} entityId
   * @return {Array} collections of topics.
   */
  topicsCompletedByEntity(entityId, frameworkId) {
    return this.requiredTopics().filter(
      (topic) =>
        (frameworkId ? topic.protocol_id === frameworkId : topic) &&
        topic.completion_meta.answeredEntities.includes(entityId) &&
        (store.getters['company/hasFeature']('collaboration')
          ? !get(topic, 'completion_meta.collaborationNotAnsweredEntities', []).includes(entityId)
          : topic)
    )
  },

  topicsCollaborationCompletedByEntity(entityId, frameworkId) {
    return this.requiredTopics().filter(
      (topic) =>
        (frameworkId ? topic.protocol_id === frameworkId : topic) &&
        (store.getters['company/hasFeature']('collaboration')
          ? !get(topic, 'completion_meta.collaborationNotAnsweredEntities', []).includes(entityId)
          : topic)
    )
  },
}

/**
 * Counts will return the number of matching objects
 */

const counts = {
  topicsCompletedByEntityCount(entityId, frameworkId) {
    if (!store.getters['auth/isAdmin'] && store.getters['company/hasFeature']('collaboration')) {
      return (
        this.topicsCompletedByEntity(entityId, frameworkId).length +
        this.topicsCollaborationCompletedByEntity(entityId, frameworkId).length
      )
    } else {
      return this.topicsCompletedByEntity(entityId, frameworkId).length
    }
  },
}

/**
 * Tests checks a condition or state and always returns true/false
 */

const tests = {
  /**
   * Checks if a question is completed for an entity.
   * @param {Number} entityId
   * @param {Number} questionId
   * @return {Boolean} Return true if complete, false if not complete.
   */
  completed(entityId, questionId) {
    return this.entityAnsweredQuestions(entityId).includes(questionId)
  },

  completedCollaborations(entityId, questionId) {
    return this.entityAnsweredCollaborationQuestions(entityId).includes(questionId)
  },

  /**
   * Checks if a parent question of a child question for an entity is complete.
   * @param {Number} entityId
   * @param {Number} questionId
   * @return {Boolean} Return true if complete, false if not complete.
   */
  parentCompleted(entityId, questionId) {
    let question = store.state.questions.questions.find((q) => q.id === questionId)
    if (!question.parent_question_id) {
      return false
    } else {
      let parent = store.state.questions.questions.find((q) => q.id === question.parent_question_id)
      return this.completed(entityId, parent.id)
    }
  },
  /**
   * Checks if a topic has been answered by all entities
   * @param {Number} topicId
   * @return {Bool}
   */
  isTopicComplete(topicId) {
    if (store.getters['company/hasFeature']('collaboration')) {
      return (
        this.getTopic(topicId).completion_meta.isComplete &&
        this.getTopic(topicId).completion_meta.isCollaborationComplete
      )
    } else {
      return this.getTopic(topicId).completion_meta.isComplete
    }
  },

  /**
   * Checks if a topic has not been answered by all entities
   * @param {Number} topicId
   * @return {Bool}
   */
  isTopicIncomplete(topicId) {
    if (store.getters['company/hasFeature']('collaboration')) {
      return (
        !this.getTopic(topicId).completion_meta.isComplete ||
        !this.getTopic(topicId).completion_meta.isCollaborationComplete
      )
    } else {
      return !this.getTopic(topicId).completion_meta.isComplete
    }
  },

  isCategoryComplete(categoryId, filteredQuestions) {
    let questions = (filteredQuestions || this.requiredTopics()).filter((q) => q.category_id === categoryId)

    if (!store.getters['auth/isAdmin'] && store.getters['company/hasFeature']('collaboration')) {
      return (
        questions.length ===
        questions.filter((q) => q.completion_meta.isComplete && q.completion_meta.isCollaborationComplete).length
      )
    } else {
      return questions.length === questions.filter((q) => q.completion_meta.isComplete).length
    }
  },
}

const CompletionMethods = Object.assign(getters, counts, tests)

export default CompletionMethods

// export const methods = Object.assign(getters, counts, tests)
