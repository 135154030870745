<template>
  <FormGroup>
    <slot />
    <FormLabel />
    <vue-editor
      ref="editor"
      v-model="localModel"
      :placeholder="placeholder"
      :name="name"
      :class="['bootstrap-editor text-small', elclass]"
      :readonly="readonly"
      :disabled="disabled"
      :required="isRequired"
      :editor-toolbar="getEditorToolbar"
      :editor-options="getEditorOptions"
    />
  </FormGroup>
</template>
<script>
import { VueEditor, Quill } from 'vue3-editor'
import ImageResize from 'quill-image-resize'
import FormMixins from '@/mixins/FormMixins'

export default {
  components: { VueEditor },
  mixins: [FormMixins],
  props: {
    toolbar: {
      type: String,
      default: 'minimal', // minimal, limited, full
    },
    editorToolbar: {
      type: Array,
      default: null,
    },
    editorOptions: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      minimal: [
        ['undo'],
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
      ],
      limited: [
        ['undo'],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['link', 'blockquote'],
        [{ indent: '-1' }, { indent: '+1' }],
        ['clean'],
      ],
      full: [
        ['undo', 'redo'],
        [{ header: [] }, { size: ['.5rem', '.75rem', false, '1.25rem', '1.5rem'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['link', 'blockquote', 'image'],
        [{ indent: '-1' }, { indent: '+1' }],
        ['clean'],
      ],
    }
  },
  computed: {
    strippedContent() {
      return (this.localModel || '').replace(/(<([^>]+)>)/gi, '')
    },
    isRequired() {
      return this.required && !this.strippedContent.length
        ? true
        : null
    },
    quill() {
      return this.$refs.editor ? this.$refs.editor.quill : null
    },
    getEditorToolbar() {
      return this.editorToolbar ? this.editorToolbar : this[this.toolbar] ? this[this.toolbar] : this.minimal
    },
    getEditorOptions() {
      return this.editorOptions
        ? this.editorOptions
        : {
            debug: 'error',
            modules: {
              imageResize: {},
              history: {
                delay: 750,
                maxStack: 500,
                userOnly: true,
              },
              toolbar: {
                container: this.getEditorToolbar,
                handlers: {
                  undo: () => {
                    this.$refs.editor.quill.history.undo()
                  },
                  redo: () => {
                    this.$refs.editor.quill.history.redo()
                  },
                },
              },
              clipboard: {
                matchVisual: false
              },
            },
            theme: 'snow',
          }
    },
    localModel: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  beforeMount() {
    let AlignStyle = Quill.import('attributors/style/align')
    let SizeStyle = Quill.import('attributors/style/size')
    let Parchment = Quill.import('parchment')

    SizeStyle.whitelist = ['.5rem', '.75rem', '1.25rem', '1.5rem']

    const levels = [1, 2, 3, 4, 5]
    const multiplier = 2

    class IndentAttributor extends Parchment.Attributor.Style {
      add(node, value) {
        return super.add(node, `${value * multiplier}rem`)
      }

      value(node) {
        return parseFloat(super.value(node)) / multiplier || undefined // Don't return NaN
      }
    }

    let IndentStyle = new IndentAttributor('indent', 'margin-left', {
      scope: Parchment.Scope.BLOCK,
      whitelist: levels.map((value) => `${value * multiplier}rem`),
    })

    Quill.register(AlignStyle, true)
    Quill.register(SizeStyle, true)
    Quill.register(IndentStyle, true)
    Quill.register('modules/imageResize', ImageResize)
  },
  mounted() {
    let container = Array.from(this.lodash.get(this.$refs, 'editor.$el.children', [])).find(
      (c) => c.id === 'quill-container'
    )
    let editor = Array.from(this.lodash.get(container, 'children', [])).find((c) => c.classList.contains('ql-editor'))

    if (editor) {
      editor.dataset.name = this.name
    }
  },
  methods: {
    setHTML(html) {
      if (this.quill) {
        this.quill.pasteHTML(0, html)
      }
    },
  },
}
</script>
