<template>
  <modal
    ref="modal"
    :class="{ loading: loading, saving: submitting }"
    :width="1200"
    body-class="p-0"
    @close-modal="$emit('close')"
  >
    <template #header>
      <AnswerHeader
        :question-type="questionType"
        :entity="entity"
      />
    </template>
    <template #body>
      <AnswerBody
        v-if="!loading && answer"
        :question="question"
      >
        <EditAnswer
          v-if="editAnswer"
          :answer="answer"
          :entity="entity"
          :options="options"
          :question-type="questionType"
          :configuration="configuration"
          :hide-control="hideControl"
          @toggle-hide-control="hideControl = $event"
          @update-option="form.question_option_id = $event"
          @update-control="form.control = $event"
        >
          <dropzone
            ref="dropzone"
            :relatedIds="getFileRelatedIds()"
            :url="`/questions/${question.id}/answer-files`"
            uploadType="Answer"
            :uploadParentId="`${answer.id}`"
            @upload-progress="files.uploadProgress = $event"
            @upload-complete="uploadComplete"
          />
        </EditAnswer>
        <Answer
          v-else
          class="border-left-0 border-right-0 border-bottom-0"
          title-class="px-3 py-2"
          body-class="px-4 py-3"
          actions-class="px-4 py-3"
          :count="0"
          :total="0"
          :answer="answer"
          @show-regulation="showRegulation = $event"
          @show-chat="showChat = $event"
          @view-notes="viewNotes = $event"
          @show-answer="editAnswer = true"
          @remediate="showLinkToPlan = true"
          @new-messages="updateDiscussionCount"
          @new-notes="updateNotesCount"
          @answer-accepted="updateAcceptance"
        />
      </AnswerBody>
      <AnswerNotFound
        v-else
        :loading="loading"
      />
    </template>
    <template
      v-if="editAnswer"
      #footer
    >
      <AnswerFooter
        :answer="answer"
        :submitting="submitting"
        @cancel="editOnLoad ? $refs.modal.close() : (editAnswer = false)"
        @answer-accepted="updateAcceptance"
        @update-answer="updateAnswer"
        @delete-answer="confirmDeleteAnswer = true"
      />
    </template>
    <template #modal>
      <ConfirmModal
        v-if="confirmDeleteAnswer"
        message="<p>Are you sure you want to delete this answer?</p><p>This cannot be undone?</p>"
        confirm-class="btn-red"
        @cancel="confirmDeleteAnswer = false"
        @confirm="deleteAnswer"
      />
      <LinkAnswerToActionPlan
        v-if="showLinkToPlan"
        :entity="entity"
        :answers="[answer]"
        @added-to-plan="addedToPlan"
        @close="showLinkToPlan = false"
      />
      <CriteriaModal
        v-if="showRegulation"
        :criteria="showRegulation"
        @close="showRegulation = false"
      />
      <ChatModal
        v-if="showChat"
        :answer="showChat"
        default-message="Start your discussion here"
        @new-messages="(e) => updateDiscussionCount(e, showChat)"
        @close="showChat = false"
      />
      <AnswerNotes
        v-if="viewNotes"
        :answer="viewNotes"
        @new-notes="(e) => updateNotesCount(e, viewNotes)"
        @close="viewNotes = false"
      />
    </template>
  </modal>
</template>

<script>
import AnswerHeader from './components/AnswerHeader.vue'
import AnswerBody from './components/AnswerBody.vue'
import AnswerFooter from './components/AnswerFooter.vue'
import AnswerNotFound from './components/AnswerNotFound.vue'
import EditAnswer from './components/EditAnswer.vue'
import { QuestionMethods } from '@/mixins/helpers/Questions'
import Answer from '@/components/reviewanswer/Answer.vue'
import Dropzone from '@/components/Dropzone.vue'
import LinkAnswerToActionPlan from '@/components/modals/LinkAnswerToActionPlan.vue'
import ChatModal from '@/components/reviewanswer/modals/ChatModal.vue'
import AnswerNotes from '@/components/reviewanswer/modals/AnswerNotes.vue'
import CriteriaModal from '@/components/modals/criteria/CriteriaModal.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AnswerModal',
  components: {
    AnswerHeader,
    AnswerBody,
    AnswerFooter,
    AnswerNotFound,
    EditAnswer,
    Answer,
    Dropzone,
    LinkAnswerToActionPlan,
    ChatModal,
    AnswerNotes,
    CriteriaModal,
  },
  mixins: [QuestionMethods],
  props: {
    fetchEntityId: {
      type: Number,
      default: 0,
    },
    providedQuestion: {
      type: Object,
      default: null,
    },
    providedAnswer: {
      type: Object,
      default: null,
    },
    editOnLoad: {
      type: Boolean,
      default: false,
    },
    openChat: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'accepted', 'deleted', 'updated'],
  data() {
    return {
      loading: false,
      answer: null,
      submitting: false,
      hideControl: false,
      confirmDeleteAnswer: false,
      editAnswer: false,
      showLinkToPlan: false,
      showRegulation: false,
      showChat: false,
      viewNotes: false,
      files: {
        answers: [],
        uploading: false,
        uploadProgress: 0,
      },
      form: this.$form({
        question_option_id: null,
        control: null,
      }),
    }
  },
  computed: {
    ...mapState('company', {
      configurations: 'configurations',
    }),
    ...mapState('app', {
      terms: 'terms',
    }),
    ...mapGetters({
      hasFeature: 'company/hasFeature',
      isAdmin: 'auth/isAdmin',
      getConfiguration: 'company/configuration',
    }),
    questionType() {
      return this.answer ? ('score' in this.answer ? 'answer' : 'collaboration') : 'answer'
    },
    options() {
      return this.lodash.get(this.answer, 'question.options', [])
    },
    selectedOption() {
      return this.options.find((option) => option.id === this.form.question_option_id)
    },
    configuration() {
      return this.lodash.get(this.answer, 'question.protocol_id', false)
        ? this.getConfiguration(this.question.protocol_id)
        : {}
    },
    entity() {
      return this.lodash.get(this.answer, 'entity')
    },
    entityId() {
      return this.lodash.get(this.entity, 'id', this.fetchEntityId)
    },
    question() {
      return this.providedQuestion ? this.providedQuestion : this.lodash.get(this.answer, 'question')
    },
    questionId() {
      return this.providedAnswer ? this.providedAnswer.question_id : this.lodash.get(this.question, 'id', 0)
    },
    answerId() {
      return this.lodash.get(this.answer, 'id', 0)
    },
    answerPath() {
      return this.answerId ? `/${this.answerId}` : ``
    },
    path() {
      return `${this.questionType !== 'answer' ? 'collaborations' : 'answers'}${this.answerPath}`
    },
    url() {
      return `/entities/${this.entityId}/questions/${this.questionId}/${this.path}`
    },
  },
  watch: {
    answer(current) {
      if (current) {
        this.form.question_option_id = this.answer.question_option_id
        this.form.control = this.answer.control
        if (this.form.control === 'N/A' && this.questionId !== 2813) {
          this.hideControl = true
        }
      }
    },
  },
  beforeMount() {
    if (this.providedAnswer) {
      this.answer = this.providedAnswer
      if (this.editOnLoad) {
        this.editAnswer = true
      }
    } else if (this.entityId && this.lodash.get(this.question, 'id')) {
      this.loading = true
      this.loadAnswer(this.questionId).then(() => {
        if (this.editOnLoad) {
          this.editAnswer = true
        }
      })
    }

    if (this.openChat) {
      this.showChat = true
    }
  },
  methods: {
    async loadAnswer() {
      await this.$http
        .get(this.url)
        .then((response) => {
          this.answer = response.data
          this.loading = false
        })
        .catch((error) => {
          this.$toast.error(error)
          this.loading = false
        })
    },
    updateAcceptance(answer) {
      this.answer = answer
      this.$emit('accepted', answer)
    },
    updateDiscussionCount(count, answer) {
      let updatedAnswer = Object.assign({}, answer)
      updatedAnswer.discussion_count = count

      this.answer = updatedAnswer

      this.$emit('updated', updatedAnswer)
    },
    updateNotesCount(count, answer) {
      let updatedAnswer = Object.assign({}, answer)
      updatedAnswer.notes_count = count

      this.answer = updatedAnswer

      this.$emit('updated', updatedAnswer)
    },
    updateAnswer() {
      this.submitting = true

      if (this.hideControl && this.answer.question_id !== 2813) {
        this.form.control === 'N/A'
      }

      // Hotfix for allowing optional control specification for quorum
      if (this.answer.question_id === 2813) {
        if (this.form.control.length <= 0) {
          this.form.control = 'Not specified'
        }
      }

      this.form
        .put(this.url)
        .then((response) => {
          if (response.data && this.configuration.allowUploads) {
            this.files.answers.push(response.data.id)
            this.files.uploading = true
            this.$refs.dropzone.startUpload()
          } else {
            this.uploadComplete()
          }
        })
        .catch(() => {
          this.submitting = false
        })
    },
    uploadComplete() {
      this.loadAnswer(this.questionId).then(() => {
        this.$emit('updated', this.answer)
        setTimeout(() => {
          this.submitting = false
          this.editAnswer = false
        }, 600)
      })
    },
    deleteAnswer() {
      this.confirmDeleteAnswer = false
      this.submitting = true
      this.form
        .delete(this.url)
        .then(() => {
          this.$emit('deleted', this.answer)
          this.$refs.modal.close()
        })
        .catch(() => {
          this.submitting = false
        })
    },
    sendingFile(file, xhr, formData) {
      formData.append('answers', JSON.stringify(this.files.answers))
    },
    getFileRelatedIds() {
      return JSON.stringify(this.files.answers)
    },
    addedToPlan() {
      this.loadAnswer(this.questionId)
      this.$emit('updated')
    },
    isControlExemptQuestion() {
      // Quorum
      if (this.answer.question_id === 2813 || this.answer.protocol_id === 24) {
        return true;
      }
      return false;
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
:deep(.modal-dialog) {
  transition: none !important;
}
:deep(.answer-title) {
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}
:deep(.file-upload-area) {
  flex: 0 0 532px;
}
</style>
