import dayjs from 'dayjs/esm'
import localizedFormat from 'dayjs/esm/plugin/localizedFormat'
import utc from 'dayjs/esm/plugin/utc'
import minMax from 'dayjs/esm/plugin/minMax'
import isSameOrAfter from 'dayjs/esm/plugin/isSameOrAfter'

dayjs.extend(isSameOrAfter)
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(minMax)

export default dayjs
