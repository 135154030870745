<template>
  <btn
    :iconic="`chevron-${direction}`"
    :link="false"
    class="btn-light btn-sm"
    :class="[actionClass, { 'no-text': noText }]"
    :disabled="disabled"
    @click="onClick"
  />
</template>
<script>
export default {
  props: {
    noText: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
