export default {
  overThreshold(state) {
    return state.threatEntityRisks.filter((risk) => {
      return risk.score.risk > risk.score.threshold
    })
  },
  hasScore(state) {
    return state.threatEntityRisks.filter((risk) => !!risk.score.risk)
  },
}
