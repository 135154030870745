export default {
  count(state) {
    return state.plans.length
  },
  active(state) {
    return state.plans.filter((plan) => plan.deleted_at === null)
  },
  get(state) {
    return (id) => state.plans.find((e) => e.id === id)
  },
  planIsComplete(state, getters) {
    return getters.planTaskCount && getters.planTaskCount === getters.planCompleteTasksCount
  },
  planTaskCount(state) {
    return state.plan ? state.plan.tasks.length : null
  },
  planCompleteTasks(state) {
    return state.plan ? state.plan.tasks.filter((task) => task.completed) : []
  },
  planCompleteTasksCount(state, getters) {
    return getters.planCompleteTasks.length
  },
  planIncompleteTasks(state) {
    return state.plan ? state.plan.tasks.filter((task) => !task.completed) : []
  },
  planIncompleteTasksCount(state, getters) {
    return getters.planIncompleteTasks.length
  },
  allEntitiesRemediated(state) {
    return !!(state.plan || { items: [] }).items.filter((item) => !item.entity_id).length
  },
  planEntities(state) {
    return (state.plan || { items: [] }).items.reduce((all, item) => {
      if (item.entity && !all.find((entity) => entity.id === item.entity_id)) {
        all.push(item.entity)
      }
      return all
    }, [])
  },
  planThreats(state) {
    return (state.plan || { items: [] }).items.reduce((all, item) => {
      if (item.threat && !all.find((threat) => threat.id === item.threat_id)) {
        all.push(item.threat)
      }
      return all
    }, [])
  },
  allPlansItems(state) {
    return state.plans.map((plan) => plan.items).reduce((all, current) => [...new Set([...all, ...current])], [])
  },
  activeThreats(state, getters) {
    return getters.allPlansItems
      .map((item) => item.threat)
      .reduce((all, item) => {
        if (item && !all.find((threat) => threat.id === item.id)) {
          all.push(item)
        }
        return all
      }, [])
  },
  activeEntities(state, getters) {
    return getters.allPlansItems
      .map((item) => item.entity)
      .reduce((all, item) => {
        if (item && !all.find((entity) => entity.id === item.id)) {
          all.push(item)
        }
        return all
      }, [])
  },
  activeManagers(state) {
    return state.plans
      .map((plan) => plan.manager)
      .reduce((all, item) => {
        if (!!item && !all.find((manager) => manager.id === item.id)) {
          all.push(item)
        }
        return all
      }, [])
  },
  hasAccounting(state) {
    return !!state.plans.filter((plan) => !!plan.cost_tracking_enabled).length
  },
}
