import { toastInstance as toast } from '@/plugins/toast'
import ApiService from "../../../services/ApiService"
import _ from "lodash"

const defaultState = () => {
  return {
    isLoading: true,
    isLoadingItems: true,
    isLoadingPointsOfContact: true,
    documentRequestList: {
      id: null,
      organization_id: null,
      title: null,
      description: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      items_count: null,
      items_awaiting_submission_count: null,
      items_submitted_count: null,
      items_returned_for_question_count: null,
      items_completed_count: null,
    },
    items: [],
    pointsOfContact: [],
    api: {
      endpoint: `/document-request-lists/`,
      paginated: false,
      filters: {
        withItems: false,
        withItemCount: true,
        withPointsOfContact: false,
      },
      items: {
        filters: {
          withAssignments: true,
          withCommentsCount: true,
          withFiles: true,
          title: '',
          status: [],
          showUnassigned: false,
        },
      },
    }
  }
}

const state = defaultState()

const getters = {

  getFilters: (state) => {
    return state.filters
  },

  isLoading: (state) => {
    return state.isLoading
  },
  isLoadingItems: (state) => {
    return state.isLoadingItems
  },
  isLoadingPointsOfContact: (state) => {
    return state.isLoadingPointsOfContact
  },
  documentRequestList: (state) => {
    return state.documentRequestList
  },
  title: (state) => {
    return state.documentRequestList.title
  },
  description: (state) => {
    return state.documentRequestList.description
  },
  id: (state) => {
    return state.documentRequestList.id
  },
  organization_id: (state) => {
    return state.documentRequestList.organization_id
  },
  created_at: (state) => {
    return state.documentRequestList.created_at
  },
  updated_at: (state) => {
    return state.documentRequestList.updated_at
  },
  deleted_at: (state) => {
    return state.documentRequestList.deleted_at
  },
  items_count: (state) => {
    return _.get(state.documentRequestList, 'items_count', 0)
  },
  items_awaiting_submission_count: (state) => {
    return _.get(state.documentRequestList, 'items_awaiting_submission_count', 0)
  },
  items_submitted_count: (state) => {
    return _.get(state.documentRequestList, 'items_submitted_count', 0)
  },
  items_returned_for_question_count: (state) => {
    return _.get(state.documentRequestList, 'items_returned_for_question_count', 0)
  },
  items_completed_count: (state) => {
    return _.get(state.documentRequestList, 'items_completed_count', 0)
  },
  pointsOfContact: (state) => {
    return state.pointsOfContact
  },
  items: (state) => {
    return state.items
  },

}

const actions = {

  async reset({commit}) {
    await commit('SET_IS_LOADING', true)
    commit('RESET');
    await commit('SET_IS_LOADING', false)
  },

  async getDocumentRequestList({commit, state, dispatch}, parameters) {
    await commit('IS_LOADING', true)
    let url = `/document-request-lists/` + parameters.id
    return await ApiService.get(url, {
      vuexModule: 'documentrequestlist',
      api: state.api,
    })
      .then((response) => {
        let data = response.data
        commit('SET_DOCUMENT_REQUEST_LIST', data.data)
        return true
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit('SET_IS_LOADING', false)
      })
  },

  async getDocumentRequests({commit, state, dispatch}, parameters) {
    await commit('SET_IS_LOADING_ITEMS', true)
    let url = `/document-request-lists/` + state.documentRequestList.id + '/items';

    return await ApiService.get(url, {
      vuexModule: 'documentrequests',
      api: state.api.items,
    })
      .then((response) => {
        let data = response.data
        commit('SET_DOCUMENT_REQUESTS', data.data)
      })
      // .catch((e) => {
      //   toast.error(e)
      // })
      .finally(() => {
        commit('SET_IS_LOADING_ITEMS', false)
      })

  },

  async getPointsOfContact({commit, state, dispatch}, parameters) {
    await commit('SET_IS_LOADING_POINTS_OF_CONTACT', true)
    let url = `/document-request-lists/` + parameters.id + '/points-of-contact'
    return await ApiService.get(url, {
      vuexModule: 'documentrequestlist',
    })
      .then((response) => {
        let data = response.data
        commit('SET_POINTS_OF_CONTACT', data.data)
      })
      .finally(() => {
        commit('SET_IS_LOADING_POINTS_OF_CONTACT', false)
      })
  },

  async createDocumentRequestList({commit, getters, rootState, rootGetters, dispatch}, payload) {
    await commit('SET_IS_LOADING', true)
    let url = `/document-request-lists/`
    return await ApiService.post(url, payload)
      .then((response) => {
        toast.success(response.data.message);
        return response;
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit('SET_IS_LOADING', false)
      })
  },

  async updateDocumentRequestList({commit, state, getters, rootState, rootGetters, dispatch}, payload) {
    await commit('SET_IS_LOADING', true)
    let url = `/document-request-lists/` + state.documentRequestList.id

    return await ApiService.post(url, payload)
      .then((response) => {
        toast.success(response.data.message);
        return response;
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit('SET_IS_LOADING', false)
      })
  },

  async batchAssignmentUpdate({commit, state, getters, rootState, rootGetters, dispatch}, payload) {
    let url = `/document-request-lists/` + state.documentRequestList.id + "/items/batch-assign";
    return await ApiService.post(url, payload)
      .then((response) => {
        toast.success(response.data.message);
        return response;
      })
      .catch((e) => {
        toast.error(e)
      })
  },

  async setRequestFilters({commit, state}, args) {
    // Iterate filters
    _.forEach(args, function(value, name) {
      if (state.api.items.filters.hasOwnProperty(name)) {
        commit('SET_REQUEST_FILTER', {"name": name, "value": args[name]});
      }
    });
  },


}

const mutations = {

  SET(state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  IS_LOADING(state, bool) {
    state.isLoading = bool
  },
  SET_IS_LOADING(state, bool) {
    state.isLoading = bool
  },
  SET_IS_LOADING_ITEMS(state, bool) {
    state.isLoadingItems = bool
  },
  SET_IS_LOADING_POINTS_OF_CONTACT(state, bool) {
    state.isLoadingPointsOfContact = bool
  },
  SET_TITLE(state, string) {
    state.documentRequestList.title = string
  },
  SET_DESCRIPTION(state, string) {
    state.documentRequestList.description = string
  },

  RESET(state) {
    Object.assign(state, defaultState())
  },
  SET_ITEMS(state, items) {
    state.items = items;
  },
  SET_DOCUMENT_REQUEST_LIST(state, documentRequestList) {
    state.documentRequestList = documentRequestList;
  },
  SET_DOCUMENT_REQUESTS(state, items) {
    state.items = items;
  },
  SET_POINTS_OF_CONTACT(state, pointsOfContact) {
    state.pointsOfContact = pointsOfContact;
  },
  SET_REQUEST_FILTER(state, args) {
    state.api.items.filters[args.name] = args.value;
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    // nested
  }
}
