<template>
  <div
    class="border border-light-gray border-bottom-0 d-flex flex-column align-self-stretch"
    :class="[answerClass]"
  >
    <div
      class="d-flex align-items-center border-bottom border-light-gray font-xs flex-shrink-1"
      :class="[titleClass]"
    >
      <h6 class="mb-0">
        <span
          v-if="total"
          class="text-secondary font-weight-light mr-2"
        >
          ({{ count }} of {{ total }})
        </span>
        <WithRoot :show="!!answer.entity.deleted_at">
          <del class="text-error">{{ answer.entity.name }}</del>
        </WithRoot>
      </h6>
      <AnsweredBy
        :answer="answer"
        :answert-type="answertType"
      />
      <AnswerFilesBtn
        :link="false"
        :answer="answer"
        class="btn-xxs px-1 py-1 btn-light-gray ml-2"
      />
    </div>
    <div
      class="flex-grow-1 scroll-y answer--body-class"
      :class="[bodyClass]"
    >
      <div class="text-secondary font-weight-normal font-italic font-xs d-flex nmt-1">
        {{ $filters.capitalize(answertType) }}
        <span class="text-black ml-auto font-normal">
          Assessment Period:
          <span class="font-weight-light mr-1 ml-1">
            {{ answer.assessment_period.name }}
            {{
              $filters.toDate(
                answer.assessment_period.end_date ||
                  answer.assessment_period.start_date ||
                  answer.assessment_period.created_at
              )
            }}
          </span>
        </span>
      </div>
      <div class="d-flex mb-2 border border-light-info-gray bg-info-gray font-xs">
        <span class="align-self-center px-3">
          {{ getSelectedOption(answer.question, answer.question_option_id) }}
        </span>
        <span
          class="border-left border-light-info-gray p-2 flex-grow-1 bg-light"
          v-html="getOptionText(answer.question, answer.question_option_id)"
        />
      </div>
      <div class="text-secondary font-weight-normal font-italic font-xs">
        {{ getConfiguration(answer.question.protocol_id).controlTerm || terms.controlTerm }}
      </div>
      <div
        class="p-2 border border-light-info-gray bg-light font-xs"
        :class="{ 'mb-2': !!regulations.length }"
        v-html="answer.control"
      />
      <div class="text-secondary font-weight-normal font-italic font-xs">Criteria</div>
      <div class="p-2 border border-light-info-gray bg-light font-xs">
        <btn
          v-for="(regulation, index) in regulations"
          :key="index"
          class="mr-2"
          @click="$emit('show-regulation', regulation)"
        >
          {{ regulation.identifier }}
        </btn>
      </div>
    </div>
    <div
      v-if="showActions"
      class="flex-shrink-1 d-flex justify-content-start border-top border-light-gray"
      :class="[actionsClass]"
    >
      <AcceptAnswerBtn
        v-if="shoudShowAcceptance && answertType === 'answer'"
        class="btn-xs mr-2"
        :answer="answer"
        :entity="answer.entity"
        @answer-accepted="$emit('answer-accepted', $event)"
      />
      <AddToActionPlanBtn
        v-if="showRemediation && answertType === 'answer' && !isEmployee"
        class="btn-xs mr-2"
        :disabled="!('score' in answer)"
        :answer="answer"
        @link-to-plan="$emit('remediate', $event)"
      />
      <btn
        iconic="chat mr-1"
        :link="false"
        :count="answer.discussion_count || 0"
        :badge-inline="true"
        class="btn-xs btn-light-gray mr-2"
        align-badge="right"
        @click="$emit('show-chat', answer)"
      >
        Discussion
      </btn>
      <btn
        v-if="answertType === 'answer'"
        iconic="document mr-1"
        :link="false"
        :count="answer.notes_count || 0"
        :badge-inline="true"
        class="btn-xs btn-light-gray"
        align-badge="right"
        @click="$emit('view-notes', answer)"
      >
        My Notes
      </btn>
      <btn
        v-if="answer.entity.is_current_assessment_locked"
        iconic="lock-locked mr-1"
        :link="false"
        class="ml-auto btn-xs btn-red disabled"
        disabled
      >
        Entity Locked
      </btn>
      <btn
        v-else-if="!answer.parent_question_id && editEnabled"
        iconic="pencil mr-1"
        class="btn-xs ml-auto btn-primary"
        :link="false"
        @click.prevent="$emit('show-answer', answer)"
      >
        Edit
      </btn>
      <btn
        v-else-if="answer.parent_question_id"
        class="btn-xs ml-auto btn-light"
        :disabled="true"
        :link="false"
      >
        View only, this is a nested response
      </btn>
    </div>
  </div>
</template>

<script>
import AcceptAnswerBtn from '@/components/buttons/AcceptAnswerBtn.vue'
import AnswerFilesBtn from '@/components/buttons/AnswerFilesBtn.vue'
import AddToActionPlanBtn from '@/components/buttons/AddToActionPlanBtn.vue'
import AnsweredBy from './components/AnsweredBy.vue'
import { QuestionMethods } from '@/mixins/helpers/Questions'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    AcceptAnswerBtn,
    AnsweredBy,
    AnswerFilesBtn,
    AddToActionPlanBtn,
  },
  mixins: [QuestionMethods],
  props: {
    count: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    answer: {
      type: Object,
      required: true,
    },
    showRemediation: {
      type: Boolean,
      default: true,
    },
    showAcceptance: {
      type: Boolean,
      default: true,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: String,
      default: 'p-2',
    },
    bodyClass: {
      type: String,
      default: 'px-6 py-4',
    },
    actionsClass: {
      type: String,
      default: 'px-6 py-25',
    },
    answerClass: {
      type: String,
      default: '',
    },
  },
  emits: ['show-regulation', 'answer-accepted', 'remediate', 'show-chat', 'view-notes', 'show-answer'],
  computed: {
    ...mapState('app', {
      terms: 'terms',
    }),
    ...mapGetters({
      getConfiguration: 'company/configuration',
      isAdmin: 'auth/isAdmin',
      isEmployee: 'auth/isEmployee',
      getUserId: 'auth/getUserId',
      hasFeature: 'company/hasFeature',
    }),
    answertType() {
      return 'score' in this.answer ? 'answer' : 'collaboration'
    },
    regulations() {
      return this.lodash.get(this.answer, 'question.regulations', [])
    },
    editEnabled() {
      return this.canEditAnswer(this.answer, this.answer.entity)
    },
    shoudShowAcceptance() {
      return this.hasFeature('acceptance')
        && this.showAcceptance
        && (this.isAdmin || this.answer.entity.manager_id === this.getUserId)
    },
    showActions() {
      return this.hideActions
        ? false
        : this.shoudShowAcceptance
          || this.showRemediation
          || this.answer.entity.is_current_assessment_locked
          || this.editEnabled
    },
  },
}
</script>
