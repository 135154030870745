export const SET = 'SET'
export const PERIODSLOADING = 'PERIODSLOADING'
export const ARCHIVEPERIODSLOADING = 'ARCHIVEPERIODSLOADING'
export const LOADPERIODS = 'LOADPERIODS'
export const LOADARCHIVEPERIODS = 'LOADARCHIVEPERIODS'
export const ARCHIVING = 'ARCHIVING'
export const UNARCHIVING = 'UNARCHIVING'
export const DELETING = 'DELETING'
export const RESTORING = 'RESTORING'
export const RESET = 'RESET'

export default {
  SET,
  PERIODSLOADING,
  ARCHIVEPERIODSLOADING,
  LOADPERIODS,
  LOADARCHIVEPERIODS,
  ARCHIVING,
  UNARCHIVING,
  DELETING,
  RESTORING,
  RESET,
}

export const noPersist = [PERIODSLOADING, ARCHIVEPERIODSLOADING, ARCHIVING, UNARCHIVING, DELETING, RESTORING]
