import { defaultState } from './state'

import { SET, EMPTY, LOAD, LOADING, PUT, ARCHIVED, RESET } from './mutation-types'

export default {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  [PUT](state, plan) {
    state.plan = plan
  },
  [LOADING](state, bool) {
    state.loading = bool
  },
  [LOAD](state, plans) {
    state.plans = plans || []
  },
  [EMPTY](state) {
    state.plans = []
  },
  [ARCHIVED](state, archivedPlans) {
    state.archivedPlans = archivedPlans || []
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
