import * as Sentry from '@sentry/browser';
import store from '@/store'
import lodash from 'lodash'

let SentryPlugin = null;

const updateUserSessionData = () => {

  // Prevent function from executing within the local environment
  if (import.meta.env.VITE_ENV === 'local') {
    return true;
  }

  let authUser = store.getters["auth/getUser"];
  let activeOrganization = store.getters["auth/activeOrganization"];

  let userId = lodash.get(authUser, "id", null)
  let userEmail = lodash.get(authUser, "email", null)
  let organizationId = lodash.get(activeOrganization, "id", null)
  let organizationName = lodash.get(activeOrganization, "name", null)

  Sentry.setContext('Organization', {
    organization_id: organizationId,
    organization_name: organizationName
  });

  Sentry.setUser({
    id: userId,
    email: userEmail
  });

}

export default SentryPlugin

export { updateUserSessionData, SentryPlugin }
