export const SET = 'SET'
export const EMPTY = 'EMPTY'
export const LOAD = 'LOAD'
export const LOADING = 'LOADING'
export const ASSIGNMENTS = 'ASSIGNMENTS'
export const COLLABORATIONS = 'COLLABORATIONS'
export const LOADINGASSIGNMENTS = 'LOADINGASSIGNMENTS'
export const LOADINGCOLLABORATIONS = 'LOADINGCOLLABORATIONS'
export const RESET = 'RESET'

export default {
  SET,
  EMPTY,
  LOAD,
  LOADING,
  ASSIGNMENTS,
  COLLABORATIONS,
  LOADINGASSIGNMENTS,
  LOADINGCOLLABORATIONS,
  RESET,
}

export const noPersist = [LOADING, LOADINGASSIGNMENTS, LOADINGCOLLABORATIONS]
