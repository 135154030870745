<template>
  <h5>
    {{ $filters.capitalize(questionType) }} Review
    <span
      v-if="entity"
      class="font-weight-light"
    >
      |
      <del
        v-if="!!entity.deleted_at"
        class="text-error"
      >
        {{ entity.name }}
      </del>
      <template v-else>
        {{ entity.name }}
      </template>
    </span>
  </h5>
</template>

<script>
export default {
  props: {
    questionType: {
      type: String,
      required: true,
    },
    entity: {
      type: Object,
      default: null,
    },
  },
}
</script>
