export const LOADTHREATRISKS = 'LOADTHREATRISKS'
export const LOADTHREATENTITYRISKS = 'LOADTHREATENTITYRISKS'
export const LOADCATEGORYRISKS = 'LOADCATEGORYRISKS'
export const LOADCATEGORYENTITYRISKS = 'LOADCATEGORYENTITYRISKS'
export const LOADCATEGORYTHREATRISKS = 'LOADCATEGORYTHREATRISKS'
export const LOADCATEGORYTHREATENTITYRISKS = 'LOADCATEGORYTHREATENTITYRISKS'
export const THREATRISKSLOADING = 'THREATRISKSLOADING'
export const THREATENTITYRISKSLOADING = 'THREATENTITYRISKSLOADING'
export const CATEGORYRISKSLOADING = 'CATEGORYRISKSLOADING'
export const CATEGORYENTITYRISKSLOADING = 'CATEGORYENTITYRISKSLOADING'
export const CATEGORYTHREATRISKSLOADING = 'CATEGORYTHREATRISKSLOADING'
export const CATEGORYTHREATENTITYRISKSLOADING = 'CATEGORYTHREATENTITYRISKSLOADING'
export const SET = 'SET'
export const RESET = 'RESET'

export default {
  LOADTHREATRISKS,
  LOADTHREATENTITYRISKS,
  LOADCATEGORYRISKS,
  LOADCATEGORYENTITYRISKS,
  LOADCATEGORYTHREATRISKS,
  LOADCATEGORYTHREATENTITYRISKS,
  THREATRISKSLOADING,
  THREATENTITYRISKSLOADING,
  CATEGORYRISKSLOADING,
  CATEGORYENTITYRISKSLOADING,
  CATEGORYTHREATRISKSLOADING,
  CATEGORYTHREATENTITYRISKSLOADING,
  SET,
  RESET,
}

export const noPersist = [
  THREATRISKSLOADING,
  THREATENTITYRISKSLOADING,
  CATEGORYRISKSLOADING,
  CATEGORYENTITYRISKSLOADING,
  CATEGORYTHREATRISKSLOADING,
  CATEGORYTHREATENTITYRISKSLOADING,
]
