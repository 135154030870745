import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'
import ApiService from "../../../services/ApiService"
import _, { get } from 'lodash'
import {
  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationPerPage,
  updatePaginationLastPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL
} from "../../globals/pagination.global"
import { RESET } from "../ui/mutation-types"

const defaultState = () => {
  return {
    loading: false,
    items: [],
    api: {
      endpoint: `/document-request-lists/{$var}/item/`,
      filters: {
        status: null,
        withAssignments: true,
        testFilter: true,
      },
      paginated: true,
      pagination: {
        currentPage: 1,
        lastPage: null,
        perPage: 50,
        from: null,
        to: null,
        total: null
      },
    }

  }
}

const state = defaultState()

const getters = {
  items: (state) => {
    return state.items
  },
  title: (state) => {
    return state.documentRequestList.title
  },
  description: (state) => {
    return state.documentRequestList.description
  },
  getFilters: (state) => {
    return state.filters
  },
  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,
}

const actions = {

  // Imported pagination actions
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationLastPage,
  updatePaginationPerPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,

  async getDocumentRequestListItems({commit, state, dispatch}, parameters) {

    // await commit('IS_LOADING', true)
    let url = `/document-request-lists/` + parameters.id + '/items';

    return await ApiService.get(url, {
      vuexModule: 'documentrequestlistitems',
      api: state.api,
    })
      .then((response) => {
        let data = response.data
        commit('SET_DOCUMENT_REQUEST_LIST_ITEMS', data.data)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit('IS_LOADING', false)
      })

  },

}

const mutations = {
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL,

  SET(state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },

  IS_LOADING(state, bool) {
    state.loading = bool
  },

  SET_DOCUMENT_REQUEST_LIST_ITEMS(state, items) {
    state.items = items
  },

  [RESET](state) {
    Object.assign(state, defaultState())
  },

}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    // nested
  }
}
