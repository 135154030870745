import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'
import ApiService from "../../../services/ApiService"
import {
  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationPerPage,
  updatePaginationLastPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL
} from "../../globals/pagination.global"
import { RESET } from "../ui/mutation-types"

const defaultState = () => {
  return {
    isLoading: false,
    users: [],
    api: {
      endpoint: '/users/',
      filters: {
        paginated: true,
        withRoles: true,
        roles: [],
        search: "",
      },
      paginated: false,
      pagination: {
        currentPage: 1,
        lastPage: null,
        perPage: 50,
        from: null,
        to: null,
        total: null
      }
    }
  }
}

const state = defaultState()

const getters = {

  users: (state) => {
    return state.users
  },

  isLoading: (state) => {
    return state.isLoading
  },

  getFilters: (state) => {
    return state.filters
  },
  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,
}

const actions = {

  // Imported pagination actions
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationLastPage,
  updatePaginationPerPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,

  async getUsers({commit, state, dispatch}, parameters) {

    await commit('IS_LOADING', true)
    let url = `/users/`;

    return await ApiService.get(url, {
      vuexModule: 'users',
      api: state.api
    })
      .then((response) => {
        let responseData = response.data
        commit('SET_USERS', responseData.data)
      })
      // .catch((e) => {
      //   toast.error(e)
      // })
      .finally(() => {
        commit('IS_LOADING', false)
      })
  },

  async createGuestUser({commit, getters, rootState, rootGetters, dispatch}, payload) {
    let url = `/users/assignment-user`
    return await ApiService.post(url, payload)
      .then((response) => {
        toast.success(response.data.message);
        return response;
      })
      .catch((e) => {
        toast.error(e)
      })
  },

}

const mutations = {
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL,

  SET(state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },

  IS_LOADING(state, bool) {
    state.isLoading = bool
  },

  SET_USERS(state, users) {
    state.users = users
  },

  [RESET](state) {
    Object.assign(state, defaultState())
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    // nested
  }
}
