import Axios from '@/plugins/axios'
import { get } from 'lodash'
import EventBus from '@/plugins/event-bus'

const LoadPage = async (url, params, results, resolve, reject) => {
  const path = url.split(import.meta.env.VITE_APP_API).pop()
  await Axios.get(path, {
    params: params ?? null,
  })
    .then((response) => {
      EventBus.emit('paginated', [path, get(response, 'data')])
      const allResults = results.concat(get(response, 'data.data', []))
      if (get(response, 'data.links.next')) {
        const parsedURL = new URL(response.data.links.next, import.meta.env.VITE_APP_API)
        LoadPage(
          `${parsedURL.origin}${parsedURL.pathname}`,
          Object.fromEntries(new URLSearchParams(parsedURL.search)),
          allResults,
          resolve,
          reject
        )
      } else {
        resolve(allResults)
      }
    })
    .catch((e) => {
      if(e.message != 'canceled') {
        reject(e)
      }
    })
}

export default LoadPage
