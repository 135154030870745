const defaultState = () => ({
  companyNavSettings: true,
  companyNavAssessments: false,
  companyNavDivisions: false,
  companyNavControls: false,
  companyNavRequests: false,
  companyNavPolicies: false,
})

const excludeFields = []

export { defaultState, excludeFields }

export default defaultState
