<template>
  <div
    class="dropdown d-inline-block"
    :class="{ disabled: disabled }"
  >
    <btn
      v-tippy
      iconic="cloud-transfer-download"
      class="btn-sm dropdown-toggle"
      :class="[classes, { disabled: disabled }]"
      :content="title"
    />
    <div
      class="dropdown-menu"
      :class="`dropdown-menu-${align}`"
      aria-labelledby="dropdownMenuButton"
    >
      <btn
        class="dropdown-item"
        @click.prevent="$emit('csv', entity)"
      >
        View Report (CSV)
      </btn>
      <btn
        v-if="hasHipaaFramework"
        class="dropdown-item"
        @click.prevent="$emit('hipaa', entity)"
      >
        HIPAA SRA OCR Report (PDF)
      </btn>
      <btn
        class="dropdown-item"
        @click.prevent="$emit('summary', entity)"
      >
        Assessment Executive Summary (PDF)
      </btn>
      <btn
        v-for="framework in nonHipaaFrameworks"
        :key="framework.id"
        class="dropdown-item"
        @click.prevent="$emit('report', framework)"
      >
        {{ framework.name }} Executive Report (PDF)
      </btn>
      <btn
        v-if="custom"
        class="dropdown-item"
        @click.prevent="$emit('custom', entity)"
      >
        {{ custom }}
      </btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    entity: {
      type: Object,
      default() {
        return {}
      },
    },
    align: {
      type: String,
      default: 'right',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: String,
      default: null,
    },
  },
  emits: ['csv', 'hipaa', 'summary', 'report', 'custom'],
  computed: {
    frameworks() {
      return this.entity.protocols
    },
    nonHipaaFrameworks() {
      return this.frameworks.filter((protocol) => protocol.id > 1)
    },
    hasHipaaFramework() {
      return !!this.frameworks.find((protocol) => protocol.id === 1)
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.dropdown {
  .dropdown-toggle:after {
    transition: transform 0.25s ease;
    transform: scaleY(1);
  }

  &:not(.disabled):hover {
    .dropdown-toggle:after {
      transform: scaleY(-1);
    }
    .dropdown-menu {
      display: block;
      margin-top: 0; // remove the gap so it doesn't close
      font-size: 0.75rem;
      min-width: 8rem;
      &.dropdown-menu-right {
        right: 0;
        left: auto;
      }
      &.dropdown-menu-left {
        left: -0.5rem;
        right: auto;
      }
      :deep(.dropdown-item) {
        font-size: 0.75rem;
        padding: 0.25rem 0.5rem;
        text-align: center;
      }
    }
  }
  .dropdown-toggle {
    &:after {
      vertical-align: middle;
    }
  }
}
</style>
