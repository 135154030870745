<template>
  <btn
    v-if="configuration.allowUploads"
    iconic="paperclip mr-1"
    :link="link"
    :class="{ disabled: !answer.files_count }"
    @click="$bus.emit('show-answer-files', answer)"
  >
    {{ getBtnText }}
  </btn>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    answer: {
      type: Object,
      default() {
        return {}
      },
    },
    link: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      getConfiguration: 'company/configuration',
    }),
    configuration() {
      return this.getConfiguration(this.answer.protocol_id)
    },
    getBtnText() {
      return this.buttonText ? this.buttonText : `${this.answer.files_count} Files`
    },
  },
}
</script>
