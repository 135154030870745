import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import { Axios, axiosRequests } from '@/plugins/axios'
import EventBus from '@/plugins/event-bus'
import { get, isObject, isEqual } from 'lodash'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  set({ commit }, data) {
    commit(types.SET, data)
  },

  async loadAssignments({ commit }, params) {
    await commit(types.ASSIGNMENTSLOADING, true)
    await Pagination(
      '/assignments',
      Object.assign(params ?? {}, {
        'filter[completed]': 0,
      })
    )
      .then((response) => {
        commit(types.LOADASSIGNMENTS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.ASSIGNMENTSLOADING, false)
      })
  },

  async loadCollaborations({ commit }, params) {
    await commit(types.COLLABORATIONSLOADING, true)
    await Pagination(
      '/collaborations',
      Object.assign(params ?? {}, {
        'filter[completed]': 0,
      })
    )
      .then((response) => {
        commit(types.LOADCOLLABORATIONS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.COLLABORATIONSLOADING, false)
      })
  },

  async loadEntityQuestionExclusions({ commit, rootGetters }, params) {
    if (rootGetters['auth/isAdmin']) {
      await commit(types.ENTITYQUESTIONEXCLUSIONSLOADING, true)
      await Pagination(
        `/organizations/${rootGetters['auth/getOrganizationId']}/entity-question-exclusions`,
        Object.assign(params ?? {}, {
          include: 'question,question.options,question.category,question.protocol',
        })
      )
        .then((response) => {
          commit(types.LOADENTITYQUESTIONEXCLUSIONS, response)
        })
        .catch((e) => {
          toast.error(e)
        })
        .finally(() => {
          commit(types.ENTITYQUESTIONEXCLUSIONSLOADING, false)
        })
    }
  },

  async loadEntityQuestionExclusionRequests({ commit, rootGetters }, params) {
    if (rootGetters['auth/isAdmin']) {
      await commit(types.ENTITYQUESTIONEXCLUSIONREQUESTSLOADING, true)
      await Pagination(
        `/organizations/${rootGetters['auth/getOrganizationId']}/entity-question-exclusion-requests`,
        Object.assign(params ?? {}, {
          include: 'question,question.options,question.category,question.protocol,entity',
        })
      )
        .then((response) => {
          commit(types.LOADENTITYQUESTIONEXCLUSIONREQUESTS, response)
        })
        .catch((e) => {
          toast.error(e)
        })
        .finally(() => {
          commit(types.ENTITYQUESTIONEXCLUSIONREQUESTSLOADING, false)
        })
    }
  },

  async loadUsers({ commit, rootGetters }, trashed) {
    if (rootGetters['auth/authenticated']) {
      await commit(types.USERSLOADING, true)
      await Pagination(
        `/organizations/${rootGetters['auth/getOrganizationId']}/users`,
        trashed ? { 'filter[trashed]': 'with' } : null
      )
        .then((response) => {
          commit(types.LOADUSERS, response)
        })
        .catch((e) => {
          toast.error(e)
        })
        .finally(() => {
          commit(types.USERSLOADING, false)
        })
    }
  },

  async loadAssessments({ commit }, divisionId) {
    await commit(types.ASSESSMENTSLOADING, true)
    await Pagination(
      `/assessment-periods`,
      Object.assign(
        {
          'filter[trashed]': 'with',
        },
        divisionId ? { 'filter[division_id]': divisionId } : null
      )
    )
      .then((response) => {
        commit(types.LOADASSESSMENTS, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.ASSESSMENTSLOADING, false)
      })
  },

  clearUsers({ commit }) {
    commit(types.LOADUSERS, [])
  },

  async loadRoles({ commit, rootGetters }) {
    if (rootGetters['auth/authenticated']) {
      await commit(types.LOADING, true)
      await Pagination(`/organizations/${rootGetters['auth/getOrganizationId']}/roles`)
        .then((response) => {
          commit(types.LOADROLES, response)
        })
        .catch((e) => {
          toast.error(e)
        })
        .finally(() => {
          commit(types.LOADING, false)
        })
    }
  },

  clearRoles({ commit }) {
    commit(types.LOADROLES, [])
  },

  async loadGroups({ commit, rootGetters }, trashed) {
    if (rootGetters['auth/authenticated']) {
      await commit(types.GROUPSLOADING, true)
      await Pagination(
        `/organizations/${rootGetters['auth/getOrganizationId']}/groups`,
        trashed ? { 'filter[trashed]': 'with' } : null
      )
        .then((response) => {
          commit(types.LOADGROUPS, response)
        })
        .catch((e) => {
          toast.error(e)
        })
        .finally(() => {
          commit(types.GROUPSLOADING, false)
        })
    }
  },

  clearGroups({ commit }) {
    commit(types.LOADGROUPS, [])
  },

  async loadTags({ commit, rootGetters }) {
    if (rootGetters['auth/authenticated']) {
      await commit(types.TAGSLOADING, true)
      await Pagination(`/organizations/${rootGetters['auth/getOrganizationId']}/tags`)
        .then((response) => {
          commit(types.LOADTAGS, response)
        })
        .catch((e) => {
          toast.error(e)
        })
        .finally(() => {
          commit(types.TAGSLOADING, false)
        })
    }
  },

  clearTags({ commit }) {
    commit(types.LOADTAGS, [])
  },

  async loadAssets({ commit, rootGetters }, trashed) {
    if (rootGetters['auth/authenticated']) {
      await commit(types.LOADING, true)
      await Pagination(
        `/organizations/${rootGetters['auth/getOrganizationId']}/assets`,
        trashed ? { 'filter[trashed]': 'with' } : null
      )
        .then((response) => {
          commit(types.LOADASSETS, response)
        })
        .catch((e) => {
          toast.error(e)
        })
        .finally(() => {
          commit(types.LOADING, false)
        })
    }
  },

  async load({ commit, dispatch, rootGetters }) {
    if (rootGetters['auth/authenticated'] && rootGetters['auth/getOrganizationId']) {
      await commit(types.LOADING, true)
      await Axios.get(`/organizations/${rootGetters['auth/getOrganizationId']}`)
        .then(async (response) => {
          await dispatch('setCompany', response.data)
        })
        .catch(async (error) => {
          if (get(error, 'response.status') === 404) {
            await dispatch('auth/getUser', null, { root: true })
          }
        })
        .finally(() => {
          commit(types.LOADING, false)
        })
    }
  },

  async loadAnswers({ commit, rootGetters }, params) {
    if (rootGetters['auth/authenticated']) {
      await commit(types.ANSWERSLOADING, true)
      await Pagination(`/organizations/${rootGetters['auth/getOrganizationId']}/answers`, params)
        .then((response) => {
          commit(types.LOADANSWERS, response)
        })
        .finally(() => {
          commit(types.ANSWERSLOADING, false)
        })
    }
  },

  async changeDivision({ commit, dispatch, rootState }, id) {
    EventBus.emit('division-changing')
    axiosRequests.cancel()
    await dispatch('auth/clearOrganizationData', null, { root: true })
    await commit(types.CHANGEDIVISION, id)
    await commit(types.SET, {
      assessment_period: get(
        rootState.company.divisions.find((d) => d.id === rootState.company.activeDivision),
        'assessment_period'
      ),
    })
    await dispatch(
      'auth/remembered',
      {
        key: 'division',
        value: id,
      },
      { root: true }
    )
    EventBus.emit('division-changed')
  },

  clearAssets({ commit }) {
    commit(types.LOADASSETS, [])
  },

  refresh({ dispatch }) {
    dispatch('load')
  },

  async setCompany({ commit, dispatch, getters }, organization) {
    await commit(types.ORG, organization)
    await dispatch('frameworks/load', null, { root: true })
    if (getters.hasFeature('divisions')) {
      await dispatch('setDivision')
    } else {
      await commit(types.CHANGEDIVISION, null)
      await dispatch('setAssessmentPeriod', organization.assessment_period)
    }
  },

  async setDivision({ commit, dispatch, rootState, rootGetters }) {
    let divisionId = null
    let assessmentPeriod = null

    if (
      rootState.company.activeDivision &&
      rootGetters['auth/myDivisions'].map((division) => division.id).includes(rootState.company.activeDivision)
    ) {
      divisionId = rootState.company.activeDivision
      assessmentPeriod = (
        rootGetters['auth/myDivisions'].find((division) => division.id === rootState.company.activeDivision) || {}
      ).assessment_period
    } else if (rootGetters['auth/myDivisions'].length && isObject(rootGetters['auth/myDivisions'][0])) {
      divisionId = rootGetters['auth/myDivisions'][0].id
      assessmentPeriod = rootGetters['auth/myDivisions'][0].assessment_period
    }

    if (
      rootGetters['auth/rememberedDivision'] &&
      rootGetters['auth/rememberedDivision'] !== divisionId &&
      rootGetters['auth/myDivisions'].find((d) => d.id === rootGetters['auth/rememberedDivision'])
    ) {
      divisionId = rootGetters['auth/rememberedDivision']
      assessmentPeriod = rootGetters['auth/myDivisions'].find(
        (d) => d.id === rootGetters['auth/rememberedDivision']
      ).assessment_period
    }

    if (divisionId !== rootState.company.activeDivision) {
      commit(types.CHANGEDIVISION, divisionId)
    }

    await dispatch('setAssessmentPeriod', assessmentPeriod)
  },

  async setAssessmentPeriod({ commit, rootState }, assessmentPeriod) {
    if (!isEqual(rootState.company.assessment_period, assessmentPeriod)) {
      await commit(types.SET, {
        assessment_period: assessmentPeriod,
      })
    }
  },

  clearCompany({ commit }) {
    commit(types.ORG, false)
  },

  async loadMetrics({ commit, rootGetters, dispatch }) {
    await commit(types.SET, {
      loadingMetrics: true,
    })
    await Axios.get(`/organizations/${rootGetters['auth/getOrganizationId']}/account-metrics`)
      .then((response) => {
        commit(types.SET, {
          metrics: response.data,
        })
      })
      .catch((error) => {
        if (get(error, 'response.status') === 404) {
          dispatch('auth/getUser', null, { root: true })
        }
      })
      .finally(() => {
        commit(types.SET, {
          loadingMetrics: false,
        })
      })
  },
}
