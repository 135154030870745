<template>
  <div
    class="input-group autocomplete-wrapper"
    :class="[size ? `input-group-${size}` : null, { fetching: fetching }, elclass]"
  >
    <input
      :id="id"
      ref="input"
      v-model="type"
      type="text"
      class="form-control rounded pr-4"
      :required="required"
      :class="`${getClassName('input')} autocomplete-input`"
      :placeholder="placeholder"
      :name="name"
      :disabled="disabled"
      autocomplete="off"
      @input="handleInput"
      @dblclick="handleDoubleClick"
      @blur="handleBlur"
      @keydown="handleKeyDown"
      @focus="handleFocus"
    />
    <btn
      v-if="dropdown"
      iconic="chevron-bottom"
      class="text-center"
      :class="{ open: !!showList && json.length, disabled: disabled }"
      :disabled="fetching && !json.length"
      @click="useAsSelect"
      @blur="handleBlur"
      @keydown.enter="useAsSelect"
      @dblclick="handleDoubleClick"
      @focus="handleFocus"
    />

    <div
      v-show="showList"
      ref="list"
      class="autocomplete autocomplete-list dropdown-menu-list dropdown-menu dropdown"
      :class="[{ 'position-relative scroll-y': inline }, getClassName('list')]"
      :style="getStyle"
    >
      <btn
        v-if="addClear && type && json.length"
        class="dropdown-item"
        iconic="circle-x mr-1"
        tabindex="-1"
        @click="clearInput"
      >
        Clear Selection
      </btn>
      <btn
        v-else-if="addClear && json.length"
        class="dropdown-item d-flex align-items-center"
        iconic="circle-x mr-1"
        tabindex="-1"
        @click="closeList"
      >
        Close
        <span class="text-secondary ml-auto">Use &#8593; &#8595; to Scroll</span>
      </btn>
      <div
        v-if="addClear && json.length"
        class="dropdown-divider"
      />
      <btn
        v-for="(data, i) in json"
        :key="i"
        class="dropdown-item selectable-item"
        :class="activeClass(i)"
        @click.prevent="selectList(data)"
        @mousemove="mousemove(i)"
        @focus="focusLink"
        @blur="handleBlur"
      >
        <b class="autocomplete-anchor-text">{{ deepValue(data, anchor) }}</b>
        <span class="autocomplete-anchor-label">{{ deepValue(data, label) }}</span>
      </btn>
      <div
        v-if="!json.length"
        class="text-center font-secondary font-xs"
      >
        No Results
      </div>
      <div
        v-if="typeof refresh === 'function'"
        class="dropdown-divider"
      />
      <btn
        v-if="typeof refresh === 'function'"
        iconic="reload mr-1"
        class="font-xs w-100 text-center"
        @click="() => refresh()"
      >
        Refresh List
      </btn>
    </div>
  </div>
</template>

<script>
import Pagination from '@/plugins/pagination'

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    elclass: {
      type: String,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
    classes: {
      type: Object,
      default: () => ({
        wrapper: false,
        input: false,
        list: false,
        item: false,
      }),
    },
    addClear: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    filterByAnchor: {
      type: Boolean,
      default: true,
    },
    anchor: {
      type: String,
      required: true,
    },
    valueKey: {
      type: String,
      default: null,
    },
    label: {
      type: [String, Boolean],
      default: null,
    },
    debounce: {
      type: Number,
      default: 1,
    },
    url: {
      type: String,
      default: null,
    },
    param: {
      type: String,
      default: 'q',
    },
    encodeParams: {
      type: Boolean,
      default: true,
    },
    customParams: {
      type: Object,
      default: null,
    },
    customHeaders: {
      type: Object,
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    process: {
      type: Function,
      default: null,
    },
    onInput: {
      type: Function,
      default: null,
    },
    onShow: {
      type: Function,
      default: null,
    },
    onBlur: {
      type: Function,
      default: null,
    },
    onHide: {
      type: Function,
      default: null,
    },
    onFocus: {
      type: Function,
      default: null,
    },
    onSelect: {
      type: Function,
      default: null,
    },
    onClear: {
      type: Function,
      default: null,
    },
    onBeforeAjax: {
      type: Function,
      default: null,
    },
    onAjaxProgress: {
      type: Function,
      default: null,
    },
    onAjaxLoaded: {
      type: Function,
      default: null,
    },
    onShouldGetData: {
      type: Function,
      default: null,
    },
    refresh: {
      type: Function,
      default: null,
    },
  },
  emits: ['update:modelValue', 'tabbed'],
  data() {
    return {
      showList: false,
      timeout: null,
      fetching: false,
      type: null,
      json: [],
      focusList: 0,
      debounceTask: undefined,
    }
  },
  computed: {
    getStyle() {
      if (this.inline && this.maxHeight) {
        return {
          'max-height': `${this.maxHeight}px`,
        }
      } else {
        return null
      }
    },
  },
  watch: {
    initValue(current) {
      if (current) {
        this.type = current
      }
    },
    showList() {
      this.focusList = this.options.findIndex((o) => o[this.anchor] === this.type)
    },
    focusList() {
      this.scrollIntoView()
    },
    loading(current) {
      this.fetching = current
    },
  },
  created() {
    this.type = this.initValue ? this.initValue : null
  },
  mounted() {
    if (this.required) {
      this.$refs.input.setAttribute('required', this.required)
    }
  },
  methods: {
    getClassName(part) {
      const { classes, className } = this
      if (classes[part]) return `${classes[part]}`
      return className ? `${className}-${part}` : ''
    },
    clearInput() {
      // this.showList = false
      this.type = null
      // this.json = []
      this.getData(false)
      this.focusList = 0
      this.$refs.input.focus()
      this.onClear ? this.onClear() : null
      this.debounceTask = setTimeout(() => {
        this.$emit('update:modelValue', null)
      }, this.debounce)
    },
    cleanUp(data) {
      return JSON.parse(JSON.stringify(data))
    },
    handleInput(e) {
      const { value } = e.target
      this.showList = true
      // Callback Event
      if (this.onInput) {
        this.onInput(value)
      }
      // If Debounce
      if (this.debounce) {
        if (this.debounceTask !== undefined) clearTimeout(this.debounceTask)
        this.debounceTask = setTimeout(() => {
          return this.getData(value)
        }, this.debounce)
      } else {
        return this.getData(value)
      }
    },
    handleKeyDown(e) {
      let key = e.keyCode
      // Disable when list isn't showing up
      if (!this.showList) {
        this.showList = true
        this.getData(this.type || false)
        this.$refs.input.focus()
        return
      }

      // Key List
      const DOWN = 40
      const RIGHT = 39
      const UP = 38
      const LEFT = 37
      const ENTER = 13
      const ESC = 27
      const TAB = 9

      // Prevent Default for Prevent Cursor Move & Form Submit
      switch (key) {
        case DOWN:
          e.preventDefault()
          this.focusList++
          break
        case UP:
          e.preventDefault()
          this.focusList--
          break
        case RIGHT:
          e.preventDefault()
          this.focusList++
          break
        case LEFT:
          e.preventDefault()
          this.focusList--
          break
        case ENTER:
          e.preventDefault()
          if (this.json[this.focusList]) {
            this.selectList(this.json[this.focusList])
          } else {
            this.$emit('update:modelValue', e.target.value)
            this.closeList()
            this.$refs.input.focus()
          }
          break
        case TAB:
          if (e.target.value && e.target.value) {
            this.$emit('tabbed', e.target.value)
            this.closeList()
            this.type = null
          }
          break
        case ESC:
          this.closeList()
          break
      }

      this.positionMouse()
    },

    scrollIntoView() {
      let el = this.$refs.list.querySelectorAll('.selectable-item')[this.focusList]
      if (el) {
        let parent = el.parentElement
        let top = el.offsetTop + el.clientHeight
        let offset = top - parent.scrollTop

        if (this.focusList === 0) {
          this.setScrollPosition(parent, 0)
        } else if (offset > parent.clientHeight || top <= parent.scrollTop) {
          this.setScrollPosition(parent, top - el.clientHeight)
        }
      }
    },

    setScrollPosition(el, n) {
      el.scrollTo({
        top: n,
        behavior: 'smooth',
      })
    },

    positionMouse() {
      const listLength = this.json.length - 1
      const outOfRangeBottom = this.focusList > listLength
      const outOfRangeTop = this.focusList < 0
      const topItemIndex = 0
      const bottomItemIndex = listLength

      let nextFocusList = this.focusList
      if (outOfRangeBottom) nextFocusList = topItemIndex
      if (outOfRangeTop) nextFocusList = bottomItemIndex
      this.focusList = nextFocusList
    },

    filterResults(data) {
      if (this.filterByAnchor && this.type) {
        return data.filter(
          (item) => item[this.anchor].search(new RegExp(this.lodash.escapeRegExp(this.type), 'i')) !== -1
        )
      } else {
        return data
      }
    },

    /* ==============================
      LIST EVENTS
    ============================= */

    handleDoubleClick() {
      this.json = []
      this.getData('')
      // Callback Event
      this.onShow ? this.onShow() : null
      this.showList = true
    },

    handleBlur(e) {
      // Callback Event
      this.onBlur ? this.onBlur(e) : null
      this.timeout = setTimeout(() => {
        // Callback Event
        this.onHide ? this.onHide() : null
        this.closeList()
      }, 250)
    },

    handleFocus(e) {
      clearTimeout(this.timeout)
      this.focusList = 0
      this.onFocus ? this.onFocus(e) : null
    },

    focusLink() {
      clearTimeout(this.timeout)
    },

    mousemove(i) {
      this.focusList = i
    },

    activeClass(i) {
      const focusClass = i === this.focusList ? 'bg-info text-white' : ''
      return `${focusClass}`
    },

    selectList(data) {
      this.$emit(
        'update:modelValue',
        this.valueKey && this.lodash.get(data, `${this.valueKey}`) ? data[this.valueKey] : data
      )
      this.type = data ? data[this.anchor] : undefined
      this.closeList()
      this.onSelect ? this.onSelect(data) : null
    },

    deepValue(obj, path) {
      const items = path ? path.split(' ') : []
      var pos = obj
      var v = []

      items.forEach((item) => {
        const arrayPath = item ? item.split('.') : []
        for (var i = 0; i < arrayPath.length; i++) {
          pos = pos[arrayPath[i]]
          if (i + 1 === arrayPath.length) {
            v.push(pos)
            pos = obj
          }
        }
      })
      return v.join(' ')
    },

    closeList() {
      if (this.$refs.list) {
        this.$refs.list.scrollTop = 0
      }
      this.showList = false
    },

    /* ==============================
      AJAX EVENTS
    ============================= */

    composeParams(val) {
      const encode = (val) => (this.encodeParams ? encodeURIComponent(val) : val)
      let params = {}

      params[this.param] = encode(val)

      if (this.customParams) {
        Object.keys(this.customParams).forEach((key) => {
          params[key] = encode(this.customParams[key])
        })
      }

      return params
    },

    composeHeader(ajax) {
      if (this.customHeaders) {
        Object.keys(this.customHeaders).forEach((key) => {
          ajax.setRequestHeader(key, this.customHeaders[key])
        })
      }
    },

    doAjax(val) {
      // if (this.url) {
      // Callback Event
      this.fetching = true
      this.onBeforeAjax ? this.onBeforeAjax(val) : null
      // Compose Params
      let params = this.composeParams(val)
      // // Init Ajax
      // let ajax = new XMLHttpRequest()
      // ajax.open('GET', `${this.url}?${params}`, true)
      // this.composeHeader(ajax)
      // // Callback Event
      // ajax.addEventListener('progress', (data) => {
      //   if (data.lengthComputable && this.onAjaxProgress) this.onAjaxProgress(data)
      // })
      // // On Done
      // ajax.addEventListener('loadend', (e) => {
      //   const { responseText } = e.target
      //   let json = JSON.parse(responseText)
      //   // Callback Event
      //   this.onAjaxLoaded ? this.onAjaxLoaded(json) : null
      //   this.json = this.process ? this.process(json) : json
      // })
      // // Send Ajax
      // ajax.send()
      Pagination(this.url, params).then((response) => {
        this.fetching = false
        this.onAjaxLoaded ? this.onAjaxLoaded(response) : null
        this.json = this.filterResults(this.process ? this.process(response) : response)
      })
      // } else {
      //   this.json = this.filterResults(this.options)
      // }
    },

    getData(value) {
      if (value !== false && value.length < this.min) {
        return
      }

      if (!this.url) {
        this.json = this.filterResults(this.options)
        return
      }

      if (this.onShouldGetData) {
        this.manualGetData(value)
        return
      }

      this.doAjax(value)
    },

    manualGetData(val) {
      const task = this.onShouldGetData(val)
      if (task && task.then) {
        return task.then((options) => {
          this.json = options
        })
      }
    },

    useAsSelect() {
      this.showList = !this.showList
      if (this.showList) {
        this.getData(false)
        this.$refs.input.focus()
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.autocomplete-wrapper {
  &.fetching {
    &:after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      right: 30px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid rgba($blue, 0.15);
      border-top-color: rgba($blue, 0.88);
      animation: spinner 0.96s linear infinite;
      z-index: 10000;
      display: flex !important;
      margin-top: -10px;
    }
  }
  .dropdown-toggle {
    &::after {
      margin-left: 0;
    }
  }
  .btn,
  .btn-outline-secondary {
    &:focus,
    &:focus,
    &:focus:active {
      box-shadow: none;
    }
  }
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: none;
  }
  .btn-outline-secondary {
    border-color: $gray-400;
    background-color: $gray-200;
    &:hover {
      background-color: $gray-400;
    }
  }
}
.input-group {
  position: relative;
  &.input-group-sm {
    input + button.btn-link {
      top: 0.5rem;
    }
  }
  &.input-group-lg {
    input + button.btn-link {
      top: 0.75rem;
      right: 0.25rem;
      font-size: 0.875rem !important;
    }
  }
}
input + button.btn-link {
  z-index: 4;
  position: absolute;
  top: 0.75rem;
  right: 0;
  width: 1.5rem;
  font-size: 0.625rem !important;
  :deep(.iconic) {
    left: -1px;
    transition: transform 0.15s ease;
    transform: scaleY(1);
  }
  &.open {
    :deep(.iconic) {
      transform: scaleY(-1);
    }
  }
  &:focus {
    color: #223b55;
  }
}
.dropdown-menu-list {
  display: list-item;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}
.dropdown-item {
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  white-space: normal;
}
</style>
