/* ============
 * Auth Module
 * ============
 */
import * as types from './mutation-types'
import router from '@/plugins/vue-router'
import EventBus from '@/plugins/event-bus'
import { Axios, axiosRequests } from '@/plugins/axios'
import lodash, { get, differenceBy, isNil, merge } from 'lodash'
import { toastInstance as toast } from '@/plugins/toast'
import { ACTIVEORGANIZATION, AUTH, LOADING, LOGIN, LOGOUT, REMEMBER, REMEMBERED, RESET, SET } from "./mutation-types"
import ApiService from "../../../services/ApiService"
import { add, isAfter } from "date-fns"
// import { localForageAuthService } from '@/plugins/LocalForageAuthService'
import { localForageAuthService } from "../../plugins/LocalForageAuthService"
import { domainBroadcastId, domainBroadcastChannel } from "../../../plugins/domain-broadcast-channel.js"

const defaultState = () => ({
  access_token: null,
  refresh_token: null,
  expires_at: null,
  remember: false,
  remembered: {},
  loading: false,
  user: null,
  pending: false,
  loggingIn: false,
  loggingOut: false,
  forcedLogout: false,
  activeOrganization: null,
  tokenIsRefreshing: false,
  tokenRefreshQueue: [],
  tokenRefreshCall: null,

})

const state = defaultState()
const excludeFields = ['remember', 'remembered', 'loading', 'loggingOut', 'loggingIn', 'forcedLogout']


const getters = {
  tokenIsRefreshing(state) {
    return state.tokenIsRefreshing
  },
  tokenRefreshCall(state) {
    return state.tokenRefreshCall
  },
  authenticated(state) {
    return !!state.access_token
  },
  token(state, getters) {
    return getters.authenticated ? state.access_token : null
  },
  tokenExpiration(state, getters) {
    return getters.authenticated ? state.expires_at : null
  },
  refreshToken(state, getters) {
    return getters.authenticated ? state.refresh_token : null
  },
  fullName(state) {
    return get(state, 'user.full_name', null)
  },
  getRoles(state) {
    return get(state, 'user.roles', []).map((r) => {
      return r.type
    })
  },
  getUser(state) {
    return state.user ? state.user : null
  },
  getUserId(state) {
    return state.user ? state.user.id : null
  },
  getUserOrganizations(state) {
    return get(state, 'user.organizations', [])
  },
  getUserOrganizationCount(state, getters) {
    return getters.getUserOrganizations.length
  },
  getOrganization(state) {
    return get(state, 'activeOrganization', {})
  },
  getOrganizationId(state) {
    return get(state, 'activeOrganization.id', null)
  },
  isPrimaryAdmin(state, getters, rootState) {
    return state.user && rootState.company.organization
      ? rootState.company.organization.primary_administrator_id === state.user.id
      : false
  },
  isDivisionPrimaryAdmin(state, getters, rootState, rootGetters) {
    return rootGetters['company/activeDivisions']
      .filter((division) => division.admin_id === getters.getUserId)
      .map((division) => division.id)
      .includes(get(rootState.company, 'activeDivision', null))
  },
  isSysAdmin(state, getters) {
    return getters.getRoles.includes('SysAdmin')
  },
  isAdmin(state, getters) {
    return getters.getRoles.includes('Admin')
  },
  isManager(state, getters) {
    return getters.getRoles.includes('Manager')
  },
  isEmployee(state, getters) {
    if (getters.getRoles.includes('Employee') && !getters.isAdmin && !getters.isManager) {
      return true
    } else {
      return false
    }
  },
  hasRole(state, getters) {
    return (role) => getters.getRoles.includes(role)
  },
  myDivisionIds(state, getters, rootState, rootGetters) {
    return rootGetters['company/hasFeature']('divisions')
      ? getters.isPrimaryAdmin
        ? get(state, 'getters.getOrganization.divisions', []).map((d) => d.id)
        : get(state, 'user.divisions', []).map((d) => d.id)
      : []
  },
  myDivisions(state, getters, rootState, rootGetters) {
    return rootGetters['company/hasFeature']('divisions')
      ? get(rootState, 'company.divisions', []).filter((division) => {
        return (
          !division.deleted_at &&
          (getters.isPrimaryAdmin || getters.getUserId === division.admin_id
            ? division
            : getters.myDivisionIds.includes(division.id))
        )
      })
      : []
  },
  rememberedEmail(state) {
    return (state.remembered || {}).email || null
  },
  rememberedDivision(state) {
    return (state.remembered || {}).division || null
  },
  todaysActionPlanTasks(state, getters) {
    return get(getters.getOrganization, 'incomplete_tasks', [])
  },

  todaysExclusionRequests(state, getters) {
    return get(getters.getOrganization, 'incomplete_exclusion_requests', [])
  },

  todaysAssignments(state, getters) {
    return [...get(getters.getOrganization, 'assignments', []), ...get(getters.getOrganization, 'collaborations', [])]
  },
  unReadWelcomeMessages(state, getters) {
    return get(getters.getOrganization, 'unread_welcome_messages', [])
  },
  activeOrganization(state, getters) {
    return state.activeOrganization
  },
  activeOrganizationId(state, getters) {
    return getters.activeOrganization.id
  },
}

const actions = {

  async set({ commit }, data) {
    await commit(types.SET, data)
  },

  async reset({ commit, dispatch, state, rootState }) {
    await commit(types.LOGOUT)
    if (rootState.route.name !== 'login.index') {
      return await router
        .push({
          name: 'login.index',
        })
        .then(async () => {
          if (!state.loggingOut) {
            await dispatch('app/loaded', null, { root: true })
          }
          return {
            type: 'reset',
            result: 'Served Login Page',
          }
        })
        .catch((error) => ({
          type: 'reset',
          error: error,
        }))
    } else {
      await dispatch('app/loaded', null, { root: true })
      return {
        type: 'reset',
        result: 'Served Login Page',
      }
    }
  },

  async setActiveOrganization({ commit, state, dispatch, rootState }, organization) {
    if (state.loggingIn) {
      await commit(types.ACTIVEORGANIZATION, organization)
    } else {
      await dispatch('app/loading', null, { root: true })
      await EventBus.emit('company-changing')
      await commit(types.ACTIVEORGANIZATION, organization)
      await ApiService.patchAsync("/users/update-active-organization", { 'organization_id': organization.id })

      return await Promise.all([dispatch('getUser'), dispatch('loadUserCompany')])
        .then(async () => {
          EventBus.emit('company-changed')
          if (rootState.app.loginRedirect) {
            await dispatch('redirectLogin')
          }
        })
        .catch(async () => {
          EventBus.emit('company-changed')
          await dispatch('logout', 'You have been logged out.')
        })
    }
  },

  async organizationSelectionNeeded({ dispatch, rootState }) {
    const excludedRoutes = ['profile.index']

    if (!excludedRoutes.includes(rootState.route.name)) {
      await router
        .push({
          name: 'profile.company',
        })
        .then(() => {
          dispatch('app/loaded', null, { root: true })
        })
    } else {
      dispatch('app/loaded', null, { root: true })
    }
  },

  async redirectLogin({ commit, dispatch, rootState }) {
    return await router
      .push(rootState.app.loginRedirect)
      .then(() => {
        commit(types.LOADING, false)
        dispatch('app/loaded', null, { root: true })
      })
      .catch(() => {
        dispatch('redirectToHomePage').then(() => {
          commit(types.LOADING, false)
          dispatch('app/loaded', null, { root: true })
          EventBus.emit('show-todays-plan', true)
        })
      })
      .finally(() => {
        dispatch(
          'app/set',
          {
            loginRedirect: null,
          },
          { root: true }
        )
        dispatch('set', {
          loggingIn: false,
        })
      })
  },

  async login({ commit, state, getters, dispatch, rootState }, data) {
    await dispatch('set', {
      loggingIn: true,
    })
    await dispatch('app/loading', null, { root: true })
    await dispatch('app/message', null, { root: true })
    await dispatch('app/error', null, { root: true })

    if (!data.expires_in !== true) {
      let tokenExpiration = add(new Date(), {
        seconds: data.expires_in,
      })
      data['expires_at'] = tokenExpiration
    }

    await dispatch('setAuthenticationData', data)
    await dispatch('getUser')
      .then(async () => {
        if (state.user) {
          if (!getters.getOrganizationId) {
            await dispatch('organizationSelectionNeeded').then(() => {
              dispatch('set', {
                loggingIn: false,
              })
            })
          } else {
            await dispatch('getUser').then(
              async () =>
                await dispatch('loadUserCompany').then(async () => {
                  if (rootState.app.loginRedirect) {
                    await dispatch('redirectLogin')
                  }
                })
            )
          }
        } else {
          await dispatch('logout', `Login Error: Could not authenticate user. Please try again later.`).then(() => {
            dispatch('set', {
              loggingIn: false,
            })
          })
        }
      })
      .catch((error) => {
        dispatch('logout', `Login ${error.toString()}. Please try again later.`)
      })
  },

  async setAuthenticationData({ commit, state, getters, dispatch }, data) {
    commit('SET_ACCESS_TOKEN', data.access_token);
    await localForageAuthService.setItem('auth.access_token', data.access_token);

    if (!isNil(get(data, 'expires_at', null))) {
      commit('SET_EXPIRES_AT', get(data, 'expires_at', null));
      await localForageAuthService.setItem('auth.expires_at', get(data, 'expires_at', null));
    }

    if (!isNil(get(data, 'remember', null))) {
      commit('SET_REMEMBER', get(data, 'remember', null));
      await localForageAuthService.setItem('auth.persist', get(data, 'remember', null));
    }

    return data.access_token;

  },

  async handleAuthPersistence({ commit, state, getters, dispatch }) {
    const getAccessToken = await localForageAuthService.getItem('auth.access_token');
    const getExpiresAt = await localForageAuthService.getItem('auth.expires_at');
    const getPersist = await localForageAuthService.getItem('auth.persist');

    var accessToken = getAccessToken;
    var expiresAt = getExpiresAt;
    var persist = getPersist;

    if (!isNil(accessToken)) {

      dispatch('setAuthenticationData', {
        access_token: accessToken,
        expires_at: expiresAt,
        persist: persist,
      }).then(() => {

        dispatch("tokenNeedsRefresh").then((needsRefresh) => {
          if (needsRefresh == true) {
            dispatch("attemptTokenRefresh").then((result) => {
              return result;
            })
          } else {
            return true;
          }
        })
      })
    }
    return false;
  },

  async attemptTokenLogin({ commit, state, getters, dispatch }, payload) {
    return await Axios.post('/login-token', payload)
      .then((response) => {
        return response.data;
      }
    )
  },

  /**
   * Determine whether the current access token is set to expire
   * @returns {Promise<boolean>}
   */
  async tokenNeedsRefresh({getters}) {
    // Get token expiration date
    let tokenExpiration = getters['tokenExpiration']
    // Check if current token will expire within the next 30 minutes
    let expirationTest = add(new Date(), {
      minutes: 30
    });

    // Return true if the expirationTest date is after the token's set expiration
    return isAfter(expirationTest, tokenExpiration)
  },

  async attemptTokenRefresh({ commit, state, getters, dispatch }) {
    const refreshRequest = await Axios({
      method:'post',
      url:'/login/refresh',
      baseURL: import.meta.env.VITE_APP_API_AUTH_URL,
      data: {}
    })

    const newToken = get(refreshRequest.data, "access_token");

      var expiresAt = null;
      if(refreshRequest.data.hasOwnProperty("expires_in")) {
        let expiresIn = get(refreshRequest.data, "expires_in");
        var expiresAt = add(new Date(), {
          seconds: expiresIn
        });
      }

      domainBroadcastChannel.postMessage({
        broadcasterId: domainBroadcastId,
        title: "ACCESS_TOKEN_UPDATED",
        message: "Test toast issued from: " + domainBroadcastId
      });

      await dispatch('setAuthenticationData', {
        access_token: newToken,
        expires_at: expiresAt,
        persist: true,
      })
      return newToken;
  },

  testBroadcast({ commit, state, getters, dispatch }) {

    domainBroadcastChannel.postMessage({
      broadcasterId: domainBroadcastId,
      title: "ACCESS_TOKEN_UPDATED",
      message: "Test toast issued from: " + domainBroadcastId
    });

  },

  async updateAccessTokenFromLocalStorage({ commit, state, getters, dispatch }) {
    const getAccessToken = await localForageAuthService.getItem('auth.access_token');
    const getExpiresAt = await localForageAuthService.getItem('auth.expires_at');
    const getPersist = await localForageAuthService.getItem('auth.persist');
    var accessToken = getAccessToken;
    var expiresAt = getExpiresAt;
    var persist = getPersist;
    if (!isNil(accessToken)) { commit('SET_ACCESS_TOKEN', accessToken); }
    if (!isNil(expiresAt)) { commit('SET_EXPIRES_AT', expiresAt); }
    if (!isNil(persist)) { commit('SET_REMEMBER', persist); }
    return true;
  },

  async redirectToHomePage({ rootGetters }) {
    let route = get(router, 'currentRoute.name')
    if (rootGetters['auth/isAdmin'] && route !== 'dashboard.index') {
      await router.push({
        name: 'dashboard.index',
      })
    } else if (route !== 'questions.index') {
      await router.push({
        name: 'questions.index',
      })
    }
  },

  async clearOrganizationData({ dispatch }) {
    await dispatch('entities/clear', null, {
      root: true,
    })
    await dispatch('questions/clear', null, {
      root: true,
    })
    await dispatch('answers/clear', null, {
      root: true,
    })
    await dispatch('plans/clear', null, {
      root: true,
    })
    await dispatch('risks/clearThreatRisks', null, {
      root: true,
    })
    await dispatch('risks/clearThreatEntityRisks', null, {
      root: true,
    })
    await dispatch('risks/clearCategoryRisks', null, {
      root: true,
    })
    await dispatch('risks/clearCategoryEntityRisks', null, {
      root: true,
    })
    await dispatch('risks/clearCategoryThreatRisks', null, {
      root: true,
    })
    await dispatch('risks/clearCategoryThreatEntityRisks', null, {
      root: true,
    })
  },

  async loadUserCompany({ commit, state, dispatch, rootState }) {
    await dispatch('app/loading', null, { root: true })
    await commit(types.LOADING, true)
    await dispatch('clearOrganizationData')
    await dispatch('company/load', null, { root: true })
      .then(() => {
        if (!rootState.app.loginRedirect) {
          dispatch('redirectToHomePage')
            .then(() => {
              dispatch(
                'app/set',
                {
                  loginRedirect: null,
                },
                { root: true }
              )
              commit(types.LOADING, false)
              dispatch('app/loaded', null, { root: true })
              EventBus.emit('show-todays-plan', true)
            })
            .finally(() => {
              if (state.loggingIn) {
                dispatch('set', {
                  loggingIn: false,
                })
              }
            })
        }
      })
      .catch((error) => {
        dispatch('logout', `Login ${error.toString()}. Please try again later.`)
      })
  },

  async getUser({ commit, state, getters, dispatch }) {
    await commit(types.LOADING, true)
    await Axios.get('/user').then(
      async (response) =>
        await dispatch('setUser', response.data).then(async () => {
          if (getters.getOrganizationId) {
            let organization = getters.getUserOrganizations.find(
              (organization) => organization.id === getters.getOrganizationId
            )
            if (!state.activeOrganization && organization.id !== getters.getOrganizationId) {
              return await dispatch('setActiveOrganization', organization).then(async () => {
                return await commit(types.LOADING, false)
              })
            } else {
              return await commit(types.LOADING, false)
            }
          } else if (getters.getUserOrganizationCount === 1) {
            return dispatch('setActiveOrganization', state.user.organizations[0]).then(async () => {
              return await commit(types.LOADING, false)
            })
          } else if (!state.loggingIn) {
            await dispatch('app/loading', null, { root: true }).then(async () => {
              await dispatch('clearOrganizationData')
              return await dispatch('organizationSelectionNeeded')
            })
          }
        })
    )
  },

  async setUser({ commit, getters }, user) {
    if (getters.getUserOrganizations.length) {
      let added = differenceBy(user.organizations, getters.getUserOrganizations, 'id')
      let removed = differenceBy(getters.getUserOrganizations, user.organizations, 'id')

      await added.forEach((organization) => {
        toast.success(`You have been added to the company: ${organization.name}`)
      })
      await removed.forEach((organization) => {
        toast.error(`You have been removed from the company: ${organization.name}`)
        if (getters.getOrganizationId === organization.id) {
          commit(types.ACTIVEORGANIZATION, null)
        }
      })
    }
    await commit(types.LOGIN, user)
  },

  async remembered({ commit }, payload) {
    await commit(types.REMEMBERED, payload)
  },

  async showLogoutMessage({ dispatch }, [responses, message]) {
    if (!!message && message !== 'logged') {
      return await dispatch('app/error', message, { root: true })
    }
    const errors = await responses
      .filter((response) => response.status === 'error' && response.type !== 'request')
      .map((response) => response.message)

    const logoutRequest = await responses.find((response) => response.type === 'request')

    const messageOrError = `app/${
      !errors.length && !!logoutRequest && logoutRequest.code === 200 ? 'message' : 'error'
    }`

    const messageToDisplay =
      !!logoutRequest && logoutRequest.code === 200 ? logoutRequest.message : 'We have logged you out'

    const appendErrorNote = errors.length
      ? `<div class="pt-2 font-xs"><strong>Important Note</strong>: Something may have prevented us from clearing the data stored in your browser. Please clear your browser cache for the domain dashbord.wavefire.com`
      : ``

    return await dispatch(messageOrError, `${messageToDisplay}${appendErrorNote}`, { root: true })
  },



  async logout({ dispatch, rootGetters }, data) {
    const isForced = !!data
    const message = typeof data === 'string' ? data : null

    await dispatch('app/loading', null, { root: true })
    await axiosRequests.cancel()

    localForageAuthService.clear();

    if (isForced) {
      await dispatch('set', {
        forcedLogout: true,
      })
    }
    await dispatch('set', {
      loggingOut: true,
    })
    if (rootGetters['auth/authenticated']) {
      return await Promise.allSettled([dispatch('reset')]).then(async (results) => {
        await dispatch('set', {
          loggingOut: false,
        })

        await dispatch('app/loaded', null, { root: true })

        const promiseResults = await results.reduce((all, result) => {
          all.push({
            status: result.status === 'rejected' || 'error' in result.value ? 'error' : 'success',
            type: get(result, 'value.type'),
            code: get(result, 'value.response.status') || get(result, 'value.error.response.status'),
            message:
              get(result, 'value.response.data.message') ||
              get(result, 'value.error.response.data.error') ||
              get(result, 'value.error.message'),
          })
          return all
        }, [])

        return await dispatch('showLogoutMessage', [promiseResults, message])
      })
    } else {
      await dispatch('reset').finally(() => {
        dispatch('set', {
          loggingOut: false,
        })
        dispatch('app/loaded', null, { root: true })
        if (message && message !== 'logged') {
          dispatch('app/error', message, { root: true })
        } else {
          dispatch('app/message', 'You have been logged out.', { root: true })
        }
      })
    }
  },

}

const mutations = {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  [LOADING](state, bool) {
    state.loading = bool
  },
  [AUTH](state, data) {
    // Convert expires_in from auth payload into token expiration date
    try {
      if(data.hasOwnProperty("expires_in")) {
        let expiresIn = get(data, "expires_in");
        let expiresAt = add(new Date(), {
          seconds: expiresIn
        });
        data["expires_at"] = expiresAt;
      }
    } catch (err) {
      console.log("Error parsing token expiration")
      console.error(err)
    }
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },

  [LOGIN](state, user) {
    if (user && `email` in user) {
      state.user = user
      let activeOrganizationId = get(state.activeOrganization, 'id')
      if (activeOrganizationId) {
        let organization = (get(user, 'organizations', []) || []).find(
          (organization) => organization.id === activeOrganizationId
        )
        if (organization) {
          state.activeOrganization = organization
        }
      }
      if (typeof state.remembered === 'string' || (state.remembered.email && state.remembered.email !== user.email)) {
        state.remembered = {}
      }
      if (state.remember) {
        state.remembered.email = user.email
      }
    }
  },
  [REMEMBER](state, value) {
    state.remember = value
  },
  [REMEMBERED](state, { key, value }) {
    state.remembered[key] = value
  },
  [ACTIVEORGANIZATION](state, org) {
    state.activeOrganization = org
  },
  [LOGOUT](state) {
    state.access_token = null
    state.refresh_token = null
    state.user = null
    localForageAuthService.clear()
  },
  [RESET](state) {
    Object.assign(state, defaultState(), {
      remember: state.remember,
      remembered: state.remembered,
      loggingOut: state.loggingOut,
    })
  },
  SET_ACCESS_TOKEN(state, value) {
    state.access_token = value;
  },
  SET_EXPIRES_AT(state, value) {
    state.expires_at = value;
  },
  SET_REMEMBER(state, value) {
    state.remember = value;
  },
  SET_TOKEN_IS_REFRESHING(state, value) {
    state.tokenIsRefreshing = value;
  },

  PUSH_TO_REFRESH_QUEUE(state, cb) {
    state.tokenRefreshQueue.push(cb);
  },
  CLEAR_REFRESH_QUEUE(state) {
    state.tokenRefreshQueue = [];
  },

}




export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
