<template>
  <modal
    body-class="p-0 m-0 font-sm"
    :class="{ saving: submitting, loading: loading && !compose }"
    size="modal-lg"
    @close-modal="$emit('close')"
  >
    <template #header>
      <h5>
        My Answer Notes
        <span class="font-weight-light">| Answer to {{ answer.question.tempid }} for {{ answer.entity.name }}</span>
      </h5>
    </template>
    <template #body>
      <FormEditor
        v-if="compose"
        ref="editor"
        v-model="form.note"
        class="fill-column mb-0"
        name="note"
        :label="false"
        toolbar="full"
        :error="form.errors.get('note')"
      />
      <Grid
        v-else
        table-class="table-sm table-borderless table-striped table-condensed table-sm-text mb-0"
        :data="gridAssets"
        :columns="gridColumns"
        :loading="loading && !notes.length"
        no-results-text="No notes added"
      />
    </template>
    <template #footer>
      <FormSubmit
        size="sm"
        :text="compose ? `Save Note` : `Add Note`"
        :with-cancel="compose ? true : false"
        :on-cancel="() => (compose = false)"
        :submit-function="onSubmit"
      />
    </template>
  </modal>
</template>
<script>
export default {
  props: {
    answer: {
      type: Object,
      default: null,
    },
    defaultMessage: {
      type: String,
      default: 'Have a question? Ask your team!',
    },
  },
  emits: ['close', 'new-notes'],
  data() {
    return {
      loading: false,
      submitting: false,
      notes: [],
      compose: false,
      form: this.$form({
        note: null,
      }),
      gridColumns: [
        {
          key: 'note',
          width: 575,
          sortable: true,
        },
        {
          key: 'date',
          label: 'Created',
          width: 150,
          sortable: true,
          sortValue: 'sortValue',
        },
        {
          label: '',
          key: 'actions',
          width: 75,
        },
      ],
    }
  },
  computed: {
    gridAssets() {
      return this.notes.map((note) => {
        return {
          note: {
            class: 'text-nowrap text-truncate w-100',
            content: `${this.$filters.stripHTML(note.note)}`,
          },
          date: {
            sortValue: note.created_at,
            content: `${this.$filters.toDateTime(note.created_at)}`,
          },
          actions: {
            class: 'text-nowrap text-center',
            content: [
              {
                methods: {
                  clicked: () => {
                    this.compose = note
                  },
                },
                template: `<btn iconic="pencil" @click="clicked" />`,
              },
              {
                data() {
                  return {
                    loading: false,
                  }
                },
                methods: {
                  clicked: () => this.deleteNote(note),
                },
                template: `<btn iconic="trash" :class="{'btn-spinner-overlay loading-xxs': loading}" class="ml-2" @click="loading = true; clicked()" />`,
              },
            ],
          },
        }
      })
    },
  },
  watch: {
    compose() {
      this.form.note = this.lodash.has(this.compose, 'id') ? this.compose.note : ''
    },
  },
  beforeMount() {
    this.loadNotes()
  },
  methods: {
    async loadNotes() {
      this.loading = true
      this.submitting = false
      this.compose = false
      await this.$paginate(`/assessment-periods/${this.answer.assessment_period_id}/answers/${this.answer.id}/notes`)
        .then((response) => {
          this.notes = response
        })
        .catch((error) => {
          this.$toast.error(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSubmit() {
      if (this.compose) {
        this.submitNote()
      } else {
        this.compose = true
      }
    },
    submitNote() {
      this.submitting = true
      this.form[this.compose === true ? 'post' : 'put'](
        `/assessment-periods/${this.answer.assessment_period_id}/answers/${this.answer.id}/notes${
          this.lodash.has(this.compose, 'id') ? `/${this.compose.id}` : ``
        }`
      ).then(() => {
        this.loadNotes().then(() => {
          this.$emit('new-notes', this.notes.length)
        })
      })
    },
    deleteNote(note) {
      this.$http
        .delete(`/assessment-periods/${this.answer.assessment_period_id}/answers/${this.answer.id}/notes/${note.id}`)
        .then(() => {
          this.loadNotes().then(() => {
            this.$toast.success('Note Deleted')
            this.$emit('new-notes', this.notes.length)
          })
        })
        .catch((error) => {
          this.$toast.error(error)
        })
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
:deep(table) {
  table-layout: fixed;
}
:deep(.bootstrap-editor .ql-toolbar) {
  border: 0 !important;
  border-radius: 0 !important;
}
:deep(.ql-editor) {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-radius: 0 !important;
}
</style>
