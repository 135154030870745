export default {
  count(state) {
    return state.frameworks.length
  },
  frameworkQuestions: (state) => (frameworkId) => {
    return state.frameworkQuestions[frameworkId] || []
  },
  get: (state) => (frameworkId) => {
    return state.frameworks.find((framework) => framework.id === frameworkId)
  },
}
