import { get } from 'lodash'
import store from '@/store'

/**
 * Getters will return an object or collection of objects the match the methods arguments
 */

const getters = {
  entitiesRequireAnswer(questionId) {
    return this.getActiveEntities().filter((e) => this.requiresAnswer(e.id, questionId))
  },

  entitiesRequireAnswers(questionIds) {
    return this.getActiveEntities().filter((e) =>
      this.getEntityQuestionMeta(e.id).requireAnswer.some((q) => questionIds.includes(q))
    )
  },

  entityAnswers(entityId) {
    return this.getEntity(entityId).answers
  },

  answer(entityId, questionId) {
    return this.getEntity(entityId).answers.find((c) => c.question_id === questionId)
  },

  answeredBy(entityId, questionId) {
    return get(this.answer(entityId, questionId), 'answered_by.full_name', 'n/a')
  },

  answeredOn(entityId, questionId) {
    return get(this.answer(entityId, questionId), 'updated_at')
  },

  entitiesAnswered(questionId) {
    return this.getActiveEntities().filter((e) => this.answered(e.id, questionId))
  },

  entitiesCollaborationsAnswered(questionId) {
    return this.getActiveEntities().filter((e) => this.collaborationsAnswered(e.id, questionId))
  },

  entitiesNeedAnswered(questionId) {
    return this.getActiveEntities().filter((e) => this.needsAnswer(e.id, questionId))
  },

  entitiesCollaborationNeedAnswered(questionId) {
    return this.getActiveEntities().filter((e) => this.collaborationsNeedsAnswer(e.id, questionId))
  },

  entitiesNotAnswered(questionId) {
    return this.getActiveEntities().filter((e) => {
      return (
        this.needsAnswer(e.id, questionId) &&
        (store.getters['auth/isEmployee'] || store.getters['auth/isManager']
          ? this.isQuestionsAssignedToMe(e.id, questionId)
          : true)
      )
    })
  },

  answersByCategoryCount(categoryId, questions) {
    return (questions || store.state.questions.questions)
      .filter((q) => !q.parent_question_id && q.category_id === categoryId)
      .reduce((sum, q) => sum + this.howManyEntitiesAnswered(q.id), 0)
  },

  collaborationAnswersByCategoryCount(categoryId, questions) {
    return (questions || store.state.questions.questions)
      .filter((q) => !q.parent_question_id && q.category_id === categoryId)
      .reduce((sum, q) => sum + this.howManyEntitiesCollaborationsAnswered(q.id), 0)
  },

  collaborationAnswersRequiredByCategoryCount(categoryId, questions) {
    return (questions || store.state.questions.questions)
      .filter((q) => !q.parent_question_id && q.category_id === categoryId)
      .reduce((sum, q) => sum + this.howManyEntitiesCollaborationsAnswered(q.id), 0)
  },

  entityAnsweredQuestions(entityId) {
    return this.getEntityQuestionMeta(entityId).answeredQuestions
  },

  entityAnsweredTopics(entityId) {
    return this.getEntityQuestionMeta(entityId).answeredTopics
  },

  entityAnsweredCollaborationQuestions(entityId) {
    return this.getEntityQuestionMeta(entityId).collaborationsAnswered
  },

  entityRequireCollaboration(entityId) {
    return this.getEntityQuestionMeta(entityId).assignedCollaborationQuestions
  },

  entityRequireAnswer(entityId) {
    return this.getEntityQuestionMeta(entityId).requireAnswer
  },

  entityTopicRequireAnswer(entityId) {
    return this.getEntityQuestionMeta(entityId).topics
  },

  entityRequiresThreat(entityId, threatId) {
    return !!this.entityRequireAnswer(entityId).filter((r) =>
      this.questionsByThreats(threatId)
        .map((q) => q.id)
        .includes(r)
    ).length
  },

  entityNeedsAnswer(entityId) {
    return this.getEntityQuestionMeta(entityId).needsAnswer
  },

  entityNeedsAnswerQuestions(entityId) {
    return store.state.questions.questions.filter((question) => this.entityNeedsAnswer(entityId).includes(question.id))
  },
}

/**
 * Counts will return the number of matching objects
 */

const counts = {
  /**
   * Checks how many entities require and answer.
   * @param {Number} questionId
   * @return {Number} count of required entities
   */
  howManyRequireAnswer(questionId) {
    return this.getActiveEntities().reduce((total, e) => total + (this.requiresAnswer(e.id, questionId) ? 1 : 0), 0)
  },

  howManyRequireCollaboration(questionId) {
    return (
      get(this.getQuestion(questionId), 'assigned_collaborations_count', 0) +
      get(this.getQuestion(questionId), 'collaboration_answers_count', 0)
    )
    // return this.getActiveEntities().reduce((total, e) => total + (this.requiresCollaboration(e.id, questionId) ? 1 : 0), 0)
  },

  /**
   * Checks how many entities require an answer by category.
   * @param {Number} categoryId
   * @return {Number} count of required entities
   */
  howManyRequireAnswerByCategory(categoryId, questions) {
    return (questions || store.state.questions.questions)
      .filter((q) => q.category_id === categoryId && q.parent_question_id === null)
      .reduce((total, q) => total + this.howManyRequireAnswer(q.id), 0)
  },

  howManyAnswerByCategory(categoryId, questions) {
    return this.answersByCategoryCount(categoryId, questions)
  },

  howManyRequireCollaborationByCategory(categoryId, questions) {
    return (questions || store.state.questions.questions)
      .filter((q) => q.category_id === categoryId && q.parent_question_id === null)
      .reduce((total, q) => total + this.howManyRequireCollaboration(q.id), 0)
  },

  howManyCollaborationAnswersByCategory(categoryId, questions) {
    return this.collaborationAnswersByCategoryCount(categoryId, questions)
  },

  howManyEntitiesAnswered(questionId) {
    return this.entitiesAnswered(questionId).length
  },

  howManyEntitiesCollaborationsAnswered(questionId) {
    return get(this.getQuestion(questionId), 'collaboration_answers_count', 0)
    // return this.entitiesCollaborationsAnswered(questionId).length
  },

  howManyEntitiesNeedAnswered(questionId) {
    return this.entitiesNeedAnswered(questionId).length
  },

  howManyEntitiesCollaborationNeedAnswered(questionId) {
    return this.entitiesCollaborationNeedAnswered(questionId).length
  },

  getAnswerCount() {
    return this.getActiveEntities().reduce((total, entity) => total + this.entityAnsweredQuestions(entity.id).length, 0)
  },

  getTopicAnswerCount() {
    return this.getActiveEntities().reduce((total, entity) => total + this.entityAnsweredTopics(entity.id).length, 0)
  },

  getRequireAnswerCount() {
    return this.getActiveEntities().reduce((total, entity) => total + this.entityRequireAnswer(entity.id).length, 0)
  },
  getTopicRequireAnswerCount() {
    return this.getActiveEntities().reduce(
      (total, entity) => total + this.entityTopicRequireAnswer(entity.id).length,
      0
    )
  },
}

/**
 * Tests checks a condition or state and always return true/false
 */

const tests = {
  /**
   * Checks if a single question is required on behalf of an entity.
   * @param {Number} entityId
   * @param {Number} questionId
   * @return {Boolean} Returns true if an entity is required to answer, false if it is not required to answer.
   */
  requiresAnswer(entityId, questionId) {
    return (
      this.getEntityQuestionMeta(entityId).requireAnswer.includes(questionId) &&
      (store.getters['auth/isEmployee'] ? this.isQuestionsAssignedToMe(entityId, questionId) : true) &&
      (store.getters['auth/isManager']
        ? this.isQuestionsAssignedToMe(entityId, questionId) ||
          (this.isEntityManager(entityId) && this.answered(entityId, questionId))
        : true)
    )
  },
  requiresCollaboration(entityId, questionId) {
    return (
      this.entityRequireCollaboration(entityId).includes(questionId) &&
      (store.getters['auth/isEmployee'] ? this.isQuestionsAssignedToMe(entityId, questionId) : true) &&
      (store.getters['auth/isManager']
        ? this.isQuestionsAssignedToMe(entityId, questionId) ||
          (this.isEntityManager(entityId) && this.collaborationsAnswered(entityId, questionId))
        : true)
    )
  },

  needsAnswer(entityId, questionId) {
    return this.getEntityQuestionMeta(entityId).needsAnswer.includes(questionId)
  },
  collaborationsNeedsAnswer(entityId, questionId) {
    return this.getEntityQuestionMeta(entityId).collaborationsNeedsAnswer.includes(questionId)
  },

  answered(entityId, questionId) {
    return this.completed(entityId, questionId)
  },
  collaborationsAnswered(entityId, questionId) {
    return this.completedCollaborations(entityId, questionId)
  },
  topicNeedsAnswerByCategory(categoryId) {
    return (
      this.howManyRequireAnswerByCategory(categoryId) !==
      this.topicsByCategories(categoryId).reduce((total, q) => total + this.howManyEntitiesAnswered(q.id), 0)
    )
  },
  canAnswerForEntity(entityId, questionId) {
    if (this.isEntityAssessmentLocked(entityId)) {
      return false
    }
    if (store.getters['auth/isAdmin']) {
      return true
    } else {
      return this.isQuestionsAssignedToMe(entityId, questionId)
    }
  },
  canAnswer(questionIds) {
    if (Number.isInteger(questionIds)) {
      questionIds = [questionIds]
    }

    if (!Array.isArray(questionIds) || !questionIds.length) {
      return false
    } else if (store.getters['auth/isAdmin']) {
      return true
    } else {
      return !!store.state.app.notifications.filter((n) => {
        let question = store.state.questions.questions.find((q) => q.id === n.task_type_id)
        if (question) {
          return (
            ['question', 'collaboration'].includes(n.task_type) &&
            questionIds.includes(n.task_type_id) &&
            !n.completed &&
            !!(question.completion_meta.incomplete + get(question, 'completion_meta.incompleteCollaboration', 0))
          )
        } else {
          return false
        }
      }).length
    }
  },
  anyAnswerableByCategory(categoryId, questions) {
    let questionIds = (questions || store.state.questions.questions)
      .filter((q) => q.category_id === categoryId)
      .map((q) => q.id)

    return this.canAnswer(questionIds)
  },
}

const AnswersMethods = Object.assign(getters, counts, tests)

export default AnswersMethods

export const AnswerMethods = {
  methods: AnswersMethods,
}
