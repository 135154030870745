import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import Axios from '@/plugins/axios'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  set({ commit }, data) {
    commit(types.SET, data)
  },

  async load({ commit }, trashed) {
    await commit(types.LOADING, true)
    await Pagination(
      `/entities`,
      Object.assign(
        {
          'page[size]': 25,
        },
        trashed ? { 'filter[trashed]': 'with' } : null
      )
    )
      .then((response) => {
        commit(types.LOAD, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  async loadWithAnswers({ commit }, trashed) {
    await commit(types.LOADING, true)
    await Pagination(
      `/entities`,
      Object.assign(
        {
          'page[size]': 25,
          'include': 'answers',
        },
        trashed ? { 'filter[trashed]': 'with' } : null
      )
    )
      .then((response) => {
        commit(types.LOAD, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  async setLock(context, [entityId, assessmentPeriodId, locked]) {
    await Axios.put('/entities/' + entityId + '/assessment/' + assessmentPeriodId + '/lock', {
      locked: locked,
    })
  },

  async loadAssignments({ commit }, entityId) {
    await commit(types.LOADINGASSIGNMENTS, true)
    await Axios.get(`/entities/${entityId}/assigned`)
      .then((response) => {
        commit(types.ASSIGNMENTS, {
          entityId: entityId,
          assignments: response.data,
        })
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADINGASSIGNMENTS, false)
      })
  },

  async loadSections({ commit }, entityId) {
    await commit(types.LOADINGSECTIONS, true)
    await Axios.get(`/entities/${entityId}/sections`)
      .then((response) => {
        commit(types.SECTIONS, { entityId: entityId, sections: response.data })
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADINGSECTIONS, false)
      })
  },

  async clear({ commit }) {
    await commit(types.LOAD, [])
  },

  refresh({ dispatch }) {
    dispatch('load')
  },
}
