import { watch } from 'vue'
import { toast } from '@/plugins/toast'
import NewVersionToast from '@/components/NewVersionToast.vue'
import { useRegisterSW } from 'virtual:pwa-register/vue'

let worker = null

const createVersionNotification = (open) => {
  if (open) {
    toast(NewVersionToast, {
      type: 'info',
      id: 'reload',
      timeout: false,
      hideProgressBar: true,
      closeButton: false,
      draggable: false,
      closeOnClick: false,
    })
  }
}

const ServiceWorker = useRegisterSW({
  // immediate: true,
  onRegistered(sw) {
    worker = sw
    sw.update()
    console.log(`WaveFire ServiceWorker Registered`)
  },
  onNeedRefresh() {
    console.log('WaveFire needs to be refreshed.')
    createVersionNotification(true)
  },
  onOfflineReady() {
    console.log('WaveFire is running in offline mode.')
  },
  onRegisterError(e) {
    console.log('WaveFire could not register service worker.', e)
  },
})

const { needRefresh } = ServiceWorker

const checkWorker = async () => worker.update()

watch(needRefresh, (current) => {
  createVersionNotification(current)
})

export default ServiceWorker

export { ServiceWorker, checkWorker, worker }
