<template>
  <div
    class="d-flex mx-auto overflow-hidden text-center position-relative profilePicture"
    :style="[containerStyle, handleBorderRadius]"
    :class="[{ 'cursor-click': clickable }, shape, getBackgroundColor]"
    @click="handleClick"
  >
    <img
      v-if="user.profile_picture"
      :src="user.profile_picture.image"
      :height="size"
      :width="size"
    />
    <div
      v-else
      class="align-self-center w-100 text-primary line-height-1 text-center"
      :class="getInitialsColor"
      :style="[handleFontSize]"
    >
      {{ initials }}
    </div>
    <div
      v-if="clickable"
      class="editImage text-center mt-2 font-xxs bg-white text-primary position-absolute"
    >
      Click to Edit
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    initialsColor: {
      type: String,
      default: 'primary',
    },
    backgroundColor: {
      type: String,
      default: 'light',
    },
    size: {
      type: Number,
      default: 128,
    },
    isCircle: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      containerStyle: {
        height: `${this.size}px`,
        width: `${this.size}px`,
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUserId: 'auth/getUserId',
      isAdmin: 'auth/isAdmin',
    }),
    clickable() {
      return 'onClick' in this.$attrs
    },
    shape() {
      return this.isCircle ? `rounded-circle` : ''
    },
    initials() {
      return `${this.user.first_name[0]}${this.user.last_name[0]}`
    },
    handleBorderRadius() {
      if (!this.isCircle) {
        return { borderRadius: `${this.borderRadius}px` }
      } else {
        return ''
      }
    },
    handleFontSize() {
      return { fontSize: `${this.size / 2}px` }
    },
    getBackgroundColor() {
      return `bg-${this.backgroundColor}`
    },
    getInitialsColor() {
      return `text-${this.initialsColor}`
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
.profilePicture {
  .editImage {
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
    opacity: 0;
    transition: opacity 0.2s;
  }
  &:hover {
    .editImage {
      opacity: 0.75;
    }
  }
}
</style>
