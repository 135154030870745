import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  async load({ commit, rootGetters }) {
    if (rootGetters['auth/authenticated']) {
      await commit(types.LOADING, true)
      await Pagination(`/organizations/${rootGetters['auth/getOrganizationId']}/protocols`)
        .then((response) => {
          commit(types.LOAD, response)
        })
        .catch((e) => {
          toast.error(e)
        })
        .finally(() => {
          commit(types.LOADING, false)
        })
    }
  },

  async loadQuestions({ commit }, frameworkId) {
    await commit(types.LOADING, true)
    await Pagination(`/protocols/${frameworkId}/questions`)
      .then((response) => {
        commit(types.LOADQUESTIONS, {
          frameworkId: frameworkId,
          questions: response,
        })
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  clear({ commit }) {
    commit(types.LOAD, [])
  },
}
