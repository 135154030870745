<template>
  <btn
    :link="false"
    class="btn-light-gray"
    iconic="clipboard mr-1"
    @click.prevent="doAction"
  >
    {{ btnText }}
  </btn>
</template>

<script>
export default {
  props: {
    answer: {
      type: Object,
      required: true,
    },
  },
  emits: ['link-to-plan'],
  computed: {
    btnText() {
      return this.answer.action_plan_task ? `View Existing Action Plan` : `Add to Action Plan`
    },
    linkToPlan() {
      return {
        entity: this.answer.entity,
        answers: [this.answer],
      }
    },
  },
  methods: {
    doAction() {
      if (this.answer.action_plan_task) {
        this.$router.push({
          name: 'action-plans.view',
          params: {
            planId: this.answer.action_plan_task.task_type_id,
            showTaskId: this.answer.action_plan_task.id,
          },
        })
      } else {
        this.$emit('link-to-plan', this.linkToPlan)
      }
    },
  },
}
</script>
