/* ============
 * Axios
 * ============
 *
 * Promise based HTTP client for the browser and node.js.
 * Because Vue Resource has been retired, Axios will now been used
 * to perform AJAX-requests.
 *
 * https://github.com/mzabriskie/axios
 */

import Axios from 'axios'
import AuthService from '@/services/AuthService'
import store from '@/store'
import EventBus from '@/plugins/event-bus'
import Sleep from '@/plugins/sleep'
import { toastInstance as toast } from '@/plugins/toast'
import { get, merge } from 'lodash'
import { commit } from "lodash/seq"
import app from "../App.vue"
import { domainBroadcastId, domainBroadcastChannel } from "./domain-broadcast-channel"

const excludeFromSignal = [/\/login/g, /\/login\/refresh/g, /\/logout/g, /\/tasks/g, /\/users\/.*\/tasks/g]

export const axiosRequests = {
  controller: null,
  signal: null,

  create() {
    const c = new AbortController()
    this.controller = c
    this.signal = c.signal
  },

  async cancel() {
    this.controller.abort()
    this.create()
  },
}

axiosRequests.create()

export const isExcluded = (url) => excludeFromSignal.find((reg) => reg.test(url))

const instance = Axios.create({
  baseURL: import.meta.env.VITE_APP_API,
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/vnd.api+json',
      'X-Requested-With': 'XmlHttpRequest',
    },
  },
})


var isRefreshing = false;
var refreshQueue = [];

instance.interceptors.response.use(
  (response) => {
    if (store.state.app.maintenanceMode) {
      store.dispatch('app/set', {
        maintenanceMode: false,
      })
      EventBus.emit('check-setup')
    }
    return response
  },
  (error) => {

    const status = get(error, 'response.status')
    const message = get(error, 'response.data.message')
    const url = get(error, 'config.url', '')
    const orgConfig = error.config

    if (url.includes('login/refresh') && status === 500) {
      console.log("Http Status 401: Handling")
      axiosRequests.cancel()
      store.commit('resetStore')
      store.dispatch('auth/logout', 'You have been logged out.').then(res => {
        return Promise.reject(error)
      })
    }

    if (status === 401) {

      console.log("======== Running Refresh Logic ========")

      // Determining if the current request has been attempted previously without success
      orgConfig._retry = orgConfig._retry === 'undefined' ? 0 : orgConfig._retry + 1
      if (orgConfig._retry === 1) {
        return Promise.reject(error)
      }

      // Determine if the app is currently attempting to refresh the active bearer token
      // if (store.state && store.state.auth && store.state.auth.tokenIsRefreshing) {
      //   return store.state.app.tokenRefreshCall
      // }

      if (!store.state.auth.tokenIsRefreshing) {
        store.commit('auth/SET_TOKEN_IS_REFRESHING', true);
        // isRefreshing = true;

        console.log("==== dispatching refresh request")

        store.dispatch('auth/attemptTokenRefresh')
          .then((res) => {
            console.log("---- Iterating Refresh Queue ----")
            store.state.auth.tokenRefreshQueue.forEach((v) => v.resolve(res))
            store.commit('auth/CLEAR_REFRESH_QUEUE');

          })

          .catch((e) => {
            console.log("---- RefreshQueue: Catch")
            store.state.auth.tokenRefreshQueue.forEach((v) => v.reject(error))
            store.commit('auth/CLEAR_REFRESH_QUEUE');

            // refreshQueue.forEach((v) => v.reject(error))
            // refreshQueue = []
          })

          .finally(() => {
            console.log("---- RefreshQueue: Finally")
            store.commit('auth/SET_TOKEN_IS_REFRESHING', false);
          })

      }

      return new Promise((resolve, reject) => {

        store.commit('auth/PUSH_TO_REFRESH_QUEUE',
          {
            resolve: (res) => {
              orgConfig.headers.Authorization = "Bearer " + res;
              const config = merge(orgConfig, res)
              resolve(instance.request(config))
            },
            reject: (err) => {
              reject(err)
            },
          }
        )

        // refreshQueue.push({
        //   resolve: (res) => {
        //     orgConfig.headers.Authorization = "Bearer " + res;
        //     const config = merge(orgConfig, res)
        //     resolve(instance.request(config))
        //   },
        //   reject: (err) => {
        //     reject(err)
        //   },
        // })

      })

      // console.log("Http Status 401: Handling")
      // axiosRequests.cancel()
      // store.commit('resetStore')
      // store.dispatch('auth/logout', 'You have been logged out.').then(res => {
      //   return Promise.reject(error)
      // })
      // ---- if (url.includes('login/refresh')) {}
      // ---- if (store.state.app.appVisible && !url.includes('login')) {}

      // return Promise.reject(error)

    }

    if (status === 503) {
      store.dispatch('app/set', {
        maintenanceMode: true,
      })
    }

    if (error.code === 'ERR_NETWORK' && store.state.app.appVisible) {
      if (window.navigator.onLine) {
        toast.info(
          `A WaveFire network error occurred.<span class="d-block font-sm font-weight-light text-nowrap">Please try again in a few minutes.</span>`,
          {
            actions: [
              {
                text: 'Try again?',
                onClick: (vm) => {
                  vm.$emit('close-toast')
                  window.location.reload(true)
                },
              },
              {
                text: 'Logout?',
                onClick: (vm) => {
                  vm.$emit('close-toast')
                  store.dispatch('auth/logout', true)
                },
              },
              {
                text: 'X',
                onClick: (vm) => {
                  vm.$emit('close-toast')
                },
              },
            ],
          },
          {
            id: error.code,
            timeout: false,
            closeButton: false,
          }
        )
      } else {
        toast.info(
          `A network error occurred.<span class="d-block font-sm font-weight-light text-nowrap">Are you connected to the internet?</span>`
        )
      }
    }

    return Promise.reject(error)
  }
)

instance.interceptors.request.use(
  (config) => {
    if (config.method === 'get' && store.getters['auth/authenticated'] && !isExcluded(config.url)) {
      config.signal = axiosRequests.signal
    }

    if (store.getters['auth/authenticated']) {
      config.headers.Authorization = `Bearer ${store.getters['auth/token']}`
      config.headers['x-wavefire-organization'] = store.getters['auth/getOrganizationId']

      if (store.state.company.activeDivision) {
        config.headers['x-wavefire-division'] = store.state.company.activeDivision
      }
    }

    if (!store.state.app.appVisible) {
      Sleep(1).then(() => axiosRequests.cancel())
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { instance as Axios }

export default instance
