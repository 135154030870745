const defaultState = () => ({
  faq: null,
  faqs: [],
  saving: false,
  loading: false,
  faqsLoading: false,
})

const excludeFields = ['saving', 'loading', 'faqsLoading']

export { defaultState, excludeFields }

export default defaultState
