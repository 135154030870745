<template>
  <FormGroup>
    <slot />
    <FormLabel />
    <TypeAhead
      v-bind="bindTypeAhead"
      :model-value="modelValue"
      :init-value="initValue"
      :options="options"
      :disabled="disabled"
      @update:model-value="$emit('update:modelValue', $event)"
    />
  </FormGroup>
</template>

<script>
import TypeAhead from './TypeAhead.vue'
import FormMixins from '@/mixins/FormMixins'
import { toRaw } from 'vue'

export default {
  name: 'FormTypeAhead',
  extends: TypeAhead,
  mixins: [FormMixins],
  emits: ['update:modelValue'],
  computed: {
    bindTypeAhead() {
      const exludeKeys = ['modelValue', 'initValue', 'options', 'disabled']
      return Object.entries(this.combinedProps).reduce((obj, [key, value]) => {
        if (!exludeKeys.includes(key) && (this.typeAheadKeys.includes(key) || key.startsWith('on'))) {
          obj[key] = value
        }
        return obj
      }, {})
    },
    combinedProps() {
      return { ...this.$props, ...this.$attrs }
    },
    typeAheadKeys() {
      return Object.keys(TypeAhead.props)
    },
  },
}
</script>
