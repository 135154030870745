<template>
  <button
    type="button"
    class="btn"
    :class="[
      alignBaseline && alignBaseline.length ? `align-${alignBaseline}` : '',
      { 'btn-link': link, 'align-baseline': link && alignBaseline === null },
    ]"
  >
    <icon
      v-if="iconic && align === 'left'"
      :iconic="iconic"
      :icon-offset="iconOffset"
    />
    <slot />
    <icon
      v-if="iconic && align === 'right'"
      :iconic="iconic"
      :icon-offset="iconOffset"
    />
    <span
      v-if="count || count === 0"
      :style="getBadgeOffset"
      class="badge"
      :class="[badgeClass, badgeTypeClass, getAlignBadge]"
      >{{ count }}</span
    >
  </button>
</template>

<script>
export default {
  props: {
    link: {
      type: Boolean,
      default: true,
    },
    btn: {
      type: Boolean,
      default: true,
    },
    iconic: {
      type: [String, Boolean],
      default: null,
    },
    align: {
      type: String,
      default: 'left',
    },
    alignBaseline: {
      type: [String, Boolean],
      default: null,
    },
    count: {
      type: Number,
      default: null,
    },
    badge: {
      type: String,
      default: null,
    },
    alignBadge: {
      type: String,
      default: 'center',
    },
    badgeOffset: {
      type: Number,
      default: null,
    },
    badgeHOffset: {
      type: Number,
      default: null,
    },
    badgeInline: {
      type: Boolean,
      default: false,
    },
    iconOffset: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    badgeClass() {
      return this.badge ? `badge-${this.badge}` : null
    },
    badgeTypeClass() {
      return this.badgeInline
        ? `${this.badge ? `badge-${this.badge}` : 'badge-info-gray'} badge-inline`
        : 'badge-disc badge-success-highlight count'
    },
    getAlignBadge() {
      return this.badgeInline ? null : this.alignBadge
    },
    getBadgeOffset() {
      return (this.badgeOffset || this.badgeHOffset) && !this.badgeInline
        ? `${this.badgeOffset ? `top: ${this.badgeOffset}px;` : ''}${
            this.badgeHOffset ? `left: ${this.badgeHOffset}px;` : ''
          }`
        : null
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
button {
  position: relative;
  .badge {
    &.badge-inline {
      /* line-height: inherit;
      padding: 0 0.25rem;
      font-size: 65%;
      font-weight: bold;
      vertical-align: inherit;
      margin-left: 0.25rem;
      top: unset; */
      margin-left: 0.25rem;
    }
    &.count {
      font-size: 0.5rem;
      position: absolute;
      top: -25%;
      &.center {
        left: 50%;
        right: unset;
      }
      &.left {
        left: 0;
        right: unset;
      }
      &.right {
        right: 0;
        left: unset;
      }
    }
  }
}
</style>
