const defaultState = () => ({
  loading: false,
  previousAnswersLoading: false,
  saving: false,
  question: null,
  selectedEntities: [],
  selectedTopics: [],
  selectedQuestionId: [],
  otherDivisionEntities: [],
  topicId: null,
  answers: [],
  previousAnswers: [],
  errors: [],
  stayput: false,
  returnToEntity: false,
  returnToPlan: null,
  savingProgress: 0,
  refreshCompany: false,
  interviewer: null,
  interviewee: null,
})

const excludeFields = ['loading', 'saving']

export { defaultState, excludeFields }

export default defaultState
