<template>
  <div class="d-flex flex-fill px-2">
    <btn
      iconic="trash"
      :link="false"
      :disabled="submitting"
      class="btn-xs btn-red"
      @click.prevent="$emit('delete-answer')"
    />
    <AcceptAnswerBtn
      v-if="questionType === 'answer'"
      class="btn-xs"
      :answer="answer"
      :disabled="submitting"
      @answer-accepted="$emit('answer-accepted')"
    />
    <btn
      class="btn-xs ml-auto mr-3 btn-light"
      :link="false"
      :disabled="submitting"
      @click="$emit('cancel')"
    >
      Cancel
    </btn>
    <btn
      class="btn-xs btn-primary"
      :link="false"
      :disabled="submitting"
      :class="{ loading: submitting }"
      @click.prevent="$emit('update-answer')"
    >
      Update Answer
    </btn>
  </div>
</template>

<script>
import AcceptAnswerBtn from '@/components/buttons/AcceptAnswerBtn.vue'

export default {
  components: {
    AcceptAnswerBtn,
  },
  props: {
    submitting: {
      type: Boolean,
      default: false,
    },
    questionType: {
      type: String,
      default: null,
    },
    answer: {
      type: Object,
      required: true,
    },
  },
  emits: ['delete-answer', 'answer-accepted', 'cancel', 'update-answer'],
}
</script>
