<script>
import { h } from 'vue'

const buildRootElement = (node) => {
  const { type, props, children } = node
  return h(type, props, children)
}

const onlyChildren = (node) => {
  const { children } = node
  return children
}

const WithRoot = (props, context) => {
  const { slots } = context
  const elements = slots.default()

  if (elements.length === 1) {
    const element = elements[0]
    if (props.show) {
      return buildRootElement(element)
    } else {
      return onlyChildren(element)
    }
  } else {
    return null
  }
}

export default WithRoot
</script>
