<template>
  <span :class="[getClass(), 'badge']">
    {{ count }} of {{ total }}
    <template v-if="showText">{{ showPercentage ? ' - ' + getPercent() + '%' : ' completed' }}</template>
  </span>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    showPercentage: {
      type: Boolean,
      default: false,
    },
    showText: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getPercent() {
      return Math.round((this.count / this.total) * 100) || 0
    },
    getClass() {
      let percent = this.getPercent()
      if (percent === 100) {
        return 'Very Low'
      }
      if (percent >= 75) {
        return 'Low'
      }
      if (percent >= 50) {
        return 'Medium'
      }
      if (percent >= 25) {
        return 'High'
      }
      if (percent === 0 && !this.count && !this.total) {
        return 'None'
      }
      return 'Very High'
    },
  },
}
</script>
