const defaultState = () => ({
  users: [],
  groups: [],
  tags: [],
  assets: [],
  roles: [],
  activeDivision: null,
  features: [],
  divisions: [],
  organization: null,
  configurations: [],
  answers: [],
  assignments: [],
  collaborations: [],
  assessment_periods: [],
  entityQuestionExclusions: [],
  entityQuestionExclusionRequests: [],
  entityQuestionExclusionRequestsLoading: false,
  entityQuestionExclusionsLoading: false,
  assessment_period: null,
  saving: false,
  loading: false,
  usersLoading: false,
  groupsLoading: false,
  tagsLoading: false,
  assignmentsLoading: false,
  collaborationsLoading: false,
  assessmentsLoading: false,
  answersLoading: false,
  loadingMetrics: false,
  metrics: {
    users_count: 0,
    groups_count: 0,
    assets_count: 0,
    protocols_count: 0,
    all_entities_count: 0,
    divisions_count: 0,
  },
})

const excludeFields = [
  'loading',
  'saving',
  'entityQuestionExclusionRequestsLoading',
  'entityQuestionExclusionsLoading',
  'usersLoading',
  'groupsLoading',
  'assignmentsLoading',
  'collaborationsLoading',
  'assessmentsLoading',
  'answersLoading',
]

export { defaultState, excludeFields }

export default defaultState
