export const SET = 'SET'
export const LOADING = 'LOADING'
export const ERROR = 'ERROR'
export const MESSAGE = 'MESSAGE'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const LOADFRAMEWORKS = 'LOADFRAMEWORKS'
export const LOADFEATURES = 'LOADFEATURES'
export const FEATURESLOADING = 'FEATURESLOADING'
export const FRAMEWORKSLOADING = 'FRAMEWORKSLOADING'
export const TRANSITIONS = 'TRANSITIONS'
export const MODALS = 'MODALS'
export const REFRESHING = 'REFRESHING'
export const SETREFRESHINGCALL = 'SETREFRESHINGCALL'
export const ARCHIVED = 'ARCHIVED'
export const RESET = 'RESET'

export default {
  SET,
  LOADING,
  ERROR,
  MESSAGE,
  NOTIFICATIONS,
  LOADFRAMEWORKS,
  LOADFEATURES,
  FEATURESLOADING,
  FRAMEWORKSLOADING,
  TRANSITIONS,
  MODALS,
  REFRESHING,
  SETREFRESHINGCALL,
  ARCHIVED,
  RESET,
}

export const noPersist = [
  REFRESHING,
  SETREFRESHINGCALL,
  MODALS,
  TRANSITIONS,
  LOADING,
  FEATURESLOADING,
  FRAMEWORKSLOADING,
  SET,
]
