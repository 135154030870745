<template>
  <div class="form-group">
    <slot />
    <h6 class="answer-title">
      {{ message }}
    </h6>
  </div>
</template>
<script>
export default {
  props: {
    configuration: {
      type: Object,
      required: true,
    },
    terms: {
      type: Object,
      required: true,
    },
  },
  computed: {
    message() {
      return this.configuration.uploadText || this.terms.uploadText
    },
  },
}
</script>
