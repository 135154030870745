<template>
  <div
    class="message card mb-0"
    :class="{ 'loading-sm': loading }"
  >
    <div class="card-body scroll-y">
      <h5 class="card-title d-flex align-items-center font-weight-light">
        {{ lodash.get(message, 'welcome_message.title', '') }}
        <span class="ml-auto font-xs text-secondary">
          {{ $filters.toDate(lodash.get(message, 'welcome_message.created_at', null)) }}
        </span>
      </h5>
      <div
        class="card-text font-sm scroll-y"
        v-html="lodash.get(message, 'welcome_message.message', '')"
      />
    </div>
    <div class="card-footer text-muted font-xs d-flex align-items-center bg-white">
      From: {{ lodash.get(message, 'welcome_message.created_by.full_name', '') }}
      <btn
        class="btn-xs ml-auto"
        :class="{ loading: loading }"
        @click="read(message.id)"
      >
        Mark as read
      </btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      isRead: 'app/isRead',
      refreshUser: 'auth/getUser',
    }),
    read(id) {
      this.loading = true
      this.isRead(id)
        .then(() => {
          this.refreshUser().then(() => {
            this.loading = false
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
