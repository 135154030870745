export default {
  count(state, getters) {
    return getters.active.length
  },
  active(state) {
    return state.categories.filter((category) => category.deleted_at === null)
  },
  get(state) {
    return (id) => state.categories.find((e) => e.id === id)
  },
  hasScore(state) {
    return state.categories.filter((category) => !!category.risk)
  },
  overThreshold(state, getters) {
    return getters.hasScore.filter(
      (category) => (category.risk || (category.score && category.score.risk_rank)) > category.threshold
    )
  },
}
