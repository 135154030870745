import Pagination from '@/plugins/pagination'
import { toastInstance as toast } from '@/plugins/toast'
import ApiService from "../../../services/ApiService"
import _, { get } from 'lodash'
import {
  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationPerPage,
  updatePaginationLastPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL
} from "../../globals/pagination.global"

const defaultState = () => {
  return {
    isLoading: false,
    documentRequestLists: [],
    api: {
      endpoint: `/document-request-lists/`,
      filters: {
        withPointsOfContact: true,
        withItemCount: true,
        withItems: false,
        title: '',
        status: null,
      },
      paginated: true,
      pagination: {
        currentPage: 1,
        lastPage: null,
        perPage: 15,
        from: null,
        to: null,
        total: null
      }
    },
  }
}

const state = defaultState()

const getters = {
  getPagination,
  getPaginationCurrentPage,
  getPaginationPerPage,
  getPaginationLastPage,
  getPaginationFrom,
  getPaginationTo,
  getPaginationTotal,

  isLoading: (state) => {
    return state.isLoading
  },
  documentRequestLists: (state) => {
    return state.documentRequestLists
  },
  getApiFilters: (state) => {
    return state.api.filters
  },

}

const actions = {
  updatePagination,
  updatePaginationCurrentPage,
  updatePaginationLastPage,
  updatePaginationPerPage,
  updatePaginationTotal,
  updatePaginationFrom,
  updatePaginationTo,

  async reset({commit}) {
    await commit('IS_LOADING', true)
    commit('RESET');
    await commit('IS_LOADING', false)
  },

  async getDocumentRequestLists({commit, state, dispatch}, parameters) {
    await commit('IS_LOADING', true)
    return await ApiService.get(`/document-request-lists`, {
      vuexModule: 'documentrequestlists',
      api: state.api,
    })
      .then((response) => {
        let responseData = response.data
        commit('SET_DOCUMENT_REQUEST_LISTS', responseData.data)
        dispatch("updatePagination", responseData.pagination)
      })
      .catch((e) => {
        console.log("====== Error Caught ======")
        console.log(e)
        toast.error(e)
      })
      .finally(() => {
        commit('IS_LOADING', false)
      })

  },

  async setApiFilters({commit, state}, args) {
    // Iterate filters
    _.forEach(args, function(value, name) {
      if (state.api.filters.hasOwnProperty(name)) {
        commit('SET_API_FILTER', {"name": name, "value": args[name]});
      }
    });
  },

  setLoadingState({ commit }, value) {
    commit('IS_LOADING', value)
  },

}

const mutations = {
  UPDATE_PAGINATION,
  UPDATE_PAGINATION_CURRENT_PAGE,
  UPDATE_PAGINATION_LAST_PAGE,
  UPDATE_PAGINATION_PER_PAGE,
  UPDATE_PAGINATION_FROM,
  UPDATE_PAGINATION_TO,
  UPDATE_PAGINATION_TOTAL,
  SET(state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  IS_LOADING(state, bool) {
    state.isLoading = bool
  },
  RESET(state) {
    Object.assign(state, defaultState())
  },
  SET_DOCUMENT_REQUEST_LISTS(state, documentRequestLists) {
    state.documentRequestLists = documentRequestLists;
  },
  SET_API_FILTER(state, args) {
    state.api.filters[args.name] = args.value;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    // nested
  }
}
