<template>
  <div
    class="wavefire-grid"
    :class="[fillClass, { 'd-flex flex-column flex-grow-1 no-scroll': search || searchAll }]"
  >
    <div
      v-if="search || searchAll"
      class="flex-shrink-1"
    >
      <SearchBar
        v-model="searched"
        :placeholder="searchPlaceholder"
        :class="searchClass"
        :input-disabled="loading"
      />
    </div>
    <div :class="{ 'flex-grow-1 scroll-y': search || searchAll }">
      <gridTABLE :class="[tableClass, { 'remove-top-border': search || searchAll }, { loading: loading }]">
        <gridCOLGROUP
          v-if="colgroup"
          v-html="colgroup"
        />
        <gridCOLGROUP
          v-else
          :selectable="selectable"
          :collapsable="collapsable"
          :columns="columns"
        />
        <gridHEAD
          v-if="thead"
          v-html="thead"
        />
        <gridHEAD v-else>
          <gridTR>
            <gridTH v-if="selectable">
              <input
                v-model="selectAll"
                type="checkbox"
                class="checkAll"
                :disabled="!anySelectable || !!selectableLimit"
              />
            </gridTH>
            <gridTH v-if="collapsable">
              <btn
                class="handle"
                iconic="caret-bottom"
                :disabled="!allChildren.length"
                :class="{ open: anyExpanded }"
                @click="toggleAll"
              />
            </gridTH>
            <gridTH
              v-for="(column, i) in showHeaders"
              :key="`grid-header-${i}`"
              :colspan="column.colspan || null"
              class="position-relative"
              :class="[
                column.class || false,
                { sortable: column.sortable || false },
                { 'border-top-0': search || searchAll },
              ]"
              @click="!!column.sortable ? sortBy(`${column.key}.${column.sortValue ? 'sortValue' : 'content'}`) : null"
            >
              <span
                v-if="!!column.help"
                v-tippy
                :content="column.help"
                class="table-header-help"
                v-html="column['label'] !== undefined ? column.label : $filters.capitalize(column.key)"
              />
              <span
                v-else
                v-html="column['label'] !== undefined ? column.label : $filters.capitalize(column.key)"
              />
              <span
                v-if="!!column.sortable"
                class="sort ml-2"
                :class="sortOrders[`${column.key}.${column.sortValue ? 'sortValue' : 'content'}`]"
              />
              <span
                v-if="
                  !!column.sortable &&
                  sortOrderPosition(`${column.key}.${column.sortValue ? 'sortValue' : 'content'}`) !== null
                "
                class="sortable-badge badge badge-info-highlight position-absolute opacity-4"
                v-html="`${sortOrderPosition(`${column.key}.${column.sortValue ? 'sortValue' : 'content'}`)}`"
              />
              <span
                v-if="selectable && column.showSelectedCount && selectedRows.length"
                class="text-muted ml-2"
                v-html="`(${selectedRows.length} selected)`"
              />
            </gridTH>
          </gridTR>
        </gridHEAD>
        <gridBODY
          v-for="(row, i) in filteredData"
          :key="`grid-tbody-${row._key || i}`"
          :class="{ expand: !collapsable || expandedItems[row._key] }"
        >
          <gridTR :class="row.rowClass || row.rowclass || row.class">
            <gridTD
              v-if="selectable && !collapsable && !hasChildren"
              :class="selectableClass"
            >
              <input
                v-if="showSelectable(row)"
                v-model="selectedRows"
                type="checkbox"
                :value="row"
                :disabled="row._disableSelect"
              />
              <icon
                v-else-if="row._showInfo"
                v-tippy
                iconic="info"
                class="text-info font-xs"
                :content="row._showInfoMessage"
              />
              <icon
                v-else-if="row._showSuccess"
                v-tippy
                iconic="circle-check"
                class="text-success font-xs"
                :content="row._showSuccessMessage"
              />
              <icon
                v-else-if="row._showLock"
                v-tippy
                iconic="lock-locked"
                class="text-error font-xs"
                :content="row._showLockMessage"
              />
            </gridTD>
            <gridTD
              v-if="selectable && (collapsable || hasChildren)"
              :class="selectableClass"
            >
              <input
                v-if="showSelectable(row)"
                v-model="selectedCategories"
                type="checkbox"
                :value="row"
                :disabled="row._disableSelect"
              />
              <icon
                v-else-if="row._showInfo"
                v-tippy
                iconic="info"
                class="text-info font-xs"
                :content="row._showInfoMessage"
              />
              <icon
                v-else-if="row._showSuccess"
                v-tippy
                iconic="circle-check"
                class="text-success font-xs"
                :content="row._showSuccessMessage"
              />
              <icon
                v-else-if="row._showLock"
                v-tippy
                iconic="lock-locked"
                class="text-error font-xs"
                :content="row._showLockMessage"
              />
            </gridTD>
            <gridTD
              v-if="collapsable"
              :class="collapsableClass"
            >
              <btn
                class="handle"
                :class="{ open: expandedItems[row._key] === true }"
                :disabled="!hasChildren"
                iconic="chevron-right"
                @click="toggleChildren(row)"
              />
            </gridTD>
            <template v-for="(column, index) in columns">
              <gridTD
                v-if="
                  !column.hidden &&
                  row[column.key] &&
                  row[column.key]['content'] !== undefined &&
                  !Array.isArray(row[column.key].content)
                "
                :key="`grid-td-${row._key || i}-${index}`"
                v-tippy="{
                  theme: 'light small',
                  onShow: (el) => row[column.key].class === 'text-truncate' && shouldShowTippy(el, row[column.key]),
                }"
                :class="row[column.key].class || false"
                :colspan="row[column.key].colspan || false"
                :content="
                  row[column.key].class === 'text-truncate' && row[column.key].content ? row[column.key].content : null
                "
                v-html="(row[column.key].prefix || '') + (row[column.key].content || '')"
              />
              <gridTD
                v-else-if="
                  !column.hidden &&
                  row[column.key] &&
                  row[column.key]['content'] !== undefined &&
                  Array.isArray(row[column.key].content)
                "
                :key="`grid-td-${row._key || i}-${index}`"
                :class="row[column.key].class || false"
                :colspan="row[column.key].colspan || false"
              >
                <gridCOMPONENT
                  :key="`grid-td-el-${row._key || i}-${index}`"
                  :elements="row[column.key].content"
                />
              </gridTD>
            </template>
          </gridTR>
          <template v-if="!collapsable || expandedItems[row._key]">
            <gridTR
              v-for="(childRow, childRowCount) in row._children || []"
              :key="`grid-child-tr-${childRow._key || childRowCount}`"
              class="vue-grid-child-row"
              :class="[childRow.rowClass || childRow.rowclass || childRow.class]"
            >
              <gridTD v-if="selectable">
                <input
                  v-if="!childRow.hideSelectable && showSelectable(childRow)"
                  v-model="selectedRows"
                  type="checkbox"
                  :value="childRow"
                  :disabled="childRow._disableSelect"
                />
                <icon
                  v-else-if="childRow._showInfo"
                  v-tippy
                  iconic="info"
                  class="text-info font-xs"
                  :content="childRow._showInfoMessage"
                />
                <icon
                  v-else-if="childRow._showSuccess"
                  v-tippy
                  iconic="circle-check"
                  class="text-success font-xs"
                  :content="childRow._showSuccessMessage"
                />
                <icon
                  v-else-if="childRow._showLock"
                  v-tippy
                  iconic="lock-locked"
                  class="text-error font-xs"
                  :content="childRow._showLockMessage"
                />
              </gridTD>
              <gridTD v-if="collapsable" />
              <template v-for="(column, index) in columns">
                <gridTD
                  v-if="
                    !column.hidden &&
                    childRow[column.key] &&
                    childRow[column.key]['content'] !== undefined &&
                    !Array.isArray(childRow[column.key].content)
                  "
                  :key="`grid-child-tr-${childRow._key || childRowCount}-td-${index}`"
                  :class="childRow[column.key].class || false"
                  :colspan="childRow[column.key].colspan || false"
                  v-html="(childRow[column.key].prefix || '') + childRow[column.key].content"
                />
                <gridTD
                  v-else-if="
                    !column.hidden &&
                    childRow[column.key] &&
                    childRow[column.key]['content'] !== undefined &&
                    Array.isArray(childRow[column.key].content)
                  "
                  :key="`grid-child-tr-${childRow._key || childRowCount}-td-${index}`"
                  :class="childRow[column.key].class || false"
                  :colspan="childRow[column.key].colspan || false"
                >
                  <gridCOMPONENT :elements="childRow[column.key].content" />
                </gridTD>
              </template>
            </gridTR>
          </template>
        </gridBODY>
        <gridBODY v-if="!anyData && !!searched">
          <gridTR>
            <gridTD
              :colspan="columns.length + (selectable ? 1 : 0) + (collapsable ? 1 : 0)"
              class="text-center"
              :class="{ [noResultsClass]: noResultsClass }"
            >
              No results found
            </gridTD>
          </gridTR>
        </gridBODY>
        <gridBODY v-else-if="!anyData && !loading && noResultsText">
          <gridTR>
            <gridTD
              :colspan="columns.length + (selectable ? 1 : 0) + (collapsable ? 1 : 0)"
              class="text-center"
              :class="{ [noResultsClass]: noResultsClass }"
              v-html="noResultsText"
            />
          </gridTR>
        </gridBODY>
        <gridFOOT
          v-if="tfoot"
          v-html="tfoot"
        />
      </gridTABLE>
    </div>
  </div>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue'
import gridTABLE from '@/components/grid/TABLE.vue'
import gridHEAD from '@/components/grid/HEAD.vue'
import gridBODY from '@/components/grid/BODY.vue'
import gridFOOT from '@/components/grid/FOOT.vue'
import gridCOLGROUP from '@/components/grid/COLGROUP.vue'
import gridTR from '@/components/grid/TR.vue'
import gridTH from '@/components/grid/TH.vue'
import gridTD from '@/components/grid/TD.vue'
import gridCOMPONENT from '@/components/grid/COMPONENT.vue'

export default {
  components: {
    SearchBar,
    gridTABLE,
    gridHEAD,
    gridBODY,
    gridFOOT,
    gridCOLGROUP,
    gridTR,
    gridTH,
    gridTD,
    gridCOMPONENT,
  },
  props: {
    tableClass: {
      type: String,
      default: null,
    },
    searchClass: {
      type: String,
      default: null,
    },
    selectableClass: {
      type: String,
      default: null,
    },
    collapsableClass: {
      type: String,
      default: null,
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    filterKey: {
      type: String,
      default: '',
    },
    defaultSortOrder: {
      type: Object,
      default() {
        return {}
      },
    },
    search: {
      type: String,
      default: '',
    },
    searchAll: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectableLimit: {
      type: Number,
      default: 0,
    },
    selectableKey: {
      type: String,
      default: null,
    },
    selectableChildren: {
      type: Boolean,
      default: true,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: null,
    },
    noResultsClass: {
      type: String,
      default: null,
    },
    noResultsText: {
      type: String,
      default: null,
    },
    thead: {
      type: String,
      default: null,
    },
    tfoot: {
      type: String,
      default: null,
    },
    colgroup: {
      type: String,
      default: null,
    },
    fillClass: {
      type: String,
      default: 'w-100',
    },
  },
  emits: ['selected-rows', 'on-data-added', 'on-grid-ready', 'toggle-all', 'toggle-parent'],
  data() {
    return {
      sortOrders: Object.assign({}, this.defaultSortOrder),
      searched: null,
      selectedCategories: [],
      selectedRows: [],
      expandedItems: {},
      categoryWatcherDisabled: false,
    }
  },
  computed: {
    filteredData() {
      let data =
        (this.search || this.searchAll) && this.searched
          ? this.data.filter((row) => {
              if (this.searchAll) {
                return (
                  Object.values(row)
                    .map((r) => (Array.isArray(r.content) ? r.map((c) => c.template).join(' ') : r.content))
                    .join(' ')
                    .search(new RegExp(this.lodash.escapeRegExp(this.searched), 'i')) !== -1
                )
              }
              if (!row[this.search]) {
                return false
              }
              if (Array.isArray(row[this.search].content)) {
                return (
                  row[this.search].content
                    .map((c) => c.template)
                    .join(' ')
                    .search(new RegExp(this.lodash.escapeRegExp(this.searched), 'i')) !== -1
                )
              } else {
                return (
                  row[this.search] &&
                  row[this.search].content &&
                  row[this.search].content.search(new RegExp(this.lodash.escapeRegExp(this.searched), 'i')) !== -1
                )
              }
            })
          : this.data

      let fieldSorter = (fields) => (aa, bb) =>
        fields
          .map((field) => {
            let dir = field[1] === 'desc' ? -1 : 1
            let key = field[0]
            let a = this.lodash.get(aa, key)
            let b = this.lodash.get(bb, key)

            let numericA = Number(a)
            let numericB = Number(b)

            if (!!numericA && !!numericB) {
              return numericA > numericB ? dir : numericA < numericB ? -dir : 0
            } else if (this.lodash._.isString(a) && this.lodash._.isString(b)) {
              return a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: 'base'
              }) * dir
            } else {
              return a > b ? dir : a < b ? -dir : 0
            }
          })
          .reduce((p, n) => (p ? p : n), 0)

      let entries = Object.entries(this.sortOrders)

      return entries.length ? data.slice().sort(fieldSorter(Object.entries(this.sortOrders))) : data
    },
    filteredDataKeys() {
      return this.filteredData.map((i) => i._key)
    },
    sortOrderKeys() {
      return Object.keys(this.sortOrders)
    },
    anyData() {
      return !!this.filteredData.length
    },
    showHeaders() {
      return this.columns.filter((c) => !c.hidden && !c.element)
    },
    anyExpanded() {
      return !!Object.keys(this.expandedItems).filter(
        (key) => this.filteredDataKeys.find(i => i == key) && this.expandedItems[key] === true
      ).length
    },
    anySelectable() {
      return !!Object.keys(this.filteredData).filter((key) => !this.filteredData[key]._disableSelect).length
    },
    allChildren() {
      return this.filteredData.reduce((all, row) => [...new Set([...all, ...(row._children || [])])], [])
    },
    hasChildren() {
      return !!this.filteredData.filter((row) => row._children && row._children.length).length
    },
    hasSelectableChildren() {
      return this.hasChildren && this.selectableChildren
    },
    selectAll: {
      get() {
        let selected = this.selectedRows.length
        return (
          selected > 0 &&
          selected ===
            (this.hasSelectableChildren
              ? this.allChildren.filter((row) => !row._disableSelect).length
              : this.filteredData.length)
        )
      },
      set(value, p) {
        this.setSelected(value)
      },
    },
  },
  watch: {
    selectedCategories(current, previous) {
      if (!this.categoryWatcherDisabled) {
        if (this.hasSelectableChildren) {
          this.toggleSelectedRows(current, previous)
        } else {
          this.selectedRows = this.selectedCategories
          // this.$emit('selected-rows', this.selectableKey ? current.map(row => row[this.selectableKey]) : current)
        }
      }
    },
    selectedRows(current, previous) {
      if(this.selectableLimit && current.length) {
        const diff = this.lodash.difference(current, previous)
        const selected = current.length && diff.length
          ? this.lodash.difference(current, previous)
          : false

        if(!!selected && !this.lodash.isEqual(selected, [...current])) {
          this.setSelectedRows(selected)
          return
        }
      }

      if (this.selectable || this.selectableChildren) {
        this.$emit('selected-rows', this.getSelected())
      }
      if (this.hasSelectableChildren && (current.length || previous.length)) {
        this.checkCategory(current, previous)
      }
    },
    data(current) {
      if (this.hasSelectableChildren) {
        let alreadySelectedKeys = this.lodash.intersection(
          this.selectedRows.map((i) => i._key),
          this.allChildren.map((i) => i._key)
        )

        this.selectedRows = this.allChildren.filter(
          (item) => item._selected || (alreadySelectedKeys.length && alreadySelectedKeys.includes(item._key))
        )
        this.removeStaleCategories()
      } else {
        let alreadySelectedKeys = this.selectedRows.map((i) => i._key)
        this.selectedCategories = this.data.filter(
          (item) => item._selected || (alreadySelectedKeys.length && alreadySelectedKeys.includes(item._key))
        )
      }
      this.$emit('on-data-added', current)
    },
    filteredData(current) {
      this.$emit('on-grid-ready', current)
    },
  },
  beforeMount() {
    if (this.hasSelectableChildren) {
      this.selectedRows = this.allChildren.filter((item) => item._selected)
    } else {
      this.selectedCategories = this.data.filter((item) => item._selected)
    }
  },
  methods: {
    disableCategoryWatcher() {
      this.categoryWatcherDisabled = true
    },
    enableCategoryWatcher() {
      this.$nextTick(() => {
        this.categoryWatcherDisabled = false
      })
    },
    sortBy(key) {
      if (this.sortOrders[key]) {
        if (this.sortOrders[key] === 'asc') {
          this.sortOrders[key] = 'desc'
        } else {
          delete this.sortOrders[key]
        }
      } else {
        this.sortOrders[key] = 'asc'
      }
    },
    getSelected() {
      return this.selectableKey ? this.selectedRows.map((row) => row[this.selectableKey]) : this.selectedRows
    },
    setSelected(value) {
      if (value) {
        if (this.hasChildren) {
          this.selectedCategories = this.filteredData.filter((row) => !row._disableSelect)
        } else {
          this.selectedRows = this.filteredData.filter((row) => !row._disableSelect)
        }
      } else {
        this.clearSelected()
      }
    },
    clearSelected() {
      if (this.hasChildren) {
        this.selectedCategories = []
      } else {
        this.selectedRows = []
      }
    },
    findRow(key, value) {
      if (this.hasSelectableChildren) {
        return this.allChildren.find((d) => d[key] === value)
      } else {
        return this.data.find((d) => d[key] === value)
      }
    },
    showSelectable(row) {
      let allowDisabled = !!row._disableSelect
      let allowSuccess = !!row._showSuccess
      let allowInfo = !!row._showInfo
      let allowLock = !!row._showLock

      if (allowDisabled) {
        if (allowSuccess || allowInfo || allowLock) {
          return !row._disableSelect
        } else {
          return allowSuccess || allowInfo || allowLock ? false : true
        }
      } else {
        return true
      }
    },
    addCategory(category) {
      let isCategorySelected = !!this.selectedCategories.filter((row) => row._key === category._key).length

      if (!isCategorySelected) {
        let children = category._children.filter((row) => !row._disableSelect)
        let childrenKeys = children.map((row) => row._key)
        let selected = this.selectedRows.filter((row) => childrenKeys.includes(row._key))
        if (children.length === selected.length && !isCategorySelected) {
          this.selectedCategories.push(category)
        }
      }
    },
    removeCategories(keys) {
      let categories = this.data
        .filter((category) => category._children.filter((row) => keys.includes(row._key)).length)
        .map((category) => category._key)

      this.selectedCategories = this.selectedCategories.filter((row) => !categories.includes(row._key))
    },
    removeStaleCategories() {
      this.disableCategoryWatcher()
      let alreadySelectedKeys = this.selectedCategories.map((row) => row._key)
      this.selectedCategories = this.data.filter(
        (item) => item._selected || (alreadySelectedKeys.length && alreadySelectedKeys.includes(item._key))
      )
      this.enableCategoryWatcher()
    },
    checkCategory(current, previous) {
      this.disableCategoryWatcher()

      let remove = this.lodash.difference(previous, current)
      let add = this.lodash.difference(current, previous)

      if (add.length) {
        let keys = add.map((item) => item._key)
        let categories = this.data.filter(
          (category) => category._children.filter((row) => keys.includes(row._key)).length
        )

        categories.forEach((category) => {
          this.addCategory(category)
        })
      }

      if (remove.length) {
        this.removeCategories(remove.map((item) => item._key))
      }

      this.enableCategoryWatcher()
    },
    toggleAll() {
      let open = !this.anyExpanded
      let updatedRows = this.filteredData.map(r => r._key)

      this.expandedItems = Object.entries(this.expandedItems).map(([key, value]) =>
        (!!this.filteredDataKeys.find(item => item == key)
          ? {[key]: open}
          : {[key]: value}
        )
      )

      // this.filteredData.forEach((row) => {
      //   this.expandedItems[row._key] = open
      // })

      this.filteredData.forEach((row) => {
        this.expandedItems[row._key] = open
      })

      this.$emit('toggle-all', open)
    },
    toggleChildren(row) {
      this.expandedItems[row._key] = !this.expandedItems[row._key]
      this.$emit('toggle-parent', row, this.expandedItems[row._key])
    },
    toggleSelectedRows(current, previous) {
      let remove = this.lodash
        .difference(previous, current)
        .reduce((all, row) => [...new Set([...all, ...row._children.filter((child) => !child._disableSelect)])], [])
      let add = this.lodash
        .difference(current, previous)
        .reduce((all, row) => [...new Set([...all, ...row._children.filter((child) => !child._disableSelect)])], [])
      this.selectedRows = this.lodash.difference(this.selectedRows, remove).concat(add)
    },
    setSelectedRows(array) {
      this.selectedRows = array
    },
    sortOrderPosition(item) {
      let pos = this.sortOrderKeys.indexOf(item)
      return pos < 0 ? null : pos + 1
    },
    shouldShowTippy(el, td) {
      return (
        el.reference.scrollWidth > el.reference.clientWidth &&
        td.class === 'text-truncate' &&
        !!td.content &&
        !!td.content.length
      )
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
.table {
  thead {
    tr:last-child {
      td,
      th {
        border-bottom: none;
      }
    }
    .table-header-help {
      border-bottom: dashed;
      border-bottom-color: $info;
      border-bottom-width: 1px;
    }
  }
}
tbody {
  tr {
    &.vue-grid-child-row {
      // display: none;
    }
  }
  &.expand {
    tr {
      &.vue-grid-child-row {
        display: table-row;
      }
    }
  }
}
.remove-top-border {
  border-top: none;
  thead {
    tr:first-child {
      td,
      th {
        border-top: none;
      }
    }
  }
}
.filter-search {
  span {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 4;
  }
  input {
    &:focus + span {
      color: #7aa1ca !important;
    }
  }
}
.sortable {
  user-select: none;
  cursor: pointer;
  .sort {
    position: relative;
    padding-left: 8px;
    &:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid #dfdfdf;
    }
    &.desc:before {
      border-bottom-color: $primary;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #dfdfdf;
    }
    &.asc:after {
      border-top-color: $primary;
    }
  }
}
input[type='checkbox'] {
  margin-top: 0.125rem;
  vertical-align: text-top;
}
.table-sm {
  input[type='checkbox'] {
    margin-top: 0.125rem;
    transform: scale(0.875);
  }
}
.sortable-badge {
  left: 0;
  top: 0;
  font-size: 0.5rem !important;
  padding: 0.125rem 0.2rem !important;
  transform: translate(-30%, -5%);
}
.horizontal-offset-3 {
  :deep(.filter-search) {
    input {
      padding-left: 1rem;
    }
    span {
      right: 1rem;
    }
  }
  :deep(.filter-search) {
    tr {
      td:first-child,
      th:first-child {
        padding-left: 1rem;
      }
      td:last-child,
      th:last-child {
        padding-right: 1rem;
      }
    }
  }
}
.horizontal-offset-2 {
  :deep(.filter-search) {
    input {
      padding-left: 0.75rem;
    }
    span {
      right: 0.75rem;
    }
  }
  :deep(.filter-search) {
    tr {
      td:first-child,
      th:first-child {
        padding-left: 0.75rem;
      }
      td:last-child,
      th:last-child {
        padding-right: 0.75rem;
      }
    }
  }
}
.horizontal-offset-1 {
  :deep(.filter-search) {
    input {
      padding-left: 0.5rem;
    }
    span {
      right: 0.5rem;
    }
  }
  :deep(.filter-search) {
    tr {
      td:first-child,
      th:first-child {
        padding-left: 0.5rem;
      }
      td:last-child,
      th:last-child {
        padding-right: 0.5rem;
      }
    }
  }
}
</style>
