/**
 * Question Assignment Helpers.
 * These methods help determine question assignment state.
 */
import { get } from 'lodash'
import store from '@/store'

/**
 * Getters will return an object or collection of objects the match the methods arguments
 */

const getters = {
  /**
   * Gets all topics that have not been assigned.
   * @return {Array} collections of topics.
   */
  unassignedTopics(questions) {
    return (questions || this.requiredTopics()).filter(
      (topic) => !topic.assigned_count && !get(topic, 'completion_meta.isComplete')
    )
  },

  unassignedCollaborationTopics(questions) {
    return (questions || this.requiredTopics()).filter(
      (topic) => !topic.assigned_collaborations_count && !get(topic, 'completion_meta.isCollaborationComplete', false)
    )
  },

  unassignedCombinedTopics(questions) {
    return (questions || this.requiredTopics()).filter(
      (topic) =>
        !topic.assigned_count &&
        !topic.assigned_collaborations_count &&
        !get(topic, 'completion_meta.isComplete') &&
        !get(topic, 'completion_meta.isCollaborationComplete', false)
    )
  },

  unassignedTopicsByType(questions, requiredType) {
    if (requiredType === 'Answers') {
      return this.unassignedTopics(questions)
    } else if (requiredType === 'Collaborators') {
      return this.unassignedCollaborationTopics(questions)
    } else {
      return this.unassignedCombinedTopics(questions)
    }
  },

  overdueTopicAssignments(questions) {
    return questions.filter((topic) => !!topic.overdue_count)
  },

  overdueTopicCollaborations(questions) {
    return questions.filter((topic) => !!topic.overdue_collaboration_count)
  },

  overdueTopicCombined(questions) {
    return questions.filter((topic) => !!topic.overdue_count || !!topic.overdue_collaboration_count)
  },

  overdueTopicsByType(questions, requiredType) {
    if (requiredType === 'Answers') {
      return this.overdueTopicAssignments(questions)
    } else if (requiredType === 'Collaborators') {
      return this.overdueTopicCollaborations(questions)
    } else {
      return this.overdueTopicCombined(questions)
    }
  },

  /**
   * Gets all questions that have been assigned.
   * @return {Array} collections of topics.
   */
  assignedQuestions() {
    return store.state.questions.questions.filter((q) => !!q.assigned_count)
  },

  /**
   * Gets all topics that have been assigned.
   * @return {Array} collections of topics.
   */
  assignedTopics(questions) {
    return (questions || this.requiredTopics()).filter((q) => !!q.assigned_count)
  },

  assignedCollaborationTopics(questions) {
    return (questions || this.requiredTopics()).filter((q) => !!q.assigned_collaborations_count)
  },

  assignedCombinedTopics(questions) {
    return (questions || this.requiredTopics()).filter((q) => !!q.assigned_count || !!q.assigned_collaborations_count)
  },

  assignedTopicsByType(questions, requiredType) {
    if (requiredType === 'Answers') {
      return this.assignedTopics(questions)
    } else if (requiredType === 'Collaborators') {
      return this.assignedCollaborationTopics(questions)
    } else {
      return this.assignedCombinedTopics(questions)
    }
  },

  /**
   * Gets all topics that have been assigned to current user.
   * @return {Array} collections of topics.
   */
  myAssignedTopics(requiredType) {
    let notificationTypes =
      Array.isArray(requiredType) && requiredType.length ? requiredType : ['question', 'collaboration']
    let assigned = store.state.app.notifications
      .filter((n) => notificationTypes.includes(n.task_type))
      .map((n) => n.task_type_id)
    return this.requiredTopics().filter((question) => assigned.includes(question.id))
  },

  /**
   * Gets all topics that have been assigned to current user that have been answered.
   * @return {Array} collections of topics.
   */
  myAssignedTopicsAnswered(requiredType) {
    let notificationTypes =
      Array.isArray(requiredType) && requiredType.length ? requiredType : ['question', 'collaboration']
    let assigned = store.state.app.notifications
      .filter((n) => notificationTypes.includes(n.task_type) && n.completed === 1)
      .map((n) => n.task_type_id)
    return this.requiredTopics().filter((question) => assigned.includes(question.id))
  },

  /**
   * Gets all topics that have been assigned to current user that have not been answered.
   * @return {Array} collections of topics.
   */
  myAssignedTopicsUnanswered(questions, requiredType) {
    return questions.filter((question) => {
      if (requiredType === 'Answers') {
        return !!get(question, 'completion_meta.myAssignedEntities', []).length
      } else if (requiredType === 'Collaborators') {
        return !!get(question, 'completion_meta.myAssignedCollaborationEntities', []).length
      } else {
        return (
          !!get(question, 'completion_meta.myAssignedEntities', []).length ||
          !!get(question, 'completion_meta.myAssignedCollaborationEntities', []).length
        )
      }
    })
  },

  /**
   * Gets all questions that have been assigned to current user.
   * @return {Array} collections of questions.
   */
  myAssignedQuestions(requiredType) {
    let notificationTypes =
      Array.isArray(requiredType) && requiredType.length ? requiredType : ['question', 'collaboration']
    let assigned = store.state.app.notifications
      .filter((n) => notificationTypes.includes(n.task_type))
      .map((n) => n.task_type_id)
    return store.state.questions.questions.filter((question) => assigned.includes(question.id))
  },

  /**
   * Gets all questions that have been assigned to current user that have been answered.
   * @return {Array} collections of questions.
   */
  myAssignedQuestionsAnswered(requiredType) {
    let notificationTypes =
      Array.isArray(requiredType) && requiredType.length ? requiredType : ['question', 'collaboration']
    let assigned = store.state.app.notifications.filter(
      (n) => n.completed === 1 && notificationTypes.includes(n.task_type)
    )
    return store.state.questions.questions.filter(
      (question) => assigned.completed === 1 && assigned.includes(question.id)
    )
  },

  /**
   * Gets all questions that have been assigned to current user that have not been answered.
   * @return {Array} collections of questions.
   */
  myAssignedQuestionsUnanswered(requiredType) {
    let notificationTypes =
      Array.isArray(requiredType) && requiredType.length ? requiredType : ['question', 'collaboration']
    let assigned = store.state.app.notifications.filter(
      (n) => n.completed === 0 && notificationTypes.includes(n.task_type)
    )
    return store.state.questions.questions.filter(
      (question) => assigned.completed === 0 && assigned.includes(question.id)
    )
  },

  /**
   * Gets all entities that have been assigned to any topic.
   * @return {Array} collections of entities.
   */
  allEntitiesAssignedTopics() {
    let topicIds = this.getActiveEntities()
      .map((e) => this.entityAssignedQuestions(e.id))
      .reduce((all, current) => [...new Set([...all, ...current])], [])
    return this.topics().filter((topic) => topicIds.includes(topic.id))
  },

  /**
   * Gets all questions that have been assigned to an entity.
   * @return {Array} id's of questions.
   */
  entityAssignedQuestions(entityId) {
    return this.getEntityQuestionMeta(entityId).assignedQuestions
  },

  entityAssignedCollaborationQuestions(entityId) {
    return this.getEntityQuestionMeta(entityId).assignedCollaborationQuestions
  },
}

/**
 * Counts will return the number of matching objects
 */

const counts = {
  /**
   * Checks how many questions are assigned by category.
   * @param {Number} categoryId
   * @return {Number} count of assignments
   */
  assignedByCategoryCount(categoryId) {
    return store.state.questions.questions
      .filter((q) => q.category_id === categoryId)
      .map((q) => q.assigned_count)
      .reduce((sum, count) => sum + count, 0)
  },

  /**
   * Checks how many questions are unassigned.
   * @return {Number} count of assignments
   */
  unassignedQuestionsCount() {
    let completeQuestionIds = this.completeQuestions().map((c) => c.id)
    return store.state.questions.questions.filter((q) => q.assigned_count === 0 && !completeQuestionIds.includes(q.id))
  },

  howManyEntitiesAssigned(questionId) {
    return this.getQuestion(questionId).assigned_count
  },

  howManyEntitiesCollaborationsAssigned(questionId) {
    return this.getQuestion(questionId).assigned_collaborations_count
  },
}

/**
 * Tests checks a condition or state and always returns true/false
 */

const tests = {
  /**
   * Checks if the question is assigned to the current user.
   * @return {Boolean}
   */
  isQuestionsAssignedToMe(entityId, questionId) {
    return (
      store.state.app.notifications.filter(
        (n) =>
          ['question', 'collaboration'].includes(n.task_type) &&
          (n.task_type_id === questionId || this.getQuestion(questionId).parent_question_id) &&
          n.entity_id === entityId &&
          n.user_id === store.getters['auth/getUserId']
      ).length && store.state.questions.questions.map((q) => q.id).includes(questionId)
    )
  },

  /**
   * Checks if the question is assigned.
   * @return {Boolean}
   */
  isQuestionAssigned(entityId, questionId) {
    return !!this.entityAssignedQuestions(entityId).includes(questionId)
  },
}

const AssignmentsMethods = Object.assign(getters, counts, tests)

export default AssignmentsMethods

// export const methods = Object.assign(getters, counts, tests)
