<template>
  <FormGroup>
    <slot />
    <FormLabel />
    <BaseSelect
      v-bind="$props"
      :loading="loading"
      @update:model-value="$emit('update:modelValue', $event)"
    />
  </FormGroup>
</template>

<script>
import BaseSelect from './BaseSelect.vue'
export default {
  extends: BaseSelect,
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
}
</script>
