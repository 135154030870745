import Toast, { useToast } from 'vue-toastification'
import ToastComponent from '@/components/ToastComponent.vue'
import { get } from 'lodash'

export const toast = useToast()

export const ToastSetup = Toast

export const ToastOptions = {
  shareAppContext: true,
  draggable: false,
  closeOnClick: false,
  position: 'bottom-right',
  timeout: 10000,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  closeButton: 'button',
  icon: true,
  container: () => document.getElementById('toast-container'),
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.find((t) => t.id === toast.id)) {
      return false
    }
    return toast
  },
}

const excludedError = (message) => {
  if (['CanceledError', 'AxiosError'].includes(get(message, 'name'))) {
    return true
  }
  if ([503, 401].includes(get(message, 'response.status'))) {
    return true
  }
}

export const toastInstance = {
  base(type, message, props, config) {
    return toast[type](
      {
        component: ToastComponent,
        props: Object.assign(
          {
            message: message,
          },
          props || {}
        ),
      },
      config || {}
    )
  },
  success(message, props, config) {
    return this.base('success', message, props, config)
  },
  info(message, props, config) {
    return this.base('info', message, props, config)
  },
  error(message, props, config) {
    return excludedError(message)
      ? null
      : import.meta.env.PROD && get(message, 'response.status') === 500
      ? this.base('error', 'Unknown Error Occured', props, config)
      : this.base('error', message, props, config)
  },
  dismiss(id) {
    return toast.dismiss(id)
  },
}
