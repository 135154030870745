<template>
  <div class="row">
    <div
      class="col"
      :class="[colClass, { 'd-flex flex-column': flex }, { 'd-flex': flexRow }]"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flex: {
      type: Boolean,
      default: false,
    },
    flexRow: {
      type: Boolean,
      default: false,
    },
    colClass: {
      type: String,
      default: null,
    },
  },
}
</script>
