<template>
  <router-link
    class="nav-link d-flex align-items-center"
    :to="to"
  >
    {{ label }}
    <slot />
    <span
      v-if="count !== null"
      class="ml-auto"
      :class="spanClass"
    >
      {{ count }}
    </span>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    spanClass: {
      type: String,
      default: null,
    },
  },
}
</script>
