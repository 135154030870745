<template>
  <FormGroup>
    <FormLabel />
    <TypeAhead
      ref="typeAhead"
      class="tag-input m-0 p-0"
      v-bind="$props"
      :add-clear="false"
      @update:model-value="addTag"
    />
    <span
      v-for="(tag, index) in tags"
      :key="index"
      class="badge badge-primary text-white mt-2 mr-2 font-weight-normal font-sm"
    >
      {{ tag }}
      <btn
        class="ml-1 text-white line-height-1"
        iconic="x-thin font-xxs"
        @click="removeTag(index)"
      />
    </span>
  </FormGroup>
</template>
<script>
import TypeAhead from './TypeAhead.vue'
import FormMixins from '@/mixins/FormMixins'

export default {
  name: 'FormTagInput',
  extends: TypeAhead,
  mixins: [FormMixins],
  props: {
    initialTags: {
      type: Array,
      default: null,
    },
    separators: {
      type: Array,
      default: () => [' '],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    classes: {
      type: Object,
      default: () => ({
        wrapper: false,
        input: false,
        list: 'pl-2 flex-tags',
        item: false,
      }),
    },
  },
  emits: ['tag-removed', 'tag-added', 'update:modelValue'],
  data() {
    return {
      tags: [],
    }
  },
  beforeMount() {
    if (this.initialTags) {
      this.tags = this.initialTags
    }
  },
  methods: {
    addTag(tag) {
      if (this.disabled || !tag) return

      if (!this.tags.includes(tag)) {
        this.tags.push(tag)
        this.$emit('tag-added', tag)
        this.$emit('update:modelValue', this.tags)
      } else {
        this.$toast.info(
          `This tag already exists<br><span class="font-sm font-weight-light text-nowrap">Tag entered: ${tag}</span>`
        ) //3
      }

      this.$nextTick(() => this.$refs.typeAhead.clearInput())

      this.$refs.clearInput
    },
    removeTag(index) {
      this.tags.splice(index, 1)
      this.$emit('tag-removed', this.tags)
      this.$emit('update:modelValue', this.tags)
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
:deep(.autocomplete-wrapper) {
  button.open {
    & ~ .dropdown-menu-list {
      display: flex;
    }
  }
  .dropdown-item {
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}

:deep(.flex-tags) {
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 0 1 140px;
  }
}
</style>
