<template>
  <div
    class="form-group"
    :class="[{ 'is-invalid': invalid }, { 'was-validated': wasValidated }]"
  >
    <btn
      v-if="help"
      v-tippy
      class="float-right"
      :content="help"
      @click.prevent="() => null"
    >
      (help)
    </btn>
    <slot />
    <div
      v-if="wasValidated || (invalid && error && !hideErrorText)"
      class="form-control-feedback invalid-feedback"
      v-text="error || hasInvalidMessage || defaultRequiredMessage"
    />
  </div>
</template>
<script>
export default {
  name: 'FormGroup',
  data() {
    return {
      wasValidated: false,
    }
  },
  computed: {
    invalid() {
      return this.$parent.hasError || !this.$parent.isValid
    },
    help() {
      return this.$parent.help
    },
    error() {
      return this.$parent.error
    },
    hideErrorText() {
      return this.$parent.hideErrorText
    },
    name() {
      return this.$parent.name
    },
    label() {
      return this.$parent.label
    },
    labelClass() {
      return this.$parent.labelClass
    },
    $element() {
      return this.$parent.$refs.element
    },
    hasInvalidMessage() {
      return this.$parent.invalidMessage
    },
    isRequired() {
      return this.$parent.required
    },
    defaultRequiredMessage() {
      return `The ${this.lodash.get(this.$parent, 'name', '').split('_').join(' ')} field is required.`
    },
    registerValidation() {
      return this.$element && !!(this.isRequired || this.hasInvalidMessage)
    },
  },
  mounted: function () {
    if (this.registerValidation) {
      this.$element.addEventListener('invalid', this.validate)
      this.$element.addEventListener('blur', this.removeValidity)
      this.$element.addEventListener('change', this.removeValidity)
      this.$element.addEventListener('keyup', this.removeValidity)
    }
  },
  unmounted: function () {
    if (this.registerValidation) {
      this.$element.removeEventListener('invalid', this.validate)
      this.$element.removeEventListener('blur', this.removeValidity)
      this.$element.removeEventListener('change', this.removeValidity)
      this.$element.removeEventListener('keyup', this.removeValidity)
    }
  },
  methods: {
    validate() {
      this.wasValidated = true
      this.$parent.isValid = false
      this.$element.scrollIntoView(false)
      this.$element.setCustomValidity(this.hasInvalidMessage || this.defaultRequiredMessage)
    },
    removeValidity(e) {
      this.$parent.isValid = true
      if (e.type === 'keyup' && (!e.target.value || e.target.type === 'email')) {
        return
      }
      this.$element.setCustomValidity('')
      if (!this.lodash.get(this.$element, 'validity.valid', false)) {
        this.$element.checkValidity()
      }
    },
  },
}
</script>
