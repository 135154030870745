<template>
  <li
    class="nav-item"
    :class="{ 'd-none': !showLink }"
  >
    <router-link
      :to="{ name: 'company.setup' }"
      class="nav-link"
    >
      Setup
    </router-link>
  </li>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      showLink: false,
    }
  },
  computed: {
    ...mapState('company', {
      assessmentPeriod: 'assessment_period',
      assessmentsLoading: 'assessmentsLoading',
      companyLoading: 'loading',
      organization: 'organization',
    }),
    ...mapState('app', {
      appLoading: 'loading',
    }),
    isReady() {
      return !this.companyLoading && !this.assessmentsLoading
    },
    hasMissingRiskProfiles() {
      return !!this.lodash
        .get(this.organization, 'protocols', [])
        .filter((framework) => !this.lodash.get(framework, 'risk_profiles', []).length).length
    },
    hasEntities() {
      return this.lodash.get(this.organization, 'entities_count', 0) > 0
    },
    hasFrameworks() {
      return this.lodash.get(this.organization, 'protocols_count', 0) > 0
    },
  },
  watch: {
    isReady() {
      if (
        this.isReady &&
        (!this.hasEntities || !this.assessmentPeriod || !this.hasFrameworks || this.hasMissingRiskProfiles)
      ) {
        this.showLink = true
      } else {
        this.showLink = !this.isReady && this.showLink ? true : false
      }
    },
  },
  beforeMount() {
    if (!this.appLoading && !this.assessmentPeriod && !this.companyLoading) {
      this.loadCompany()
    }
  },
  methods: {
    ...mapActions({
      loadCompany: 'company/load',
    }),
  },
}
</script>
