export default {
  questionAssignments: (state) => (questionId) => {
    return state.assignments[questionId] || []
  },
  questionCollaborations: (state) => (questionId) => {
    return state.collaborations[questionId] || []
  },
  overdue(state, getters, rootState, rootGetters) {
    return state.questions.reduce((sum, question) => {
      sum = sum + question.overdue_count
      if (rootGetters['company/hasFeature']('collaboration')) {
        sum = sum + question.overdue_collaboration_count
      }
      return sum
    }, 0)
  },
}
