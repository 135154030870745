<template>
  <div class="d-flex flex-column">
    <div
      class="flex-shrink-1"
      :class="searchClass"
    >
      <slot name="search" />
    </div>
    <div
      class="flex-grow-1 scroll-y"
      :class="{ loading: loading }"
    >
      <div
        v-if="hasError && !loading"
        class="text-red font-weight-bold text-center mt-3"
      >
        {{ error }}
      </div>
      <div
        v-if="noResults && !loading"
        class="p-3"
      >
        No matches found
      </div>
      <slot name="list" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    error: {
      type: String,
      default: '',
    },
    searchClass: {
      type: String,
      default: '',
    },
    noResults: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasError() {
      return !!this.error.length
    },
  },
}
</script>
