<template>
  <btn
    v-tippy="{ trigger: 'click' }"
    class="ml-2"
    :iconic="tags.length > 1 ? `tags` : `tag`"
    :disabled="!tags.length"
    :class="{ 'text-primary': tags.length }"
    :content="tagList"
  />
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tagList() {
      return this.tags.length ? `Tags: ${this.tags.map((tag) => this.lodash.get(tag, 'name', tag)).join(', ')}` : null
    },
  },
}
</script>
