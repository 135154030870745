<template>
  <div class="row justify-content-center flex-grow-1">
    <slot name="header" />
    <slot />
  </div>
</template>

<script>
export default {
  beforeMount() {
    this.$parent.isActive = true
  },
  async activated() {
    this.$parent.isActive = true
  },
  async deactivated() {
    this.$parent.isActive = false
  },
}
</script>

<style
  lang="scss"
  scoped
>
@import '@/assets/scss/_opacity';
@import '@/assets/scss/_scroll';
.submenu-open {
  :deep(.sidebar),
  :deep(.page-content) {
    pointer-events: none;
    @extend .opacity-2;
    @extend .no-scroll;
  }
}
</style>
