const defaultState = () => ({
  loading: false,
  frameworks: [],
  frameworkQuestions: {},
})

const excludeFields = ['loading']

export { defaultState, excludeFields }

export default defaultState
