<template>
  <div class="toast-inner-content text-nowrap">
    A new version of WaveFire will be installed in {{ timeleft }} seconds
    <btn
      class="btn-xs align-baseline btn-light ml-2"
      :link="false"
      @click="refresh"
    >
      Refresh Now
    </btn>
  </div>
</template>

<script>
import { ServiceWorker } from '@/plugins/serviceworker'
import { mapActions } from 'vuex'

export default {
  emits: ['close-toast'],
  data() {
    return {
      timeleft: 99,
      refreshTimer: null,
    }
  },
  mounted() {
    this.refreshTimer = setInterval(() => {
      this.timeleft -= 1
      if (this.timeleft < 1) {
        this.refresh()
      }
    }, 1000)
  },
  methods: {
    ...mapActions({
      appLoading: 'app/loading',
      appLoaded: 'app/loaded',
    }),
    refresh() {
      clearInterval(this.refreshTimer)
      const { updateServiceWorker } = ServiceWorker
      this.appLoading()
      this.$emit('close-toast')
      setTimeout(() => {
        updateServiceWorker().then(() => {
          this.appLoaded()
        })
      }, 1000)
    },
  },
}
</script>
