<template>
  <div class="card font-xs border-0 m-0">
    <div class="card-body">
      <div
        ref="commentBox"
        class="scroll-y comments d-flex flex-nowrap align-items-end flex-column-reverse"
      >
        <div
          v-for="(message, index) in sortedMessages"
          :key="index"
          class="comment d-flex flex-column"
          :class="message.created_by === userId ? 'sent' : 'received'"
        >
          <div class="date">
            {{ message.author.full_name }} on
            {{ $filters.toDateTime(message.created_at) }}
          </div>
          <div class="bubble">
            {{ message.message }}
          </div>
        </div>
        <span
          v-if="!loading && !messages.length"
          class="align-self-center"
        >
          {{ defaultMessage }}
        </span>
      </div>
    </div>
    <div class="card-footer">
      <BaseForm
        :form="form"
        class="d-flex"
        @submit-form.prevent="submitMessage"
      >
        <FormInput
          v-model="newMessage"
          name="message"
          placeholder="Question..."
          class="flex-fill mr-25 mb-0"
          :elclass="`form-control form-control-sm w-100 ${loading ? 'disabled' : ''}`"
          :label="false"
        />
        <FormSubmit
          class="font-sm mb-0"
          :elclass="`${loading || !form.message || !form.message.length ? 'disabled' : ''}`"
          :link="true"
          :inline="true"
          size="sm"
          text="Send"
        />
      </BaseForm>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    form: {
      type: Object,
      required: true,
    },
    defaultMessage: {
      type: String,
      default: 'Have a question? Ask your team!',
    },
  },
  emits: ['update-message', 'send-message'],
  data() {
    return {
      newMessage: null,
    }
  },
  computed: {
    ...mapGetters({
      userId: 'auth/getUserId',
    }),
    sortedMessages() {
      return this.lodash.orderBy(this.messages, 'created_at', 'desc')
    },
  },
  watch: {
    messages() {
      this.scrollMessages()
    },
    newMessage(current) {
      this.$emit('update-message', current)
    },
  },
  mounted() {
    if (this.$refs.commentBox) {
      this.$nextTick(() => {
        this.scrollMessages()
      })
    }
  },
  methods: {
    scrollMessages() {
      this.$refs.commentBox.scrollTop = this.$refs.commentBox.scrollHeight
    },
    submitMessage() {
      if (!this.loading && this.form.message && this.form.message.trim().length) {
        this.$emit('send-message', true)
        this.$forceNextTick(() => {
          this.newMessage = null
        })
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.comments {
  min-height: 200px;
  max-height: 300px;
}
.comment {
  width: 100%;
  margin-top: 0.625rem;
  .date {
    text-transform: uppercase;
    color: $secondary;
    max-width: 88%;
    font-size: 0.5rem;
    padding: 0.125rem;
  }
  .bubble {
    position: relative;
    padding: 0.25rem 0.5rem;
    max-width: 88%;
    min-width: auto;
    border-radius: 0.25rem;
    &:after {
      content: '';
      position: absolute;
      border: 0.5rem solid transparent;
      top: 100%;
      width: 0;
      height: 0;
    }
  }
  &.sent {
    .date {
      margin-left: auto;
      text-align: right;
      padding-right: 0.5rem;
    }
    .bubble {
      margin-left: auto;
      margin-right: 0.5rem;
      color: $white;
      background-color: $bright;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        border-left-color: $bright;
        border-right: 0;
        border-bottom: 0;
        margin-top: -0.75rem;
        margin-right: -0.5rem;
      }
    }
  }
  &.received {
    .date {
      padding-left: 0.5rem;
    }
    .bubble {
      margin-right: auto;
      margin-left: 0.5rem;
      background-color: $light-gray;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        border-right-color: $light-gray;
        border-left: 0;
        border-bottom: 0;
        margin-top: -0.75rem;
        margin-left: -0.5rem;
      }
    }
  }
}
</style>
