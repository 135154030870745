<template>
  <div>
    <row
      class="border-top border-light-gray no-gutters"
      col-class="text-center font-xs py-2 px-3"
    >
      Assessment Period:
      <span class="font-weight-light mr-1">
        {{ answer.assessment_period.name }}
        {{
          $filters.toDate(
            answer.assessment_period.end_date ||
              answer.assessment_period.start_date ||
              answer.assessment_period.created_at
          )
        }}
      </span>
    </row>
    <row
      class="border-top border-bottom border-light-gray no-gutters"
      col-class="d-flex align-items-center font-xs py-2 px-3"
    >
      <h6 class="mb-0">
        <WithRoot :show="!!entity.deleted_at">
          <del class="text-error">{{ entity.name }}</del>
        </WithRoot>
      </h6>
      <span class="font-xs ml-auto">
        {{ isAdmin && questionType === 'collaboration' ? 'Collaborative response' : 'Answered' }}
        by:
        <UserEmail
          :user="answeredBy"
        />
        <template
          v-if="isInterview"
        >
          <span
            class="mx-1"
          >
            |
          </span>
          <span
            class="font-weight-light"
          >
            Interviewed by:
          </span>
          <UserEmail
            class="font-weight-light ml-1"
            :user="answer.interviewer"
          />
        </template>
        on {{ $filters.toDateTime(answer.updated_at) }}
      </span>
      <AnswerFilesBtn
        :link="false"
        :answer="answer"
        class="btn-xxs px-1 py-1 btn-light-gray ml-2"
      />
    </row>
    <row
      class="no-gutters"
      :col-class="configuration.allowUploads ? 'pb-4' : 'py-4'"
    >
      <ul
        v-if="configuration.allowUploads"
        class="nav nav-tabs mb-4"
      >
        <li class="nav-item">
          <btn
            class="nav-link btn-sm py-2 px-3 rounded-0 border-top-0"
            :link="false"
            :class="{ active: tab === 'answer' }"
            @click.prevent="tab = 'answer'"
          >
            Question Answer
          </btn>
        </li>
        <li class="nav-item">
          <btn
            class="nav-link btn-sm py-2 px-3 rounded-0 border-top-0"
            :link="false"
            :class="{ active: tab === 'files' }"
            :badge-inline="true"
            :count="answer.files_count"
            @click.prevent="tab = 'files'"
          >
            Upload {{ !!answer.files_count ? `Additional` : `` }} Files
          </btn>
        </li>
      </ul>
      <FormRadioSelect
        v-model="questionOptionId"
        name="question_option_id"
        label="option"
        class="mb-4 mx-4"
        :class="{ 'd-none': tab !== 'answer' }"
        option-value-label="option"
        option-value-key="id"
        :options="options"
        :groupvalue="questionOptionId"
      />
      <FormTextarea
        v-model="control"
        name="control"
        class="mb-4 mx-4 font-italic font-xs"
        :class="formTextAreaStyles()"
        elclass="font-sm"
        :label="configuration.controlText || terms.controlText"
      />
      <ToggleCheck
        v-if="!isControlExemptQuestion()"
        v-model:checked="isHidden"
        class="ml-4"
        :class="{ 'd-none': hideControl || tab !== 'answer' }"
        :small="true"
        :no-label="true"
        :text="configuration.noControlText || terms.noControlText"
      />
      <AnswerFiles
        v-if="configuration.allowUploads"
        class="text-center mx-4"
        :class="{ 'd-none': tab !== 'files' }"
        :configuration="configuration"
        :terms="terms"
      >
        <AnswerFilesBtn
          v-if="answer.files_count"
          :link="false"
          :answer="answer"
          :button-text="`View ${answer.files_count} exisiting ${$filters.pluralize('file', answer.files_count)}`"
          class="btn-light-gray mb-4 w-50"
        />
        <div
          v-else
          class="font-italic mb-4 text-secondary line-height-1"
        >
          This answer does not have any existing files
        </div>
        <slot />
      </AnswerFiles>
    </row>
  </div>
</template>

<script>
import AnswerFiles from '@/pages/questions/components/AnswerFiles.vue'
import AnswerFilesBtn from '@/components/buttons/AnswerFilesBtn.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    AnswerFiles,
    AnswerFilesBtn,
  },
  props: {
    answer: {
      type: Object,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    questionType: {
      type: String,
      required: true,
    },
    configuration: {
      type: Object,
      required: true,
    },
    hideControl: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggle-hide-control', 'update-option', 'update-control'],
  data() {
    return {
      isHidden: false,
      questionOptionId: this.answer.question_option_id,
      control: this.answer.control,
      tab: 'answer',
      files: {
        answers: [],
        uploading: false,
        uploadProgress: 0,
      },
    }
  },
  computed: {
    ...mapState('app', {
      terms: 'terms',
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      hasFeature: 'company/hasFeature',
    }),
    isInterview() {
      return this.hasFeature('interviews') && !!this.answer.interviewee && !!this.answer.interviewer
    },
    answeredBy() {
      return this.isInterview
        ? this.answer.interviewee
        : this.answer.answered_by
    }
  },
  watch: {
    hideControl(current) {
      if (current !== this.isHidden) {
        this.isHidden = current
      }
    },
    isHidden(current) {
      this.$emit('toggle-hide-control', current)
    },
    questionOptionId(current) {
      this.$emit('update-option', current)
    },
    control(current) {
      this.$emit('update-control', current)
    },
  },

  methods: {

    isControlExemptQuestion() {
      // Quorum
      if (this.answer.question_id === 2813 || this.answer.protocol_id === 24 || this.answer.protocol_id === 35) {
        return true;
      }

      return false;
    },

    formTextAreaStyles() {

      if (this.isControlExemptQuestion()) {
        return {}
      }

      return {
        'd-none': this.hideControl || this.tab !== 'answer'
      }

    }


  },


}
</script>
