<template>
  <span
    class="iconic"
    :class="iconicClass"
    :style="getOffset"
    aria-hidden="true"
  />
</template>
<script>
export default {
  props: {
    iconic: {
      type: String,
      required: true,
    },
    iconOffset: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    iconicClass() {
      return `iconic-${this.iconic}`
    },
    getOffset() {
      return this.iconOffset ? `top: ${this.iconOffset}px` : null
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
span {
  &:focus {
    outline: none;
  }
}
</style>
