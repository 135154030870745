<template>
  <span class="font-xs ml-auto">
    <icon
      v-if="showIcon"
      :iconic="`font-sm ${responseTypeIcon}`"
      class="text-bright mr-1"
    />
    {{ responseTypeText }} by:
    <UserEmail
      class="font-weight-light ml-1"
      :user="answeredBy"
    />
    <template
      v-if="isInterview"
    >
      <span
        class="mx-1"
      >
        |
      </span>
      <span
        class="font-weight-light"
      >
        Interviewed by:
      </span>
      <UserEmail
        class="font-weight-light ml-1"
        :user="answer.interviewer"
      />
    </template>
    <span class="font-weight-light ml-1">
      on {{ $filters.toDateTime(answer.updated_at) }}
    </span>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    answer: {
      type: Object,
      required: true,
    },
    answertType: {
      type: String,
      default: 'answer'
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isEmployee: 'auth/isEmployee',
      hasFeature: 'company/hasFeature',
    }),
    isInterview() {
      return this.hasFeature('interviews') && !!this.answer.interviewee && !!this.answer.interviewer
    },
    showIcon() {
      return !!this.responseTypeIcon
    },
    responseTypeIcon() {
      if (this.isAdmin && this.isInterview) {
        return 'clipboard'
      }
      if (!this.isEmployee && this.answertType === 'collaboration') {
        return 'people'
      }
    },
    responseTypeText() {
      if (!this.isEmployee && this.answertType === 'collaboration') {
        return 'Collaborative response'
      }
      return 'Answered'
    },
    answeredBy() {
      return this.isInterview
        ? this.answer.interviewee
        : this.answer.answered_by
    }
  }
}
</script>
