import { defaultState } from './state'

import {
  SET,
  PERIODSLOADING,
  ARCHIVEPERIODSLOADING,
  LOADPERIODS,
  LOADARCHIVEPERIODS,
  ARCHIVING,
  UNARCHIVING,
  DELETING,
  RESTORING,
  RESET,
} from './mutation-types'

export default {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },
  [PERIODSLOADING](state, bool) {
    state.loading = bool
  },
  [ARCHIVEPERIODSLOADING](state, bool) {
    state.loadingArchived = bool
  },
  [LOADPERIODS](state, PERIODs) {
    state.PERIODs = PERIODs || []
  },
  [LOADARCHIVEPERIODS](state, PERIODs) {
    state.archivedPERIODs = PERIODs || []
  },
  [ARCHIVING](state, id) {
    state.archiving = id || null
  },
  [UNARCHIVING](state, id) {
    state.unarchiving = id || null
  },
  [DELETING](state, id) {
    state.deleting = id || null
  },
  [RESTORING](state, id) {
    state.restoring = id || null
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
