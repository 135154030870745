<template>
  <btn
    v-if="hasFeature('acceptance') && answer && (isAdmin || entity.manager_id === getUserId || answer.accepted)"
    v-tippy="{ onShow: () => !!getContent }"
    :content="getContent"
    :iconic="shortText ? null : (answer.accepted ? `circle-check mr-1` : `circle-x mr-1`)"
    :class="[btnClass, { 'btn-spinner-overlay loading-xxs': saving }]"
    :disabled="!(isAdmin || entity.manager_id === getUserId)"
    :link="false"
    @click.prevent="toggleAcceptence()"
  >
    {{ btnText }}
  </btn>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    answer: {
      type: Object,
      default() {
        return {}
      },
    },
    entity: {
      type: Object,
      default() {
        return {}
      },
    },
    shortText: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['answer-accepted'],
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      hasFeature: 'company/hasFeature',
      getUserId: 'auth/getUserId',
    }),
    fullName() {
      return this.lodash.get(this.answer, 'acceptor.full_name', '')
    },
    getContent() {
      return this.answer.accepted && this.fullName
        ? `Accepted by ${this.fullName} on ${this.$filters.toDateTime(this.answer.accepted_at)}`
        : null
    },
    btnClass() {
      return this.answer.accepted
        ? this.shortText
          ? `text-success-dark`
          : `btn-success`
        : this.shortText
          ? `text-error`
          : `btn-light-gray`
    },
    btnText() {
      return this.answer.accepted
        ? this.shortText
          ? `Yes`
          : `Accepted`
        : this.shortText
          ? `No`
          : `Needs Acceptence`

    }
  },
  methods: {
    toggleAcceptence() {
      this.saving = true
      this.$http
        .post(
          `/entities/${this.answer.entity_id}/questions/${this.answer.question_id}/answers/${this.answer.id}/acceptance`,
          {
            accepted: !this.answer.accepted,
          }
        )
        .then((response) => {
          this.$emit('answer-accepted', response.data)
          this.saving = null
        })
        .catch((error) => {
          this.$toast.error(error)
          this.saving = null
        })
    },
  },
}
</script>
