import { defaultState } from './state'

import { LOAD, LOADING, LOADQUESTIONS, RESET } from './mutation-types'

export default {
  [LOADING](state, bool) {
    state.loading = bool
  },
  [LOAD](state, frameworks) {
    state.frameworks = frameworks || []
  },
  [LOADQUESTIONS](state, { frameworkId, questions }) {
    state.frameworkQuestions[frameworkId] = questions || []
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
