<template>
  <modal
    :class="{ saving: submitting, loading: loading }"
    :width="1080"
    :body-class="`p-0 ${results ? 'height-max' : ''}`"
    @close-modal="$emit('close')"
  >
    <template #header>
      <h5>Question Answer <span class="font-weight-light">| Remediation</span></h5>
    </template>
    <template #body>
      <div
        v-if="!results"
        class="row no-gutters"
      >
        <div class="col p-3 font-sm">
          <BaseForm
            ref="taskForm"
            :form="form"
            :url="`/action-plans/${planId}/tasks/mass-assignment`"
            method="post"
            @submitting="beforeSumbit"
            @success="onSuccess"
            @error="submitting = false"
          >
            <btn
              v-if="!newPlan"
              class="btn btn-xs position-absolute"
              style="top: 1.375rem; right: 1.25rem"
              :disabled="loading"
              @click.prevent="showCreateActionPlan = true"
            >
              Create Action Plan
            </btn>
            <FormTypeAhead
              ref="plan"
              v-model="planId"
              anchor="name"
              name="name"
              label="Active Plans (required)"
              value-key="id"
              placeholder="Search by Action Plan name"
              :disabled="!plans.length"
              :init-value="planName"
              :options="plans"
              :filter-by-anchor="true"
              :dropdown="true"
              :inline="true"
              :max-height="200"
            />
            <FormTextarea
              v-model="description"
              name="description"
              label="Description (required)"
            />
            <FormDate
              v-model="due_date"
              name="due_date"
              label="Due Date (required)"
              class="mb-4"
              :min="$moment().format('YYYY-MM-DD')"
            />
            <div class="border-left border-info px-3 py-2 bg-white font-italic">
              <h5 class="font-sm mb-2 font-weight-bold">
                Review the {{ questions.length }} {{ $filters.pluralize('Question', questions.length) }} on the right.
              </h5>
              All Action Plan tasks will be assigned to the person who answered the question for the selected action
              plan above.
            </div>
          </BaseForm>
        </div>
        <div class="col-8 border-left">
          <AnswersSlider
            v-model="activeSlide"
            :question="activeQuestion"
            :answers="answers"
            :loading="loading"
            :hide-actions="true"
          />
        </div>
      </div>
      <div
        v-else
        class="d-flex flex-column font-xs h-100"
      >
        <div class="row no-gutters border-bottom flex-shrink-1">
          <div class="col-12 py-1 text-center font-weight-bold border-bottom">Mass Assignment Report</div>
          <div class="col py-1 text-center font-weight-bold">
            Assigned {{ assignedQuestions.length }}
            {{ $filters.pluralize('Question', questions.length) }}
          </div>
          <div class="col py-1 border-left border-right text-center font-weight-bold">
            {{ notAssignedQuestions.length }}
            {{ $filters.pluralize('Question', questions.length) }} had errors and may not have been assigned
          </div>
          <div class="col py-1 text-center font-weight-bold">Action Plan Updates</div>
        </div>
        <div class="row no-gutters flex-grow-1 no-scroll">
          <div class="col p-3 scroll-y h-100">
            <div
              v-for="(task, index) in assignedQuestions"
              :key="index"
              class="border-left border-success-dark px-3 py-2 bg-white font-xs"
              :class="{ 'mb-3': index + 1 < assignedQuestions.length }"
            >
              Task assigned to {{ lodash.get(task, 'user.full_name') }} for:
              <div
                class="font-italic font-xxs my-2"
                v-html="lodash.get(task, 'answer.question.question')"
              />
            </div>
          </div>
          <div class="col p-3 border-left border-right scroll-y h-100">
            <div
              v-for="(error, index) in notAssignedQuestions"
              :key="index"
              class="border-left border-error px-3 py-2 bg-white font-xs"
              :class="{ 'mb-3': index + 1 < notAssignedQuestions.length }"
            >
              Error while assigning task to
              {{ lodash.get(error, 'answer.answered_by.full_name') }} for:
              <div
                class="font-italic font-xxs my-2"
                v-html="lodash.get(error, 'answer.question.question')"
              />
              Error: {{ lodash.get(error, 'error') }}
            </div>
          </div>
          <div
            v-if="missingItems.length"
            class="col p-3 scroll-y h-100"
          >
            <p>
              The action plan
              <strong>
                <i>{{ selectedPlan.name }}</i>
              </strong>
              is now tracking the following threats for
              <strong>
                <i>{{ entity.name }}</i> </strong
              >:
            </p>
            <ul class="font-xs">
              <li
                v-for="(item, index) in missingItems"
                :key="index"
              >
                {{
                  lodash.get(
                    threats.find((t) => t.id === item.threat_id),
                    'name',
                    'N/A'
                  )
                }}
              </li>
            </ul>
          </div>
          <div
            v-else
            class="col p-3 scroll-y h-100"
          >
            No Action Plan Updates Needed
          </div>
        </div>
        <row
          class="no-gutters border-top flex-shrink-1"
          col-class="py-2 text-center font-sm"
        >
          <btn
            iconic="share-boxed"
            @click="
              $router.push({
                name: 'action-plans.view',
                params: { planId: planId },
              })
            "
          >
            Go to Action Plan: {{ selectedPlan.name }}
          </btn>
        </row>
      </div>
    </template>
    <template
      v-if="!results && !loading"
      #footer
    >
      <FormSubmit
        size="sm"
        :disabled="!ready"
        :text="
          ready
            ? `Create ${answers.length} New ${$filters.pluralize('Task', answers.length)}`
            : `Complete the form to continue`
        "
        :submit-function="() => $refs.taskForm.onSubmit()"
      />
    </template>
    <template #modal>
      <LinkAnswerCreateActionPlanModal
        v-if="showCreateActionPlan"
        :items="items"
        @added="planAdded"
        @close="showCreateActionPlan = false"
      />
    </template>
  </modal>
</template>
<script>
import LinkAnswerCreateActionPlanModal from '@/components/modals/LinkAnswerCreateActionPlanModal.vue'
import AnswersSlider from '@/components/reviewanswer/AnswersSlider.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    AnswersSlider,
    LinkAnswerCreateActionPlanModal,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
  },
  emits: ['close', 'added-to-plan'],
  data() {
    return {
      activeSlide: 0,
      submitting: false,
      searchUsers: null,
      planId: null,
      newPlan: null,
      description: 'Please update your answer to the assigned question.',
      showCreateActionPlan: false,
      due_date: null,
      results: null,
      planUpdates: null,
      form: this.$form({
        tasks: [],
      }),
    }
  },
  computed: {
    ...mapState('plans', {
      allPlans: 'plans',
      plansLoading: 'loading',
    }),
    loading() {
      return !!this.plans.length ? false : this.plansLoading
    },
    assignedQuestions() {
      return this.lodash.get(this.results, 'assigned', [])
    },
    notAssignedQuestions() {
      return this.lodash.get(this.results, 'notAssigned', []).map((item) => ({
        error: item.error,
        answer: this.answers.find((a) => a.id === item.task.answer_id),
      }))
    },
    plans() {
      let plans = this.lodash.get(this.entity, 'action_plans', []).length
        ? this.entity.action_plans.filter((plan) => !plan.completed)
        : this.allPlans.filter(
            // (plan) => !plan.completed && plan.items.find((item) => item.entity_id === this.entity.id)
            (plan) => !plan.completed
          )

      if (this.newPlan) {
        plans.push(this.newPlan)
      }

      return this.lodash.uniqBy(plans, 'id')
    },
    planName() {
      return this.lodash.get(this.newPlan, 'name')
    },
    activeAnswer() {
      return this.answers[this.activeSlide]
    },
    activeQuestion() {
      return this.answers.length ? this.answers[this.activeSlide].question : null
    },
    questions() {
      return this.answers.map((a) => a.question)
    },
    threats() {
      return this.questions
        .map((q) => q.threat)
        .reduce((all, threat) => {
          if (!all.find((i) => i.id === threat.id)) {
            all.push(threat)
          }
          return all
        }, [])
    },
    items() {
      return this.threats.map((t) => ({
        threat_id: t.id,
        entity_id: this.entity.id,
      }))
    },
    selectedPlan() {
      return this.plans.find((plan) => plan.id === this.planId)
    },
    selectedPlanItems() {
      return this.lodash.get(this.selectedPlan, 'items', []).map((item) => ({
        threat_id: item.threat_id,
        entity_id: item.entity_id,
      }))
    },
    missingItems() {
      return this.items.filter(
        (item) => !this.selectedPlanItems.find((i) => i.entity_id === item.entity_id && i.threat_id === item.threat_id)
      )
    },
    ready() {
      return !!this.planId && !!this.due_date && !!this.description.length
    },
  },
  beforeMount() {
    if (!this.lodash.get(this.entity, 'action_plans', []).length) {
      this.loadActionPlans()
    }
  },
  methods: {
    ...mapActions({
      loadActionPlans: 'plans/load',
    }),
    planAdded(plan) {
      this.loadActionPlans()
      this.newPlan = plan
      this.planId = plan.id
      this.showCreateActionPlan = false
    },
    beforeSumbit() {
      this.submitting = true
      this.form.tasks = this.answers.map((answer) => ({
        due_date: this.due_date,
        description: `${this.description} (ID: ${answer.question.tempid}/${answer.id})`,
        user_id: answer.answered_by.id,
        entity_id: this.entity.id,
        state: 'todo',
        answer_id: answer.id,
      }))
    },
    onSuccess(response) {
      this.$emit('added-to-plan', response.data)
      this.results = response.data
      if (this.missingItems.length) {
        this.$http
          .put(`/action-plans/${this.planId}/items`, {
            items: [...this.selectedPlanItems, ...this.missingItems],
          })
          .then((response) => {
            this.planUpdates = response.data
            this.submitting = false
          })
          .catch(() => {
            this.planUpdates = this.lodash.get(
              error,
              'response.data.error',
              this.lodash.get(error, 'response.data.message', 'Unknown error occurred.')
            )
            this.submitting = false
          })
      } else {
        this.submitting = false
      }
    },
  },
}
</script>
