import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import Axios from '@/plugins/axios'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  set({ commit }, data) {
    commit(types.SET, data)
  },

  async load({ commit }, trashed) {
    await commit(types.LOADING, true)
    await Pagination(`/action-plans`, trashed ? { 'filter[trashed]': 'with' } : null)
      .then((response) => {
        commit(types.LOAD, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  async loadArchivedPlans({ commit }) {
    await commit(types.LOADING, true)
    await Pagination(`/action-plans`, {
      'filter[only_archived]': 1,
    })
      .then((response) => {
        commit(types.ARCHIVED, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  async archivePlan(ctx, planId) {
    await Axios.post(`/action-plans/${planId}/archive`)
  },

  async unarchivePlan(ctx, planId) {
    await Axios.post(`/action-plans/${planId}/unarchive`)
  },

  put({ commit }, plan) {
    commit(types.PUT, plan)
  },

  async fetch({ commit }, planId) {
    await commit(types.LOADING, true)
    await Axios.get(`/action-plans/${planId}`)
      .then((response) => {
        commit(types.PUT, response.data)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  clear({ commit }) {
    commit(types.LOAD, [])
    commit(types.PUT, null)
  },

  refresh({ dispatch }) {
    dispatch('load')
  },
}
