import { get, orderBy } from 'lodash'
import store from '@/store'

export const getters = {
  getQuestions() {
    return orderBy(store.state.questions.questions, ['category_id', 'tempid'], ['asc', 'asc'])
  },

  overdueQuestions(frameworkId) {
    return store.state.questions.questions
      .filter((q) => (frameworkId ? q.protocol_id === frameworkId : q))
      .map((q) => q.overdue_count)
      .reduce((prev, next) => prev + next, 0)
  },

  overdueTopics(frameworkId) {
    return this.requiredTopics().filter(
      (topic) => (frameworkId ? topic.protocol_id === frameworkId : topic) && !!topic.overdue_count
    )
  },

  overdueCollaborationTopics(frameworkId) {
    return this.requiredTopics().filter(
      (topic) => (frameworkId ? topic.protocol_id === frameworkId : topic) && !!topic.overdue_collaboration_count
    )
  },

  overdueCombinedTopics(frameworkId) {
    return this.requiredTopics().filter(
      (topic) =>
        (frameworkId ? topic.protocol_id === frameworkId : topic) &&
        !!topic.overdue_count &&
        !!topic.overdue_collaboration_count
    )
  },

  getSelectedOption(question, optionId) {
    let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    return alphabet.substr(this.getSelectedOptionPosition(question, optionId) - 1, 1) || '-'
  },

  getSelectedOptionPosition(question, optionId) {
    return (
      ((get(question, 'options', this.getQuestion(question).options) || []).find((o) => o.id === optionId) || {})
        .display_order || 0
    )
  },

  getOption(question, optionId) {
    return (get(question, 'options', this.getQuestion(question).options) || []).find((o) => o.id === optionId)
  },

  getOptions(question) {
    return get(question, 'options', this.getQuestion(question).options) || []
  },

  getOptionText(questionId, optionId) {
    return this.getOption(questionId, optionId).option
  },

  getQuestion(questionId) {
    return Number.isInteger(questionId)
      ? (store.state.questions.questions || []).find((q) => q.id === questionId) || {}
      : questionId || {}
  },

  getTopic(questionId) {
    let question = store.state.questions.questions.find((q) => q.id === questionId)
    if (question && question.parent_question_id) {
      return this.getTopic(question.parent_question_id)
    } else {
      return question
    }
  },

  getParent(questionId) {
    let question = store.state.questions.questions.find((q) => q.id === questionId)
    if (question && question.parent_question_id) {
      return store.state.questions.questions.find((q) => q.id === question.parent_question_id)
    } else {
      return null
    }
  },

  requiredTopics(requiredType) {
    return this.topics().filter((topic) => {
      if (requiredType === 'Combined') {
        return !!topic.completion_meta.totalCombined
      } else if (requiredType === 'Collaborators') {
        return !!topic.completion_meta.totalCollaboration
      } else {
        return !!topic.completion_meta.total
      }
    })
  },

  requiredQuestions() {
    return store.state.questions.questions.filter((question) => this.howManyRequireAnswer(question.id))
  },

  requiredFrameworks() {
    return this.requiredTopics()
      .map((topic) => topic.protocol)
      .reduce((all, current) => {
        if (!all.find((protocol) => protocol.id === current.id)) {
          all.push(current)
        }
        return all
      }, [])
  },

  /**
   * Topics Helper.
   * Questions with no parent_question_id
   */

  topics() {
    return store.state.questions.questions.filter((q) => !q.parent_question_id)
  },

  getTopics() {
    return orderBy(this.requiredTopics(), ['category_id', 'tempid'], ['asc', 'asc'])
  },

  entityTopics(entityId, frameworkId) {
    return this.topics().filter(
      (topic) =>
        (frameworkId ? topic.protocol_id === frameworkId : topic) &&
        this.getEntityQuestionMeta(entityId).topics.includes(topic.id)
    )
  },

  topicsByCategories(categoryId) {
    return this.requiredTopics().filter((q) => q.category_id === categoryId)
  },

  topicsByThreat(threatId) {
    return this.requiredTopics().filter((q) => q.threat_id === threatId)
  },

  topicsByThreats(threatIds) {
    return this.requiredTopics().filter((q) => threatIds.includes(q.threat_id))
  },

  entityQuestionStatus(entityId, questionId) {
    if (this.entityExcludedQuestion(entityId, questionId)) {
      return 'Excluded'
    }
    if (this.needsAnswer(entityId, questionId)) {
      return 'Needs Answer'
    }
    return 'Answered'
  },

  questionFramework(questionId) {
    return store.state.questions.questions.length
      ? store.state.questions.questions.find((q) => q.id === questionId).protocol_id
      : null
  },

  getQuestionsByFramework(frameworkId) {
    return store.state.questions.questions.filter((q) => {
      return q.protocol_id === frameworkId
    })
  },

  questionsByThreats(threatIds) {
    let threats = typeof threatIds === 'array' ? threatIds : [threatIds]
    return store.state.questions.questions.slice(0).filter((q) => threats.includes(q.threat_id))
  },

  questionsByCategory(categoryId) {
    return store.state.questions.questions.filter((q) => q.category_id === categoryId)
  },

  threatsByCategory(categoryId) {
    return this.questionsByCategory(categoryId)
      .map((q) => q.threat)
      .reduce((all, threat) => {
        if (!all.find((t) => t.id === threat.id)) {
          all.push(threat)
        }
        return all
      }, [])
  },

  entityExcludedQuestion(entityId, questionId) {
    return this.getEntityQuestionMeta(entityId).excludedQuestions.includes(questionId)
  },

  entityExcludedQuestions(entityId) {
    return this.getEntityQuestionMeta(entityId).excludedQuestions
  },

  entitiesExcluded(questionId) {
    return this.getActiveEntities().filter((e) =>
      this.getEntityQuestionMeta(e.id).excludedQuestions.includes(questionId)
    )
  },
}

const counts = {
  getQuestionCount() {
    return store.state.questions.questions.length
  },
  getChildCount(topicId) {
    return store.state.questions.questions.find((q) => q.parent_question_id === topicId).length
  },
  getTopicsByCategoryCount(categoryId) {
    return this.requiredTopics().filter((q) => q.category_id === categoryId)
  },
}

const tests = {
  hasChild(topicId, questionId) {
    return !!store.state.questions.questions.find((q) => q.id === questionId && q.parent_question_id === topicId)
  },

  canEditQuestion(question) {
    if (
      store.getters['auth/isSysAdmin'] ||
      (store.getters['auth/isPrimaryAdmin'] && question.organization_id === store.getters['auth/getOrganizationId'])
    ) {
      return true
    } else {
      return false
    }
  },

  canEditAnswer(answer, entity) {
    if (entity && entity.is_current_assessment_locked) {
      return false
    }

    if (!entity && this.isEntityAssessmentLocked(answer.entity_id)) {
      return false
    }

    if (!store.getters['auth/isAdmin'] && answer.accepted) {
      return false
    }

    if (
      ![
        ...[store.getters['company/assessmentPeriodId']],
        ...store.getters['app/uniqueNotifications'].map((n) => n.assessment_period_id),
      ].includes(answer.assessment_period_id)
    ) {
      return false
    }

    return (store.getters['auth/isAdmin'] && answer.organization_id === store.getters['auth/getOrganizationId']) || (answer.created_by === store.getters['auth/getUserId'])
  },

  canReassign(questionIds) {
    if (Number.isInteger(questionIds)) {
      questionIds = [questionIds]
    }
    if (!Array.isArray(questionIds) || !questionIds.length) {
      return false
    } else if (store.getters['auth/isAdmin']) {
      return true
    } else if (store.getters['auth/isManager']) {
      return !!store.state.app.notifications.filter(
        (n) =>
          n.reassignable === 1 &&
          n.task_type === 'question' &&
          n.completed === 0 &&
          questionIds.includes(n.task_type_id)
      ).length
    } else {
      return !!store.state.app.notifications.filter(
        (n) => n.reassignable === 1 && n.task_type === 'question' && questionIds.includes(n.task_type_id)
      ).length
    }
  },

  anyReasignableByCategory(categoryId, questions) {
    let questionIds = (questions || store.state.questions.questions)
      .filter((q) => q.category_id === categoryId)
      .map((q) => q.id)

    return this.canReassign(questionIds)
  },
}

const QuestionsMethods = Object.assign(getters, counts, tests)

export default QuestionsMethods

export const QuestionMethods = {
  methods: QuestionsMethods,
}
