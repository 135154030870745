import EventBus from '@/plugins/event-bus'
// import { toast } from '@/plugins/toast'
import Sleep from '@/plugins/sleep'
import { axiosRequests } from '@/plugins/axios'

const onLogout = (store) => {
  store.subscribe((mutation) => {
    if (mutation.type === 'auth/LOGOUT') {
      axiosRequests.cancel()

      // if (!store.state.auth.forcedLogout) {
      //   toast.clear()
      // }
      EventBus.emit('on-logout')
      Sleep(1)
        .then(() => {
          store.commit('resetStore')
        })
        .then(() => {
          if (!store.state.auth.remember) {
            store.commit('resetRemembered')
          }
          if (console && typeof console.clear === 'function' && import.meta.env.PROD) {
            console.clear()
          }
        })
    }
  })
}

export default onLogout
