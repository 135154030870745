/* ============
 * Automatic Global Registration of Base Components
 * https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
 * ============
 */

import { upperFirst, camelCase } from 'lodash'

const requireComponent = import.meta.globEager('/src/components/base/**/*.(vue|js)')

export default {
  install(app) {
    Object.entries(requireComponent).forEach(([fileName, componentConfig]) => {
      // Get PascalCase name of component
      const componentName = upperFirst(
        camelCase(
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
      )

      // Register component globally
      app.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
      )
    })
  },
}
