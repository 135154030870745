<template>
  <div
    class="h-100 border-right background-light py-5 scroll-y sidebar d-flex flex-column"
    :class="[classes, col]"
    @scroll="handleScroll"
  >
    <div class="flex-grow-1">
      <h5 v-if="title">
        {{ title }}
      </h5>
      <slot name="filters" />
      <h6 v-if="actions">
        {{ actions }}
      </h6>
      <slot name="actions" />
      <slot name="body" />
    </div>
    <div class="flex-shrink-1">
      <h6 v-if="reports">
        {{ reports }}
      </h6>
      <slot name="reports" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    actions: {
      type: String,
      default: null,
    },
    reports: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: null,
    },
    col: {
      type: String,
      default: 'col-2',
    },
  },
  data() {
    return {
      scrollTop: 0,
    }
  },
  activated() {
    if (this.scrollTop) {
      this.$el.scrollTop = this.scrollTop
    }
  },
  methods: {
    handleScroll(e) {
      this.scrollTop = e.target.scrollTop
    },
  },
}
</script>
<style
  lang="scss"
  scoped
>
.sidebar {
  &.right {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 0.75rem;
    border-left: 1px solid $gray-300;
    border-right: none;
  }
  :deep(h5) {
    color: #153b50;
    margin-bottom: 1.75rem;
  }
  :deep(h6) {
    color: #a1b1b9;
    margin-top: 3rem;
    font-size: 0.625rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  }
  :deep(nav),
  :deep(.company-nav) .card-body {
    > a:not(.dropdown-item),
    > button.btn-link:not(.dropdown-item),
    > .json-to-csv button.btn-link:not(.dropdown-item) {
      &:not(.disabled) {
        color: #738996;
      }
      font-size: 0.875rem;
      padding: 0;
      margin-bottom: 0.875rem;
      text-align: left;
      border: none;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      &:hover {
        color: $bright;
        text-decoration: none;
      }
      &.router-link-exact-active {
        &:not(.disabled) {
          color: $gray-900;
        }
        &::before {
          content: '\25ba';
          margin-right: 6px;
        }
      }
    }
    &.actions,
    &.reports {
      > a,
      > button.btn-link,
      > .json-to-csv button.btn-link {
        &:not(.disabled) {
          color: #1976d2;
        }
        &:last-child {
          margin-bottom: 0;
        }
        span {
          font-size: 0.75rem;
          color: inherit;
          margin-right: 0.5rem;
        }
      }
    }
  }
  :deep(.card) {
    margin-bottom: 1rem;
    .card-body {
      &.comments {
        min-height: 100px;
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
    .card-footer {
      background: $gray-100;
      input.form-control {
        border-radius: 2px;
      }
    }
  }
}
</style>
