import { defaultState } from './state'

import {
  LOADTHREATRISKS,
  LOADTHREATENTITYRISKS,
  LOADCATEGORYRISKS,
  LOADCATEGORYENTITYRISKS,
  LOADCATEGORYTHREATRISKS,
  LOADCATEGORYTHREATENTITYRISKS,
  THREATRISKSLOADING,
  THREATENTITYRISKSLOADING,
  CATEGORYRISKSLOADING,
  CATEGORYENTITYRISKSLOADING,
  CATEGORYTHREATRISKSLOADING,
  CATEGORYTHREATENTITYRISKSLOADING,
  SET,
  RESET,
} from './mutation-types'

export default {
  [SET](state, data) {
    for (let field in data) {
      if (field in data && field in state) {
        state[field] = data[field]
      }
    }
  },

  [THREATRISKSLOADING](state, bool) {
    state.threatRisksloading = bool
  },

  [THREATENTITYRISKSLOADING](state, bool) {
    state.threatEntityRisksloading = bool
  },

  [CATEGORYRISKSLOADING](state, bool) {
    state.categoryRisksloading = bool
  },

  [CATEGORYENTITYRISKSLOADING](state, bool) {
    state.categoryEntityRisksloading = bool
  },

  [CATEGORYTHREATRISKSLOADING](state, bool) {
    state.categoryThreatRisksloading = bool
  },

  [CATEGORYTHREATENTITYRISKSLOADING](state, bool) {
    state.categoryThreatEntityRisksloading = bool
  },

  [LOADTHREATRISKS](state, risks) {
    state.threatRisks = risks || []
  },

  [LOADTHREATENTITYRISKS](state, risks) {
    state.threatEntityRisks = risks || []
  },

  [LOADCATEGORYRISKS](state, risks) {
    state.categoryRisks = risks || []
  },

  [LOADCATEGORYENTITYRISKS](state, risks) {
    state.categoryEntityRisks = risks || []
  },

  [LOADCATEGORYTHREATRISKS](state, risks) {
    state.categoryThreatRisks = risks || []
  },

  [LOADCATEGORYTHREATENTITYRISKS](state, risks) {
    state.categoryThreatEntityRisks = risks || []
  },
  [RESET](state) {
    Object.assign(state, defaultState())
  },
}
