<template>
  <div class="row metrics">
    <slot name="metrics">
      <div
        v-if="ready"
        class="card-deck flex-fill"
      >
        <slot name="first" />
        <div
          class="card-group"
          :class="{ 'flex-grow-1': groupflex }"
        >
          <slot name="second" />
          <slot name="third" />
        </div>
        <slot name="fourth" />
        <slot />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    ready: {
      type: Boolean,
      default: true,
    },
    groupflex: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.metrics {
  padding: 2rem 1.5rem;
  .card-deck {
    margin: 0;
    > :first-child {
      margin-left: 0;
    }
    > :last-child {
      margin-right: 0;
    }
  }
  .text-small {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
  :deep(.card-footer) {
    background: none;
    padding: 0.25rem !important;
    font-size: 0.625rem !important;
    text-align: center;
  }
  :deep(.kpi) {
    flex: 0 0 148px;
  }
  @media (min-width: 576px) {
    .card-group > :deep(.card):first-child {
      margin-right: 0;
    }
  }
}
</style>
