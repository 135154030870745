import * as types from './mutation-types'
import Pagination from '@/plugins/pagination'
import Axios from '@/plugins/axios'
import { toastInstance as toast } from '@/plugins/toast'

export default {
  set({ commit }, data) {
    commit(types.SET, data)
  },

  async load({ commit }, sysAdmin) {
    await commit(types.LOADING, true)
    await Pagination(
      `/categories`,
      Object.assign(
        {
          include: 'organizationsCount',
        },
        sysAdmin ? { sysAdmin: 1 } : null
      )
    )
      .then((response) => {
        commit(types.LOAD, response)
      })
      .catch((e) => {
        toast.error(e)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  async deleteCategory({ dispatch }, categoryId) {
    await Axios.delete(`/categories/${categoryId}`, {
      params: this.isSysAdmin
        ? {
            sysAdmin: 1,
          }
        : null,
    })
      .then(() => {
        dispatch('categories/load', null, { root: true }).then(() => {
          toast.success('Category Deleted')
        })
      })
      .catch((error) => {
        toast.error(error)
      })
  },

  clear({ commit }) {
    commit(types.LOAD, [])
  },

  refresh({ dispatch }) {
    dispatch('load')
  },
}
